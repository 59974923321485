@charset "UTF-8";
/**
 * Extra scss based on free items of Themesberg (https://www.themesberg.com) licensed under MIT.
 */
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0948B3;
  --bs-indigo: #4c5680;
  --bs-purple: #8965e0;
  --bs-pink: #C96480;
  --bs-red: #FA5252;
  --bs-orange: #FF9F89;
  --bs-yellow: #f5b759;
  --bs-green: #05A677;
  --bs-teal: #1E90FF;
  --bs-cyan: #63b1bd;
  --bs-white: #ffffff;
  --bs-gray: #93a5be;
  --bs-gray-dark: #506690;
  --bs-adobe: #ff9a00;
  --bs-bootstrap: #7C277D;
  --bs-python: #306998;
  --bs-react: #00d8ff;
  --bs-azure: #2892df;
  --bs-js: #EFD81F;
  --bs-swe: #FFCF3B;
  --bs-gray-100: #f3f7fa;
  --bs-gray-200: #f5f8fb;
  --bs-gray-300: #F0F3F6;
  --bs-gray-400: #eaedf2;
  --bs-gray-500: #d1d7e0;
  --bs-gray-600: #93a5be;
  --bs-gray-700: #66799e;
  --bs-gray-800: #506690;
  --bs-gray-900: #4A5073;
  --bs-primary: #262B40;
  --bs-secondary: #61DAFB;
  --bs-tertiary: #1B998B;
  --bs-quaternary: #C96480;
  --bs-primary-app: #EBF4F6;
  --bs-secondary-app: #424AA0;
  --bs-light: #eaedf2;
  --bs-lighten: #F0F3F6;
  --bs-success: #05A677;
  --bs-info: #0948B3;
  --bs-warning: #f5b759;
  --bs-danger: #FA5252;
  --bs-white: #ffffff;
  --bs-gray: #4A5073;
  --bs-indigo: #4c5680;
  --bs-dark: #262B40;
  --bs-soft: #f5f8fb;
  --bs-black: #2e3650;
  --bs-blue: #0948B3;
  --bs-pink: #C96480;
  --bs-cyan: #63b1bd;
  --bs-purple: #8965e0;
  --bs-soft-indigo: #f5e8ff;
  --bs-soft-green: #2CA58D;
  --bs-gray-100: #f3f7fa;
  --bs-gray-200: #f5f8fb;
  --bs-gray-300: #F0F3F6;
  --bs-gray-400: #eaedf2;
  --bs-gray-500: #d1d7e0;
  --bs-gray-600: #93a5be;
  --bs-gray-700: #66799e;
  --bs-gray-800: #506690;
  --bs-facebook: #3b5999;
  --bs-twitter: #1da1f2;
  --bs-google: #DB4337;
  --bs-instagram: #e4405f;
  --bs-pinterest: #bd081c;
  --bs-youtube: #cd201f;
  --bs-slack: #3aaf85;
  --bs-dribbble: #ea4c89;
  --bs-dropbox: #1E90FF;
  --bs-twitch: #4B367C;
  --bs-paypal: #ecb32c;
  --bs-behance: #0057ff;
  --bs-reddit: #E84422;
  --bs-github: #222222;
  --bs-adobe: #ff9a00;
  --bs-bootstrap: #7C277D;
  --bs-python: #306998;
  --bs-react: #00d8ff;
  --bs-azure: #2892df;
  --bs-js: #EFD81F;
  --bs-swe: #FFCF3B;
  --bs-primary-rgb: 38, 43, 64;
  --bs-secondary-rgb: 97, 218, 251;
  --bs-tertiary-rgb: 27, 153, 139;
  --bs-quaternary-rgb: 201, 100, 128;
  --bs-primary-app-rgb: 235, 244, 246;
  --bs-secondary-app-rgb: 66, 74, 160;
  --bs-light-rgb: 234, 237, 242;
  --bs-lighten-rgb: 240, 243, 246;
  --bs-success-rgb: 5, 166, 119;
  --bs-info-rgb: 9, 72, 179;
  --bs-warning-rgb: 245, 183, 89;
  --bs-danger-rgb: 250, 82, 82;
  --bs-white-rgb: 255, 255, 255;
  --bs-gray-rgb: 74, 80, 115;
  --bs-indigo-rgb: 76, 86, 128;
  --bs-dark-rgb: 38, 43, 64;
  --bs-soft-rgb: 245, 248, 251;
  --bs-black-rgb: 46, 54, 80;
  --bs-blue-rgb: 9, 72, 179;
  --bs-pink-rgb: 201, 100, 128;
  --bs-cyan-rgb: 99, 177, 189;
  --bs-purple-rgb: 137, 101, 224;
  --bs-soft-indigo-rgb: 245, 232, 255;
  --bs-soft-green-rgb: 44, 165, 141;
  --bs-gray-100-rgb: 243, 247, 250;
  --bs-gray-200-rgb: 245, 248, 251;
  --bs-gray-300-rgb: 240, 243, 246;
  --bs-gray-400-rgb: 234, 237, 242;
  --bs-gray-500-rgb: 209, 215, 224;
  --bs-gray-600-rgb: 147, 165, 190;
  --bs-gray-700-rgb: 102, 121, 158;
  --bs-gray-800-rgb: 80, 102, 144;
  --bs-facebook-rgb: 59, 89, 153;
  --bs-twitter-rgb: 29, 161, 242;
  --bs-google-rgb: 219, 67, 55;
  --bs-instagram-rgb: 228, 64, 95;
  --bs-pinterest-rgb: 189, 8, 28;
  --bs-youtube-rgb: 205, 32, 31;
  --bs-slack-rgb: 58, 175, 133;
  --bs-dribbble-rgb: 234, 76, 137;
  --bs-dropbox-rgb: 30, 144, 255;
  --bs-twitch-rgb: 75, 54, 124;
  --bs-paypal-rgb: 236, 179, 44;
  --bs-behance-rgb: 0, 87, 255;
  --bs-reddit-rgb: 232, 68, 34;
  --bs-github-rgb: 34, 34, 34;
  --bs-adobe-rgb: 255, 154, 0;
  --bs-bootstrap-rgb: 124, 39, 125;
  --bs-python-rgb: 48, 105, 152;
  --bs-react-rgb: 0, 216, 255;
  --bs-azure-rgb: 40, 146, 223;
  --bs-js-rgb: 239, 216, 31;
  --bs-swe-rgb: 255, 207, 59;
  --bs-primary-text-emphasis: #0f111a;
  --bs-secondary-text-emphasis: #275764;
  --bs-success-text-emphasis: #024230;
  --bs-info-text-emphasis: #041d48;
  --bs-warning-text-emphasis: #624924;
  --bs-danger-text-emphasis: #642121;
  --bs-light-text-emphasis: #66799e;
  --bs-dark-text-emphasis: #66799e;
  --bs-primary-bg-subtle: #d4d5d9;
  --bs-secondary-bg-subtle: #dff8fe;
  --bs-success-bg-subtle: #cdede4;
  --bs-info-bg-subtle: #cedaf0;
  --bs-warning-bg-subtle: #fdf1de;
  --bs-danger-bg-subtle: #fedcdc;
  --bs-light-bg-subtle: #f9fbfd;
  --bs-dark-bg-subtle: #eaedf2;
  --bs-primary-border-subtle: #a8aab3;
  --bs-secondary-border-subtle: #c0f0fd;
  --bs-success-border-subtle: #9bdbc9;
  --bs-info-border-subtle: #9db6e1;
  --bs-warning-border-subtle: #fbe2bd;
  --bs-danger-border-subtle: #fdbaba;
  --bs-light-border-subtle: #f5f8fb;
  --bs-dark-border-subtle: #d1d7e0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 46, 54, 80;
  --bs-font-sans-serif: "Nunito Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #4A5073;
  --bs-body-color-rgb: 74, 80, 115;
  --bs-body-bg: #f5f8fb;
  --bs-body-bg-rgb: 245, 248, 251;
  --bs-emphasis-color: #2e3650;
  --bs-emphasis-color-rgb: 46, 54, 80;
  --bs-secondary-color: rgba(74, 80, 115, 0.75);
  --bs-secondary-color-rgb: 74, 80, 115;
  --bs-secondary-bg: #f5f8fb;
  --bs-secondary-bg-rgb: 245, 248, 251;
  --bs-tertiary-color: rgba(74, 80, 115, 0.5);
  --bs-tertiary-color-rgb: 74, 80, 115;
  --bs-tertiary-bg: #f3f7fa;
  --bs-tertiary-bg-rgb: 243, 247, 250;
  --bs-heading-color: #262B40;
  --bs-link-color: #262B40;
  --bs-link-color-rgb: 38, 43, 64;
  --bs-link-decoration: none;
  --bs-link-hover-color: #0a0b10;
  --bs-link-hover-color-rgb: 10, 11, 16;
  --bs-code-color: #0948B3;
  --bs-highlight-color: #4A5073;
  --bs-highlight-bg: #fcf8e3;
  --bs-border-width: 0.0625rem;
  --bs-border-style: solid;
  --bs-border-color: #eaedf2;
  --bs-border-color-translucent: rgba(46, 54, 80, 0.175);
  --bs-border-radius: 0.5rem;
  --bs-border-radius-sm: 0.1rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 0.875rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 2rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  --bs-box-shadow-sm: 0 2px 18px rgba(0, 0, 0, 0.02);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(38, 43, 64, 0.25);
  --bs-form-valid-color: #05A677;
  --bs-form-valid-border-color: #05A677;
  --bs-form-invalid-color: #FA5252;
  --bs-form-invalid-border-color: #FA5252;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #F0F3F6;
  --bs-body-color-rgb: 240, 243, 246;
  --bs-body-bg: #4A5073;
  --bs-body-bg-rgb: 74, 80, 115;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(240, 243, 246, 0.75);
  --bs-secondary-color-rgb: 240, 243, 246;
  --bs-secondary-bg: #506690;
  --bs-secondary-bg-rgb: 80, 102, 144;
  --bs-tertiary-color: rgba(240, 243, 246, 0.5);
  --bs-tertiary-color-rgb: 240, 243, 246;
  --bs-tertiary-bg: #4d5b82;
  --bs-tertiary-bg-rgb: 77, 91, 130;
  --bs-primary-text-emphasis: #7d808c;
  --bs-secondary-text-emphasis: #a0e9fd;
  --bs-success-text-emphasis: #69caad;
  --bs-info-text-emphasis: #6b91d1;
  --bs-warning-text-emphasis: #f9d49b;
  --bs-danger-text-emphasis: #fc9797;
  --bs-light-text-emphasis: #f3f7fa;
  --bs-dark-text-emphasis: #F0F3F6;
  --bs-primary-bg-subtle: #08090d;
  --bs-secondary-bg-subtle: #132c32;
  --bs-success-bg-subtle: #012118;
  --bs-info-bg-subtle: #020e24;
  --bs-warning-bg-subtle: #312512;
  --bs-danger-bg-subtle: #321010;
  --bs-light-bg-subtle: #506690;
  --bs-dark-bg-subtle: #3f4e70;
  --bs-primary-border-subtle: #171a26;
  --bs-secondary-border-subtle: #3a8397;
  --bs-success-border-subtle: #036447;
  --bs-info-border-subtle: #052b6b;
  --bs-warning-border-subtle: #936e35;
  --bs-danger-border-subtle: #963131;
  --bs-light-border-subtle: #66799e;
  --bs-dark-border-subtle: #506690;
  --bs-heading-color: inherit;
  --bs-link-color: #7d808c;
  --bs-link-hover-color: #9799a3;
  --bs-link-color-rgb: 125, 128, 140;
  --bs-link-hover-color-rgb: 151, 153, 163;
  --bs-code-color: #6b91d1;
  --bs-highlight-color: #F0F3F6;
  --bs-highlight-bg: #e6ac54;
  --bs-border-color: #66799e;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #0faa7c;
  --bs-form-valid-border-color: #0faa7c;
  --bs-form-invalid-color: #fa5959;
  --bs-form-invalid-border-color: #fa5959;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(46, 54, 80, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #4A5073;
  border-radius: 0.1rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #58677d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.5rem;
  }
}

.display-3 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.5rem;
  }
}

.display-4 {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 1.875rem;
  }
}

.display-5 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3;
}

.display-6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #93a5be;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f8fb;
  border: 0.0625rem solid #F0F3F6;
  border-radius: 0.5rem;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #93a5be;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 8rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 8rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 10rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 10rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 11rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 11rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 14rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 14rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 16rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 16rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 20rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 20rem;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 1rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 1rem;
}

.g-md,
.gx-md {
  --bs-gutter-x: 2rem;
}

.g-md,
.gy-md {
  --bs-gutter-y: 2rem;
}

.g-lg,
.gx-lg {
  --bs-gutter-x: 4rem;
}

.g-lg,
.gy-lg {
  --bs-gutter-y: 4rem;
}

.g-xl,
.gx-xl {
  --bs-gutter-x: 8rem;
}

.g-xl,
.gy-xl {
  --bs-gutter-y: 8rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 8rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 11rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 11rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 14rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 14rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 16rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 16rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 20rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 20rem;
  }
  .g-sm-sm,
  .gx-sm-sm {
    --bs-gutter-x: 1rem;
  }
  .g-sm-sm,
  .gy-sm-sm {
    --bs-gutter-y: 1rem;
  }
  .g-sm-md,
  .gx-sm-md {
    --bs-gutter-x: 2rem;
  }
  .g-sm-md,
  .gy-sm-md {
    --bs-gutter-y: 2rem;
  }
  .g-sm-lg,
  .gx-sm-lg {
    --bs-gutter-x: 4rem;
  }
  .g-sm-lg,
  .gy-sm-lg {
    --bs-gutter-y: 4rem;
  }
  .g-sm-xl,
  .gx-sm-xl {
    --bs-gutter-x: 8rem;
  }
  .g-sm-xl,
  .gy-sm-xl {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 8rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 8rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 10rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 10rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 11rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 11rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 14rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 14rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 16rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 16rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 20rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 20rem;
  }
  .g-md-sm,
  .gx-md-sm {
    --bs-gutter-x: 1rem;
  }
  .g-md-sm,
  .gy-md-sm {
    --bs-gutter-y: 1rem;
  }
  .g-md-md,
  .gx-md-md {
    --bs-gutter-x: 2rem;
  }
  .g-md-md,
  .gy-md-md {
    --bs-gutter-y: 2rem;
  }
  .g-md-lg,
  .gx-md-lg {
    --bs-gutter-x: 4rem;
  }
  .g-md-lg,
  .gy-md-lg {
    --bs-gutter-y: 4rem;
  }
  .g-md-xl,
  .gx-md-xl {
    --bs-gutter-x: 8rem;
  }
  .g-md-xl,
  .gy-md-xl {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 8rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 11rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 11rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 14rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 14rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 16rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 16rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 20rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 20rem;
  }
  .g-lg-sm,
  .gx-lg-sm {
    --bs-gutter-x: 1rem;
  }
  .g-lg-sm,
  .gy-lg-sm {
    --bs-gutter-y: 1rem;
  }
  .g-lg-md,
  .gx-lg-md {
    --bs-gutter-x: 2rem;
  }
  .g-lg-md,
  .gy-lg-md {
    --bs-gutter-y: 2rem;
  }
  .g-lg-lg,
  .gx-lg-lg {
    --bs-gutter-x: 4rem;
  }
  .g-lg-lg,
  .gy-lg-lg {
    --bs-gutter-y: 4rem;
  }
  .g-lg-xl,
  .gx-lg-xl {
    --bs-gutter-x: 8rem;
  }
  .g-lg-xl,
  .gy-lg-xl {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 8rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 11rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 11rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 14rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 14rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 16rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 16rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 20rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 20rem;
  }
  .g-xl-sm,
  .gx-xl-sm {
    --bs-gutter-x: 1rem;
  }
  .g-xl-sm,
  .gy-xl-sm {
    --bs-gutter-y: 1rem;
  }
  .g-xl-md,
  .gx-xl-md {
    --bs-gutter-x: 2rem;
  }
  .g-xl-md,
  .gy-xl-md {
    --bs-gutter-y: 2rem;
  }
  .g-xl-lg,
  .gx-xl-lg {
    --bs-gutter-x: 4rem;
  }
  .g-xl-lg,
  .gy-xl-lg {
    --bs-gutter-y: 4rem;
  }
  .g-xl-xl,
  .gx-xl-xl {
    --bs-gutter-x: 8rem;
  }
  .g-xl-xl,
  .gy-xl-xl {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 11rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 11rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 14rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 14rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 16rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 16rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 20rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 20rem;
  }
  .g-xxl-sm,
  .gx-xxl-sm {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-sm,
  .gy-xxl-sm {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-md,
  .gx-xxl-md {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-md,
  .gy-xxl-md {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-lg,
  .gx-xxl-lg {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-lg,
  .gy-xxl-lg {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-xl,
  .gx-xxl-xl {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-xl,
  .gy-xxl-xl {
    --bs-gutter-y: 8rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #4A5073;
  --bs-table-bg: transparent;
  --bs-table-border-color: #eaedf2;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #4A5073;
  --bs-table-striped-bg: rgba(46, 54, 80, 0.05);
  --bs-table-active-color: #4A5073;
  --bs-table-active-bg: rgba(46, 54, 80, 0.1);
  --bs-table-hover-color: #4A5073;
  --bs-table-hover-bg: rgba(46, 54, 80, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 0.0625rem;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(0.0625rem * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0.0625rem 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 0.0625rem;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #262B40;
  --bs-table-border-color: #3c4053;
  --bs-table-striped-bg: #31364a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3c4053;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #363b4e;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #262B40;
  --bs-table-bg: #61DAFB;
  --bs-table-border-color: #5bc9e8;
  --bs-table-striped-bg: #5ed1f2;
  --bs-table-striped-color: #262B40;
  --bs-table-active-bg: #5bc9e8;
  --bs-table-active-color: #262B40;
  --bs-table-hover-bg: #5dcded;
  --bs-table-hover-color: #262B40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #ffffff;
  --bs-table-bg: #05A677;
  --bs-table-border-color: #1eaf85;
  --bs-table-striped-bg: #12aa7e;
  --bs-table-striped-color: #262B40;
  --bs-table-active-bg: #1eaf85;
  --bs-table-active-color: #262B40;
  --bs-table-hover-bg: #18ad81;
  --bs-table-hover-color: #262B40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #ffffff;
  --bs-table-bg: #0948B3;
  --bs-table-border-color: #225abb;
  --bs-table-striped-bg: #1551b7;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #225abb;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #1b56b9;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #262B40;
  --bs-table-bg: #f5b759;
  --bs-table-border-color: #e0a957;
  --bs-table-striped-bg: #ebb058;
  --bs-table-striped-color: #262B40;
  --bs-table-active-bg: #e0a957;
  --bs-table-active-color: #262B40;
  --bs-table-hover-bg: #e5ad57;
  --bs-table-hover-color: #262B40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #ffffff;
  --bs-table-bg: #FA5252;
  --bs-table-border-color: #fb6363;
  --bs-table-striped-bg: #fa5b5b;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #fb6363;
  --bs-table-active-color: #262B40;
  --bs-table-hover-bg: #fa5f5f;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #262B40;
  --bs-table-bg: #eaedf2;
  --bs-table-border-color: #d6dae0;
  --bs-table-striped-bg: #e0e3e9;
  --bs-table-striped-color: #262B40;
  --bs-table-active-bg: #d6dae0;
  --bs-table-active-color: #262B40;
  --bs-table-hover-bg: #dbdee5;
  --bs-table-hover-color: #262B40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #262B40;
  --bs-table-border-color: #3c4053;
  --bs-table-striped-bg: #31364a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3c4053;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #363b4e;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: 0.6125rem;
  padding-bottom: 0.6125rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #58677d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #66799e;
  background-color: #ffffff;
  border-color: #566190;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: #506690;
  opacity: 1;
}
.form-control::placeholder {
  color: #506690;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f5f8fb;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #66799e;
  background-color: #ffffff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.55rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #4A5073;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.625rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.1rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.125rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.225rem);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.625rem);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.125rem);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.225rem);
  padding: 0.55rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.625rem);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.125rem);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.55rem 1rem 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #566190;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #93a5be;
  background-color: #f5f8fb;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #66799e;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.1rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F0F3F6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.625em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.625em;
}

.form-check-reverse {
  padding-right: 1.625em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.625em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #f5f8fb;
  flex-shrink: 0;
  width: 1.125em;
  height: 1.125em;
  margin-top: 0.1875em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #d1d7e0;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  transition: background-color 0.2s ease-in-out, background-position 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #566190;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.form-check-input:checked {
  background-color: #262B40;
  border-color: #262B40;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #262B40;
  border-color: #262B40;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.75em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2393a5be'/%3e%3c/svg%3e");
  width: 2.25em;
  margin-left: -2.75em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2.25em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23566190'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.75em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.75em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f8fb, 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f8fb, 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #262B40;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #6f7aaa;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #F0F3F6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
       appearance: none;
  background-color: #262B40;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #6f7aaa;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #F0F3F6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #d1d7e0;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #d1d7e0;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 3.625rem;
  min-height: 3.625rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 0.0625rem solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 0.0625rem 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #93a5be;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: #f5f8fb;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #93a5be;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.1rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(0.0625rem * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #05A677;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.55rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(5, 166, 119, 0.99);
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #05A677;
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #05A677;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #05A677;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: calc(0.75em + 2.575rem);
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #05A677;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.1rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #05A677;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #05A677;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #05A677;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FA5252;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.55rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(250, 82, 82, 0.99);
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FA5252;
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #FA5252;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #FA5252;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
  padding-right: calc(0.75em + 2.575rem);
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #FA5252;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.1rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #FA5252;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #FA5252;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FA5252;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0.0625rem;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #262B40;
  --bs-btn-border-color: #262B40;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #202536;
  --bs-btn-hover-border-color: #1e2233;
  --bs-btn-focus-shadow-rgb: 71, 75, 93;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1e2233;
  --bs-btn-active-border-color: #1d2030;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #262B40;
  --bs-btn-disabled-border-color: #262B40;
}

.btn-secondary {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #61DAFB;
  --bs-btn-border-color: #61DAFB;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #79e0fc;
  --bs-btn-hover-border-color: #71defb;
  --bs-btn-focus-shadow-rgb: 88, 192, 223;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #81e1fc;
  --bs-btn-active-border-color: #71defb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #61DAFB;
  --bs-btn-disabled-border-color: #61DAFB;
}

.btn-tertiary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1B998B;
  --bs-btn-border-color: #1B998B;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #178276;
  --bs-btn-hover-border-color: #167a6f;
  --bs-btn-focus-shadow-rgb: 61, 168, 156;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #167a6f;
  --bs-btn-active-border-color: #147368;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1B998B;
  --bs-btn-disabled-border-color: #1B998B;
}

.btn-quaternary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #C96480;
  --bs-btn-border-color: #C96480;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ab556d;
  --bs-btn-hover-border-color: #a15066;
  --bs-btn-focus-shadow-rgb: 209, 123, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a15066;
  --bs-btn-active-border-color: #974b60;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #C96480;
  --bs-btn-disabled-border-color: #C96480;
}

.btn-primary-app {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #EBF4F6;
  --bs-btn-border-color: #EBF4F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #eef6f7;
  --bs-btn-hover-border-color: #edf5f7;
  --bs-btn-focus-shadow-rgb: 205, 214, 219;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #eff6f8;
  --bs-btn-active-border-color: #edf5f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #EBF4F6;
  --bs-btn-disabled-border-color: #EBF4F6;
}

.btn-secondary-app {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #424AA0;
  --bs-btn-border-color: #424AA0;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #383f88;
  --bs-btn-hover-border-color: #353b80;
  --bs-btn-focus-shadow-rgb: 94, 101, 174;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #353b80;
  --bs-btn-active-border-color: #323878;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #424AA0;
  --bs-btn-disabled-border-color: #424AA0;
}

.btn-light {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #eaedf2;
  --bs-btn-border-color: #eaedf2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #c7c9ce;
  --bs-btn-hover-border-color: #bbbec2;
  --bs-btn-focus-shadow-rgb: 205, 208, 215;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #bbbec2;
  --bs-btn-active-border-color: #b0b2b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #eaedf2;
  --bs-btn-disabled-border-color: #eaedf2;
}

.btn-lighten {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #F0F3F6;
  --bs-btn-border-color: #F0F3F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f2f5f7;
  --bs-btn-hover-border-color: #f2f4f7;
  --bs-btn-focus-shadow-rgb: 210, 213, 219;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f3f5f8;
  --bs-btn-active-border-color: #f2f4f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #F0F3F6;
  --bs-btn-disabled-border-color: #F0F3F6;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #05A677;
  --bs-btn-border-color: #05A677;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #048d65;
  --bs-btn-hover-border-color: #04855f;
  --bs-btn-focus-shadow-rgb: 43, 179, 139;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #04855f;
  --bs-btn-active-border-color: #047d59;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #05A677;
  --bs-btn-disabled-border-color: #05A677;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0948B3;
  --bs-btn-border-color: #0948B3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #083d98;
  --bs-btn-hover-border-color: #073a8f;
  --bs-btn-focus-shadow-rgb: 46, 99, 190;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #073a8f;
  --bs-btn-active-border-color: #073686;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0948B3;
  --bs-btn-disabled-border-color: #0948B3;
}

.btn-warning {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #f5b759;
  --bs-btn-border-color: #f5b759;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f7c272;
  --bs-btn-hover-border-color: #f6be6a;
  --bs-btn-focus-shadow-rgb: 214, 162, 85;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f7c57a;
  --bs-btn-active-border-color: #f6be6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #f5b759;
  --bs-btn-disabled-border-color: #f5b759;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #FA5252;
  --bs-btn-border-color: #FA5252;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d54646;
  --bs-btn-hover-border-color: #c84242;
  --bs-btn-focus-shadow-rgb: 251, 108, 108;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c84242;
  --bs-btn-active-border-color: #bc3e3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #FA5252;
  --bs-btn-disabled-border-color: #FA5252;
}

.btn-white {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 222, 223, 226;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-gray {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4A5073;
  --bs-btn-border-color: #4A5073;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3f4462;
  --bs-btn-hover-border-color: #3b405c;
  --bs-btn-focus-shadow-rgb: 101, 106, 136;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3b405c;
  --bs-btn-active-border-color: #383c56;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4A5073;
  --bs-btn-disabled-border-color: #4A5073;
}

.btn-indigo {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4c5680;
  --bs-btn-border-color: #4c5680;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #41496d;
  --bs-btn-hover-border-color: #3d4566;
  --bs-btn-focus-shadow-rgb: 103, 111, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3d4566;
  --bs-btn-active-border-color: #394160;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4c5680;
  --bs-btn-disabled-border-color: #4c5680;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #262B40;
  --bs-btn-border-color: #262B40;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #474b5d;
  --bs-btn-hover-border-color: #3c4053;
  --bs-btn-focus-shadow-rgb: 71, 75, 93;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #515566;
  --bs-btn-active-border-color: #3c4053;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #262B40;
  --bs-btn-disabled-border-color: #262B40;
}

.btn-soft {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #f5f8fb;
  --bs-btn-border-color: #f5f8fb;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f7f9fc;
  --bs-btn-hover-border-color: #f6f9fb;
  --bs-btn-focus-shadow-rgb: 214, 217, 223;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f7f9fc;
  --bs-btn-active-border-color: #f6f9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #f5f8fb;
  --bs-btn-disabled-border-color: #f5f8fb;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2e3650;
  --bs-btn-border-color: #2e3650;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #272e44;
  --bs-btn-hover-border-color: #252b40;
  --bs-btn-focus-shadow-rgb: 77, 84, 106;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #252b40;
  --bs-btn-active-border-color: #23293c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2e3650;
  --bs-btn-disabled-border-color: #2e3650;
}

.btn-blue {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0948B3;
  --bs-btn-border-color: #0948B3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #083d98;
  --bs-btn-hover-border-color: #073a8f;
  --bs-btn-focus-shadow-rgb: 46, 99, 190;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #073a8f;
  --bs-btn-active-border-color: #073686;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0948B3;
  --bs-btn-disabled-border-color: #0948B3;
}

.btn-pink {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #C96480;
  --bs-btn-border-color: #C96480;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ab556d;
  --bs-btn-hover-border-color: #a15066;
  --bs-btn-focus-shadow-rgb: 209, 123, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a15066;
  --bs-btn-active-border-color: #974b60;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #C96480;
  --bs-btn-disabled-border-color: #C96480;
}

.btn-cyan {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #63b1bd;
  --bs-btn-border-color: #63b1bd;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #7abdc7;
  --bs-btn-hover-border-color: #73b9c4;
  --bs-btn-focus-shadow-rgb: 90, 157, 170;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #82c1ca;
  --bs-btn-active-border-color: #73b9c4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #63b1bd;
  --bs-btn-disabled-border-color: #63b1bd;
}

.btn-purple {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #8965e0;
  --bs-btn-border-color: #8965e0;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #7456be;
  --bs-btn-hover-border-color: #6e51b3;
  --bs-btn-focus-shadow-rgb: 155, 124, 229;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6e51b3;
  --bs-btn-active-border-color: #674ca8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #8965e0;
  --bs-btn-disabled-border-color: #8965e0;
}

.btn-soft-indigo {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #f5e8ff;
  --bs-btn-border-color: #f5e8ff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f7ebff;
  --bs-btn-hover-border-color: #f6eaff;
  --bs-btn-focus-shadow-rgb: 214, 204, 226;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f7edff;
  --bs-btn-active-border-color: #f6eaff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #f5e8ff;
  --bs-btn-disabled-border-color: #f5e8ff;
}

.btn-soft-green {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2CA58D;
  --bs-btn-border-color: #2CA58D;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #258c78;
  --bs-btn-hover-border-color: #238471;
  --bs-btn-focus-shadow-rgb: 76, 179, 158;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #238471;
  --bs-btn-active-border-color: #217c6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2CA58D;
  --bs-btn-disabled-border-color: #2CA58D;
}

.btn-gray-100 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #f3f7fa;
  --bs-btn-border-color: #f3f7fa;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f5f8fb;
  --bs-btn-hover-border-color: #f4f8fb;
  --bs-btn-focus-shadow-rgb: 212, 216, 222;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f5f9fb;
  --bs-btn-active-border-color: #f4f8fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #f3f7fa;
  --bs-btn-disabled-border-color: #f3f7fa;
}

.btn-gray-200 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #f5f8fb;
  --bs-btn-border-color: #f5f8fb;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f7f9fc;
  --bs-btn-hover-border-color: #f6f9fb;
  --bs-btn-focus-shadow-rgb: 214, 217, 223;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f7f9fc;
  --bs-btn-active-border-color: #f6f9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #f5f8fb;
  --bs-btn-disabled-border-color: #f5f8fb;
}

.btn-gray-300 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #F0F3F6;
  --bs-btn-border-color: #F0F3F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f2f5f7;
  --bs-btn-hover-border-color: #f2f4f7;
  --bs-btn-focus-shadow-rgb: 210, 213, 219;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f3f5f8;
  --bs-btn-active-border-color: #f2f4f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #F0F3F6;
  --bs-btn-disabled-border-color: #F0F3F6;
}

.btn-gray-400 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #eaedf2;
  --bs-btn-border-color: #eaedf2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #edf0f4;
  --bs-btn-hover-border-color: #eceff3;
  --bs-btn-focus-shadow-rgb: 205, 208, 215;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #eef1f5;
  --bs-btn-active-border-color: #eceff3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #eaedf2;
  --bs-btn-disabled-border-color: #eaedf2;
}

.btn-gray-500 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #d1d7e0;
  --bs-btn-border-color: #d1d7e0;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #d8dde5;
  --bs-btn-hover-border-color: #d6dbe3;
  --bs-btn-focus-shadow-rgb: 183, 189, 200;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #dadfe6;
  --bs-btn-active-border-color: #d6dbe3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #d1d7e0;
  --bs-btn-disabled-border-color: #d1d7e0;
}

.btn-gray-600 {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #93a5be;
  --bs-btn-border-color: #93a5be;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #a3b3c8;
  --bs-btn-hover-border-color: #9eaec5;
  --bs-btn-focus-shadow-rgb: 131, 147, 171;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #a9b7cb;
  --bs-btn-active-border-color: #9eaec5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #93a5be;
  --bs-btn-disabled-border-color: #93a5be;
}

.btn-gray-700 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #66799e;
  --bs-btn-border-color: #66799e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #576786;
  --bs-btn-hover-border-color: #52617e;
  --bs-btn-focus-shadow-rgb: 125, 141, 173;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #52617e;
  --bs-btn-active-border-color: #4d5b77;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #66799e;
  --bs-btn-disabled-border-color: #66799e;
}

.btn-gray-800 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #506690;
  --bs-btn-border-color: #506690;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #44577a;
  --bs-btn-hover-border-color: #405273;
  --bs-btn-focus-shadow-rgb: 106, 125, 161;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #405273;
  --bs-btn-active-border-color: #3c4d6c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #506690;
  --bs-btn-disabled-border-color: #506690;
}

.btn-facebook {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3b5999;
  --bs-btn-border-color: #3b5999;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #324c82;
  --bs-btn-hover-border-color: #2f477a;
  --bs-btn-focus-shadow-rgb: 88, 114, 168;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2f477a;
  --bs-btn-active-border-color: #2c4373;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3b5999;
  --bs-btn-disabled-border-color: #3b5999;
}

.btn-twitter {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #1da1f2;
  --bs-btn-border-color: #1da1f2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #3faff4;
  --bs-btn-hover-border-color: #34aaf3;
  --bs-btn-focus-shadow-rgb: 30, 143, 215;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #4ab4f5;
  --bs-btn-active-border-color: #34aaf3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #1da1f2;
  --bs-btn-disabled-border-color: #1da1f2;
}

.btn-google {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #DB4337;
  --bs-btn-border-color: #DB4337;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ba392f;
  --bs-btn-hover-border-color: #af362c;
  --bs-btn-focus-shadow-rgb: 224, 95, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #af362c;
  --bs-btn-active-border-color: #a43229;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #DB4337;
  --bs-btn-disabled-border-color: #DB4337;
}

.btn-instagram {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e4405f;
  --bs-btn-border-color: #e4405f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c23651;
  --bs-btn-hover-border-color: #b6334c;
  --bs-btn-focus-shadow-rgb: 232, 93, 119;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b6334c;
  --bs-btn-active-border-color: #ab3047;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e4405f;
  --bs-btn-disabled-border-color: #e4405f;
}

.btn-pinterest {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #bd081c;
  --bs-btn-border-color: #bd081c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #a10718;
  --bs-btn-hover-border-color: #970616;
  --bs-btn-focus-shadow-rgb: 199, 45, 62;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #970616;
  --bs-btn-active-border-color: #8e0615;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #bd081c;
  --bs-btn-disabled-border-color: #bd081c;
}

.btn-youtube {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #cd201f;
  --bs-btn-border-color: #cd201f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ae1b1a;
  --bs-btn-hover-border-color: #a41a19;
  --bs-btn-focus-shadow-rgb: 213, 65, 65;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a41a19;
  --bs-btn-active-border-color: #9a1817;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #cd201f;
  --bs-btn-disabled-border-color: #cd201f;
}

.btn-slack {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #3aaf85;
  --bs-btn-border-color: #3aaf85;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #58bb97;
  --bs-btn-hover-border-color: #4eb791;
  --bs-btn-focus-shadow-rgb: 55, 155, 123;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #61bf9d;
  --bs-btn-active-border-color: #4eb791;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #3aaf85;
  --bs-btn-disabled-border-color: #3aaf85;
}

.btn-dribbble {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ea4c89;
  --bs-btn-border-color: #ea4c89;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c74174;
  --bs-btn-hover-border-color: #bb3d6e;
  --bs-btn-focus-shadow-rgb: 237, 103, 155;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #bb3d6e;
  --bs-btn-active-border-color: #b03967;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ea4c89;
  --bs-btn-disabled-border-color: #ea4c89;
}

.btn-dropbox {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1E90FF;
  --bs-btn-border-color: #1E90FF;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1a7ad9;
  --bs-btn-hover-border-color: #1873cc;
  --bs-btn-focus-shadow-rgb: 64, 161, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1873cc;
  --bs-btn-active-border-color: #176cbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1E90FF;
  --bs-btn-disabled-border-color: #1E90FF;
}

.btn-twitch {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4B367C;
  --bs-btn-border-color: #4B367C;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #402e69;
  --bs-btn-hover-border-color: #3c2b63;
  --bs-btn-focus-shadow-rgb: 102, 84, 144;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3c2b63;
  --bs-btn-active-border-color: #38295d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4B367C;
  --bs-btn-disabled-border-color: #4B367C;
}

.btn-paypal {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #ecb32c;
  --bs-btn-border-color: #ecb32c;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #efbe4c;
  --bs-btn-hover-border-color: #eebb41;
  --bs-btn-focus-shadow-rgb: 206, 159, 47;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f0c256;
  --bs-btn-active-border-color: #eebb41;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #ecb32c;
  --bs-btn-disabled-border-color: #ecb32c;
}

.btn-behance {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0057ff;
  --bs-btn-border-color: #0057ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #004ad9;
  --bs-btn-hover-border-color: #0046cc;
  --bs-btn-focus-shadow-rgb: 38, 112, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0046cc;
  --bs-btn-active-border-color: #0041bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0057ff;
  --bs-btn-disabled-border-color: #0057ff;
}

.btn-reddit {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #E84422;
  --bs-btn-border-color: #E84422;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c53a1d;
  --bs-btn-hover-border-color: #ba361b;
  --bs-btn-focus-shadow-rgb: 235, 96, 67;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ba361b;
  --bs-btn-active-border-color: #ae331a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #E84422;
  --bs-btn-disabled-border-color: #E84422;
}

.btn-github {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1d1d1d;
  --bs-btn-hover-border-color: #1b1b1b;
  --bs-btn-focus-shadow-rgb: 67, 67, 67;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b1b1b;
  --bs-btn-active-border-color: #1a1a1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #222222;
  --bs-btn-disabled-border-color: #222222;
}

.btn-adobe {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #ff9a00;
  --bs-btn-border-color: #ff9a00;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #ffa926;
  --bs-btn-hover-border-color: #ffa41a;
  --bs-btn-focus-shadow-rgb: 222, 137, 10;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #ffae33;
  --bs-btn-active-border-color: #ffa41a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #ff9a00;
  --bs-btn-disabled-border-color: #ff9a00;
}

.btn-bootstrap {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #7C277D;
  --bs-btn-border-color: #7C277D;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #69216a;
  --bs-btn-hover-border-color: #631f64;
  --bs-btn-focus-shadow-rgb: 144, 71, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #631f64;
  --bs-btn-active-border-color: #5d1d5e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #7C277D;
  --bs-btn-disabled-border-color: #7C277D;
}

.btn-python {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #306998;
  --bs-btn-border-color: #306998;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #295981;
  --bs-btn-hover-border-color: #26547a;
  --bs-btn-focus-shadow-rgb: 79, 128, 167;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #26547a;
  --bs-btn-active-border-color: #244f72;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #306998;
  --bs-btn-disabled-border-color: #306998;
}

.btn-react {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #00d8ff;
  --bs-btn-border-color: #00d8ff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #26deff;
  --bs-btn-hover-border-color: #1adcff;
  --bs-btn-focus-shadow-rgb: 6, 190, 226;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #33e0ff;
  --bs-btn-active-border-color: #1adcff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #00d8ff;
  --bs-btn-disabled-border-color: #00d8ff;
}

.btn-azure {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2892df;
  --bs-btn-border-color: #2892df;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #227cbe;
  --bs-btn-hover-border-color: #2075b2;
  --bs-btn-focus-shadow-rgb: 72, 162, 228;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2075b2;
  --bs-btn-active-border-color: #1e6ea7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2892df;
  --bs-btn-disabled-border-color: #2892df;
}

.btn-js {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #EFD81F;
  --bs-btn-border-color: #EFD81F;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f1de41;
  --bs-btn-hover-border-color: #f1dc35;
  --bs-btn-focus-shadow-rgb: 209, 190, 36;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f2e04c;
  --bs-btn-active-border-color: #f1dc35;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #EFD81F;
  --bs-btn-disabled-border-color: #EFD81F;
}

.btn-swe {
  --bs-btn-color: #262B40;
  --bs-btn-bg: #FFCF3B;
  --bs-btn-border-color: #FFCF3B;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #ffd658;
  --bs-btn-hover-border-color: #ffd44f;
  --bs-btn-focus-shadow-rgb: 222, 182, 60;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #ffd962;
  --bs-btn-active-border-color: #ffd44f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: #FFCF3B;
  --bs-btn-disabled-border-color: #FFCF3B;
}

.btn-outline-primary {
  --bs-btn-color: #262B40;
  --bs-btn-border-color: #262B40;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #262B40;
  --bs-btn-hover-border-color: #262B40;
  --bs-btn-focus-shadow-rgb: 38, 43, 64;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #262B40;
  --bs-btn-active-border-color: #262B40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #262B40;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #61DAFB;
  --bs-btn-border-color: #61DAFB;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #61DAFB;
  --bs-btn-hover-border-color: #61DAFB;
  --bs-btn-focus-shadow-rgb: 97, 218, 251;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #61DAFB;
  --bs-btn-active-border-color: #61DAFB;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #61DAFB;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #61DAFB;
  --bs-gradient: none;
}

.btn-outline-tertiary {
  --bs-btn-color: #1B998B;
  --bs-btn-border-color: #1B998B;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1B998B;
  --bs-btn-hover-border-color: #1B998B;
  --bs-btn-focus-shadow-rgb: 27, 153, 139;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1B998B;
  --bs-btn-active-border-color: #1B998B;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #1B998B;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1B998B;
  --bs-gradient: none;
}

.btn-outline-quaternary {
  --bs-btn-color: #C96480;
  --bs-btn-border-color: #C96480;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #C96480;
  --bs-btn-hover-border-color: #C96480;
  --bs-btn-focus-shadow-rgb: 201, 100, 128;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #C96480;
  --bs-btn-active-border-color: #C96480;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #C96480;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C96480;
  --bs-gradient: none;
}

.btn-outline-primary-app {
  --bs-btn-color: #EBF4F6;
  --bs-btn-border-color: #EBF4F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #EBF4F6;
  --bs-btn-hover-border-color: #EBF4F6;
  --bs-btn-focus-shadow-rgb: 235, 244, 246;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #EBF4F6;
  --bs-btn-active-border-color: #EBF4F6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #EBF4F6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EBF4F6;
  --bs-gradient: none;
}

.btn-outline-secondary-app {
  --bs-btn-color: #424AA0;
  --bs-btn-border-color: #424AA0;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424AA0;
  --bs-btn-hover-border-color: #424AA0;
  --bs-btn-focus-shadow-rgb: 66, 74, 160;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #424AA0;
  --bs-btn-active-border-color: #424AA0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #424AA0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #424AA0;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #eaedf2;
  --bs-btn-border-color: #eaedf2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #eaedf2;
  --bs-btn-hover-border-color: #eaedf2;
  --bs-btn-focus-shadow-rgb: 234, 237, 242;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #eaedf2;
  --bs-btn-active-border-color: #eaedf2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #eaedf2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eaedf2;
  --bs-gradient: none;
}

.btn-outline-lighten {
  --bs-btn-color: #F0F3F6;
  --bs-btn-border-color: #F0F3F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #F0F3F6;
  --bs-btn-hover-border-color: #F0F3F6;
  --bs-btn-focus-shadow-rgb: 240, 243, 246;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #F0F3F6;
  --bs-btn-active-border-color: #F0F3F6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #F0F3F6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F0F3F6;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #05A677;
  --bs-btn-border-color: #05A677;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #05A677;
  --bs-btn-hover-border-color: #05A677;
  --bs-btn-focus-shadow-rgb: 5, 166, 119;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #05A677;
  --bs-btn-active-border-color: #05A677;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #05A677;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #05A677;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0948B3;
  --bs-btn-border-color: #0948B3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0948B3;
  --bs-btn-hover-border-color: #0948B3;
  --bs-btn-focus-shadow-rgb: 9, 72, 179;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0948B3;
  --bs-btn-active-border-color: #0948B3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #0948B3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0948B3;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #f5b759;
  --bs-btn-border-color: #f5b759;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f5b759;
  --bs-btn-hover-border-color: #f5b759;
  --bs-btn-focus-shadow-rgb: 245, 183, 89;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f5b759;
  --bs-btn-active-border-color: #f5b759;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #f5b759;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5b759;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #FA5252;
  --bs-btn-border-color: #FA5252;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #FA5252;
  --bs-btn-hover-border-color: #FA5252;
  --bs-btn-focus-shadow-rgb: 250, 82, 82;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #FA5252;
  --bs-btn-active-border-color: #FA5252;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #FA5252;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FA5252;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #4A5073;
  --bs-btn-border-color: #4A5073;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4A5073;
  --bs-btn-hover-border-color: #4A5073;
  --bs-btn-focus-shadow-rgb: 74, 80, 115;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4A5073;
  --bs-btn-active-border-color: #4A5073;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #4A5073;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4A5073;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #4c5680;
  --bs-btn-border-color: #4c5680;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4c5680;
  --bs-btn-hover-border-color: #4c5680;
  --bs-btn-focus-shadow-rgb: 76, 86, 128;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4c5680;
  --bs-btn-active-border-color: #4c5680;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #4c5680;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4c5680;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #262B40;
  --bs-btn-border-color: #262B40;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #262B40;
  --bs-btn-hover-border-color: #262B40;
  --bs-btn-focus-shadow-rgb: 38, 43, 64;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #262B40;
  --bs-btn-active-border-color: #262B40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #262B40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #262B40;
  --bs-gradient: none;
}

.btn-outline-soft {
  --bs-btn-color: #f5f8fb;
  --bs-btn-border-color: #f5f8fb;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f5f8fb;
  --bs-btn-hover-border-color: #f5f8fb;
  --bs-btn-focus-shadow-rgb: 245, 248, 251;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f5f8fb;
  --bs-btn-active-border-color: #f5f8fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #f5f8fb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f8fb;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #2e3650;
  --bs-btn-border-color: #2e3650;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2e3650;
  --bs-btn-hover-border-color: #2e3650;
  --bs-btn-focus-shadow-rgb: 46, 54, 80;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2e3650;
  --bs-btn-active-border-color: #2e3650;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #2e3650;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2e3650;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #0948B3;
  --bs-btn-border-color: #0948B3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0948B3;
  --bs-btn-hover-border-color: #0948B3;
  --bs-btn-focus-shadow-rgb: 9, 72, 179;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0948B3;
  --bs-btn-active-border-color: #0948B3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #0948B3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0948B3;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #C96480;
  --bs-btn-border-color: #C96480;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #C96480;
  --bs-btn-hover-border-color: #C96480;
  --bs-btn-focus-shadow-rgb: 201, 100, 128;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #C96480;
  --bs-btn-active-border-color: #C96480;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #C96480;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C96480;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #63b1bd;
  --bs-btn-border-color: #63b1bd;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #63b1bd;
  --bs-btn-hover-border-color: #63b1bd;
  --bs-btn-focus-shadow-rgb: 99, 177, 189;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #63b1bd;
  --bs-btn-active-border-color: #63b1bd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #63b1bd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #63b1bd;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #8965e0;
  --bs-btn-border-color: #8965e0;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #8965e0;
  --bs-btn-hover-border-color: #8965e0;
  --bs-btn-focus-shadow-rgb: 137, 101, 224;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #8965e0;
  --bs-btn-active-border-color: #8965e0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #8965e0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8965e0;
  --bs-gradient: none;
}

.btn-outline-soft-indigo {
  --bs-btn-color: #f5e8ff;
  --bs-btn-border-color: #f5e8ff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f5e8ff;
  --bs-btn-hover-border-color: #f5e8ff;
  --bs-btn-focus-shadow-rgb: 245, 232, 255;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f5e8ff;
  --bs-btn-active-border-color: #f5e8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #f5e8ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5e8ff;
  --bs-gradient: none;
}

.btn-outline-soft-green {
  --bs-btn-color: #2CA58D;
  --bs-btn-border-color: #2CA58D;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2CA58D;
  --bs-btn-hover-border-color: #2CA58D;
  --bs-btn-focus-shadow-rgb: 44, 165, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2CA58D;
  --bs-btn-active-border-color: #2CA58D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #2CA58D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2CA58D;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #f3f7fa;
  --bs-btn-border-color: #f3f7fa;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f3f7fa;
  --bs-btn-hover-border-color: #f3f7fa;
  --bs-btn-focus-shadow-rgb: 243, 247, 250;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f3f7fa;
  --bs-btn-active-border-color: #f3f7fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #f3f7fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f3f7fa;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #f5f8fb;
  --bs-btn-border-color: #f5f8fb;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #f5f8fb;
  --bs-btn-hover-border-color: #f5f8fb;
  --bs-btn-focus-shadow-rgb: 245, 248, 251;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #f5f8fb;
  --bs-btn-active-border-color: #f5f8fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #f5f8fb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f8fb;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #F0F3F6;
  --bs-btn-border-color: #F0F3F6;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #F0F3F6;
  --bs-btn-hover-border-color: #F0F3F6;
  --bs-btn-focus-shadow-rgb: 240, 243, 246;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #F0F3F6;
  --bs-btn-active-border-color: #F0F3F6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #F0F3F6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F0F3F6;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #eaedf2;
  --bs-btn-border-color: #eaedf2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #eaedf2;
  --bs-btn-hover-border-color: #eaedf2;
  --bs-btn-focus-shadow-rgb: 234, 237, 242;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #eaedf2;
  --bs-btn-active-border-color: #eaedf2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #eaedf2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eaedf2;
  --bs-gradient: none;
}

.btn-outline-gray-500 {
  --bs-btn-color: #d1d7e0;
  --bs-btn-border-color: #d1d7e0;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #d1d7e0;
  --bs-btn-hover-border-color: #d1d7e0;
  --bs-btn-focus-shadow-rgb: 209, 215, 224;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #d1d7e0;
  --bs-btn-active-border-color: #d1d7e0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #d1d7e0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d1d7e0;
  --bs-gradient: none;
}

.btn-outline-gray-600 {
  --bs-btn-color: #93a5be;
  --bs-btn-border-color: #93a5be;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #93a5be;
  --bs-btn-hover-border-color: #93a5be;
  --bs-btn-focus-shadow-rgb: 147, 165, 190;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #93a5be;
  --bs-btn-active-border-color: #93a5be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #93a5be;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #93a5be;
  --bs-gradient: none;
}

.btn-outline-gray-700 {
  --bs-btn-color: #66799e;
  --bs-btn-border-color: #66799e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #66799e;
  --bs-btn-hover-border-color: #66799e;
  --bs-btn-focus-shadow-rgb: 102, 121, 158;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #66799e;
  --bs-btn-active-border-color: #66799e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #66799e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66799e;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #506690;
  --bs-btn-border-color: #506690;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #506690;
  --bs-btn-hover-border-color: #506690;
  --bs-btn-focus-shadow-rgb: 80, 102, 144;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #506690;
  --bs-btn-active-border-color: #506690;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #506690;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #506690;
  --bs-gradient: none;
}

.btn-outline-facebook {
  --bs-btn-color: #3b5999;
  --bs-btn-border-color: #3b5999;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3b5999;
  --bs-btn-hover-border-color: #3b5999;
  --bs-btn-focus-shadow-rgb: 59, 89, 153;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3b5999;
  --bs-btn-active-border-color: #3b5999;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #3b5999;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b5999;
  --bs-gradient: none;
}

.btn-outline-twitter {
  --bs-btn-color: #1da1f2;
  --bs-btn-border-color: #1da1f2;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #1da1f2;
  --bs-btn-hover-border-color: #1da1f2;
  --bs-btn-focus-shadow-rgb: 29, 161, 242;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #1da1f2;
  --bs-btn-active-border-color: #1da1f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #1da1f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1da1f2;
  --bs-gradient: none;
}

.btn-outline-google {
  --bs-btn-color: #DB4337;
  --bs-btn-border-color: #DB4337;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #DB4337;
  --bs-btn-hover-border-color: #DB4337;
  --bs-btn-focus-shadow-rgb: 219, 67, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #DB4337;
  --bs-btn-active-border-color: #DB4337;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #DB4337;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DB4337;
  --bs-gradient: none;
}

.btn-outline-instagram {
  --bs-btn-color: #e4405f;
  --bs-btn-border-color: #e4405f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e4405f;
  --bs-btn-hover-border-color: #e4405f;
  --bs-btn-focus-shadow-rgb: 228, 64, 95;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e4405f;
  --bs-btn-active-border-color: #e4405f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #e4405f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4405f;
  --bs-gradient: none;
}

.btn-outline-pinterest {
  --bs-btn-color: #bd081c;
  --bs-btn-border-color: #bd081c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bd081c;
  --bs-btn-hover-border-color: #bd081c;
  --bs-btn-focus-shadow-rgb: 189, 8, 28;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #bd081c;
  --bs-btn-active-border-color: #bd081c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #bd081c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bd081c;
  --bs-gradient: none;
}

.btn-outline-youtube {
  --bs-btn-color: #cd201f;
  --bs-btn-border-color: #cd201f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cd201f;
  --bs-btn-hover-border-color: #cd201f;
  --bs-btn-focus-shadow-rgb: 205, 32, 31;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cd201f;
  --bs-btn-active-border-color: #cd201f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #cd201f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cd201f;
  --bs-gradient: none;
}

.btn-outline-slack {
  --bs-btn-color: #3aaf85;
  --bs-btn-border-color: #3aaf85;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #3aaf85;
  --bs-btn-hover-border-color: #3aaf85;
  --bs-btn-focus-shadow-rgb: 58, 175, 133;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #3aaf85;
  --bs-btn-active-border-color: #3aaf85;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #3aaf85;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3aaf85;
  --bs-gradient: none;
}

.btn-outline-dribbble {
  --bs-btn-color: #ea4c89;
  --bs-btn-border-color: #ea4c89;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ea4c89;
  --bs-btn-hover-border-color: #ea4c89;
  --bs-btn-focus-shadow-rgb: 234, 76, 137;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ea4c89;
  --bs-btn-active-border-color: #ea4c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ea4c89;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea4c89;
  --bs-gradient: none;
}

.btn-outline-dropbox {
  --bs-btn-color: #1E90FF;
  --bs-btn-border-color: #1E90FF;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1E90FF;
  --bs-btn-hover-border-color: #1E90FF;
  --bs-btn-focus-shadow-rgb: 30, 144, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1E90FF;
  --bs-btn-active-border-color: #1E90FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #1E90FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1E90FF;
  --bs-gradient: none;
}

.btn-outline-twitch {
  --bs-btn-color: #4B367C;
  --bs-btn-border-color: #4B367C;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4B367C;
  --bs-btn-hover-border-color: #4B367C;
  --bs-btn-focus-shadow-rgb: 75, 54, 124;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4B367C;
  --bs-btn-active-border-color: #4B367C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #4B367C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4B367C;
  --bs-gradient: none;
}

.btn-outline-paypal {
  --bs-btn-color: #ecb32c;
  --bs-btn-border-color: #ecb32c;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #ecb32c;
  --bs-btn-hover-border-color: #ecb32c;
  --bs-btn-focus-shadow-rgb: 236, 179, 44;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #ecb32c;
  --bs-btn-active-border-color: #ecb32c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ecb32c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ecb32c;
  --bs-gradient: none;
}

.btn-outline-behance {
  --bs-btn-color: #0057ff;
  --bs-btn-border-color: #0057ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0057ff;
  --bs-btn-hover-border-color: #0057ff;
  --bs-btn-focus-shadow-rgb: 0, 87, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0057ff;
  --bs-btn-active-border-color: #0057ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #0057ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0057ff;
  --bs-gradient: none;
}

.btn-outline-reddit {
  --bs-btn-color: #E84422;
  --bs-btn-border-color: #E84422;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #E84422;
  --bs-btn-hover-border-color: #E84422;
  --bs-btn-focus-shadow-rgb: 232, 68, 34;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #E84422;
  --bs-btn-active-border-color: #E84422;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #E84422;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E84422;
  --bs-gradient: none;
}

.btn-outline-github {
  --bs-btn-color: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #222222;
  --bs-btn-hover-border-color: #222222;
  --bs-btn-focus-shadow-rgb: 34, 34, 34;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #222222;
  --bs-btn-active-border-color: #222222;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #222222;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #222222;
  --bs-gradient: none;
}

.btn-outline-adobe {
  --bs-btn-color: #ff9a00;
  --bs-btn-border-color: #ff9a00;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #ff9a00;
  --bs-btn-hover-border-color: #ff9a00;
  --bs-btn-focus-shadow-rgb: 255, 154, 0;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #ff9a00;
  --bs-btn-active-border-color: #ff9a00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #ff9a00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff9a00;
  --bs-gradient: none;
}

.btn-outline-bootstrap {
  --bs-btn-color: #7C277D;
  --bs-btn-border-color: #7C277D;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #7C277D;
  --bs-btn-hover-border-color: #7C277D;
  --bs-btn-focus-shadow-rgb: 124, 39, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #7C277D;
  --bs-btn-active-border-color: #7C277D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #7C277D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7C277D;
  --bs-gradient: none;
}

.btn-outline-python {
  --bs-btn-color: #306998;
  --bs-btn-border-color: #306998;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #306998;
  --bs-btn-hover-border-color: #306998;
  --bs-btn-focus-shadow-rgb: 48, 105, 152;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #306998;
  --bs-btn-active-border-color: #306998;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #306998;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #306998;
  --bs-gradient: none;
}

.btn-outline-react {
  --bs-btn-color: #00d8ff;
  --bs-btn-border-color: #00d8ff;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #00d8ff;
  --bs-btn-hover-border-color: #00d8ff;
  --bs-btn-focus-shadow-rgb: 0, 216, 255;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #00d8ff;
  --bs-btn-active-border-color: #00d8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #00d8ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00d8ff;
  --bs-gradient: none;
}

.btn-outline-azure {
  --bs-btn-color: #2892df;
  --bs-btn-border-color: #2892df;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2892df;
  --bs-btn-hover-border-color: #2892df;
  --bs-btn-focus-shadow-rgb: 40, 146, 223;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2892df;
  --bs-btn-active-border-color: #2892df;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #2892df;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2892df;
  --bs-gradient: none;
}

.btn-outline-js {
  --bs-btn-color: #EFD81F;
  --bs-btn-border-color: #EFD81F;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #EFD81F;
  --bs-btn-hover-border-color: #EFD81F;
  --bs-btn-focus-shadow-rgb: 239, 216, 31;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #EFD81F;
  --bs-btn-active-border-color: #EFD81F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #EFD81F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EFD81F;
  --bs-gradient: none;
}

.btn-outline-swe {
  --bs-btn-color: #FFCF3B;
  --bs-btn-border-color: #FFCF3B;
  --bs-btn-hover-color: #262B40;
  --bs-btn-hover-bg: #FFCF3B;
  --bs-btn-hover-border-color: #FFCF3B;
  --bs-btn-focus-shadow-rgb: 255, 207, 59;
  --bs-btn-active-color: #262B40;
  --bs-btn-active-bg: #FFCF3B;
  --bs-btn-active-border-color: #FFCF3B;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  --bs-btn-disabled-color: #FFCF3B;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFCF3B;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #262B40;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #0a0b10;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #0a0b10;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #93a5be;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 71, 75, 93;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #4A5073;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: #eaedf2;
  --bs-dropdown-border-radius: 0.5rem;
  --bs-dropdown-border-width: 0.0625rem;
  --bs-dropdown-inner-border-radius: 0.4375rem;
  --bs-dropdown-divider-bg: #f5f8fb;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  --bs-dropdown-link-color: #4A5073;
  --bs-dropdown-link-hover-color: #404563;
  --bs-dropdown-link-hover-bg: #f5f8fb;
  --bs-dropdown-link-active-color: #4A5073;
  --bs-dropdown-link-active-bg: #eaedf2;
  --bs-dropdown-link-disabled-color: #93a5be;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #4A5073;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #F0F3F6;
  --bs-dropdown-bg: #506690;
  --bs-dropdown-border-color: #eaedf2;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #F0F3F6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #f5f8fb;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #4A5073;
  --bs-dropdown-link-active-bg: #eaedf2;
  --bs-dropdown-link-disabled-color: #d1d7e0;
  --bs-dropdown-header-color: #d1d7e0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.5rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(0.0625rem * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(0.0625rem * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #506690;
  --bs-nav-link-hover-color: #262B40;
  --bs-nav-link-disabled-color: #93a5be;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(38, 43, 64, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0.0625rem;
  --bs-nav-tabs-border-color: #eaedf2;
  --bs-nav-tabs-border-radius: 0.5rem;
  --bs-nav-tabs-link-hover-border-color: #f5f8fb #f5f8fb #eaedf2;
  --bs-nav-tabs-link-active-color: #66799e;
  --bs-nav-tabs-link-active-bg: #eaedf2;
  --bs-nav-tabs-link-active-border-color: #eaedf2;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.5rem;
  --bs-nav-pills-link-active-color: #262B40;
  --bs-nav-pills-link-active-bg: #f8f8f8;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 1rem;
  --bs-navbar-color: rgba(46, 54, 80, 0.9);
  --bs-navbar-hover-color: #2e3650;
  --bs-navbar-disabled-color: rgba(46, 54, 80, 0.9);
  --bs-navbar-active-color: #2e3650;
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #2e3650;
  --bs-navbar-brand-hover-color: #2e3650;
  --bs-navbar-nav-link-padding-x: 0.8rem;
  --bs-navbar-toggler-padding-y: 0.4rem;
  --bs-navbar-toggler-padding-x: 0.6rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2846, 54, 80, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(46, 54, 80, 0.1);
  --bs-navbar-toggler-border-radius: 0.5rem;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.9);
  --bs-navbar-hover-color: white;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.9);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.875rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(46, 54, 80, 0.125);
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0.4375rem;
  --bs-card-cap-padding-y: 1.25rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: rgba(46, 54, 80, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%234A5073' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%230f111a' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237d808c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237d808c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.5rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: #f5f8fb;
  --bs-breadcrumb-border-radius: 0.5rem;
  --bs-breadcrumb-divider-color: #66799e;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #262B40;
  --bs-pagination-bg: #ffffff;
  --bs-pagination-border-width: 0.0625rem;
  --bs-pagination-border-color: #eaedf2;
  --bs-pagination-border-radius: 0.5rem;
  --bs-pagination-hover-color: #0a0b10;
  --bs-pagination-hover-bg: #f5f8fb;
  --bs-pagination-hover-border-color: #F0F3F6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #262B40;
  --bs-pagination-active-border-color: #262B40;
  --bs-pagination-disabled-color: #93a5be;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #F0F3F6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -0.0625rem;
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.4rem;
  --bs-badge-padding-y: 0.1rem;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.5rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0.0625rem solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.5rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-tertiary {
  --bs-alert-color: var(--bs-tertiary-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-text-emphasis);
}

.alert-quaternary {
  --bs-alert-color: var(--bs-quaternary-text-emphasis);
  --bs-alert-bg: var(--bs-quaternary-bg-subtle);
  --bs-alert-border-color: var(--bs-quaternary-border-subtle);
  --bs-alert-link-color: var(--bs-quaternary-text-emphasis);
}

.alert-primary-app {
  --bs-alert-color: var(--bs-primary-app-text-emphasis);
  --bs-alert-bg: var(--bs-primary-app-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-app-border-subtle);
  --bs-alert-link-color: var(--bs-primary-app-text-emphasis);
}

.alert-secondary-app {
  --bs-alert-color: var(--bs-secondary-app-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-app-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-app-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-app-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-lighten {
  --bs-alert-color: var(--bs-lighten-text-emphasis);
  --bs-alert-bg: var(--bs-lighten-bg-subtle);
  --bs-alert-border-color: var(--bs-lighten-border-subtle);
  --bs-alert-link-color: var(--bs-lighten-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-indigo {
  --bs-alert-color: var(--bs-indigo-text-emphasis);
  --bs-alert-bg: var(--bs-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-soft {
  --bs-alert-color: var(--bs-soft-text-emphasis);
  --bs-alert-bg: var(--bs-soft-bg-subtle);
  --bs-alert-border-color: var(--bs-soft-border-subtle);
  --bs-alert-link-color: var(--bs-soft-text-emphasis);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-cyan {
  --bs-alert-color: var(--bs-cyan-text-emphasis);
  --bs-alert-bg: var(--bs-cyan-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-text-emphasis);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}

.alert-soft-indigo {
  --bs-alert-color: var(--bs-soft-indigo-text-emphasis);
  --bs-alert-bg: var(--bs-soft-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-soft-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-soft-indigo-text-emphasis);
}

.alert-soft-green {
  --bs-alert-color: var(--bs-soft-green-text-emphasis);
  --bs-alert-bg: var(--bs-soft-green-bg-subtle);
  --bs-alert-border-color: var(--bs-soft-green-border-subtle);
  --bs-alert-link-color: var(--bs-soft-green-text-emphasis);
}

.alert-gray-100 {
  --bs-alert-color: var(--bs-gray-100-text-emphasis);
  --bs-alert-bg: var(--bs-gray-100-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-100-border-subtle);
  --bs-alert-link-color: var(--bs-gray-100-text-emphasis);
}

.alert-gray-200 {
  --bs-alert-color: var(--bs-gray-200-text-emphasis);
  --bs-alert-bg: var(--bs-gray-200-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-200-border-subtle);
  --bs-alert-link-color: var(--bs-gray-200-text-emphasis);
}

.alert-gray-300 {
  --bs-alert-color: var(--bs-gray-300-text-emphasis);
  --bs-alert-bg: var(--bs-gray-300-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-300-border-subtle);
  --bs-alert-link-color: var(--bs-gray-300-text-emphasis);
}

.alert-gray-400 {
  --bs-alert-color: var(--bs-gray-400-text-emphasis);
  --bs-alert-bg: var(--bs-gray-400-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-400-border-subtle);
  --bs-alert-link-color: var(--bs-gray-400-text-emphasis);
}

.alert-gray-500 {
  --bs-alert-color: var(--bs-gray-500-text-emphasis);
  --bs-alert-bg: var(--bs-gray-500-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-500-border-subtle);
  --bs-alert-link-color: var(--bs-gray-500-text-emphasis);
}

.alert-gray-600 {
  --bs-alert-color: var(--bs-gray-600-text-emphasis);
  --bs-alert-bg: var(--bs-gray-600-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-600-border-subtle);
  --bs-alert-link-color: var(--bs-gray-600-text-emphasis);
}

.alert-gray-700 {
  --bs-alert-color: var(--bs-gray-700-text-emphasis);
  --bs-alert-bg: var(--bs-gray-700-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-700-border-subtle);
  --bs-alert-link-color: var(--bs-gray-700-text-emphasis);
}

.alert-gray-800 {
  --bs-alert-color: var(--bs-gray-800-text-emphasis);
  --bs-alert-bg: var(--bs-gray-800-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-800-border-subtle);
  --bs-alert-link-color: var(--bs-gray-800-text-emphasis);
}

.alert-facebook {
  --bs-alert-color: var(--bs-facebook-text-emphasis);
  --bs-alert-bg: var(--bs-facebook-bg-subtle);
  --bs-alert-border-color: var(--bs-facebook-border-subtle);
  --bs-alert-link-color: var(--bs-facebook-text-emphasis);
}

.alert-twitter {
  --bs-alert-color: var(--bs-twitter-text-emphasis);
  --bs-alert-bg: var(--bs-twitter-bg-subtle);
  --bs-alert-border-color: var(--bs-twitter-border-subtle);
  --bs-alert-link-color: var(--bs-twitter-text-emphasis);
}

.alert-google {
  --bs-alert-color: var(--bs-google-text-emphasis);
  --bs-alert-bg: var(--bs-google-bg-subtle);
  --bs-alert-border-color: var(--bs-google-border-subtle);
  --bs-alert-link-color: var(--bs-google-text-emphasis);
}

.alert-instagram {
  --bs-alert-color: var(--bs-instagram-text-emphasis);
  --bs-alert-bg: var(--bs-instagram-bg-subtle);
  --bs-alert-border-color: var(--bs-instagram-border-subtle);
  --bs-alert-link-color: var(--bs-instagram-text-emphasis);
}

.alert-pinterest {
  --bs-alert-color: var(--bs-pinterest-text-emphasis);
  --bs-alert-bg: var(--bs-pinterest-bg-subtle);
  --bs-alert-border-color: var(--bs-pinterest-border-subtle);
  --bs-alert-link-color: var(--bs-pinterest-text-emphasis);
}

.alert-youtube {
  --bs-alert-color: var(--bs-youtube-text-emphasis);
  --bs-alert-bg: var(--bs-youtube-bg-subtle);
  --bs-alert-border-color: var(--bs-youtube-border-subtle);
  --bs-alert-link-color: var(--bs-youtube-text-emphasis);
}

.alert-slack {
  --bs-alert-color: var(--bs-slack-text-emphasis);
  --bs-alert-bg: var(--bs-slack-bg-subtle);
  --bs-alert-border-color: var(--bs-slack-border-subtle);
  --bs-alert-link-color: var(--bs-slack-text-emphasis);
}

.alert-dribbble {
  --bs-alert-color: var(--bs-dribbble-text-emphasis);
  --bs-alert-bg: var(--bs-dribbble-bg-subtle);
  --bs-alert-border-color: var(--bs-dribbble-border-subtle);
  --bs-alert-link-color: var(--bs-dribbble-text-emphasis);
}

.alert-dropbox {
  --bs-alert-color: var(--bs-dropbox-text-emphasis);
  --bs-alert-bg: var(--bs-dropbox-bg-subtle);
  --bs-alert-border-color: var(--bs-dropbox-border-subtle);
  --bs-alert-link-color: var(--bs-dropbox-text-emphasis);
}

.alert-twitch {
  --bs-alert-color: var(--bs-twitch-text-emphasis);
  --bs-alert-bg: var(--bs-twitch-bg-subtle);
  --bs-alert-border-color: var(--bs-twitch-border-subtle);
  --bs-alert-link-color: var(--bs-twitch-text-emphasis);
}

.alert-paypal {
  --bs-alert-color: var(--bs-paypal-text-emphasis);
  --bs-alert-bg: var(--bs-paypal-bg-subtle);
  --bs-alert-border-color: var(--bs-paypal-border-subtle);
  --bs-alert-link-color: var(--bs-paypal-text-emphasis);
}

.alert-behance {
  --bs-alert-color: var(--bs-behance-text-emphasis);
  --bs-alert-bg: var(--bs-behance-bg-subtle);
  --bs-alert-border-color: var(--bs-behance-border-subtle);
  --bs-alert-link-color: var(--bs-behance-text-emphasis);
}

.alert-reddit {
  --bs-alert-color: var(--bs-reddit-text-emphasis);
  --bs-alert-bg: var(--bs-reddit-bg-subtle);
  --bs-alert-border-color: var(--bs-reddit-border-subtle);
  --bs-alert-link-color: var(--bs-reddit-text-emphasis);
}

.alert-github {
  --bs-alert-color: var(--bs-github-text-emphasis);
  --bs-alert-bg: var(--bs-github-bg-subtle);
  --bs-alert-border-color: var(--bs-github-border-subtle);
  --bs-alert-link-color: var(--bs-github-text-emphasis);
}

.alert-adobe {
  --bs-alert-color: var(--bs-adobe-text-emphasis);
  --bs-alert-bg: var(--bs-adobe-bg-subtle);
  --bs-alert-border-color: var(--bs-adobe-border-subtle);
  --bs-alert-link-color: var(--bs-adobe-text-emphasis);
}

.alert-bootstrap {
  --bs-alert-color: var(--bs-bootstrap-text-emphasis);
  --bs-alert-bg: var(--bs-bootstrap-bg-subtle);
  --bs-alert-border-color: var(--bs-bootstrap-border-subtle);
  --bs-alert-link-color: var(--bs-bootstrap-text-emphasis);
}

.alert-python {
  --bs-alert-color: var(--bs-python-text-emphasis);
  --bs-alert-bg: var(--bs-python-bg-subtle);
  --bs-alert-border-color: var(--bs-python-border-subtle);
  --bs-alert-link-color: var(--bs-python-text-emphasis);
}

.alert-react {
  --bs-alert-color: var(--bs-react-text-emphasis);
  --bs-alert-bg: var(--bs-react-bg-subtle);
  --bs-alert-border-color: var(--bs-react-border-subtle);
  --bs-alert-link-color: var(--bs-react-text-emphasis);
}

.alert-azure {
  --bs-alert-color: var(--bs-azure-text-emphasis);
  --bs-alert-bg: var(--bs-azure-bg-subtle);
  --bs-alert-border-color: var(--bs-azure-border-subtle);
  --bs-alert-link-color: var(--bs-azure-text-emphasis);
}

.alert-js {
  --bs-alert-color: var(--bs-js-text-emphasis);
  --bs-alert-bg: var(--bs-js-bg-subtle);
  --bs-alert-border-color: var(--bs-js-border-subtle);
  --bs-alert-link-color: var(--bs-js-text-emphasis);
}

.alert-swe {
  --bs-alert-color: var(--bs-swe-text-emphasis);
  --bs-alert-bg: var(--bs-swe-bg-subtle);
  --bs-alert-border-color: var(--bs-swe-border-subtle);
  --bs-alert-link-color: var(--bs-swe-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f5f8fb;
  --bs-progress-border-radius: 0.5rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #262B40;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: rgba(46, 54, 80, 0.125);
  --bs-list-group-border-width: 0.0625rem;
  --bs-list-group-border-radius: 0.5rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #66799e;
  --bs-list-group-action-hover-color: #66799e;
  --bs-list-group-action-hover-bg: #f3f7fa;
  --bs-list-group-action-active-color: #4A5073;
  --bs-list-group-action-active-bg: #f5f8fb;
  --bs-list-group-disabled-color: #93a5be;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #262B40;
  --bs-list-group-active-border-color: #262B40;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-tertiary {
  --bs-list-group-color: var(--bs-tertiary-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-text-emphasis);
}

.list-group-item-quaternary {
  --bs-list-group-color: var(--bs-quaternary-text-emphasis);
  --bs-list-group-bg: var(--bs-quaternary-bg-subtle);
  --bs-list-group-border-color: var(--bs-quaternary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-quaternary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-quaternary-border-subtle);
  --bs-list-group-active-color: var(--bs-quaternary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-quaternary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-quaternary-text-emphasis);
}

.list-group-item-primary-app {
  --bs-list-group-color: var(--bs-primary-app-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-app-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-app-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-app-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-app-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-app-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-app-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-app-text-emphasis);
}

.list-group-item-secondary-app {
  --bs-list-group-color: var(--bs-secondary-app-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-app-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-app-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-app-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-app-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-app-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-app-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-app-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-lighten {
  --bs-list-group-color: var(--bs-lighten-text-emphasis);
  --bs-list-group-bg: var(--bs-lighten-bg-subtle);
  --bs-list-group-border-color: var(--bs-lighten-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-lighten-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-lighten-border-subtle);
  --bs-list-group-active-color: var(--bs-lighten-bg-subtle);
  --bs-list-group-active-bg: var(--bs-lighten-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-lighten-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-indigo {
  --bs-list-group-color: var(--bs-indigo-text-emphasis);
  --bs-list-group-bg: var(--bs-indigo-bg-subtle);
  --bs-list-group-border-color: var(--bs-indigo-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-active-color: var(--bs-indigo-bg-subtle);
  --bs-list-group-active-bg: var(--bs-indigo-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-indigo-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-soft {
  --bs-list-group-color: var(--bs-soft-text-emphasis);
  --bs-list-group-bg: var(--bs-soft-bg-subtle);
  --bs-list-group-border-color: var(--bs-soft-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-soft-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-soft-border-subtle);
  --bs-list-group-active-color: var(--bs-soft-bg-subtle);
  --bs-list-group-active-bg: var(--bs-soft-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-soft-text-emphasis);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis);
}

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-cyan {
  --bs-list-group-color: var(--bs-cyan-text-emphasis);
  --bs-list-group-bg: var(--bs-cyan-bg-subtle);
  --bs-list-group-border-color: var(--bs-cyan-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-active-color: var(--bs-cyan-bg-subtle);
  --bs-list-group-active-bg: var(--bs-cyan-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-cyan-text-emphasis);
}

.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}

.list-group-item-soft-indigo {
  --bs-list-group-color: var(--bs-soft-indigo-text-emphasis);
  --bs-list-group-bg: var(--bs-soft-indigo-bg-subtle);
  --bs-list-group-border-color: var(--bs-soft-indigo-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-soft-indigo-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-soft-indigo-border-subtle);
  --bs-list-group-active-color: var(--bs-soft-indigo-bg-subtle);
  --bs-list-group-active-bg: var(--bs-soft-indigo-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-soft-indigo-text-emphasis);
}

.list-group-item-soft-green {
  --bs-list-group-color: var(--bs-soft-green-text-emphasis);
  --bs-list-group-bg: var(--bs-soft-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-soft-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-soft-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-soft-green-border-subtle);
  --bs-list-group-active-color: var(--bs-soft-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-soft-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-soft-green-text-emphasis);
}

.list-group-item-gray-100 {
  --bs-list-group-color: var(--bs-gray-100-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-100-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-100-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-100-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-100-text-emphasis);
}

.list-group-item-gray-200 {
  --bs-list-group-color: var(--bs-gray-200-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-200-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-200-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-200-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-200-text-emphasis);
}

.list-group-item-gray-300 {
  --bs-list-group-color: var(--bs-gray-300-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-300-text-emphasis);
}

.list-group-item-gray-400 {
  --bs-list-group-color: var(--bs-gray-400-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-400-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-400-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-400-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-400-text-emphasis);
}

.list-group-item-gray-500 {
  --bs-list-group-color: var(--bs-gray-500-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-500-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-500-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-500-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-500-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-500-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-500-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-500-text-emphasis);
}

.list-group-item-gray-600 {
  --bs-list-group-color: var(--bs-gray-600-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-600-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-600-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-600-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-600-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-600-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-600-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-600-text-emphasis);
}

.list-group-item-gray-700 {
  --bs-list-group-color: var(--bs-gray-700-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-700-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-700-text-emphasis);
}

.list-group-item-gray-800 {
  --bs-list-group-color: var(--bs-gray-800-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-800-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-800-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-800-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-800-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-800-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-800-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-800-text-emphasis);
}

.list-group-item-facebook {
  --bs-list-group-color: var(--bs-facebook-text-emphasis);
  --bs-list-group-bg: var(--bs-facebook-bg-subtle);
  --bs-list-group-border-color: var(--bs-facebook-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-facebook-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-facebook-border-subtle);
  --bs-list-group-active-color: var(--bs-facebook-bg-subtle);
  --bs-list-group-active-bg: var(--bs-facebook-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-facebook-text-emphasis);
}

.list-group-item-twitter {
  --bs-list-group-color: var(--bs-twitter-text-emphasis);
  --bs-list-group-bg: var(--bs-twitter-bg-subtle);
  --bs-list-group-border-color: var(--bs-twitter-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-twitter-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-twitter-border-subtle);
  --bs-list-group-active-color: var(--bs-twitter-bg-subtle);
  --bs-list-group-active-bg: var(--bs-twitter-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-twitter-text-emphasis);
}

.list-group-item-google {
  --bs-list-group-color: var(--bs-google-text-emphasis);
  --bs-list-group-bg: var(--bs-google-bg-subtle);
  --bs-list-group-border-color: var(--bs-google-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-google-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-google-border-subtle);
  --bs-list-group-active-color: var(--bs-google-bg-subtle);
  --bs-list-group-active-bg: var(--bs-google-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-google-text-emphasis);
}

.list-group-item-instagram {
  --bs-list-group-color: var(--bs-instagram-text-emphasis);
  --bs-list-group-bg: var(--bs-instagram-bg-subtle);
  --bs-list-group-border-color: var(--bs-instagram-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-instagram-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-instagram-border-subtle);
  --bs-list-group-active-color: var(--bs-instagram-bg-subtle);
  --bs-list-group-active-bg: var(--bs-instagram-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-instagram-text-emphasis);
}

.list-group-item-pinterest {
  --bs-list-group-color: var(--bs-pinterest-text-emphasis);
  --bs-list-group-bg: var(--bs-pinterest-bg-subtle);
  --bs-list-group-border-color: var(--bs-pinterest-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pinterest-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pinterest-border-subtle);
  --bs-list-group-active-color: var(--bs-pinterest-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pinterest-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pinterest-text-emphasis);
}

.list-group-item-youtube {
  --bs-list-group-color: var(--bs-youtube-text-emphasis);
  --bs-list-group-bg: var(--bs-youtube-bg-subtle);
  --bs-list-group-border-color: var(--bs-youtube-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-youtube-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-youtube-border-subtle);
  --bs-list-group-active-color: var(--bs-youtube-bg-subtle);
  --bs-list-group-active-bg: var(--bs-youtube-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-youtube-text-emphasis);
}

.list-group-item-slack {
  --bs-list-group-color: var(--bs-slack-text-emphasis);
  --bs-list-group-bg: var(--bs-slack-bg-subtle);
  --bs-list-group-border-color: var(--bs-slack-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-slack-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-slack-border-subtle);
  --bs-list-group-active-color: var(--bs-slack-bg-subtle);
  --bs-list-group-active-bg: var(--bs-slack-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-slack-text-emphasis);
}

.list-group-item-dribbble {
  --bs-list-group-color: var(--bs-dribbble-text-emphasis);
  --bs-list-group-bg: var(--bs-dribbble-bg-subtle);
  --bs-list-group-border-color: var(--bs-dribbble-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dribbble-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dribbble-border-subtle);
  --bs-list-group-active-color: var(--bs-dribbble-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dribbble-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dribbble-text-emphasis);
}

.list-group-item-dropbox {
  --bs-list-group-color: var(--bs-dropbox-text-emphasis);
  --bs-list-group-bg: var(--bs-dropbox-bg-subtle);
  --bs-list-group-border-color: var(--bs-dropbox-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dropbox-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dropbox-border-subtle);
  --bs-list-group-active-color: var(--bs-dropbox-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dropbox-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dropbox-text-emphasis);
}

.list-group-item-twitch {
  --bs-list-group-color: var(--bs-twitch-text-emphasis);
  --bs-list-group-bg: var(--bs-twitch-bg-subtle);
  --bs-list-group-border-color: var(--bs-twitch-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-twitch-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-twitch-border-subtle);
  --bs-list-group-active-color: var(--bs-twitch-bg-subtle);
  --bs-list-group-active-bg: var(--bs-twitch-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-twitch-text-emphasis);
}

.list-group-item-paypal {
  --bs-list-group-color: var(--bs-paypal-text-emphasis);
  --bs-list-group-bg: var(--bs-paypal-bg-subtle);
  --bs-list-group-border-color: var(--bs-paypal-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-paypal-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-paypal-border-subtle);
  --bs-list-group-active-color: var(--bs-paypal-bg-subtle);
  --bs-list-group-active-bg: var(--bs-paypal-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-paypal-text-emphasis);
}

.list-group-item-behance {
  --bs-list-group-color: var(--bs-behance-text-emphasis);
  --bs-list-group-bg: var(--bs-behance-bg-subtle);
  --bs-list-group-border-color: var(--bs-behance-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-behance-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-behance-border-subtle);
  --bs-list-group-active-color: var(--bs-behance-bg-subtle);
  --bs-list-group-active-bg: var(--bs-behance-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-behance-text-emphasis);
}

.list-group-item-reddit {
  --bs-list-group-color: var(--bs-reddit-text-emphasis);
  --bs-list-group-bg: var(--bs-reddit-bg-subtle);
  --bs-list-group-border-color: var(--bs-reddit-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-reddit-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-reddit-border-subtle);
  --bs-list-group-active-color: var(--bs-reddit-bg-subtle);
  --bs-list-group-active-bg: var(--bs-reddit-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-reddit-text-emphasis);
}

.list-group-item-github {
  --bs-list-group-color: var(--bs-github-text-emphasis);
  --bs-list-group-bg: var(--bs-github-bg-subtle);
  --bs-list-group-border-color: var(--bs-github-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-github-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-github-border-subtle);
  --bs-list-group-active-color: var(--bs-github-bg-subtle);
  --bs-list-group-active-bg: var(--bs-github-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-github-text-emphasis);
}

.list-group-item-adobe {
  --bs-list-group-color: var(--bs-adobe-text-emphasis);
  --bs-list-group-bg: var(--bs-adobe-bg-subtle);
  --bs-list-group-border-color: var(--bs-adobe-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-adobe-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-adobe-border-subtle);
  --bs-list-group-active-color: var(--bs-adobe-bg-subtle);
  --bs-list-group-active-bg: var(--bs-adobe-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-adobe-text-emphasis);
}

.list-group-item-bootstrap {
  --bs-list-group-color: var(--bs-bootstrap-text-emphasis);
  --bs-list-group-bg: var(--bs-bootstrap-bg-subtle);
  --bs-list-group-border-color: var(--bs-bootstrap-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-bootstrap-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-bootstrap-border-subtle);
  --bs-list-group-active-color: var(--bs-bootstrap-bg-subtle);
  --bs-list-group-active-bg: var(--bs-bootstrap-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-bootstrap-text-emphasis);
}

.list-group-item-python {
  --bs-list-group-color: var(--bs-python-text-emphasis);
  --bs-list-group-bg: var(--bs-python-bg-subtle);
  --bs-list-group-border-color: var(--bs-python-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-python-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-python-border-subtle);
  --bs-list-group-active-color: var(--bs-python-bg-subtle);
  --bs-list-group-active-bg: var(--bs-python-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-python-text-emphasis);
}

.list-group-item-react {
  --bs-list-group-color: var(--bs-react-text-emphasis);
  --bs-list-group-bg: var(--bs-react-bg-subtle);
  --bs-list-group-border-color: var(--bs-react-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-react-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-react-border-subtle);
  --bs-list-group-active-color: var(--bs-react-bg-subtle);
  --bs-list-group-active-bg: var(--bs-react-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-react-text-emphasis);
}

.list-group-item-azure {
  --bs-list-group-color: var(--bs-azure-text-emphasis);
  --bs-list-group-bg: var(--bs-azure-bg-subtle);
  --bs-list-group-border-color: var(--bs-azure-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-azure-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-azure-border-subtle);
  --bs-list-group-active-color: var(--bs-azure-bg-subtle);
  --bs-list-group-active-bg: var(--bs-azure-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-azure-text-emphasis);
}

.list-group-item-js {
  --bs-list-group-color: var(--bs-js-text-emphasis);
  --bs-list-group-bg: var(--bs-js-bg-subtle);
  --bs-list-group-border-color: var(--bs-js-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-js-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-js-border-subtle);
  --bs-list-group-active-color: var(--bs-js-bg-subtle);
  --bs-list-group-active-bg: var(--bs-js-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-js-text-emphasis);
}

.list-group-item-swe {
  --bs-list-group-color: var(--bs-swe-text-emphasis);
  --bs-list-group-bg: var(--bs-swe-bg-subtle);
  --bs-list-group-border-color: var(--bs-swe-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-swe-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-swe-border-subtle);
  --bs-list-group-active-color: var(--bs-swe-bg-subtle);
  --bs-list-group-active-bg: var(--bs-swe-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-swe-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #2e3650;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e3650'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(38, 43, 64, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 2rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.5rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  --bs-toast-header-color: #93a5be;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: rgba(46, 54, 80, 0.2);
  --bs-modal-border-width: 0.0625rem;
  --bs-modal-border-radius: 0.3rem;
  --bs-modal-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  --bs-modal-inner-border-radius: 0.2375rem;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #eaedf2;
  --bs-modal-header-border-width: 0.0625rem;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #eaedf2;
  --bs-modal-footer-border-width: 0.0625rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1040;
  --bs-backdrop-bg: #2e3650;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1070;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.55rem;
  --bs-tooltip-padding-y: 0.75rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #262B40;
  --bs-tooltip-border-radius: 0.5rem;
  --bs-tooltip-opacity: 0.99;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 0.0625rem;
  --bs-popover-border-color: rgba(46, 54, 80, 0.2);
  --bs-popover-border-radius: 0.3rem;
  --bs-popover-inner-border-radius: 0.2375rem;
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #262B40;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #4A5073;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #2e3650;
}
.carousel-dark .carousel-caption {
  color: #2e3650;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #2e3650;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #2e3650;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1.2rem;
  --bs-spinner-height: 1.2rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1.2rem;
  --bs-spinner-height: 1.2rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0.0625rem;
  --bs-offcanvas-border-color: rgba(46, 54, 80, 0.2);
  --bs-offcanvas-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #2e3650;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #2e3650 55%, rgba(0, 0, 0, 0.8) 75%, #2e3650 95%);
          mask-image: linear-gradient(130deg, #2e3650 55%, rgba(0, 0, 0, 0.8) 75%, #2e3650 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-quaternary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-quaternary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-app {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-primary-app-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-app {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-secondary-app-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-lighten {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-lighten-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-soft {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-soft-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-cyan-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-soft-indigo {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-soft-indigo-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-soft-green {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-soft-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-100 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-100-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-200 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-200-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-300 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-300-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-400 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-400-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-500 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-500-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-600 {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-gray-600-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-700 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-gray-700-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-800 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-gray-800-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-facebook {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-facebook-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-twitter {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-twitter-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-google {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-google-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-instagram {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-instagram-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pinterest {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-pinterest-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-youtube {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-youtube-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-slack {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-slack-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dribbble {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dribbble-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dropbox {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dropbox-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-twitch {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-twitch-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-paypal {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-paypal-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-behance {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-behance-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-reddit {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-reddit-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-github {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-github-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-adobe {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-adobe-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-bootstrap {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-bootstrap-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-python {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-python-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-react {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-react-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-azure {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-azure-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-js {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-js-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-swe {
  color: #262B40 !important;
  background-color: RGBA(var(--bs-swe-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(30, 34, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 34, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(129, 225, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(129, 225, 252, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary {
  color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary:hover, .link-tertiary:focus {
  color: RGBA(22, 122, 111, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(22, 122, 111, var(--bs-link-underline-opacity, 1)) !important;
}

.link-quaternary {
  color: RGBA(var(--bs-quaternary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-quaternary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-quaternary:hover, .link-quaternary:focus {
  color: RGBA(161, 80, 102, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(161, 80, 102, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-app {
  color: RGBA(var(--bs-primary-app-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-app-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-app:hover, .link-primary-app:focus {
  color: RGBA(239, 246, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(239, 246, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-app {
  color: RGBA(var(--bs-secondary-app-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-app-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-app:hover, .link-secondary-app:focus {
  color: RGBA(53, 59, 128, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 59, 128, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(238, 241, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(238, 241, 245, var(--bs-link-underline-opacity, 1)) !important;
}

.link-lighten {
  color: RGBA(var(--bs-lighten-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-lighten-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-lighten:hover, .link-lighten:focus {
  color: RGBA(243, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(243, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(4, 133, 95, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(4, 133, 95, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(7, 58, 143, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(7, 58, 143, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(247, 197, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 197, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(200, 66, 66, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(200, 66, 66, var(--bs-link-underline-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(59, 64, 92, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(59, 64, 92, var(--bs-link-underline-opacity, 1)) !important;
}

.link-indigo {
  color: RGBA(var(--bs-indigo-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-indigo-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: RGBA(61, 69, 102, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 69, 102, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(30, 34, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 34, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-soft {
  color: RGBA(var(--bs-soft-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-soft-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-soft:hover, .link-soft:focus {
  color: RGBA(247, 249, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 249, 252, var(--bs-link-underline-opacity, 1)) !important;
}

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-black:hover, .link-black:focus {
  color: RGBA(37, 43, 64, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(37, 43, 64, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(7, 58, 143, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(7, 58, 143, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(161, 80, 102, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(161, 80, 102, var(--bs-link-underline-opacity, 1)) !important;
}

.link-cyan {
  color: RGBA(var(--bs-cyan-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-cyan-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: RGBA(130, 193, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(130, 193, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-purple:hover, .link-purple:focus {
  color: RGBA(110, 81, 179, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(110, 81, 179, var(--bs-link-underline-opacity, 1)) !important;
}

.link-soft-indigo {
  color: RGBA(var(--bs-soft-indigo-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-soft-indigo-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-soft-indigo:hover, .link-soft-indigo:focus {
  color: RGBA(247, 237, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 237, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-soft-green {
  color: RGBA(var(--bs-soft-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-soft-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-soft-green:hover, .link-soft-green:focus {
  color: RGBA(35, 132, 113, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(35, 132, 113, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-100 {
  color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-100:hover, .link-gray-100:focus {
  color: RGBA(245, 249, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 249, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-200 {
  color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-200:hover, .link-gray-200:focus {
  color: RGBA(247, 249, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 249, 252, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-300 {
  color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-300:hover, .link-gray-300:focus {
  color: RGBA(243, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(243, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-400 {
  color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-400:hover, .link-gray-400:focus {
  color: RGBA(238, 241, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(238, 241, 245, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-500 {
  color: RGBA(var(--bs-gray-500-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-500-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-500:hover, .link-gray-500:focus {
  color: RGBA(218, 223, 230, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(218, 223, 230, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-600 {
  color: RGBA(var(--bs-gray-600-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-600-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-600:hover, .link-gray-600:focus {
  color: RGBA(169, 183, 203, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(169, 183, 203, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-700 {
  color: RGBA(var(--bs-gray-700-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-700-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-700:hover, .link-gray-700:focus {
  color: RGBA(82, 97, 126, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 97, 126, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-800 {
  color: RGBA(var(--bs-gray-800-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-800-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-800:hover, .link-gray-800:focus {
  color: RGBA(64, 82, 115, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(64, 82, 115, var(--bs-link-underline-opacity, 1)) !important;
}

.link-facebook {
  color: RGBA(var(--bs-facebook-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-facebook-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-facebook:hover, .link-facebook:focus {
  color: RGBA(47, 71, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(47, 71, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-twitter {
  color: RGBA(var(--bs-twitter-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-twitter-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-twitter:hover, .link-twitter:focus {
  color: RGBA(74, 180, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(74, 180, 245, var(--bs-link-underline-opacity, 1)) !important;
}

.link-google {
  color: RGBA(var(--bs-google-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-google-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-google:hover, .link-google:focus {
  color: RGBA(175, 54, 44, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(175, 54, 44, var(--bs-link-underline-opacity, 1)) !important;
}

.link-instagram {
  color: RGBA(var(--bs-instagram-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-instagram-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-instagram:hover, .link-instagram:focus {
  color: RGBA(182, 51, 76, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(182, 51, 76, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pinterest {
  color: RGBA(var(--bs-pinterest-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pinterest-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pinterest:hover, .link-pinterest:focus {
  color: RGBA(151, 6, 22, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(151, 6, 22, var(--bs-link-underline-opacity, 1)) !important;
}

.link-youtube {
  color: RGBA(var(--bs-youtube-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-youtube-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-youtube:hover, .link-youtube:focus {
  color: RGBA(164, 26, 25, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(164, 26, 25, var(--bs-link-underline-opacity, 1)) !important;
}

.link-slack {
  color: RGBA(var(--bs-slack-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-slack-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-slack:hover, .link-slack:focus {
  color: RGBA(97, 191, 157, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 191, 157, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dribbble {
  color: RGBA(var(--bs-dribbble-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dribbble-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dribbble:hover, .link-dribbble:focus {
  color: RGBA(187, 61, 110, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(187, 61, 110, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dropbox {
  color: RGBA(var(--bs-dropbox-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dropbox-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dropbox:hover, .link-dropbox:focus {
  color: RGBA(24, 115, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 115, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-twitch {
  color: RGBA(var(--bs-twitch-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-twitch-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-twitch:hover, .link-twitch:focus {
  color: RGBA(60, 43, 99, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(60, 43, 99, var(--bs-link-underline-opacity, 1)) !important;
}

.link-paypal {
  color: RGBA(var(--bs-paypal-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-paypal-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-paypal:hover, .link-paypal:focus {
  color: RGBA(240, 194, 86, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(240, 194, 86, var(--bs-link-underline-opacity, 1)) !important;
}

.link-behance {
  color: RGBA(var(--bs-behance-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-behance-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-behance:hover, .link-behance:focus {
  color: RGBA(0, 70, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 70, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-reddit {
  color: RGBA(var(--bs-reddit-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-reddit-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-reddit:hover, .link-reddit:focus {
  color: RGBA(186, 54, 27, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(186, 54, 27, var(--bs-link-underline-opacity, 1)) !important;
}

.link-github {
  color: RGBA(var(--bs-github-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-github-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-github:hover, .link-github:focus {
  color: RGBA(27, 27, 27, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(27, 27, 27, var(--bs-link-underline-opacity, 1)) !important;
}

.link-adobe {
  color: RGBA(var(--bs-adobe-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-adobe-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-adobe:hover, .link-adobe:focus {
  color: RGBA(255, 174, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 174, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-bootstrap {
  color: RGBA(var(--bs-bootstrap-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-bootstrap-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-bootstrap:hover, .link-bootstrap:focus {
  color: RGBA(99, 31, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(99, 31, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-python {
  color: RGBA(var(--bs-python-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-python-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-python:hover, .link-python:focus {
  color: RGBA(38, 84, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 84, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-react {
  color: RGBA(var(--bs-react-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-react-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-react:hover, .link-react:focus {
  color: RGBA(51, 224, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 224, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-azure {
  color: RGBA(var(--bs-azure-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-azure-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-azure:hover, .link-azure:focus {
  color: RGBA(32, 117, 178, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(32, 117, 178, var(--bs-link-underline-opacity, 1)) !important;
}

.link-js {
  color: RGBA(var(--bs-js-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-js-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-js:hover, .link-js:focus {
  color: RGBA(242, 224, 76, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(242, 224, 76, var(--bs-link-underline-opacity, 1)) !important;
}

.link-swe {
  color: RGBA(var(--bs-swe-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-swe-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-swe:hover, .link-swe:focus {
  color: RGBA(255, 217, 98, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 217, 98, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-quaternary {
  --bs-focus-ring-color: rgba(var(--bs-quaternary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-app {
  --bs-focus-ring-color: rgba(var(--bs-primary-app-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-app {
  --bs-focus-ring-color: rgba(var(--bs-secondary-app-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-lighten {
  --bs-focus-ring-color: rgba(var(--bs-lighten-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-indigo {
  --bs-focus-ring-color: rgba(var(--bs-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-soft {
  --bs-focus-ring-color: rgba(var(--bs-soft-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-cyan {
  --bs-focus-ring-color: rgba(var(--bs-cyan-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-soft-indigo {
  --bs-focus-ring-color: rgba(var(--bs-soft-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-soft-green {
  --bs-focus-ring-color: rgba(var(--bs-soft-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-100 {
  --bs-focus-ring-color: rgba(var(--bs-gray-100-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-200 {
  --bs-focus-ring-color: rgba(var(--bs-gray-200-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-300 {
  --bs-focus-ring-color: rgba(var(--bs-gray-300-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-400 {
  --bs-focus-ring-color: rgba(var(--bs-gray-400-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-500 {
  --bs-focus-ring-color: rgba(var(--bs-gray-500-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-600 {
  --bs-focus-ring-color: rgba(var(--bs-gray-600-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-700 {
  --bs-focus-ring-color: rgba(var(--bs-gray-700-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-800 {
  --bs-focus-ring-color: rgba(var(--bs-gray-800-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-facebook {
  --bs-focus-ring-color: rgba(var(--bs-facebook-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-twitter {
  --bs-focus-ring-color: rgba(var(--bs-twitter-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-google {
  --bs-focus-ring-color: rgba(var(--bs-google-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-instagram {
  --bs-focus-ring-color: rgba(var(--bs-instagram-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pinterest {
  --bs-focus-ring-color: rgba(var(--bs-pinterest-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-youtube {
  --bs-focus-ring-color: rgba(var(--bs-youtube-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-slack {
  --bs-focus-ring-color: rgba(var(--bs-slack-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dribbble {
  --bs-focus-ring-color: rgba(var(--bs-dribbble-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dropbox {
  --bs-focus-ring-color: rgba(var(--bs-dropbox-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-twitch {
  --bs-focus-ring-color: rgba(var(--bs-twitch-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-paypal {
  --bs-focus-ring-color: rgba(var(--bs-paypal-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-behance {
  --bs-focus-ring-color: rgba(var(--bs-behance-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-reddit {
  --bs-focus-ring-color: rgba(var(--bs-reddit-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-github {
  --bs-focus-ring-color: rgba(var(--bs-github-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-adobe {
  --bs-focus-ring-color: rgba(var(--bs-adobe-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-bootstrap {
  --bs-focus-ring-color: rgba(var(--bs-bootstrap-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-python {
  --bs-focus-ring-color: rgba(var(--bs-python-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-react {
  --bs-focus-ring-color: rgba(var(--bs-react-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-azure {
  --bs-focus-ring-color: rgba(var(--bs-azure-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-js {
  --bs-focus-ring-color: rgba(var(--bs-js-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-swe {
  --bs-focus-ring-color: rgba(var(--bs-swe-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed, .headroom--unpinned, .headroom--pinned {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important;
}

.border-quaternary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-quaternary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-app {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-app-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-app {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-app-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-lighten {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-lighten-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-soft-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-soft-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-soft-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-soft-green-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-100-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-200-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-300-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-400-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-500-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-600-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-700-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-800-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-google {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-google-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-pinterest {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pinterest-rgb), var(--bs-border-opacity)) !important;
}

.border-youtube {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-youtube-rgb), var(--bs-border-opacity)) !important;
}

.border-slack {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-slack-rgb), var(--bs-border-opacity)) !important;
}

.border-dribbble {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dribbble-rgb), var(--bs-border-opacity)) !important;
}

.border-dropbox {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dropbox-rgb), var(--bs-border-opacity)) !important;
}

.border-twitch {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitch-rgb), var(--bs-border-opacity)) !important;
}

.border-paypal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-paypal-rgb), var(--bs-border-opacity)) !important;
}

.border-behance {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-behance-rgb), var(--bs-border-opacity)) !important;
}

.border-reddit {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-reddit-rgb), var(--bs-border-opacity)) !important;
}

.border-github {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-github-rgb), var(--bs-border-opacity)) !important;
}

.border-adobe {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-adobe-rgb), var(--bs-border-opacity)) !important;
}

.border-bootstrap {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-bootstrap-rgb), var(--bs-border-opacity)) !important;
}

.border-python {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-python-rgb), var(--bs-border-opacity)) !important;
}

.border-react {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-react-rgb), var(--bs-border-opacity)) !important;
}

.border-azure {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-azure-rgb), var(--bs-border-opacity)) !important;
}

.border-js {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-js-rgb), var(--bs-border-opacity)) !important;
}

.border-swe {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-swe-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.m-7 {
  margin: 8rem !important;
}

.m-8 {
  margin: 10rem !important;
}

.m-9 {
  margin: 11rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.m-11 {
  margin: 16rem !important;
}

.m-12 {
  margin: 20rem !important;
}

.m-sm {
  margin: 1rem !important;
}

.m-md {
  margin: 2rem !important;
}

.m-lg {
  margin: 4rem !important;
}

.m-xl {
  margin: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-7 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-8 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-9 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.mx-10 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-11 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-12 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-sm {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-md {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-lg {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-xl {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-7 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-8 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-9 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-10 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-11 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-12 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-sm {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-md {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-lg {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-xl {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mt-7 {
  margin-top: 8rem !important;
}

.mt-8 {
  margin-top: 10rem !important;
}

.mt-9 {
  margin-top: 11rem !important;
}

.mt-10 {
  margin-top: 14rem !important;
}

.mt-11 {
  margin-top: 16rem !important;
}

.mt-12 {
  margin-top: 20rem !important;
}

.mt-sm {
  margin-top: 1rem !important;
}

.mt-md {
  margin-top: 2rem !important;
}

.mt-lg {
  margin-top: 4rem !important;
}

.mt-xl {
  margin-top: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 5rem !important;
}

.me-7 {
  margin-right: 8rem !important;
}

.me-8 {
  margin-right: 10rem !important;
}

.me-9 {
  margin-right: 11rem !important;
}

.me-10 {
  margin-right: 14rem !important;
}

.me-11 {
  margin-right: 16rem !important;
}

.me-12 {
  margin-right: 20rem !important;
}

.me-sm {
  margin-right: 1rem !important;
}

.me-md {
  margin-right: 2rem !important;
}

.me-lg {
  margin-right: 4rem !important;
}

.me-xl {
  margin-right: 8rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.mb-7 {
  margin-bottom: 8rem !important;
}

.mb-8 {
  margin-bottom: 10rem !important;
}

.mb-9 {
  margin-bottom: 11rem !important;
}

.mb-10 {
  margin-bottom: 14rem !important;
}

.mb-11 {
  margin-bottom: 16rem !important;
}

.mb-12 {
  margin-bottom: 20rem !important;
}

.mb-sm {
  margin-bottom: 1rem !important;
}

.mb-md {
  margin-bottom: 2rem !important;
}

.mb-lg {
  margin-bottom: 4rem !important;
}

.mb-xl {
  margin-bottom: 8rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 5rem !important;
}

.ms-7 {
  margin-left: 8rem !important;
}

.ms-8 {
  margin-left: 10rem !important;
}

.ms-9 {
  margin-left: 11rem !important;
}

.ms-10 {
  margin-left: 14rem !important;
}

.ms-11 {
  margin-left: 16rem !important;
}

.ms-12 {
  margin-left: 20rem !important;
}

.ms-sm {
  margin-left: 1rem !important;
}

.ms-md {
  margin-left: 2rem !important;
}

.ms-lg {
  margin-left: 4rem !important;
}

.ms-xl {
  margin-left: 8rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.m-n7 {
  margin: -8rem !important;
}

.m-n8 {
  margin: -10rem !important;
}

.m-n9 {
  margin: -11rem !important;
}

.m-n10 {
  margin: -14rem !important;
}

.m-n11 {
  margin: -16rem !important;
}

.m-n12 {
  margin: -20rem !important;
}

.m-nsm {
  margin: -1rem !important;
}

.m-nmd {
  margin: -2rem !important;
}

.m-nlg {
  margin: -4rem !important;
}

.m-nxl {
  margin: -8rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n7 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n8 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n9 {
  margin-right: -11rem !important;
  margin-left: -11rem !important;
}

.mx-n10 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.mx-n11 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.mx-n12 {
  margin-right: -20rem !important;
  margin-left: -20rem !important;
}

.mx-nsm {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-nmd {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-nlg {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-nxl {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n7 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n8 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n9 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.my-n10 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n11 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n12 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-nsm {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-nmd {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-nlg {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-nxl {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -5rem !important;
}

.mt-n7 {
  margin-top: -8rem !important;
}

.mt-n8 {
  margin-top: -10rem !important;
}

.mt-n9 {
  margin-top: -11rem !important;
}

.mt-n10 {
  margin-top: -14rem !important;
}

.mt-n11 {
  margin-top: -16rem !important;
}

.mt-n12 {
  margin-top: -20rem !important;
}

.mt-nsm {
  margin-top: -1rem !important;
}

.mt-nmd {
  margin-top: -2rem !important;
}

.mt-nlg {
  margin-top: -4rem !important;
}

.mt-nxl {
  margin-top: -8rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -5rem !important;
}

.me-n7 {
  margin-right: -8rem !important;
}

.me-n8 {
  margin-right: -10rem !important;
}

.me-n9 {
  margin-right: -11rem !important;
}

.me-n10 {
  margin-right: -14rem !important;
}

.me-n11 {
  margin-right: -16rem !important;
}

.me-n12 {
  margin-right: -20rem !important;
}

.me-nsm {
  margin-right: -1rem !important;
}

.me-nmd {
  margin-right: -2rem !important;
}

.me-nlg {
  margin-right: -4rem !important;
}

.me-nxl {
  margin-right: -8rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -5rem !important;
}

.mb-n7 {
  margin-bottom: -8rem !important;
}

.mb-n8 {
  margin-bottom: -10rem !important;
}

.mb-n9 {
  margin-bottom: -11rem !important;
}

.mb-n10 {
  margin-bottom: -14rem !important;
}

.mb-n11 {
  margin-bottom: -16rem !important;
}

.mb-n12 {
  margin-bottom: -20rem !important;
}

.mb-nsm {
  margin-bottom: -1rem !important;
}

.mb-nmd {
  margin-bottom: -2rem !important;
}

.mb-nlg {
  margin-bottom: -4rem !important;
}

.mb-nxl {
  margin-bottom: -8rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -5rem !important;
}

.ms-n7 {
  margin-left: -8rem !important;
}

.ms-n8 {
  margin-left: -10rem !important;
}

.ms-n9 {
  margin-left: -11rem !important;
}

.ms-n10 {
  margin-left: -14rem !important;
}

.ms-n11 {
  margin-left: -16rem !important;
}

.ms-n12 {
  margin-left: -20rem !important;
}

.ms-nsm {
  margin-left: -1rem !important;
}

.ms-nmd {
  margin-left: -2rem !important;
}

.ms-nlg {
  margin-left: -4rem !important;
}

.ms-nxl {
  margin-left: -8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.p-7 {
  padding: 8rem !important;
}

.p-8 {
  padding: 10rem !important;
}

.p-9 {
  padding: 11rem !important;
}

.p-10 {
  padding: 14rem !important;
}

.p-11 {
  padding: 16rem !important;
}

.p-12 {
  padding: 20rem !important;
}

.p-sm {
  padding: 1rem !important;
}

.p-md {
  padding: 2rem !important;
}

.p-lg {
  padding: 4rem !important;
}

.p-xl {
  padding: 8rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-7 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-8 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-9 {
  padding-right: 11rem !important;
  padding-left: 11rem !important;
}

.px-10 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-11 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-12 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-sm {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-md {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-lg {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-xl {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-7 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-8 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-9 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-10 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-11 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-12 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-sm {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-md {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-lg {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-xl {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pt-7 {
  padding-top: 8rem !important;
}

.pt-8 {
  padding-top: 10rem !important;
}

.pt-9 {
  padding-top: 11rem !important;
}

.pt-10 {
  padding-top: 14rem !important;
}

.pt-11 {
  padding-top: 16rem !important;
}

.pt-12 {
  padding-top: 20rem !important;
}

.pt-sm {
  padding-top: 1rem !important;
}

.pt-md {
  padding-top: 2rem !important;
}

.pt-lg {
  padding-top: 4rem !important;
}

.pt-xl {
  padding-top: 8rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 5rem !important;
}

.pe-7 {
  padding-right: 8rem !important;
}

.pe-8 {
  padding-right: 10rem !important;
}

.pe-9 {
  padding-right: 11rem !important;
}

.pe-10 {
  padding-right: 14rem !important;
}

.pe-11 {
  padding-right: 16rem !important;
}

.pe-12 {
  padding-right: 20rem !important;
}

.pe-sm {
  padding-right: 1rem !important;
}

.pe-md {
  padding-right: 2rem !important;
}

.pe-lg {
  padding-right: 4rem !important;
}

.pe-xl {
  padding-right: 8rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.pb-7 {
  padding-bottom: 8rem !important;
}

.pb-8 {
  padding-bottom: 10rem !important;
}

.pb-9 {
  padding-bottom: 11rem !important;
}

.pb-10 {
  padding-bottom: 14rem !important;
}

.pb-11 {
  padding-bottom: 16rem !important;
}

.pb-12 {
  padding-bottom: 20rem !important;
}

.pb-sm {
  padding-bottom: 1rem !important;
}

.pb-md {
  padding-bottom: 2rem !important;
}

.pb-lg {
  padding-bottom: 4rem !important;
}

.pb-xl {
  padding-bottom: 8rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 5rem !important;
}

.ps-7 {
  padding-left: 8rem !important;
}

.ps-8 {
  padding-left: 10rem !important;
}

.ps-9 {
  padding-left: 11rem !important;
}

.ps-10 {
  padding-left: 14rem !important;
}

.ps-11 {
  padding-left: 16rem !important;
}

.ps-12 {
  padding-left: 20rem !important;
}

.ps-sm {
  padding-left: 1rem !important;
}

.ps-md {
  padding-left: 2rem !important;
}

.ps-lg {
  padding-left: 4rem !important;
}

.ps-xl {
  padding-left: 8rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 5rem !important;
}

.gap-7 {
  gap: 8rem !important;
}

.gap-8 {
  gap: 10rem !important;
}

.gap-9 {
  gap: 11rem !important;
}

.gap-10 {
  gap: 14rem !important;
}

.gap-11 {
  gap: 16rem !important;
}

.gap-12 {
  gap: 20rem !important;
}

.gap-sm {
  gap: 1rem !important;
}

.gap-md {
  gap: 2rem !important;
}

.gap-lg {
  gap: 4rem !important;
}

.gap-xl {
  gap: 8rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 5rem !important;
}

.row-gap-7 {
  row-gap: 8rem !important;
}

.row-gap-8 {
  row-gap: 10rem !important;
}

.row-gap-9 {
  row-gap: 11rem !important;
}

.row-gap-10 {
  row-gap: 14rem !important;
}

.row-gap-11 {
  row-gap: 16rem !important;
}

.row-gap-12 {
  row-gap: 20rem !important;
}

.row-gap-sm {
  row-gap: 1rem !important;
}

.row-gap-md {
  row-gap: 2rem !important;
}

.row-gap-lg {
  row-gap: 4rem !important;
}

.row-gap-xl {
  row-gap: 8rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.column-gap-6 {
  -moz-column-gap: 5rem !important;
       column-gap: 5rem !important;
}

.column-gap-7 {
  -moz-column-gap: 8rem !important;
       column-gap: 8rem !important;
}

.column-gap-8 {
  -moz-column-gap: 10rem !important;
       column-gap: 10rem !important;
}

.column-gap-9 {
  -moz-column-gap: 11rem !important;
       column-gap: 11rem !important;
}

.column-gap-10 {
  -moz-column-gap: 14rem !important;
       column-gap: 14rem !important;
}

.column-gap-11 {
  -moz-column-gap: 16rem !important;
       column-gap: 16rem !important;
}

.column-gap-12 {
  -moz-column-gap: 20rem !important;
       column-gap: 20rem !important;
}

.column-gap-sm {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-md {
  -moz-column-gap: 2rem !important;
       column-gap: 2rem !important;
}

.column-gap-lg {
  -moz-column-gap: 4rem !important;
       column-gap: 4rem !important;
}

.column-gap-xl {
  -moz-column-gap: 8rem !important;
       column-gap: 8rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
}

.text-quaternary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-quaternary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-app {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-app-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-app {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-app-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-lighten {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-lighten-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-soft-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-soft-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-soft-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-soft-green-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-500-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-600-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-700-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-google {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-google-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-pinterest {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pinterest-rgb), var(--bs-text-opacity)) !important;
}

.text-youtube {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-youtube-rgb), var(--bs-text-opacity)) !important;
}

.text-slack {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-slack-rgb), var(--bs-text-opacity)) !important;
}

.text-dribbble {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dribbble-rgb), var(--bs-text-opacity)) !important;
}

.text-dropbox {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dropbox-rgb), var(--bs-text-opacity)) !important;
}

.text-twitch {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitch-rgb), var(--bs-text-opacity)) !important;
}

.text-paypal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-paypal-rgb), var(--bs-text-opacity)) !important;
}

.text-behance {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-behance-rgb), var(--bs-text-opacity)) !important;
}

.text-reddit {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-reddit-rgb), var(--bs-text-opacity)) !important;
}

.text-github {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-github-rgb), var(--bs-text-opacity)) !important;
}

.text-adobe {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-adobe-rgb), var(--bs-text-opacity)) !important;
}

.text-bootstrap {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bootstrap-rgb), var(--bs-text-opacity)) !important;
}

.text-python {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-python-rgb), var(--bs-text-opacity)) !important;
}

.text-react {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-react-rgb), var(--bs-text-opacity)) !important;
}

.text-azure {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-azure-rgb), var(--bs-text-opacity)) !important;
}

.text-js {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-js-rgb), var(--bs-text-opacity)) !important;
}

.text-swe {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-swe-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(46, 54, 80, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-quaternary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-quaternary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-app {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-app-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-app {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-app-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-lighten {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-lighten-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-soft {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-soft-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-cyan-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-soft-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-soft-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-soft-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-soft-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-100 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-200 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-300 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-400 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-500 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-500-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-600 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-600-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-700 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-700-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-800 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-800-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-facebook {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-facebook-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-twitter {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-twitter-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-google {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-google-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-instagram {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-instagram-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pinterest {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pinterest-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-youtube {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-youtube-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-slack {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-slack-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dribbble {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dribbble-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dropbox {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dropbox-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-twitch {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-twitch-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-paypal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-paypal-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-behance {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-behance-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-reddit {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-reddit-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-github {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-github-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-adobe {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-adobe-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-bootstrap {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-bootstrap-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-python {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-python-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-react {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-react-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-azure {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-azure-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-js {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-js-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-swe {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-swe-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-quaternary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-quaternary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-app {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-app-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-app {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-app-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-lighten {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-lighten-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-soft-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-soft-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-soft-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-soft-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-facebook {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
}

.bg-twitter {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
}

.bg-google {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-google-rgb), var(--bs-bg-opacity)) !important;
}

.bg-instagram {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pinterest {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pinterest-rgb), var(--bs-bg-opacity)) !important;
}

.bg-youtube {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-youtube-rgb), var(--bs-bg-opacity)) !important;
}

.bg-slack {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-slack-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dribbble {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dribbble-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dropbox {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dropbox-rgb), var(--bs-bg-opacity)) !important;
}

.bg-twitch {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-twitch-rgb), var(--bs-bg-opacity)) !important;
}

.bg-paypal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-paypal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-behance {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-behance-rgb), var(--bs-bg-opacity)) !important;
}

.bg-reddit {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-reddit-rgb), var(--bs-bg-opacity)) !important;
}

.bg-github {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-github-rgb), var(--bs-bg-opacity)) !important;
}

.bg-adobe {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-adobe-rgb), var(--bs-bg-opacity)) !important;
}

.bg-bootstrap {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bootstrap-rgb), var(--bs-bg-opacity)) !important;
}

.bg-python {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-python-rgb), var(--bs-bg-opacity)) !important;
}

.bg-react {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-react-rgb), var(--bs-bg-opacity)) !important;
}

.bg-azure {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-azure-rgb), var(--bs-bg-opacity)) !important;
}

.bg-js {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-js-rgb), var(--bs-bg-opacity)) !important;
}

.bg-swe {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-swe-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 5rem !important;
  }
  .m-sm-7 {
    margin: 8rem !important;
  }
  .m-sm-8 {
    margin: 10rem !important;
  }
  .m-sm-9 {
    margin: 11rem !important;
  }
  .m-sm-10 {
    margin: 14rem !important;
  }
  .m-sm-11 {
    margin: 16rem !important;
  }
  .m-sm-12 {
    margin: 20rem !important;
  }
  .m-sm-sm {
    margin: 1rem !important;
  }
  .m-sm-md {
    margin: 2rem !important;
  }
  .m-sm-lg {
    margin: 4rem !important;
  }
  .m-sm-xl {
    margin: 8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-sm-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-sm-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 5rem !important;
  }
  .mt-sm-7 {
    margin-top: 8rem !important;
  }
  .mt-sm-8 {
    margin-top: 10rem !important;
  }
  .mt-sm-9 {
    margin-top: 11rem !important;
  }
  .mt-sm-10 {
    margin-top: 14rem !important;
  }
  .mt-sm-11 {
    margin-top: 16rem !important;
  }
  .mt-sm-12 {
    margin-top: 20rem !important;
  }
  .mt-sm-sm {
    margin-top: 1rem !important;
  }
  .mt-sm-md {
    margin-top: 2rem !important;
  }
  .mt-sm-lg {
    margin-top: 4rem !important;
  }
  .mt-sm-xl {
    margin-top: 8rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 5rem !important;
  }
  .me-sm-7 {
    margin-right: 8rem !important;
  }
  .me-sm-8 {
    margin-right: 10rem !important;
  }
  .me-sm-9 {
    margin-right: 11rem !important;
  }
  .me-sm-10 {
    margin-right: 14rem !important;
  }
  .me-sm-11 {
    margin-right: 16rem !important;
  }
  .me-sm-12 {
    margin-right: 20rem !important;
  }
  .me-sm-sm {
    margin-right: 1rem !important;
  }
  .me-sm-md {
    margin-right: 2rem !important;
  }
  .me-sm-lg {
    margin-right: 4rem !important;
  }
  .me-sm-xl {
    margin-right: 8rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 11rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 1rem !important;
  }
  .mb-sm-md {
    margin-bottom: 2rem !important;
  }
  .mb-sm-lg {
    margin-bottom: 4rem !important;
  }
  .mb-sm-xl {
    margin-bottom: 8rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 5rem !important;
  }
  .ms-sm-7 {
    margin-left: 8rem !important;
  }
  .ms-sm-8 {
    margin-left: 10rem !important;
  }
  .ms-sm-9 {
    margin-left: 11rem !important;
  }
  .ms-sm-10 {
    margin-left: 14rem !important;
  }
  .ms-sm-11 {
    margin-left: 16rem !important;
  }
  .ms-sm-12 {
    margin-left: 20rem !important;
  }
  .ms-sm-sm {
    margin-left: 1rem !important;
  }
  .ms-sm-md {
    margin-left: 2rem !important;
  }
  .ms-sm-lg {
    margin-left: 4rem !important;
  }
  .ms-sm-xl {
    margin-left: 8rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -5rem !important;
  }
  .m-sm-n7 {
    margin: -8rem !important;
  }
  .m-sm-n8 {
    margin: -10rem !important;
  }
  .m-sm-n9 {
    margin: -11rem !important;
  }
  .m-sm-n10 {
    margin: -14rem !important;
  }
  .m-sm-n11 {
    margin: -16rem !important;
  }
  .m-sm-n12 {
    margin: -20rem !important;
  }
  .m-sm-nsm {
    margin: -1rem !important;
  }
  .m-sm-nmd {
    margin: -2rem !important;
  }
  .m-sm-nlg {
    margin: -4rem !important;
  }
  .m-sm-nxl {
    margin: -8rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-sm-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-sm-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-sm-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-sm-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-sm-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-sm-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-sm-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-sm-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-sm-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-sm-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -8rem !important;
  }
  .mt-sm-n8 {
    margin-top: -10rem !important;
  }
  .mt-sm-n9 {
    margin-top: -11rem !important;
  }
  .mt-sm-n10 {
    margin-top: -14rem !important;
  }
  .mt-sm-n11 {
    margin-top: -16rem !important;
  }
  .mt-sm-n12 {
    margin-top: -20rem !important;
  }
  .mt-sm-nsm {
    margin-top: -1rem !important;
  }
  .mt-sm-nmd {
    margin-top: -2rem !important;
  }
  .mt-sm-nlg {
    margin-top: -4rem !important;
  }
  .mt-sm-nxl {
    margin-top: -8rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -5rem !important;
  }
  .me-sm-n7 {
    margin-right: -8rem !important;
  }
  .me-sm-n8 {
    margin-right: -10rem !important;
  }
  .me-sm-n9 {
    margin-right: -11rem !important;
  }
  .me-sm-n10 {
    margin-right: -14rem !important;
  }
  .me-sm-n11 {
    margin-right: -16rem !important;
  }
  .me-sm-n12 {
    margin-right: -20rem !important;
  }
  .me-sm-nsm {
    margin-right: -1rem !important;
  }
  .me-sm-nmd {
    margin-right: -2rem !important;
  }
  .me-sm-nlg {
    margin-right: -4rem !important;
  }
  .me-sm-nxl {
    margin-right: -8rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -14rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -16rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -20rem !important;
  }
  .mb-sm-nsm {
    margin-bottom: -1rem !important;
  }
  .mb-sm-nmd {
    margin-bottom: -2rem !important;
  }
  .mb-sm-nlg {
    margin-bottom: -4rem !important;
  }
  .mb-sm-nxl {
    margin-bottom: -8rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -8rem !important;
  }
  .ms-sm-n8 {
    margin-left: -10rem !important;
  }
  .ms-sm-n9 {
    margin-left: -11rem !important;
  }
  .ms-sm-n10 {
    margin-left: -14rem !important;
  }
  .ms-sm-n11 {
    margin-left: -16rem !important;
  }
  .ms-sm-n12 {
    margin-left: -20rem !important;
  }
  .ms-sm-nsm {
    margin-left: -1rem !important;
  }
  .ms-sm-nmd {
    margin-left: -2rem !important;
  }
  .ms-sm-nlg {
    margin-left: -4rem !important;
  }
  .ms-sm-nxl {
    margin-left: -8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 5rem !important;
  }
  .p-sm-7 {
    padding: 8rem !important;
  }
  .p-sm-8 {
    padding: 10rem !important;
  }
  .p-sm-9 {
    padding: 11rem !important;
  }
  .p-sm-10 {
    padding: 14rem !important;
  }
  .p-sm-11 {
    padding: 16rem !important;
  }
  .p-sm-12 {
    padding: 20rem !important;
  }
  .p-sm-sm {
    padding: 1rem !important;
  }
  .p-sm-md {
    padding: 2rem !important;
  }
  .p-sm-lg {
    padding: 4rem !important;
  }
  .p-sm-xl {
    padding: 8rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-sm-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-sm-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-sm-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-sm-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-sm-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-sm-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 5rem !important;
  }
  .pt-sm-7 {
    padding-top: 8rem !important;
  }
  .pt-sm-8 {
    padding-top: 10rem !important;
  }
  .pt-sm-9 {
    padding-top: 11rem !important;
  }
  .pt-sm-10 {
    padding-top: 14rem !important;
  }
  .pt-sm-11 {
    padding-top: 16rem !important;
  }
  .pt-sm-12 {
    padding-top: 20rem !important;
  }
  .pt-sm-sm {
    padding-top: 1rem !important;
  }
  .pt-sm-md {
    padding-top: 2rem !important;
  }
  .pt-sm-lg {
    padding-top: 4rem !important;
  }
  .pt-sm-xl {
    padding-top: 8rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 5rem !important;
  }
  .pe-sm-7 {
    padding-right: 8rem !important;
  }
  .pe-sm-8 {
    padding-right: 10rem !important;
  }
  .pe-sm-9 {
    padding-right: 11rem !important;
  }
  .pe-sm-10 {
    padding-right: 14rem !important;
  }
  .pe-sm-11 {
    padding-right: 16rem !important;
  }
  .pe-sm-12 {
    padding-right: 20rem !important;
  }
  .pe-sm-sm {
    padding-right: 1rem !important;
  }
  .pe-sm-md {
    padding-right: 2rem !important;
  }
  .pe-sm-lg {
    padding-right: 4rem !important;
  }
  .pe-sm-xl {
    padding-right: 8rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 11rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 14rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 20rem !important;
  }
  .pb-sm-sm {
    padding-bottom: 1rem !important;
  }
  .pb-sm-md {
    padding-bottom: 2rem !important;
  }
  .pb-sm-lg {
    padding-bottom: 4rem !important;
  }
  .pb-sm-xl {
    padding-bottom: 8rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 5rem !important;
  }
  .ps-sm-7 {
    padding-left: 8rem !important;
  }
  .ps-sm-8 {
    padding-left: 10rem !important;
  }
  .ps-sm-9 {
    padding-left: 11rem !important;
  }
  .ps-sm-10 {
    padding-left: 14rem !important;
  }
  .ps-sm-11 {
    padding-left: 16rem !important;
  }
  .ps-sm-12 {
    padding-left: 20rem !important;
  }
  .ps-sm-sm {
    padding-left: 1rem !important;
  }
  .ps-sm-md {
    padding-left: 2rem !important;
  }
  .ps-sm-lg {
    padding-left: 4rem !important;
  }
  .ps-sm-xl {
    padding-left: 8rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 5rem !important;
  }
  .gap-sm-7 {
    gap: 8rem !important;
  }
  .gap-sm-8 {
    gap: 10rem !important;
  }
  .gap-sm-9 {
    gap: 11rem !important;
  }
  .gap-sm-10 {
    gap: 14rem !important;
  }
  .gap-sm-11 {
    gap: 16rem !important;
  }
  .gap-sm-12 {
    gap: 20rem !important;
  }
  .gap-sm-sm {
    gap: 1rem !important;
  }
  .gap-sm-md {
    gap: 2rem !important;
  }
  .gap-sm-lg {
    gap: 4rem !important;
  }
  .gap-sm-xl {
    gap: 8rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 11rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 14rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 16rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 20rem !important;
  }
  .row-gap-sm-sm {
    row-gap: 1rem !important;
  }
  .row-gap-sm-md {
    row-gap: 2rem !important;
  }
  .row-gap-sm-lg {
    row-gap: 4rem !important;
  }
  .row-gap-sm-xl {
    row-gap: 8rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }
  .column-gap-sm-7 {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .column-gap-sm-8 {
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }
  .column-gap-sm-9 {
    -moz-column-gap: 11rem !important;
         column-gap: 11rem !important;
  }
  .column-gap-sm-10 {
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }
  .column-gap-sm-11 {
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }
  .column-gap-sm-12 {
    -moz-column-gap: 20rem !important;
         column-gap: 20rem !important;
  }
  .column-gap-sm-sm {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-md {
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .column-gap-sm-lg {
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }
  .column-gap-sm-xl {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 5rem !important;
  }
  .m-md-7 {
    margin: 8rem !important;
  }
  .m-md-8 {
    margin: 10rem !important;
  }
  .m-md-9 {
    margin: 11rem !important;
  }
  .m-md-10 {
    margin: 14rem !important;
  }
  .m-md-11 {
    margin: 16rem !important;
  }
  .m-md-12 {
    margin: 20rem !important;
  }
  .m-md-sm {
    margin: 1rem !important;
  }
  .m-md-md {
    margin: 2rem !important;
  }
  .m-md-lg {
    margin: 4rem !important;
  }
  .m-md-xl {
    margin: 8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-md-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-md-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 5rem !important;
  }
  .mt-md-7 {
    margin-top: 8rem !important;
  }
  .mt-md-8 {
    margin-top: 10rem !important;
  }
  .mt-md-9 {
    margin-top: 11rem !important;
  }
  .mt-md-10 {
    margin-top: 14rem !important;
  }
  .mt-md-11 {
    margin-top: 16rem !important;
  }
  .mt-md-12 {
    margin-top: 20rem !important;
  }
  .mt-md-sm {
    margin-top: 1rem !important;
  }
  .mt-md-md {
    margin-top: 2rem !important;
  }
  .mt-md-lg {
    margin-top: 4rem !important;
  }
  .mt-md-xl {
    margin-top: 8rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 5rem !important;
  }
  .me-md-7 {
    margin-right: 8rem !important;
  }
  .me-md-8 {
    margin-right: 10rem !important;
  }
  .me-md-9 {
    margin-right: 11rem !important;
  }
  .me-md-10 {
    margin-right: 14rem !important;
  }
  .me-md-11 {
    margin-right: 16rem !important;
  }
  .me-md-12 {
    margin-right: 20rem !important;
  }
  .me-md-sm {
    margin-right: 1rem !important;
  }
  .me-md-md {
    margin-right: 2rem !important;
  }
  .me-md-lg {
    margin-right: 4rem !important;
  }
  .me-md-xl {
    margin-right: 8rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 8rem !important;
  }
  .mb-md-8 {
    margin-bottom: 10rem !important;
  }
  .mb-md-9 {
    margin-bottom: 11rem !important;
  }
  .mb-md-10 {
    margin-bottom: 14rem !important;
  }
  .mb-md-11 {
    margin-bottom: 16rem !important;
  }
  .mb-md-12 {
    margin-bottom: 20rem !important;
  }
  .mb-md-sm {
    margin-bottom: 1rem !important;
  }
  .mb-md-md {
    margin-bottom: 2rem !important;
  }
  .mb-md-lg {
    margin-bottom: 4rem !important;
  }
  .mb-md-xl {
    margin-bottom: 8rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 5rem !important;
  }
  .ms-md-7 {
    margin-left: 8rem !important;
  }
  .ms-md-8 {
    margin-left: 10rem !important;
  }
  .ms-md-9 {
    margin-left: 11rem !important;
  }
  .ms-md-10 {
    margin-left: 14rem !important;
  }
  .ms-md-11 {
    margin-left: 16rem !important;
  }
  .ms-md-12 {
    margin-left: 20rem !important;
  }
  .ms-md-sm {
    margin-left: 1rem !important;
  }
  .ms-md-md {
    margin-left: 2rem !important;
  }
  .ms-md-lg {
    margin-left: 4rem !important;
  }
  .ms-md-xl {
    margin-left: 8rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -5rem !important;
  }
  .m-md-n7 {
    margin: -8rem !important;
  }
  .m-md-n8 {
    margin: -10rem !important;
  }
  .m-md-n9 {
    margin: -11rem !important;
  }
  .m-md-n10 {
    margin: -14rem !important;
  }
  .m-md-n11 {
    margin: -16rem !important;
  }
  .m-md-n12 {
    margin: -20rem !important;
  }
  .m-md-nsm {
    margin: -1rem !important;
  }
  .m-md-nmd {
    margin: -2rem !important;
  }
  .m-md-nlg {
    margin: -4rem !important;
  }
  .m-md-nxl {
    margin: -8rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-md-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-md-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-md-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-md-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-md-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-md-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-md-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-md-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-md-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-md-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -5rem !important;
  }
  .mt-md-n7 {
    margin-top: -8rem !important;
  }
  .mt-md-n8 {
    margin-top: -10rem !important;
  }
  .mt-md-n9 {
    margin-top: -11rem !important;
  }
  .mt-md-n10 {
    margin-top: -14rem !important;
  }
  .mt-md-n11 {
    margin-top: -16rem !important;
  }
  .mt-md-n12 {
    margin-top: -20rem !important;
  }
  .mt-md-nsm {
    margin-top: -1rem !important;
  }
  .mt-md-nmd {
    margin-top: -2rem !important;
  }
  .mt-md-nlg {
    margin-top: -4rem !important;
  }
  .mt-md-nxl {
    margin-top: -8rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -5rem !important;
  }
  .me-md-n7 {
    margin-right: -8rem !important;
  }
  .me-md-n8 {
    margin-right: -10rem !important;
  }
  .me-md-n9 {
    margin-right: -11rem !important;
  }
  .me-md-n10 {
    margin-right: -14rem !important;
  }
  .me-md-n11 {
    margin-right: -16rem !important;
  }
  .me-md-n12 {
    margin-right: -20rem !important;
  }
  .me-md-nsm {
    margin-right: -1rem !important;
  }
  .me-md-nmd {
    margin-right: -2rem !important;
  }
  .me-md-nlg {
    margin-right: -4rem !important;
  }
  .me-md-nxl {
    margin-right: -8rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -14rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -16rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -20rem !important;
  }
  .mb-md-nsm {
    margin-bottom: -1rem !important;
  }
  .mb-md-nmd {
    margin-bottom: -2rem !important;
  }
  .mb-md-nlg {
    margin-bottom: -4rem !important;
  }
  .mb-md-nxl {
    margin-bottom: -8rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -5rem !important;
  }
  .ms-md-n7 {
    margin-left: -8rem !important;
  }
  .ms-md-n8 {
    margin-left: -10rem !important;
  }
  .ms-md-n9 {
    margin-left: -11rem !important;
  }
  .ms-md-n10 {
    margin-left: -14rem !important;
  }
  .ms-md-n11 {
    margin-left: -16rem !important;
  }
  .ms-md-n12 {
    margin-left: -20rem !important;
  }
  .ms-md-nsm {
    margin-left: -1rem !important;
  }
  .ms-md-nmd {
    margin-left: -2rem !important;
  }
  .ms-md-nlg {
    margin-left: -4rem !important;
  }
  .ms-md-nxl {
    margin-left: -8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
  .p-md-7 {
    padding: 8rem !important;
  }
  .p-md-8 {
    padding: 10rem !important;
  }
  .p-md-9 {
    padding: 11rem !important;
  }
  .p-md-10 {
    padding: 14rem !important;
  }
  .p-md-11 {
    padding: 16rem !important;
  }
  .p-md-12 {
    padding: 20rem !important;
  }
  .p-md-sm {
    padding: 1rem !important;
  }
  .p-md-md {
    padding: 2rem !important;
  }
  .p-md-lg {
    padding: 4rem !important;
  }
  .p-md-xl {
    padding: 8rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-md-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-md-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-md-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-md-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-md-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-md-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 5rem !important;
  }
  .pt-md-7 {
    padding-top: 8rem !important;
  }
  .pt-md-8 {
    padding-top: 10rem !important;
  }
  .pt-md-9 {
    padding-top: 11rem !important;
  }
  .pt-md-10 {
    padding-top: 14rem !important;
  }
  .pt-md-11 {
    padding-top: 16rem !important;
  }
  .pt-md-12 {
    padding-top: 20rem !important;
  }
  .pt-md-sm {
    padding-top: 1rem !important;
  }
  .pt-md-md {
    padding-top: 2rem !important;
  }
  .pt-md-lg {
    padding-top: 4rem !important;
  }
  .pt-md-xl {
    padding-top: 8rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 5rem !important;
  }
  .pe-md-7 {
    padding-right: 8rem !important;
  }
  .pe-md-8 {
    padding-right: 10rem !important;
  }
  .pe-md-9 {
    padding-right: 11rem !important;
  }
  .pe-md-10 {
    padding-right: 14rem !important;
  }
  .pe-md-11 {
    padding-right: 16rem !important;
  }
  .pe-md-12 {
    padding-right: 20rem !important;
  }
  .pe-md-sm {
    padding-right: 1rem !important;
  }
  .pe-md-md {
    padding-right: 2rem !important;
  }
  .pe-md-lg {
    padding-right: 4rem !important;
  }
  .pe-md-xl {
    padding-right: 8rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 8rem !important;
  }
  .pb-md-8 {
    padding-bottom: 10rem !important;
  }
  .pb-md-9 {
    padding-bottom: 11rem !important;
  }
  .pb-md-10 {
    padding-bottom: 14rem !important;
  }
  .pb-md-11 {
    padding-bottom: 16rem !important;
  }
  .pb-md-12 {
    padding-bottom: 20rem !important;
  }
  .pb-md-sm {
    padding-bottom: 1rem !important;
  }
  .pb-md-md {
    padding-bottom: 2rem !important;
  }
  .pb-md-lg {
    padding-bottom: 4rem !important;
  }
  .pb-md-xl {
    padding-bottom: 8rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 5rem !important;
  }
  .ps-md-7 {
    padding-left: 8rem !important;
  }
  .ps-md-8 {
    padding-left: 10rem !important;
  }
  .ps-md-9 {
    padding-left: 11rem !important;
  }
  .ps-md-10 {
    padding-left: 14rem !important;
  }
  .ps-md-11 {
    padding-left: 16rem !important;
  }
  .ps-md-12 {
    padding-left: 20rem !important;
  }
  .ps-md-sm {
    padding-left: 1rem !important;
  }
  .ps-md-md {
    padding-left: 2rem !important;
  }
  .ps-md-lg {
    padding-left: 4rem !important;
  }
  .ps-md-xl {
    padding-left: 8rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 5rem !important;
  }
  .gap-md-7 {
    gap: 8rem !important;
  }
  .gap-md-8 {
    gap: 10rem !important;
  }
  .gap-md-9 {
    gap: 11rem !important;
  }
  .gap-md-10 {
    gap: 14rem !important;
  }
  .gap-md-11 {
    gap: 16rem !important;
  }
  .gap-md-12 {
    gap: 20rem !important;
  }
  .gap-md-sm {
    gap: 1rem !important;
  }
  .gap-md-md {
    gap: 2rem !important;
  }
  .gap-md-lg {
    gap: 4rem !important;
  }
  .gap-md-xl {
    gap: 8rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 8rem !important;
  }
  .row-gap-md-8 {
    row-gap: 10rem !important;
  }
  .row-gap-md-9 {
    row-gap: 11rem !important;
  }
  .row-gap-md-10 {
    row-gap: 14rem !important;
  }
  .row-gap-md-11 {
    row-gap: 16rem !important;
  }
  .row-gap-md-12 {
    row-gap: 20rem !important;
  }
  .row-gap-md-sm {
    row-gap: 1rem !important;
  }
  .row-gap-md-md {
    row-gap: 2rem !important;
  }
  .row-gap-md-lg {
    row-gap: 4rem !important;
  }
  .row-gap-md-xl {
    row-gap: 8rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }
  .column-gap-md-7 {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .column-gap-md-8 {
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }
  .column-gap-md-9 {
    -moz-column-gap: 11rem !important;
         column-gap: 11rem !important;
  }
  .column-gap-md-10 {
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }
  .column-gap-md-11 {
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }
  .column-gap-md-12 {
    -moz-column-gap: 20rem !important;
         column-gap: 20rem !important;
  }
  .column-gap-md-sm {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-md {
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .column-gap-md-lg {
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }
  .column-gap-md-xl {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 5rem !important;
  }
  .m-lg-7 {
    margin: 8rem !important;
  }
  .m-lg-8 {
    margin: 10rem !important;
  }
  .m-lg-9 {
    margin: 11rem !important;
  }
  .m-lg-10 {
    margin: 14rem !important;
  }
  .m-lg-11 {
    margin: 16rem !important;
  }
  .m-lg-12 {
    margin: 20rem !important;
  }
  .m-lg-sm {
    margin: 1rem !important;
  }
  .m-lg-md {
    margin: 2rem !important;
  }
  .m-lg-lg {
    margin: 4rem !important;
  }
  .m-lg-xl {
    margin: 8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-lg-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-lg-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 5rem !important;
  }
  .mt-lg-7 {
    margin-top: 8rem !important;
  }
  .mt-lg-8 {
    margin-top: 10rem !important;
  }
  .mt-lg-9 {
    margin-top: 11rem !important;
  }
  .mt-lg-10 {
    margin-top: 14rem !important;
  }
  .mt-lg-11 {
    margin-top: 16rem !important;
  }
  .mt-lg-12 {
    margin-top: 20rem !important;
  }
  .mt-lg-sm {
    margin-top: 1rem !important;
  }
  .mt-lg-md {
    margin-top: 2rem !important;
  }
  .mt-lg-lg {
    margin-top: 4rem !important;
  }
  .mt-lg-xl {
    margin-top: 8rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 5rem !important;
  }
  .me-lg-7 {
    margin-right: 8rem !important;
  }
  .me-lg-8 {
    margin-right: 10rem !important;
  }
  .me-lg-9 {
    margin-right: 11rem !important;
  }
  .me-lg-10 {
    margin-right: 14rem !important;
  }
  .me-lg-11 {
    margin-right: 16rem !important;
  }
  .me-lg-12 {
    margin-right: 20rem !important;
  }
  .me-lg-sm {
    margin-right: 1rem !important;
  }
  .me-lg-md {
    margin-right: 2rem !important;
  }
  .me-lg-lg {
    margin-right: 4rem !important;
  }
  .me-lg-xl {
    margin-right: 8rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 11rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 1rem !important;
  }
  .mb-lg-md {
    margin-bottom: 2rem !important;
  }
  .mb-lg-lg {
    margin-bottom: 4rem !important;
  }
  .mb-lg-xl {
    margin-bottom: 8rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 5rem !important;
  }
  .ms-lg-7 {
    margin-left: 8rem !important;
  }
  .ms-lg-8 {
    margin-left: 10rem !important;
  }
  .ms-lg-9 {
    margin-left: 11rem !important;
  }
  .ms-lg-10 {
    margin-left: 14rem !important;
  }
  .ms-lg-11 {
    margin-left: 16rem !important;
  }
  .ms-lg-12 {
    margin-left: 20rem !important;
  }
  .ms-lg-sm {
    margin-left: 1rem !important;
  }
  .ms-lg-md {
    margin-left: 2rem !important;
  }
  .ms-lg-lg {
    margin-left: 4rem !important;
  }
  .ms-lg-xl {
    margin-left: 8rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -5rem !important;
  }
  .m-lg-n7 {
    margin: -8rem !important;
  }
  .m-lg-n8 {
    margin: -10rem !important;
  }
  .m-lg-n9 {
    margin: -11rem !important;
  }
  .m-lg-n10 {
    margin: -14rem !important;
  }
  .m-lg-n11 {
    margin: -16rem !important;
  }
  .m-lg-n12 {
    margin: -20rem !important;
  }
  .m-lg-nsm {
    margin: -1rem !important;
  }
  .m-lg-nmd {
    margin: -2rem !important;
  }
  .m-lg-nlg {
    margin: -4rem !important;
  }
  .m-lg-nxl {
    margin: -8rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-lg-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-lg-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-lg-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-lg-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-lg-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-lg-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-lg-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-lg-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-lg-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-lg-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -8rem !important;
  }
  .mt-lg-n8 {
    margin-top: -10rem !important;
  }
  .mt-lg-n9 {
    margin-top: -11rem !important;
  }
  .mt-lg-n10 {
    margin-top: -14rem !important;
  }
  .mt-lg-n11 {
    margin-top: -16rem !important;
  }
  .mt-lg-n12 {
    margin-top: -20rem !important;
  }
  .mt-lg-nsm {
    margin-top: -1rem !important;
  }
  .mt-lg-nmd {
    margin-top: -2rem !important;
  }
  .mt-lg-nlg {
    margin-top: -4rem !important;
  }
  .mt-lg-nxl {
    margin-top: -8rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -5rem !important;
  }
  .me-lg-n7 {
    margin-right: -8rem !important;
  }
  .me-lg-n8 {
    margin-right: -10rem !important;
  }
  .me-lg-n9 {
    margin-right: -11rem !important;
  }
  .me-lg-n10 {
    margin-right: -14rem !important;
  }
  .me-lg-n11 {
    margin-right: -16rem !important;
  }
  .me-lg-n12 {
    margin-right: -20rem !important;
  }
  .me-lg-nsm {
    margin-right: -1rem !important;
  }
  .me-lg-nmd {
    margin-right: -2rem !important;
  }
  .me-lg-nlg {
    margin-right: -4rem !important;
  }
  .me-lg-nxl {
    margin-right: -8rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -14rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -16rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -20rem !important;
  }
  .mb-lg-nsm {
    margin-bottom: -1rem !important;
  }
  .mb-lg-nmd {
    margin-bottom: -2rem !important;
  }
  .mb-lg-nlg {
    margin-bottom: -4rem !important;
  }
  .mb-lg-nxl {
    margin-bottom: -8rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -8rem !important;
  }
  .ms-lg-n8 {
    margin-left: -10rem !important;
  }
  .ms-lg-n9 {
    margin-left: -11rem !important;
  }
  .ms-lg-n10 {
    margin-left: -14rem !important;
  }
  .ms-lg-n11 {
    margin-left: -16rem !important;
  }
  .ms-lg-n12 {
    margin-left: -20rem !important;
  }
  .ms-lg-nsm {
    margin-left: -1rem !important;
  }
  .ms-lg-nmd {
    margin-left: -2rem !important;
  }
  .ms-lg-nlg {
    margin-left: -4rem !important;
  }
  .ms-lg-nxl {
    margin-left: -8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
  .p-lg-7 {
    padding: 8rem !important;
  }
  .p-lg-8 {
    padding: 10rem !important;
  }
  .p-lg-9 {
    padding: 11rem !important;
  }
  .p-lg-10 {
    padding: 14rem !important;
  }
  .p-lg-11 {
    padding: 16rem !important;
  }
  .p-lg-12 {
    padding: 20rem !important;
  }
  .p-lg-sm {
    padding: 1rem !important;
  }
  .p-lg-md {
    padding: 2rem !important;
  }
  .p-lg-lg {
    padding: 4rem !important;
  }
  .p-lg-xl {
    padding: 8rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-lg-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-lg-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-lg-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-lg-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-lg-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-lg-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 5rem !important;
  }
  .pt-lg-7 {
    padding-top: 8rem !important;
  }
  .pt-lg-8 {
    padding-top: 10rem !important;
  }
  .pt-lg-9 {
    padding-top: 11rem !important;
  }
  .pt-lg-10 {
    padding-top: 14rem !important;
  }
  .pt-lg-11 {
    padding-top: 16rem !important;
  }
  .pt-lg-12 {
    padding-top: 20rem !important;
  }
  .pt-lg-sm {
    padding-top: 1rem !important;
  }
  .pt-lg-md {
    padding-top: 2rem !important;
  }
  .pt-lg-lg {
    padding-top: 4rem !important;
  }
  .pt-lg-xl {
    padding-top: 8rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 5rem !important;
  }
  .pe-lg-7 {
    padding-right: 8rem !important;
  }
  .pe-lg-8 {
    padding-right: 10rem !important;
  }
  .pe-lg-9 {
    padding-right: 11rem !important;
  }
  .pe-lg-10 {
    padding-right: 14rem !important;
  }
  .pe-lg-11 {
    padding-right: 16rem !important;
  }
  .pe-lg-12 {
    padding-right: 20rem !important;
  }
  .pe-lg-sm {
    padding-right: 1rem !important;
  }
  .pe-lg-md {
    padding-right: 2rem !important;
  }
  .pe-lg-lg {
    padding-right: 4rem !important;
  }
  .pe-lg-xl {
    padding-right: 8rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 11rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 20rem !important;
  }
  .pb-lg-sm {
    padding-bottom: 1rem !important;
  }
  .pb-lg-md {
    padding-bottom: 2rem !important;
  }
  .pb-lg-lg {
    padding-bottom: 4rem !important;
  }
  .pb-lg-xl {
    padding-bottom: 8rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 5rem !important;
  }
  .ps-lg-7 {
    padding-left: 8rem !important;
  }
  .ps-lg-8 {
    padding-left: 10rem !important;
  }
  .ps-lg-9 {
    padding-left: 11rem !important;
  }
  .ps-lg-10 {
    padding-left: 14rem !important;
  }
  .ps-lg-11 {
    padding-left: 16rem !important;
  }
  .ps-lg-12 {
    padding-left: 20rem !important;
  }
  .ps-lg-sm {
    padding-left: 1rem !important;
  }
  .ps-lg-md {
    padding-left: 2rem !important;
  }
  .ps-lg-lg {
    padding-left: 4rem !important;
  }
  .ps-lg-xl {
    padding-left: 8rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 5rem !important;
  }
  .gap-lg-7 {
    gap: 8rem !important;
  }
  .gap-lg-8 {
    gap: 10rem !important;
  }
  .gap-lg-9 {
    gap: 11rem !important;
  }
  .gap-lg-10 {
    gap: 14rem !important;
  }
  .gap-lg-11 {
    gap: 16rem !important;
  }
  .gap-lg-12 {
    gap: 20rem !important;
  }
  .gap-lg-sm {
    gap: 1rem !important;
  }
  .gap-lg-md {
    gap: 2rem !important;
  }
  .gap-lg-lg {
    gap: 4rem !important;
  }
  .gap-lg-xl {
    gap: 8rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 11rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 14rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 16rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 20rem !important;
  }
  .row-gap-lg-sm {
    row-gap: 1rem !important;
  }
  .row-gap-lg-md {
    row-gap: 2rem !important;
  }
  .row-gap-lg-lg {
    row-gap: 4rem !important;
  }
  .row-gap-lg-xl {
    row-gap: 8rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }
  .column-gap-lg-7 {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .column-gap-lg-8 {
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }
  .column-gap-lg-9 {
    -moz-column-gap: 11rem !important;
         column-gap: 11rem !important;
  }
  .column-gap-lg-10 {
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }
  .column-gap-lg-11 {
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }
  .column-gap-lg-12 {
    -moz-column-gap: 20rem !important;
         column-gap: 20rem !important;
  }
  .column-gap-lg-sm {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-md {
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .column-gap-lg-lg {
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }
  .column-gap-lg-xl {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 5rem !important;
  }
  .m-xl-7 {
    margin: 8rem !important;
  }
  .m-xl-8 {
    margin: 10rem !important;
  }
  .m-xl-9 {
    margin: 11rem !important;
  }
  .m-xl-10 {
    margin: 14rem !important;
  }
  .m-xl-11 {
    margin: 16rem !important;
  }
  .m-xl-12 {
    margin: 20rem !important;
  }
  .m-xl-sm {
    margin: 1rem !important;
  }
  .m-xl-md {
    margin: 2rem !important;
  }
  .m-xl-lg {
    margin: 4rem !important;
  }
  .m-xl-xl {
    margin: 8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 5rem !important;
  }
  .mt-xl-7 {
    margin-top: 8rem !important;
  }
  .mt-xl-8 {
    margin-top: 10rem !important;
  }
  .mt-xl-9 {
    margin-top: 11rem !important;
  }
  .mt-xl-10 {
    margin-top: 14rem !important;
  }
  .mt-xl-11 {
    margin-top: 16rem !important;
  }
  .mt-xl-12 {
    margin-top: 20rem !important;
  }
  .mt-xl-sm {
    margin-top: 1rem !important;
  }
  .mt-xl-md {
    margin-top: 2rem !important;
  }
  .mt-xl-lg {
    margin-top: 4rem !important;
  }
  .mt-xl-xl {
    margin-top: 8rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 5rem !important;
  }
  .me-xl-7 {
    margin-right: 8rem !important;
  }
  .me-xl-8 {
    margin-right: 10rem !important;
  }
  .me-xl-9 {
    margin-right: 11rem !important;
  }
  .me-xl-10 {
    margin-right: 14rem !important;
  }
  .me-xl-11 {
    margin-right: 16rem !important;
  }
  .me-xl-12 {
    margin-right: 20rem !important;
  }
  .me-xl-sm {
    margin-right: 1rem !important;
  }
  .me-xl-md {
    margin-right: 2rem !important;
  }
  .me-xl-lg {
    margin-right: 4rem !important;
  }
  .me-xl-xl {
    margin-right: 8rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 11rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 1rem !important;
  }
  .mb-xl-md {
    margin-bottom: 2rem !important;
  }
  .mb-xl-lg {
    margin-bottom: 4rem !important;
  }
  .mb-xl-xl {
    margin-bottom: 8rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 5rem !important;
  }
  .ms-xl-7 {
    margin-left: 8rem !important;
  }
  .ms-xl-8 {
    margin-left: 10rem !important;
  }
  .ms-xl-9 {
    margin-left: 11rem !important;
  }
  .ms-xl-10 {
    margin-left: 14rem !important;
  }
  .ms-xl-11 {
    margin-left: 16rem !important;
  }
  .ms-xl-12 {
    margin-left: 20rem !important;
  }
  .ms-xl-sm {
    margin-left: 1rem !important;
  }
  .ms-xl-md {
    margin-left: 2rem !important;
  }
  .ms-xl-lg {
    margin-left: 4rem !important;
  }
  .ms-xl-xl {
    margin-left: 8rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -5rem !important;
  }
  .m-xl-n7 {
    margin: -8rem !important;
  }
  .m-xl-n8 {
    margin: -10rem !important;
  }
  .m-xl-n9 {
    margin: -11rem !important;
  }
  .m-xl-n10 {
    margin: -14rem !important;
  }
  .m-xl-n11 {
    margin: -16rem !important;
  }
  .m-xl-n12 {
    margin: -20rem !important;
  }
  .m-xl-nsm {
    margin: -1rem !important;
  }
  .m-xl-nmd {
    margin: -2rem !important;
  }
  .m-xl-nlg {
    margin: -4rem !important;
  }
  .m-xl-nxl {
    margin: -8rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-xl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-xl-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xl-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-xl-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-xl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-xl-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xl-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-xl-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -8rem !important;
  }
  .mt-xl-n8 {
    margin-top: -10rem !important;
  }
  .mt-xl-n9 {
    margin-top: -11rem !important;
  }
  .mt-xl-n10 {
    margin-top: -14rem !important;
  }
  .mt-xl-n11 {
    margin-top: -16rem !important;
  }
  .mt-xl-n12 {
    margin-top: -20rem !important;
  }
  .mt-xl-nsm {
    margin-top: -1rem !important;
  }
  .mt-xl-nmd {
    margin-top: -2rem !important;
  }
  .mt-xl-nlg {
    margin-top: -4rem !important;
  }
  .mt-xl-nxl {
    margin-top: -8rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -5rem !important;
  }
  .me-xl-n7 {
    margin-right: -8rem !important;
  }
  .me-xl-n8 {
    margin-right: -10rem !important;
  }
  .me-xl-n9 {
    margin-right: -11rem !important;
  }
  .me-xl-n10 {
    margin-right: -14rem !important;
  }
  .me-xl-n11 {
    margin-right: -16rem !important;
  }
  .me-xl-n12 {
    margin-right: -20rem !important;
  }
  .me-xl-nsm {
    margin-right: -1rem !important;
  }
  .me-xl-nmd {
    margin-right: -2rem !important;
  }
  .me-xl-nlg {
    margin-right: -4rem !important;
  }
  .me-xl-nxl {
    margin-right: -8rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -14rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -16rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -20rem !important;
  }
  .mb-xl-nsm {
    margin-bottom: -1rem !important;
  }
  .mb-xl-nmd {
    margin-bottom: -2rem !important;
  }
  .mb-xl-nlg {
    margin-bottom: -4rem !important;
  }
  .mb-xl-nxl {
    margin-bottom: -8rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -8rem !important;
  }
  .ms-xl-n8 {
    margin-left: -10rem !important;
  }
  .ms-xl-n9 {
    margin-left: -11rem !important;
  }
  .ms-xl-n10 {
    margin-left: -14rem !important;
  }
  .ms-xl-n11 {
    margin-left: -16rem !important;
  }
  .ms-xl-n12 {
    margin-left: -20rem !important;
  }
  .ms-xl-nsm {
    margin-left: -1rem !important;
  }
  .ms-xl-nmd {
    margin-left: -2rem !important;
  }
  .ms-xl-nlg {
    margin-left: -4rem !important;
  }
  .ms-xl-nxl {
    margin-left: -8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .p-xl-7 {
    padding: 8rem !important;
  }
  .p-xl-8 {
    padding: 10rem !important;
  }
  .p-xl-9 {
    padding: 11rem !important;
  }
  .p-xl-10 {
    padding: 14rem !important;
  }
  .p-xl-11 {
    padding: 16rem !important;
  }
  .p-xl-12 {
    padding: 20rem !important;
  }
  .p-xl-sm {
    padding: 1rem !important;
  }
  .p-xl-md {
    padding: 2rem !important;
  }
  .p-xl-lg {
    padding: 4rem !important;
  }
  .p-xl-xl {
    padding: 8rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xl-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xl-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xl-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xl-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 5rem !important;
  }
  .pt-xl-7 {
    padding-top: 8rem !important;
  }
  .pt-xl-8 {
    padding-top: 10rem !important;
  }
  .pt-xl-9 {
    padding-top: 11rem !important;
  }
  .pt-xl-10 {
    padding-top: 14rem !important;
  }
  .pt-xl-11 {
    padding-top: 16rem !important;
  }
  .pt-xl-12 {
    padding-top: 20rem !important;
  }
  .pt-xl-sm {
    padding-top: 1rem !important;
  }
  .pt-xl-md {
    padding-top: 2rem !important;
  }
  .pt-xl-lg {
    padding-top: 4rem !important;
  }
  .pt-xl-xl {
    padding-top: 8rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 5rem !important;
  }
  .pe-xl-7 {
    padding-right: 8rem !important;
  }
  .pe-xl-8 {
    padding-right: 10rem !important;
  }
  .pe-xl-9 {
    padding-right: 11rem !important;
  }
  .pe-xl-10 {
    padding-right: 14rem !important;
  }
  .pe-xl-11 {
    padding-right: 16rem !important;
  }
  .pe-xl-12 {
    padding-right: 20rem !important;
  }
  .pe-xl-sm {
    padding-right: 1rem !important;
  }
  .pe-xl-md {
    padding-right: 2rem !important;
  }
  .pe-xl-lg {
    padding-right: 4rem !important;
  }
  .pe-xl-xl {
    padding-right: 8rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 11rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 20rem !important;
  }
  .pb-xl-sm {
    padding-bottom: 1rem !important;
  }
  .pb-xl-md {
    padding-bottom: 2rem !important;
  }
  .pb-xl-lg {
    padding-bottom: 4rem !important;
  }
  .pb-xl-xl {
    padding-bottom: 8rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 5rem !important;
  }
  .ps-xl-7 {
    padding-left: 8rem !important;
  }
  .ps-xl-8 {
    padding-left: 10rem !important;
  }
  .ps-xl-9 {
    padding-left: 11rem !important;
  }
  .ps-xl-10 {
    padding-left: 14rem !important;
  }
  .ps-xl-11 {
    padding-left: 16rem !important;
  }
  .ps-xl-12 {
    padding-left: 20rem !important;
  }
  .ps-xl-sm {
    padding-left: 1rem !important;
  }
  .ps-xl-md {
    padding-left: 2rem !important;
  }
  .ps-xl-lg {
    padding-left: 4rem !important;
  }
  .ps-xl-xl {
    padding-left: 8rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 5rem !important;
  }
  .gap-xl-7 {
    gap: 8rem !important;
  }
  .gap-xl-8 {
    gap: 10rem !important;
  }
  .gap-xl-9 {
    gap: 11rem !important;
  }
  .gap-xl-10 {
    gap: 14rem !important;
  }
  .gap-xl-11 {
    gap: 16rem !important;
  }
  .gap-xl-12 {
    gap: 20rem !important;
  }
  .gap-xl-sm {
    gap: 1rem !important;
  }
  .gap-xl-md {
    gap: 2rem !important;
  }
  .gap-xl-lg {
    gap: 4rem !important;
  }
  .gap-xl-xl {
    gap: 8rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 11rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 14rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 16rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 20rem !important;
  }
  .row-gap-xl-sm {
    row-gap: 1rem !important;
  }
  .row-gap-xl-md {
    row-gap: 2rem !important;
  }
  .row-gap-xl-lg {
    row-gap: 4rem !important;
  }
  .row-gap-xl-xl {
    row-gap: 8rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }
  .column-gap-xl-7 {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .column-gap-xl-8 {
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }
  .column-gap-xl-9 {
    -moz-column-gap: 11rem !important;
         column-gap: 11rem !important;
  }
  .column-gap-xl-10 {
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }
  .column-gap-xl-11 {
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }
  .column-gap-xl-12 {
    -moz-column-gap: 20rem !important;
         column-gap: 20rem !important;
  }
  .column-gap-xl-sm {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-md {
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .column-gap-xl-lg {
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }
  .column-gap-xl-xl {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 5rem !important;
  }
  .m-xxl-7 {
    margin: 8rem !important;
  }
  .m-xxl-8 {
    margin: 10rem !important;
  }
  .m-xxl-9 {
    margin: 11rem !important;
  }
  .m-xxl-10 {
    margin: 14rem !important;
  }
  .m-xxl-11 {
    margin: 16rem !important;
  }
  .m-xxl-12 {
    margin: 20rem !important;
  }
  .m-xxl-sm {
    margin: 1rem !important;
  }
  .m-xxl-md {
    margin: 2rem !important;
  }
  .m-xxl-lg {
    margin: 4rem !important;
  }
  .m-xxl-xl {
    margin: 8rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 8rem !important;
  }
  .mt-xxl-8 {
    margin-top: 10rem !important;
  }
  .mt-xxl-9 {
    margin-top: 11rem !important;
  }
  .mt-xxl-10 {
    margin-top: 14rem !important;
  }
  .mt-xxl-11 {
    margin-top: 16rem !important;
  }
  .mt-xxl-12 {
    margin-top: 20rem !important;
  }
  .mt-xxl-sm {
    margin-top: 1rem !important;
  }
  .mt-xxl-md {
    margin-top: 2rem !important;
  }
  .mt-xxl-lg {
    margin-top: 4rem !important;
  }
  .mt-xxl-xl {
    margin-top: 8rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 5rem !important;
  }
  .me-xxl-7 {
    margin-right: 8rem !important;
  }
  .me-xxl-8 {
    margin-right: 10rem !important;
  }
  .me-xxl-9 {
    margin-right: 11rem !important;
  }
  .me-xxl-10 {
    margin-right: 14rem !important;
  }
  .me-xxl-11 {
    margin-right: 16rem !important;
  }
  .me-xxl-12 {
    margin-right: 20rem !important;
  }
  .me-xxl-sm {
    margin-right: 1rem !important;
  }
  .me-xxl-md {
    margin-right: 2rem !important;
  }
  .me-xxl-lg {
    margin-right: 4rem !important;
  }
  .me-xxl-xl {
    margin-right: 8rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 11rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-sm {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-md {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-lg {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-xl {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 8rem !important;
  }
  .ms-xxl-8 {
    margin-left: 10rem !important;
  }
  .ms-xxl-9 {
    margin-left: 11rem !important;
  }
  .ms-xxl-10 {
    margin-left: 14rem !important;
  }
  .ms-xxl-11 {
    margin-left: 16rem !important;
  }
  .ms-xxl-12 {
    margin-left: 20rem !important;
  }
  .ms-xxl-sm {
    margin-left: 1rem !important;
  }
  .ms-xxl-md {
    margin-left: 2rem !important;
  }
  .ms-xxl-lg {
    margin-left: 4rem !important;
  }
  .ms-xxl-xl {
    margin-left: 8rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -5rem !important;
  }
  .m-xxl-n7 {
    margin: -8rem !important;
  }
  .m-xxl-n8 {
    margin: -10rem !important;
  }
  .m-xxl-n9 {
    margin: -11rem !important;
  }
  .m-xxl-n10 {
    margin: -14rem !important;
  }
  .m-xxl-n11 {
    margin: -16rem !important;
  }
  .m-xxl-n12 {
    margin: -20rem !important;
  }
  .m-xxl-nsm {
    margin: -1rem !important;
  }
  .m-xxl-nmd {
    margin: -2rem !important;
  }
  .m-xxl-nlg {
    margin: -4rem !important;
  }
  .m-xxl-nxl {
    margin: -8rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-xxl-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xxl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xxl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-xxl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .my-xxl-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xxl-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-xxl-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -11rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -14rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -16rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -20rem !important;
  }
  .mt-xxl-nsm {
    margin-top: -1rem !important;
  }
  .mt-xxl-nmd {
    margin-top: -2rem !important;
  }
  .mt-xxl-nlg {
    margin-top: -4rem !important;
  }
  .mt-xxl-nxl {
    margin-top: -8rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -8rem !important;
  }
  .me-xxl-n8 {
    margin-right: -10rem !important;
  }
  .me-xxl-n9 {
    margin-right: -11rem !important;
  }
  .me-xxl-n10 {
    margin-right: -14rem !important;
  }
  .me-xxl-n11 {
    margin-right: -16rem !important;
  }
  .me-xxl-n12 {
    margin-right: -20rem !important;
  }
  .me-xxl-nsm {
    margin-right: -1rem !important;
  }
  .me-xxl-nmd {
    margin-right: -2rem !important;
  }
  .me-xxl-nlg {
    margin-right: -4rem !important;
  }
  .me-xxl-nxl {
    margin-right: -8rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -14rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -16rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -20rem !important;
  }
  .mb-xxl-nsm {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-nmd {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-nlg {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-nxl {
    margin-bottom: -8rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -11rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -14rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -16rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -20rem !important;
  }
  .ms-xxl-nsm {
    margin-left: -1rem !important;
  }
  .ms-xxl-nmd {
    margin-left: -2rem !important;
  }
  .ms-xxl-nlg {
    margin-left: -4rem !important;
  }
  .ms-xxl-nxl {
    margin-left: -8rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 5rem !important;
  }
  .p-xxl-7 {
    padding: 8rem !important;
  }
  .p-xxl-8 {
    padding: 10rem !important;
  }
  .p-xxl-9 {
    padding: 11rem !important;
  }
  .p-xxl-10 {
    padding: 14rem !important;
  }
  .p-xxl-11 {
    padding: 16rem !important;
  }
  .p-xxl-12 {
    padding: 20rem !important;
  }
  .p-xxl-sm {
    padding: 1rem !important;
  }
  .p-xxl-md {
    padding: 2rem !important;
  }
  .p-xxl-lg {
    padding: 4rem !important;
  }
  .p-xxl-xl {
    padding: 8rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xxl-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxl-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xxl-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxl-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 8rem !important;
  }
  .pt-xxl-8 {
    padding-top: 10rem !important;
  }
  .pt-xxl-9 {
    padding-top: 11rem !important;
  }
  .pt-xxl-10 {
    padding-top: 14rem !important;
  }
  .pt-xxl-11 {
    padding-top: 16rem !important;
  }
  .pt-xxl-12 {
    padding-top: 20rem !important;
  }
  .pt-xxl-sm {
    padding-top: 1rem !important;
  }
  .pt-xxl-md {
    padding-top: 2rem !important;
  }
  .pt-xxl-lg {
    padding-top: 4rem !important;
  }
  .pt-xxl-xl {
    padding-top: 8rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 8rem !important;
  }
  .pe-xxl-8 {
    padding-right: 10rem !important;
  }
  .pe-xxl-9 {
    padding-right: 11rem !important;
  }
  .pe-xxl-10 {
    padding-right: 14rem !important;
  }
  .pe-xxl-11 {
    padding-right: 16rem !important;
  }
  .pe-xxl-12 {
    padding-right: 20rem !important;
  }
  .pe-xxl-sm {
    padding-right: 1rem !important;
  }
  .pe-xxl-md {
    padding-right: 2rem !important;
  }
  .pe-xxl-lg {
    padding-right: 4rem !important;
  }
  .pe-xxl-xl {
    padding-right: 8rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 11rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 14rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 20rem !important;
  }
  .pb-xxl-sm {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-md {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-lg {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-xl {
    padding-bottom: 8rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 8rem !important;
  }
  .ps-xxl-8 {
    padding-left: 10rem !important;
  }
  .ps-xxl-9 {
    padding-left: 11rem !important;
  }
  .ps-xxl-10 {
    padding-left: 14rem !important;
  }
  .ps-xxl-11 {
    padding-left: 16rem !important;
  }
  .ps-xxl-12 {
    padding-left: 20rem !important;
  }
  .ps-xxl-sm {
    padding-left: 1rem !important;
  }
  .ps-xxl-md {
    padding-left: 2rem !important;
  }
  .ps-xxl-lg {
    padding-left: 4rem !important;
  }
  .ps-xxl-xl {
    padding-left: 8rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 5rem !important;
  }
  .gap-xxl-7 {
    gap: 8rem !important;
  }
  .gap-xxl-8 {
    gap: 10rem !important;
  }
  .gap-xxl-9 {
    gap: 11rem !important;
  }
  .gap-xxl-10 {
    gap: 14rem !important;
  }
  .gap-xxl-11 {
    gap: 16rem !important;
  }
  .gap-xxl-12 {
    gap: 20rem !important;
  }
  .gap-xxl-sm {
    gap: 1rem !important;
  }
  .gap-xxl-md {
    gap: 2rem !important;
  }
  .gap-xxl-lg {
    gap: 4rem !important;
  }
  .gap-xxl-xl {
    gap: 8rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 11rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 14rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 16rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 20rem !important;
  }
  .row-gap-xxl-sm {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-md {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-lg {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-xl {
    row-gap: 8rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }
  .column-gap-xxl-7 {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .column-gap-xxl-8 {
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }
  .column-gap-xxl-9 {
    -moz-column-gap: 11rem !important;
         column-gap: 11rem !important;
  }
  .column-gap-xxl-10 {
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }
  .column-gap-xxl-11 {
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }
  .column-gap-xxl-12 {
    -moz-column-gap: 20rem !important;
         column-gap: 20rem !important;
  }
  .column-gap-xxl-sm {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-md {
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .column-gap-xxl-lg {
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }
  .column-gap-xxl-xl {
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.headroom {
  will-change: transform;
  background-color: inherit;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .headroom {
    transition: none;
  }
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.headroom--not-top.navbar-theme-primary {
  background-color: #262B40;
}
.headroom--not-top.navbar-theme-primary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-primary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-primary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-primary .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-secondary {
  background-color: #61DAFB;
}
.headroom--not-top.navbar-theme-secondary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-secondary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-secondary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-secondary .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-tertiary {
  background-color: #1B998B;
}
.headroom--not-top.navbar-theme-tertiary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-tertiary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-tertiary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-tertiary .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-quaternary {
  background-color: #C96480;
}
.headroom--not-top.navbar-theme-quaternary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-quaternary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-quaternary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-quaternary .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-primary-app {
  background-color: #EBF4F6;
}
.headroom--not-top.navbar-theme-primary-app .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-primary-app .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-primary-app .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-primary-app .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-secondary-app {
  background-color: #424AA0;
}
.headroom--not-top.navbar-theme-secondary-app .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-secondary-app .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-secondary-app .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-secondary-app .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-light {
  background-color: #eaedf2;
}
.headroom--not-top.navbar-theme-light .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-light .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-light .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-light .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-lighten {
  background-color: #F0F3F6;
}
.headroom--not-top.navbar-theme-lighten .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-lighten .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-lighten .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-lighten .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-success {
  background-color: #05A677;
}
.headroom--not-top.navbar-theme-success .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-success .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-success .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-success .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-info {
  background-color: #0948B3;
}
.headroom--not-top.navbar-theme-info .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-info .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-info .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-info .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-warning {
  background-color: #f5b759;
}
.headroom--not-top.navbar-theme-warning .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-warning .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-warning .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-warning .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-danger {
  background-color: #FA5252;
}
.headroom--not-top.navbar-theme-danger .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-danger .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-danger .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-danger .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-white {
  background-color: #ffffff;
}
.headroom--not-top.navbar-theme-white .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-white .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-white .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-white .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray {
  background-color: #4A5073;
}
.headroom--not-top.navbar-theme-gray .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-indigo {
  background-color: #4c5680;
}
.headroom--not-top.navbar-theme-indigo .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-indigo .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-indigo .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-indigo .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-dark {
  background-color: #262B40;
}
.headroom--not-top.navbar-theme-dark .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-dark .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-dark .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-dark .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-soft {
  background-color: #f5f8fb;
}
.headroom--not-top.navbar-theme-soft .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-soft .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-soft .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-soft .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-black {
  background-color: #2e3650;
}
.headroom--not-top.navbar-theme-black .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-black .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-black .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-black .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-blue {
  background-color: #0948B3;
}
.headroom--not-top.navbar-theme-blue .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-blue .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-blue .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-blue .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-pink {
  background-color: #C96480;
}
.headroom--not-top.navbar-theme-pink .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-pink .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-pink .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-pink .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-cyan {
  background-color: #63b1bd;
}
.headroom--not-top.navbar-theme-cyan .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-cyan .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-cyan .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-cyan .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-purple {
  background-color: #8965e0;
}
.headroom--not-top.navbar-theme-purple .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-purple .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-purple .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-purple .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-soft-indigo {
  background-color: #f5e8ff;
}
.headroom--not-top.navbar-theme-soft-indigo .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-soft-indigo .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-soft-indigo .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-soft-indigo .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-soft-green {
  background-color: #2CA58D;
}
.headroom--not-top.navbar-theme-soft-green .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-soft-green .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-soft-green .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-soft-green .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-100 {
  background-color: #f3f7fa;
}
.headroom--not-top.navbar-theme-gray-100 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-100 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-100 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-100 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-200 {
  background-color: #f5f8fb;
}
.headroom--not-top.navbar-theme-gray-200 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-200 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-200 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-200 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-300 {
  background-color: #F0F3F6;
}
.headroom--not-top.navbar-theme-gray-300 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-300 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-300 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-300 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-400 {
  background-color: #eaedf2;
}
.headroom--not-top.navbar-theme-gray-400 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-400 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-400 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-400 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-500 {
  background-color: #d1d7e0;
}
.headroom--not-top.navbar-theme-gray-500 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-500 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-500 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-500 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-600 {
  background-color: #93a5be;
}
.headroom--not-top.navbar-theme-gray-600 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-600 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-600 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-600 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-700 {
  background-color: #66799e;
}
.headroom--not-top.navbar-theme-gray-700 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-700 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-700 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-700 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-gray-800 {
  background-color: #506690;
}
.headroom--not-top.navbar-theme-gray-800 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-800 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-800 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-800 .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-facebook {
  background-color: #3b5999;
}
.headroom--not-top.navbar-theme-facebook .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-facebook .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-facebook .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-facebook .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-twitter {
  background-color: #1da1f2;
}
.headroom--not-top.navbar-theme-twitter .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-twitter .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-twitter .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-twitter .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-google {
  background-color: #DB4337;
}
.headroom--not-top.navbar-theme-google .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-google .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-google .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-google .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-instagram {
  background-color: #e4405f;
}
.headroom--not-top.navbar-theme-instagram .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-instagram .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-instagram .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-instagram .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-pinterest {
  background-color: #bd081c;
}
.headroom--not-top.navbar-theme-pinterest .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-pinterest .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-pinterest .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-pinterest .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-youtube {
  background-color: #cd201f;
}
.headroom--not-top.navbar-theme-youtube .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-youtube .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-youtube .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-youtube .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-slack {
  background-color: #3aaf85;
}
.headroom--not-top.navbar-theme-slack .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-slack .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-slack .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-slack .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-dribbble {
  background-color: #ea4c89;
}
.headroom--not-top.navbar-theme-dribbble .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-dribbble .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-dribbble .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-dribbble .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-dropbox {
  background-color: #1E90FF;
}
.headroom--not-top.navbar-theme-dropbox .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-dropbox .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-dropbox .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-dropbox .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-twitch {
  background-color: #4B367C;
}
.headroom--not-top.navbar-theme-twitch .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-twitch .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-twitch .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-twitch .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-paypal {
  background-color: #ecb32c;
}
.headroom--not-top.navbar-theme-paypal .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-paypal .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-paypal .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-paypal .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-behance {
  background-color: #0057ff;
}
.headroom--not-top.navbar-theme-behance .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-behance .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-behance .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-behance .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-reddit {
  background-color: #E84422;
}
.headroom--not-top.navbar-theme-reddit .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-reddit .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-reddit .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-reddit .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-github {
  background-color: #222222;
}
.headroom--not-top.navbar-theme-github .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-github .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-github .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-github .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-adobe {
  background-color: #ff9a00;
}
.headroom--not-top.navbar-theme-adobe .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-adobe .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-adobe .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-adobe .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-bootstrap {
  background-color: #7C277D;
}
.headroom--not-top.navbar-theme-bootstrap .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-bootstrap .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-bootstrap .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-bootstrap .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-python {
  background-color: #306998;
}
.headroom--not-top.navbar-theme-python .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-python .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-python .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-python .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-react {
  background-color: #00d8ff;
}
.headroom--not-top.navbar-theme-react .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-react .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-react .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-react .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-azure {
  background-color: #2892df;
}
.headroom--not-top.navbar-theme-azure .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-azure .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-azure .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-azure .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-js {
  background-color: #EFD81F;
}
.headroom--not-top.navbar-theme-js .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-js .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-js .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-js .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-theme-swe {
  background-color: #FFCF3B;
}
.headroom--not-top.navbar-theme-swe .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-swe .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-swe .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-swe .nav-link:hover {
  color: white;
}
.headroom--not-top.navbar-light .navbar-nav > .nav-item.show > .nav-link,
.headroom--not-top.navbar-light .navbar-nav > .nav-item > .nav-link:focus,
.headroom--not-top.navbar-light .nav-item .nav-link > span {
  color: #ffffff;
}
.headroom--not-top.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*=language-],
pre[class*=language-] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection, pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection, code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::-moz-selection, pre[class*=language-] ::-moz-selection, code[class*=language-]::-moz-selection, code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::selection, pre[class*=language-] ::selection,
code[class*=language-]::selection, code[class*=language-] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

@media (max-width: 767.98px) {
  .prism-code {
    padding-top: 2.5rem !important;
  }
}
.ct-label {
  fill: #66799e;
  color: #66799e;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: #eaedf2;
  stroke-width: 1px;
  stroke-dasharray: 1px;
}

.ct-grid-background {
  fill: none;
}

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 3px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}

.ct-bar {
  fill: none;
  stroke-width: 7px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 50px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #1B998B;
}
.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #1B998B;
}

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #17a5ce;
}
.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #17a5ce;
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #262B40;
}
.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #262B40;
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #f5b759;
}
.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: #f5b759;
}

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #C96480;
}
.ct-series-e .ct-slice-pie, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-area {
  fill: #C96480;
}

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #ffffff;
}
.ct-series-f .ct-slice-pie, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-area {
  fill: #ffffff;
}

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #17a5ce;
}
.ct-series-g .ct-slice-pie, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-area {
  fill: #17a5ce;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}
.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
}
.ct-square:after {
  content: "";
  display: table;
  clear: both;
}
.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}
.ct-minor-second:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%;
}
.ct-major-second:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%;
}
.ct-minor-third:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
}
.ct-major-third:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
}
.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%;
}
.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}
.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}
.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%;
}
.ct-golden-section:after {
  content: "";
  display: table;
  clear: both;
}
.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
}
.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}
.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%;
}
.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
}
.ct-octave:after {
  content: "";
  display: table;
  clear: both;
}
.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
}
.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}
.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%;
}
.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
}
.ct-double-octave:after {
  content: "";
  display: table;
  clear: both;
}
.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.rdtPicker .dow {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}
.rdtPicker td {
  height: 35px !important;
}
.rdtPicker td.rdtActive {
  background-color: #61DAFB !important;
  color: #262B40 !important;
  border-radius: 0.5rem;
  text-shadow: none !important;
}
.rdtPicker td.rdtDay:hover {
  border-radius: 0.5rem;
}
.rdtPicker td.rdtToday {
  background-color: #eaedf2;
  border-radius: 0.5rem;
}
.rdtPicker td.rdtToday::before {
  border-bottom: 7px solid #61DAFB;
}
.rdtPicker th {
  border-bottom: 0 !important;
}

iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 600;
}

button:focus {
  outline: 0;
}

label {
  font-weight: 600;
}

/**
 * = Backgrounds
*/
.bg-gradient-primary {
  background: linear-gradient(87deg, #262B40 0, #212845 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #61DAFB 0, #5ddcff 100%) !important;
}

.bg-gradient-tertiary {
  background: linear-gradient(87deg, #1B998B 0, #12a292 100%) !important;
}

.bg-gradient-quaternary {
  background: linear-gradient(87deg, #C96480 0, #d35a7b 100%) !important;
}

.bg-gradient-primary-app {
  background: linear-gradient(87deg, #EBF4F6 0, #eaf5f7 100%) !important;
}

.bg-gradient-secondary-app {
  background: linear-gradient(87deg, #424AA0 0, #3741ab 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}

.bg-gradient-lighten {
  background: linear-gradient(87deg, #F0F3F6 0, #eff3f7 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #05A677 0, #00ab79 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #0948B3 0, #0046bc 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(87deg, #f5b759 0, #feb950 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #FA5252 0, #ff4d4d 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important;
}

.bg-gradient-gray {
  background: linear-gradient(87deg, #4A5073 0, #41497c 100%) !important;
}

.bg-gradient-indigo {
  background: linear-gradient(87deg, #4c5680 0, #42508a 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #262B40 0, #212845 100%) !important;
}

.bg-gradient-soft {
  background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}

.bg-gradient-black {
  background: linear-gradient(87deg, #2e3650 0, #283356 100%) !important;
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #0948B3 0, #0046bc 100%) !important;
}

.bg-gradient-pink {
  background: linear-gradient(87deg, #C96480 0, #d35a7b 100%) !important;
}

.bg-gradient-cyan {
  background: linear-gradient(87deg, #63b1bd 0, #58b9c8 100%) !important;
}

.bg-gradient-purple {
  background: linear-gradient(87deg, #8965e0 0, #855ce9 100%) !important;
}

.bg-gradient-soft-indigo {
  background: linear-gradient(87deg, #f5e8ff 0, #f5e8ff 100%) !important;
}

.bg-gradient-soft-green {
  background: linear-gradient(87deg, #2CA58D 0, #22af93 100%) !important;
}

.bg-gradient-gray-100 {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}

.bg-gradient-gray-200 {
  background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}

.bg-gradient-gray-300 {
  background: linear-gradient(87deg, #F0F3F6 0, #eff3f7 100%) !important;
}

.bg-gradient-gray-400 {
  background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}

.bg-gradient-gray-500 {
  background: linear-gradient(87deg, #d1d7e0 0, #cdd6e4 100%) !important;
}

.bg-gradient-gray-600 {
  background: linear-gradient(87deg, #93a5be 0, #8aa4c7 100%) !important;
}

.bg-gradient-gray-700 {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}

.bg-gradient-gray-800 {
  background: linear-gradient(87deg, #506690 0, #45639b 100%) !important;
}

.bg-gradient-facebook {
  background: linear-gradient(87deg, #3b5999 0, #3055a4 100%) !important;
}

.bg-gradient-twitter {
  background: linear-gradient(87deg, #1da1f2 0, #11a4fe 100%) !important;
}

.bg-gradient-google {
  background: linear-gradient(87deg, #DB4337 0, #e7392b 100%) !important;
}

.bg-gradient-instagram {
  background: linear-gradient(87deg, #e4405f 0, #ef3558 100%) !important;
}

.bg-gradient-pinterest {
  background: linear-gradient(87deg, #bd081c 0, #c50016 100%) !important;
}

.bg-gradient-youtube {
  background: linear-gradient(87deg, #cd201f 0, #d91413 100%) !important;
}

.bg-gradient-slack {
  background: linear-gradient(87deg, #3aaf85 0, #2ebb88 100%) !important;
}

.bg-gradient-dribbble {
  background: linear-gradient(87deg, #ea4c89 0, #f44287 100%) !important;
}

.bg-gradient-dropbox {
  background: linear-gradient(87deg, #1E90FF 0, dodgerblue 100%) !important;
}

.bg-gradient-twitch {
  background: linear-gradient(87deg, #4B367C 0, #472d85 100%) !important;
}

.bg-gradient-paypal {
  background: linear-gradient(87deg, #ecb32c 0, #f8b821 100%) !important;
}

.bg-gradient-behance {
  background: linear-gradient(87deg, #0057ff 0, #0057ff 100%) !important;
}

.bg-gradient-reddit {
  background: linear-gradient(87deg, #E84422 0, #f43c16 100%) !important;
}

.bg-gradient-github {
  background: linear-gradient(87deg, #222222 0, #251f1f 100%) !important;
}

.bg-gradient-adobe {
  background: linear-gradient(87deg, #ff9a00 0, #ff9a00 100%) !important;
}

.bg-gradient-bootstrap {
  background: linear-gradient(87deg, #7C277D 0, #841f85 100%) !important;
}

.bg-gradient-python {
  background: linear-gradient(87deg, #306998 0, #266aa2 100%) !important;
}

.bg-gradient-react {
  background: linear-gradient(87deg, #00d8ff 0, #00d8ff 100%) !important;
}

.bg-gradient-azure {
  background: linear-gradient(87deg, #2892df 0, #1c94eb 100%) !important;
}

.bg-gradient-js {
  background: linear-gradient(87deg, #EFD81F 0, #fbe113 100%) !important;
}

.bg-gradient-swe {
  background: linear-gradient(87deg, #FFCF3B 0, #ffcf3b 100%) !important;
}

.overlay-primary:before {
  position: absolute;
  content: "";
  background: #262B40;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-secondary:before {
  position: absolute;
  content: "";
  background: #61DAFB;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-tertiary:before {
  position: absolute;
  content: "";
  background: #1B998B;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-quaternary:before {
  position: absolute;
  content: "";
  background: #C96480;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-primary-app:before {
  position: absolute;
  content: "";
  background: #EBF4F6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-secondary-app:before {
  position: absolute;
  content: "";
  background: #424AA0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-light:before {
  position: absolute;
  content: "";
  background: #eaedf2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-lighten:before {
  position: absolute;
  content: "";
  background: #F0F3F6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-success:before {
  position: absolute;
  content: "";
  background: #05A677;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-info:before {
  position: absolute;
  content: "";
  background: #0948B3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-warning:before {
  position: absolute;
  content: "";
  background: #f5b759;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-danger:before {
  position: absolute;
  content: "";
  background: #FA5252;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-white:before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray:before {
  position: absolute;
  content: "";
  background: #4A5073;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-indigo:before {
  position: absolute;
  content: "";
  background: #4c5680;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dark:before {
  position: absolute;
  content: "";
  background: #262B40;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft:before {
  position: absolute;
  content: "";
  background: #f5f8fb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-black:before {
  position: absolute;
  content: "";
  background: #2e3650;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-blue:before {
  position: absolute;
  content: "";
  background: #0948B3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-pink:before {
  position: absolute;
  content: "";
  background: #C96480;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-cyan:before {
  position: absolute;
  content: "";
  background: #63b1bd;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-purple:before {
  position: absolute;
  content: "";
  background: #8965e0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft-indigo:before {
  position: absolute;
  content: "";
  background: #f5e8ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft-green:before {
  position: absolute;
  content: "";
  background: #2CA58D;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-100:before {
  position: absolute;
  content: "";
  background: #f3f7fa;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-200:before {
  position: absolute;
  content: "";
  background: #f5f8fb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-300:before {
  position: absolute;
  content: "";
  background: #F0F3F6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-400:before {
  position: absolute;
  content: "";
  background: #eaedf2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-500:before {
  position: absolute;
  content: "";
  background: #d1d7e0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-600:before {
  position: absolute;
  content: "";
  background: #93a5be;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-700:before {
  position: absolute;
  content: "";
  background: #66799e;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-800:before {
  position: absolute;
  content: "";
  background: #506690;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-facebook:before {
  position: absolute;
  content: "";
  background: #3b5999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-twitter:before {
  position: absolute;
  content: "";
  background: #1da1f2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-google:before {
  position: absolute;
  content: "";
  background: #DB4337;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-instagram:before {
  position: absolute;
  content: "";
  background: #e4405f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-pinterest:before {
  position: absolute;
  content: "";
  background: #bd081c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-youtube:before {
  position: absolute;
  content: "";
  background: #cd201f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-slack:before {
  position: absolute;
  content: "";
  background: #3aaf85;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dribbble:before {
  position: absolute;
  content: "";
  background: #ea4c89;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dropbox:before {
  position: absolute;
  content: "";
  background: #1E90FF;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-twitch:before {
  position: absolute;
  content: "";
  background: #4B367C;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-paypal:before {
  position: absolute;
  content: "";
  background: #ecb32c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-behance:before {
  position: absolute;
  content: "";
  background: #0057ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-reddit:before {
  position: absolute;
  content: "";
  background: #E84422;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-github:before {
  position: absolute;
  content: "";
  background: #222222;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-adobe:before {
  position: absolute;
  content: "";
  background: #ff9a00;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-bootstrap:before {
  position: absolute;
  content: "";
  background: #7C277D;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-python:before {
  position: absolute;
  content: "";
  background: #306998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-react:before {
  position: absolute;
  content: "";
  background: #00d8ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-azure:before {
  position: absolute;
  content: "";
  background: #2892df;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-js:before {
  position: absolute;
  content: "";
  background: #EFD81F;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-swe:before {
  position: absolute;
  content: "";
  background: #FFCF3B;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.section-image {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-secondary-alt {
  background-color: #acebfd;
}

.form-bg-image {
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.fill-soft {
  fill: #f5f8fb;
}

/**
* = Helper classes
*/
.overflow-visible {
  overflow: visible !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.fill-opacity-0 {
  fill-opacity: 0 !important;
}

.fill-opacity-1 {
  fill-opacity: 0.1 !important;
}

.fill-opacity-2 {
  fill-opacity: 0.2 !important;
}

.fill-opacity-3 {
  fill-opacity: 0.3 !important;
}

.fill-opacity-4 {
  fill-opacity: 0.4 !important;
}

.fill-opacity-5 {
  fill-opacity: 0.5 !important;
}

.fill-opacity-6 {
  fill-opacity: 0.6 !important;
}

.fill-opacity-7 {
  fill-opacity: 0.7 !important;
}

.fill-opacity-8 {
  fill-opacity: 0.8 !important;
}

.fill-opacity-9 {
  fill-opacity: 0.9 !important;
}

.z-0 {
  position: relative;
  z-index: 0 !important;
}

.z-1 {
  position: relative;
  z-index: 1 !important;
}

.z-2 {
  position: relative;
  z-index: 2 !important;
}

.z-3 {
  position: relative;
  z-index: 3 !important;
}

.z-4 {
  position: relative;
  z-index: 4 !important;
}

.z-5 {
  position: relative;
  z-index: 5 !important;
}

.z-6 {
  position: relative;
  z-index: 6 !important;
}

.z-7 {
  position: relative;
  z-index: 7 !important;
}

.z-8 {
  position: relative;
  z-index: 8 !important;
}

.z-9 {
  position: relative;
  z-index: 9 !important;
}

.w-auto {
  width: auto !important;
}

.bw-md {
  border-width: 0.125rem !important;
}

.bw-lg {
  border-width: 0.25rem !important;
}

.bw-xl {
  border-width: 0.375rem !important;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
  border-top-end-radius: 0 !important;
}

.border-bottom-left-radius-0 {
  border-bottom-start-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-end-radius: 0 !important;
}

.rounded-xl {
  border-radius: 0.875rem !important;
}

/**
 * = Spacing
 */
.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.left-1 {
  left: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.top-3 {
  top: 1rem;
}

.right-3 {
  right: 1rem;
}

.bottom-3 {
  bottom: 1rem;
}

.left-3 {
  left: 1rem;
}

.top-4 {
  top: 1.5rem;
}

.right-4 {
  right: 1.5rem;
}

.bottom-4 {
  bottom: 1.5rem;
}

.left-4 {
  left: 1.5rem;
}

.top-5 {
  top: 3rem;
}

.right-5 {
  right: 3rem;
}

.bottom-5 {
  bottom: 3rem;
}

.left-5 {
  left: 3rem;
}

.top-6 {
  top: 5rem;
}

.right-6 {
  right: 5rem;
}

.bottom-6 {
  bottom: 5rem;
}

.left-6 {
  left: 5rem;
}

.top-7 {
  top: 8rem;
}

.right-7 {
  right: 8rem;
}

.bottom-7 {
  bottom: 8rem;
}

.left-7 {
  left: 8rem;
}

.top-8 {
  top: 10rem;
}

.right-8 {
  right: 10rem;
}

.bottom-8 {
  bottom: 10rem;
}

.left-8 {
  left: 10rem;
}

.top-9 {
  top: 11rem;
}

.right-9 {
  right: 11rem;
}

.bottom-9 {
  bottom: 11rem;
}

.left-9 {
  left: 11rem;
}

.top-10 {
  top: 14rem;
}

.right-10 {
  right: 14rem;
}

.bottom-10 {
  bottom: 14rem;
}

.left-10 {
  left: 14rem;
}

.top-11 {
  top: 16rem;
}

.right-11 {
  right: 16rem;
}

.bottom-11 {
  bottom: 16rem;
}

.left-11 {
  left: 16rem;
}

.top-12 {
  top: 20rem;
}

.right-12 {
  right: 20rem;
}

.bottom-12 {
  bottom: 20rem;
}

.left-12 {
  left: 20rem;
}

.top-sm {
  top: 1rem;
}

.right-sm {
  right: 1rem;
}

.bottom-sm {
  bottom: 1rem;
}

.left-sm {
  left: 1rem;
}

.top-md {
  top: 2rem;
}

.right-md {
  right: 2rem;
}

.bottom-md {
  bottom: 2rem;
}

.left-md {
  left: 2rem;
}

.top-lg {
  top: 4rem;
}

.right-lg {
  right: 4rem;
}

.bottom-lg {
  bottom: 4rem;
}

.left-lg {
  left: 4rem;
}

.top-xl {
  top: 8rem;
}

.right-xl {
  right: 8rem;
}

.bottom-xl {
  bottom: 8rem;
}

.left-xl {
  left: 8rem;
}

.center-vertical-absolute,
.center-horizontal-absolute {
  position: absolute;
}

.center-vertical-absolute {
  top: 50%;
  transform: translateY(-50%);
}

.center-horizontal-absolute {
  left: 50%;
  transform: translateX(-50%);
}

/**
 * = Sizing
 */
.h-100vh {
  height: 100vh !important;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh !important;
  }
}
.fh-50 {
  height: 50px !important;
}

.fh-100 {
  height: 100px !important;
}

.fh-150 {
  height: 150px !important;
}

.fh-200 {
  height: 200px !important;
}

.fh-250 {
  height: 250px !important;
}

.fh-300 {
  height: 300px !important;
}

.fh-350 {
  height: 350px !important;
}

.fh-400 {
  height: 400px !important;
}

.fh-450 {
  height: 450px !important;
}

.fh-500 {
  height: 500px !important;
}

.fh-550 {
  height: 550px !important;
}

.fh-600 {
  height: 600px !important;
}

.fh-650 {
  height: 650px !important;
}

.fh-700 {
  height: 700px !important;
}

.fh-750 {
  height: 750px !important;
}

.fh-800 {
  height: 800px !important;
}

.fh-850 {
  height: 850px !important;
}

.fh-900 {
  height: 900px !important;
}

.fh-950 {
  height: 950px !important;
}

.fh-1000 {
  height: 1000px !important;
}

.fh-1050 {
  height: 1050px !important;
}

.fh-1100 {
  height: 1100px !important;
}

.fh-1150 {
  height: 1150px !important;
}

.fh-1200 {
  height: 1200px !important;
}

.fh-1250 {
  height: 1250px !important;
}

.fh-1300 {
  height: 1300px !important;
}

.fh-1350 {
  height: 1350px !important;
}

.fh-1400 {
  height: 1400px !important;
}

.fh-1450 {
  height: 1450px !important;
}

.fh-1500 {
  height: 1500px !important;
}

.fh-1550 {
  height: 1550px !important;
}

.fh-1600 {
  height: 1600px !important;
}

.fh-1650 {
  height: 1650px !important;
}

.fh-1700 {
  height: 1700px !important;
}

.fh-1750 {
  height: 1750px !important;
}

.fh-1800 {
  height: 1800px !important;
}

.fh-1850 {
  height: 1850px !important;
}

.fh-1900 {
  height: 1900px !important;
}

.fh-1950 {
  height: 1950px !important;
}

.fw-50 {
  width: 50px !important;
}

.fw-100 {
  width: 100px !important;
}

.fw-150 {
  width: 150px !important;
}

.fw-200 {
  width: 200px !important;
}

.fw-250 {
  width: 250px !important;
}

.fw-300 {
  width: 300px !important;
}

.fw-350 {
  width: 350px !important;
}

.fw-400 {
  width: 400px !important;
}

.fw-450 {
  width: 450px !important;
}

.fw-500 {
  width: 500px !important;
}

.fw-550 {
  width: 550px !important;
}

.fw-600 {
  width: 600px !important;
}

.fw-650 {
  width: 650px !important;
}

.fw-700 {
  width: 700px !important;
}

.fw-750 {
  width: 750px !important;
}

.fw-800 {
  width: 800px !important;
}

.fw-850 {
  width: 850px !important;
}

.fw-900 {
  width: 900px !important;
}

.fw-950 {
  width: 950px !important;
}

.fw-1000 {
  width: 1000px !important;
}

.fw-1050 {
  width: 1050px !important;
}

.fw-1100 {
  width: 1100px !important;
}

.fw-1150 {
  width: 1150px !important;
}

.fw-1200 {
  width: 1200px !important;
}

.fw-1250 {
  width: 1250px !important;
}

.fw-1300 {
  width: 1300px !important;
}

.fw-1350 {
  width: 1350px !important;
}

.fw-1400 {
  width: 1400px !important;
}

.fw-1450 {
  width: 1450px !important;
}

.fw-1500 {
  width: 1500px !important;
}

.fw-1550 {
  width: 1550px !important;
}

.fw-1600 {
  width: 1600px !important;
}

.fw-1650 {
  width: 1650px !important;
}

.fw-1700 {
  width: 1700px !important;
}

.fw-1750 {
  width: 1750px !important;
}

.fw-1800 {
  width: 1800px !important;
}

.fw-1850 {
  width: 1850px !important;
}

.fw-1900 {
  width: 1900px !important;
}

.fw-1950 {
  width: 1950px !important;
}

.fmh-50 {
  min-height: 50px !important;
}

.fmh-100 {
  min-height: 100px !important;
}

.fmh-150 {
  min-height: 150px !important;
}

.fmh-200 {
  min-height: 200px !important;
}

.fmh-250 {
  min-height: 250px !important;
}

.fmh-300 {
  min-height: 300px !important;
}

.fmh-350 {
  min-height: 350px !important;
}

.fmh-400 {
  min-height: 400px !important;
}

.fmh-450 {
  min-height: 450px !important;
}

.fmh-500 {
  min-height: 500px !important;
}

.fmh-550 {
  min-height: 550px !important;
}

.fmh-600 {
  min-height: 600px !important;
}

.fmh-650 {
  min-height: 650px !important;
}

.fmh-700 {
  min-height: 700px !important;
}

.fmh-750 {
  min-height: 750px !important;
}

.fmh-800 {
  min-height: 800px !important;
}

.fmh-850 {
  min-height: 850px !important;
}

.fmh-900 {
  min-height: 900px !important;
}

.fmh-950 {
  min-height: 950px !important;
}

.fmh-1000 {
  min-height: 1000px !important;
}

.fmh-1050 {
  min-height: 1050px !important;
}

.fmh-1100 {
  min-height: 1100px !important;
}

.fmh-1150 {
  min-height: 1150px !important;
}

.fmh-1200 {
  min-height: 1200px !important;
}

.fmh-1250 {
  min-height: 1250px !important;
}

.fmh-1300 {
  min-height: 1300px !important;
}

.fmh-1350 {
  min-height: 1350px !important;
}

.fmh-1400 {
  min-height: 1400px !important;
}

.fmh-1450 {
  min-height: 1450px !important;
}

.fmh-1500 {
  min-height: 1500px !important;
}

.fmh-1550 {
  min-height: 1550px !important;
}

.fmh-1600 {
  min-height: 1600px !important;
}

.fmh-1650 {
  min-height: 1650px !important;
}

.fmh-1700 {
  min-height: 1700px !important;
}

.fmh-1750 {
  min-height: 1750px !important;
}

.fmh-1800 {
  min-height: 1800px !important;
}

.fmh-1850 {
  min-height: 1850px !important;
}

.fmh-1900 {
  min-height: 1900px !important;
}

.fmh-1950 {
  min-height: 1950px !important;
}

.fmw-50 {
  min-width: 50px !important;
}

.fmw-100 {
  min-width: 100px !important;
}

.fmw-150 {
  min-width: 150px !important;
}

.fmw-200 {
  min-width: 200px !important;
}

.fmw-250 {
  min-width: 250px !important;
}

.fmw-300 {
  min-width: 300px !important;
}

.fmw-350 {
  min-width: 350px !important;
}

.fmw-400 {
  min-width: 400px !important;
}

.fmw-450 {
  min-width: 450px !important;
}

.fmw-500 {
  min-width: 500px !important;
}

.fmw-550 {
  min-width: 550px !important;
}

.fmw-600 {
  min-width: 600px !important;
}

.fmw-650 {
  min-width: 650px !important;
}

.fmw-700 {
  min-width: 700px !important;
}

.fmw-750 {
  min-width: 750px !important;
}

.fmw-800 {
  min-width: 800px !important;
}

.fmw-850 {
  min-width: 850px !important;
}

.fmw-900 {
  min-width: 900px !important;
}

.fmw-950 {
  min-width: 950px !important;
}

.fmw-1000 {
  min-width: 1000px !important;
}

.fmw-1050 {
  min-width: 1050px !important;
}

.fmw-1100 {
  min-width: 1100px !important;
}

.fmw-1150 {
  min-width: 1150px !important;
}

.fmw-1200 {
  min-width: 1200px !important;
}

.fmw-1250 {
  min-width: 1250px !important;
}

.fmw-1300 {
  min-width: 1300px !important;
}

.fmw-1350 {
  min-width: 1350px !important;
}

.fmw-1400 {
  min-width: 1400px !important;
}

.fmw-1450 {
  min-width: 1450px !important;
}

.fmw-1500 {
  min-width: 1500px !important;
}

.fmw-1550 {
  min-width: 1550px !important;
}

.fmw-1600 {
  min-width: 1600px !important;
}

.fmw-1650 {
  min-width: 1650px !important;
}

.fmw-1700 {
  min-width: 1700px !important;
}

.fmw-1750 {
  min-width: 1750px !important;
}

.fmw-1800 {
  min-width: 1800px !important;
}

.fmw-1850 {
  min-width: 1850px !important;
}

.fmw-1900 {
  min-width: 1900px !important;
}

.fmw-1950 {
  min-width: 1950px !important;
}

.fmxh-50 {
  max-height: 50px !important;
}

.fmxh-100 {
  max-height: 100px !important;
}

.fmxh-150 {
  max-height: 150px !important;
}

.fmxh-200 {
  max-height: 200px !important;
}

.fmxh-250 {
  max-height: 250px !important;
}

.fmxh-300 {
  max-height: 300px !important;
}

.fmxh-350 {
  max-height: 350px !important;
}

.fmxh-400 {
  max-height: 400px !important;
}

.fmxh-450 {
  max-height: 450px !important;
}

.fmxh-500 {
  max-height: 500px !important;
}

.fmxh-550 {
  max-height: 550px !important;
}

.fmxh-600 {
  max-height: 600px !important;
}

.fmxh-650 {
  max-height: 650px !important;
}

.fmxh-700 {
  max-height: 700px !important;
}

.fmxh-750 {
  max-height: 750px !important;
}

.fmxh-800 {
  max-height: 800px !important;
}

.fmxh-850 {
  max-height: 850px !important;
}

.fmxh-900 {
  max-height: 900px !important;
}

.fmxh-950 {
  max-height: 950px !important;
}

.fmxh-1000 {
  max-height: 1000px !important;
}

.fmxh-1050 {
  max-height: 1050px !important;
}

.fmxh-1100 {
  max-height: 1100px !important;
}

.fmxh-1150 {
  max-height: 1150px !important;
}

.fmxh-1200 {
  max-height: 1200px !important;
}

.fmxh-1250 {
  max-height: 1250px !important;
}

.fmxh-1300 {
  max-height: 1300px !important;
}

.fmxh-1350 {
  max-height: 1350px !important;
}

.fmxh-1400 {
  max-height: 1400px !important;
}

.fmxh-1450 {
  max-height: 1450px !important;
}

.fmxh-1500 {
  max-height: 1500px !important;
}

.fmxh-1550 {
  max-height: 1550px !important;
}

.fmxh-1600 {
  max-height: 1600px !important;
}

.fmxh-1650 {
  max-height: 1650px !important;
}

.fmxh-1700 {
  max-height: 1700px !important;
}

.fmxh-1750 {
  max-height: 1750px !important;
}

.fmxh-1800 {
  max-height: 1800px !important;
}

.fmxh-1850 {
  max-height: 1850px !important;
}

.fmxh-1900 {
  max-height: 1900px !important;
}

.fmxh-1950 {
  max-height: 1950px !important;
}

.fmxw-50 {
  max-width: 50px !important;
}

.fmxw-100 {
  max-width: 100px !important;
}

.fmxw-150 {
  max-width: 150px !important;
}

.fmxw-200 {
  max-width: 200px !important;
}

.fmxw-250 {
  max-width: 250px !important;
}

.fmxw-300 {
  max-width: 300px !important;
}

.fmxw-350 {
  max-width: 350px !important;
}

.fmxw-400 {
  max-width: 400px !important;
}

.fmxw-450 {
  max-width: 450px !important;
}

.fmxw-500 {
  max-width: 500px !important;
}

.fmxw-550 {
  max-width: 550px !important;
}

.fmxw-600 {
  max-width: 600px !important;
}

.fmxw-650 {
  max-width: 650px !important;
}

.fmxw-700 {
  max-width: 700px !important;
}

.fmxw-750 {
  max-width: 750px !important;
}

.fmxw-800 {
  max-width: 800px !important;
}

.fmxw-850 {
  max-width: 850px !important;
}

.fmxw-900 {
  max-width: 900px !important;
}

.fmxw-950 {
  max-width: 950px !important;
}

.fmxw-1000 {
  max-width: 1000px !important;
}

.fmxw-1050 {
  max-width: 1050px !important;
}

.fmxw-1100 {
  max-width: 1100px !important;
}

.fmxw-1150 {
  max-width: 1150px !important;
}

.fmxw-1200 {
  max-width: 1200px !important;
}

.fmxw-1250 {
  max-width: 1250px !important;
}

.fmxw-1300 {
  max-width: 1300px !important;
}

.fmxw-1350 {
  max-width: 1350px !important;
}

.fmxw-1400 {
  max-width: 1400px !important;
}

.fmxw-1450 {
  max-width: 1450px !important;
}

.fmxw-1500 {
  max-width: 1500px !important;
}

.fmxw-1550 {
  max-width: 1550px !important;
}

.fmxw-1600 {
  max-width: 1600px !important;
}

.fmxw-1650 {
  max-width: 1650px !important;
}

.fmxw-1700 {
  max-width: 1700px !important;
}

.fmxw-1750 {
  max-width: 1750px !important;
}

.fmxw-1800 {
  max-width: 1800px !important;
}

.fmxw-1850 {
  max-width: 1850px !important;
}

.fmxw-1900 {
  max-width: 1900px !important;
}

.fmxw-1950 {
  max-width: 1950px !important;
}

/**
 * = Shadows
 */
[class*=shadow] {
  transition: all 0.2s ease;
}

.shadow-soft {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}

.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
}

.shadow-sm-hover:hover {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}

.shadow-lg-hover:hover {
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
}

.shadow-none-hover:hover {
  box-shadow: none !important;
}

/**
 * = Text utility classes
 */
.text-underline {
  text-decoration: underline !important;
}

.text-through {
  text-decoration: line-through !important;
}

.display-1-xs {
  font-size: 5rem;
}

.display-2-xs {
  font-size: 3.5rem;
}

.display-3-xs {
  font-size: 2.5rem;
}

.display-4-xs {
  font-size: 1.875rem;
}

@media (min-width: 576px) {
  .display-1-sm {
    font-size: 5rem;
  }
  .display-2-sm {
    font-size: 3.5rem;
  }
  .display-3-sm {
    font-size: 2.5rem;
  }
  .display-4-sm {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  .display-1-md {
    font-size: 5rem;
  }
  .display-2-md {
    font-size: 3.5rem;
  }
  .display-3-md {
    font-size: 2.5rem;
  }
  .display-4-md {
    font-size: 1.875rem;
  }
}
@media (min-width: 992px) {
  .display-1-lg {
    font-size: 5rem;
  }
  .display-2-lg {
    font-size: 3.5rem;
  }
  .display-3-lg {
    font-size: 2.5rem;
  }
  .display-4-lg {
    font-size: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .display-1-xl {
    font-size: 5rem;
  }
  .display-2-xl {
    font-size: 3.5rem;
  }
  .display-3-xl {
    font-size: 2.5rem;
  }
  .display-4-xl {
    font-size: 1.875rem;
  }
}
@media (min-width: 1400px) {
  .display-1-xxl {
    font-size: 5rem;
  }
  .display-2-xxl {
    font-size: 3.5rem;
  }
  .display-3-xxl {
    font-size: 2.5rem;
  }
  .display-4-xxl {
    font-size: 1.875rem;
  }
}
.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}

.lh-190 {
  line-height: 1.9;
}

.lh-200 {
  line-height: 2;
}

.lh-210 {
  line-height: 2.1;
}

.lh-220 {
  line-height: 2.2;
}

.lh-230 {
  line-height: 2.3;
}

.lh-240 {
  line-height: 2.4;
}

.lh-250 {
  line-height: 2.5;
}

.lh-260 {
  line-height: 2.6;
}

.lh-270 {
  line-height: 2.7;
}

.lh-280 {
  line-height: 2.8;
}

.lh-290 {
  line-height: 2.9;
}

.lh-300 {
  line-height: 3;
}

.ls-1 {
  letter-spacing: 0.0625rem;
}

.ls-2 {
  letter-spacing: 0.09375rem;
}

.ls-3 {
  letter-spacing: 0.125rem;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-body {
  color: #4A5073 !important;
}

.text-black-50 {
  color: rgba(46, 54, 80, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.list-style-none {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

/**
 * = Transform
 */
@media (min-width: 992px) {
  .transform-perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
  }
  .transform-perspective-left {
    transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg);
  }
}
.t-none {
  transform: none !important;
}

/**
 * = Animations
 */
.animate-up-1,
.animate-right-1,
.animate-down-1,
.animate-left-1,
.scale-up-1,
.scale-down-1 {
  transition: all 0.2s ease;
}

.scale-up-1:hover {
  transform: scale(0.5555555556);
}

.scale-up-hover-1:hover .scale {
  transform: scale(0.5555555556);
}

.scale-down-1:hover {
  transform: scale(0.4);
}

.animate-up-1:hover, .animate-hover:hover .animate-up-1 {
  transform: translate(0, -1px);
}

.animate-right-1:hover, .animate-hover:hover .animate-right-1 {
  transform: translate(1px, 0);
}

.animate-down-1:hover, .animate-hover:hover .animate-down-1 {
  transform: translate(0, 1px);
}

.animate-left-1:hover, .animate-hover:hover .animate-left-1 {
  transform: translate(-1px, 0);
}

.animate-up-2,
.animate-right-2,
.animate-down-2,
.animate-left-2,
.scale-up-2,
.scale-down-2 {
  transition: all 0.2s ease;
}

.scale-up-2:hover {
  transform: scale(1.1111111111);
}

.scale-up-hover-2:hover .scale {
  transform: scale(1.1111111111);
}

.scale-down-2:hover {
  transform: scale(0.8);
}

.animate-up-2:hover, .animate-hover:hover .animate-up-2 {
  transform: translate(0, -2px);
}

.animate-right-2:hover, .animate-hover:hover .animate-right-2 {
  transform: translate(2px, 0);
}

.animate-down-2:hover, .animate-hover:hover .animate-down-2 {
  transform: translate(0, 2px);
}

.animate-left-2:hover, .animate-hover:hover .animate-left-2 {
  transform: translate(-2px, 0);
}

.animate-up-3,
.animate-right-3,
.animate-down-3,
.animate-left-3,
.scale-up-3,
.scale-down-3 {
  transition: all 0.2s ease;
}

.scale-up-3:hover {
  transform: scale(1.6666666667);
}

.scale-up-hover-3:hover .scale {
  transform: scale(1.6666666667);
}

.scale-down-3:hover {
  transform: scale(1.2);
}

.animate-up-3:hover, .animate-hover:hover .animate-up-3 {
  transform: translate(0, -3px);
}

.animate-right-3:hover, .animate-hover:hover .animate-right-3 {
  transform: translate(3px, 0);
}

.animate-down-3:hover, .animate-hover:hover .animate-down-3 {
  transform: translate(0, 3px);
}

.animate-left-3:hover, .animate-hover:hover .animate-left-3 {
  transform: translate(-3px, 0);
}

.animate-up-4,
.animate-right-4,
.animate-down-4,
.animate-left-4,
.scale-up-4,
.scale-down-4 {
  transition: all 0.2s ease;
}

.scale-up-4:hover {
  transform: scale(2.2222222222);
}

.scale-up-hover-4:hover .scale {
  transform: scale(2.2222222222);
}

.scale-down-4:hover {
  transform: scale(1.6);
}

.animate-up-4:hover, .animate-hover:hover .animate-up-4 {
  transform: translate(0, -4px);
}

.animate-right-4:hover, .animate-hover:hover .animate-right-4 {
  transform: translate(4px, 0);
}

.animate-down-4:hover, .animate-hover:hover .animate-down-4 {
  transform: translate(0, 4px);
}

.animate-left-4:hover, .animate-hover:hover .animate-left-4 {
  transform: translate(-4px, 0);
}

.animate-up-5,
.animate-right-5,
.animate-down-5,
.animate-left-5,
.scale-up-5,
.scale-down-5 {
  transition: all 0.2s ease;
}

.scale-up-5:hover {
  transform: scale(2.7777777778);
}

.scale-up-hover-5:hover .scale {
  transform: scale(2.7777777778);
}

.scale-down-5:hover {
  transform: scale(2);
}

.animate-up-5:hover, .animate-hover:hover .animate-up-5 {
  transform: translate(0, -5px);
}

.animate-right-5:hover, .animate-hover:hover .animate-right-5 {
  transform: translate(5px, 0);
}

.animate-down-5:hover, .animate-hover:hover .animate-down-5 {
  transform: translate(0, 5px);
}

.animate-left-5:hover, .animate-hover:hover .animate-left-5 {
  transform: translate(-5px, 0);
}

.animate-up-6,
.animate-right-6,
.animate-down-6,
.animate-left-6,
.scale-up-6,
.scale-down-6 {
  transition: all 0.2s ease;
}

.scale-up-6:hover {
  transform: scale(3.3333333333);
}

.scale-up-hover-6:hover .scale {
  transform: scale(3.3333333333);
}

.scale-down-6:hover {
  transform: scale(2.4);
}

.animate-up-6:hover, .animate-hover:hover .animate-up-6 {
  transform: translate(0, -6px);
}

.animate-right-6:hover, .animate-hover:hover .animate-right-6 {
  transform: translate(6px, 0);
}

.animate-down-6:hover, .animate-hover:hover .animate-down-6 {
  transform: translate(0, 6px);
}

.animate-left-6:hover, .animate-hover:hover .animate-left-6 {
  transform: translate(-6px, 0);
}

.animate-up-7,
.animate-right-7,
.animate-down-7,
.animate-left-7,
.scale-up-7,
.scale-down-7 {
  transition: all 0.2s ease;
}

.scale-up-7:hover {
  transform: scale(3.8888888889);
}

.scale-up-hover-7:hover .scale {
  transform: scale(3.8888888889);
}

.scale-down-7:hover {
  transform: scale(2.8);
}

.animate-up-7:hover, .animate-hover:hover .animate-up-7 {
  transform: translate(0, -7px);
}

.animate-right-7:hover, .animate-hover:hover .animate-right-7 {
  transform: translate(7px, 0);
}

.animate-down-7:hover, .animate-hover:hover .animate-down-7 {
  transform: translate(0, 7px);
}

.animate-left-7:hover, .animate-hover:hover .animate-left-7 {
  transform: translate(-7px, 0);
}

.animate-up-8,
.animate-right-8,
.animate-down-8,
.animate-left-8,
.scale-up-8,
.scale-down-8 {
  transition: all 0.2s ease;
}

.scale-up-8:hover {
  transform: scale(4.4444444444);
}

.scale-up-hover-8:hover .scale {
  transform: scale(4.4444444444);
}

.scale-down-8:hover {
  transform: scale(3.2);
}

.animate-up-8:hover, .animate-hover:hover .animate-up-8 {
  transform: translate(0, -8px);
}

.animate-right-8:hover, .animate-hover:hover .animate-right-8 {
  transform: translate(8px, 0);
}

.animate-down-8:hover, .animate-hover:hover .animate-down-8 {
  transform: translate(0, 8px);
}

.animate-left-8:hover, .animate-hover:hover .animate-left-8 {
  transform: translate(-8px, 0);
}

.animate-up-9,
.animate-right-9,
.animate-down-9,
.animate-left-9,
.scale-up-9,
.scale-down-9 {
  transition: all 0.2s ease;
}

.scale-up-9:hover {
  transform: scale(5);
}

.scale-up-hover-9:hover .scale {
  transform: scale(5);
}

.scale-down-9:hover {
  transform: scale(3.6);
}

.animate-up-9:hover, .animate-hover:hover .animate-up-9 {
  transform: translate(0, -9px);
}

.animate-right-9:hover, .animate-hover:hover .animate-right-9 {
  transform: translate(9px, 0);
}

.animate-down-9:hover, .animate-hover:hover .animate-down-9 {
  transform: translate(0, 9px);
}

.animate-left-9:hover, .animate-hover:hover .animate-left-9 {
  transform: translate(-9px, 0);
}

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.45s, transform 0.45s;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform-origin: perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.5s;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
.bell-shake.shaking {
  animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  transform-origin: top right;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

/**
 * = Navigation bars
 */
.navbar-main {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
.navbar-main .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar .navbar-nav .nav-link {
  transition: all 0.8s all 0.2s ease;
}
@media (max-width: 767.98px) {
  .navbar .navbar-nav .nav-link {
    font-size: 1.2rem;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
}
.navbar .navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem;
}
.navbar .navbar-nav .nav-item:focus,
.navbar .navbar-nav .nav-item .nav-link:focus {
  outline: none !important;
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  top: 100%;
}
.navbar .navbar-nav .dropdown .dropdown-menu span {
  width: 30px;
}
.navbar .navbar-nav .dropdown .dropdown-item {
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu,
  .navbar .navbar-nav .dropdown-megamenu-sm,
  .navbar .navbar-nav .dropdown-megamenu-md {
    min-width: 580px;
  }
}
.navbar .navbar-nav .dropdown-megamenu .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-link {
  color: #4A5073;
  font-weight: 400;
  font-size: 0.875rem;
}
.navbar .navbar-nav .dropdown-megamenu .megamenu-item,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item {
  position: relative;
}
.navbar .navbar-nav .dropdown-megamenu .megamenu-item:hover .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item:hover .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item:hover .megamenu-link {
  color: #262B40;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu-sm {
    min-width: 320px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu-md {
    min-width: 960px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-menu .dropdown-toggle .nav-link-arrow {
    transform: rotate(-90deg);
  }
  .navbar .navbar-nav .dropdown.show > .dropdown-toggle .nav-link-arrow {
    transform: rotate(180deg);
  }
  .navbar .navbar-nav .dropdown-toggle:hover .nav-link-arrow {
    transform: rotate(0deg);
  }
}
.navbar .navbar-nav .dropdown-submenu {
  position: relative;
}
.navbar .navbar-nav .dropdown-submenu .dropdown-menu {
  top: 0;
  left: calc(100% - 2px);
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  opacity: 0;
  display: none;
}
.navbar .navbar-nav .dropdown-submenu .dropdown-menu.show {
  opacity: 1;
  pointer-events: all;
  display: block;
}
.navbar .navbar-nav .nav-link-arrow {
  transition: transform 0.2s ease;
}

.navbar-brand .brand-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #61DAFB;
}
.navbar-brand img {
  height: 27px;
}

.navbar-dark .navbar-brand-dark {
  display: none;
}
.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark > .nav-item:hover > .nav-link {
  color: #262B40;
}

.navbar-light:not(.headroom--not-top) .btn-outline-soft {
  border-color: #262B40;
  color: #262B40;
}
.navbar-light:not(.headroom--not-top) .btn-outline-soft:hover {
  background: #262B40;
  color: #ffffff;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(46, 54, 80, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-light .navbar-brand-light {
  display: none;
}
.navbar-light .navbar-brand {
  color: #506690;
}

.navbar-theme-primary:not(.headroom) {
  background-color: #262B40;
}

.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-primary .navbar-nav .dropdown-item.active, .navbar-theme-primary .navbar-nav .dropdown-item:hover,
.navbar-theme-primary .navbar-nav .list-group-item.active,
.navbar-theme-primary .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-primary .navbar-nav .nav-link, .navbar-theme-primary .navbar-nav .nav-link:focus, .navbar-theme-primary .navbar-nav .nav-link.active, .navbar-theme-primary .navbar-nav .nav-link:hover,
  .navbar-theme-primary .navbar-nav .show > .nav-link,
  .navbar-theme-primary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary .navbar-nav .dropdown-item,
  .navbar-theme-primary .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary .navbar-nav .dropdown-item.active,
  .navbar-theme-primary .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary .navbar-nav .list-group-item,
  .navbar-theme-primary .navbar-nav .list-group-item:focus,
  .navbar-theme-primary .navbar-nav .list-group-item.active,
  .navbar-theme-primary .navbar-nav .list-group-item:hover {
    color: #262B40;
    background: transparent;
  }
  .navbar-theme-primary .navbar-nav .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-primary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-primary .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-primary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-secondary:not(.headroom) {
  background-color: #61DAFB;
}

.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-secondary .navbar-nav .dropdown-item.active, .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary .navbar-nav .list-group-item.active,
.navbar-theme-secondary .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-secondary .navbar-nav .nav-link, .navbar-theme-secondary .navbar-nav .nav-link:focus, .navbar-theme-secondary .navbar-nav .nav-link.active, .navbar-theme-secondary .navbar-nav .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .show > .nav-link,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .dropdown-item,
  .navbar-theme-secondary .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary .navbar-nav .list-group-item,
  .navbar-theme-secondary .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary .navbar-nav .list-group-item.active,
  .navbar-theme-secondary .navbar-nav .list-group-item:hover {
    color: #61DAFB;
    background: transparent;
  }
  .navbar-theme-secondary .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-secondary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-secondary .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-secondary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-tertiary:not(.headroom) {
  background-color: #1B998B;
}

.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-tertiary .navbar-nav .dropdown-item.active, .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary .navbar-nav .list-group-item.active,
.navbar-theme-tertiary .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-tertiary .navbar-nav .nav-link, .navbar-theme-tertiary .navbar-nav .nav-link:focus, .navbar-theme-tertiary .navbar-nav .nav-link.active, .navbar-theme-tertiary .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .dropdown-item,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary .navbar-nav .list-group-item,
  .navbar-theme-tertiary .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary .navbar-nav .list-group-item:hover {
    color: #1B998B;
    background: transparent;
  }
  .navbar-theme-tertiary .navbar-nav .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-tertiary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-tertiary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-tertiary .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-tertiary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-quaternary:not(.headroom) {
  background-color: #C96480;
}

.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-quaternary .navbar-nav .dropdown-item.active, .navbar-theme-quaternary .navbar-nav .dropdown-item:hover,
.navbar-theme-quaternary .navbar-nav .list-group-item.active,
.navbar-theme-quaternary .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-quaternary .navbar-nav .nav-link, .navbar-theme-quaternary .navbar-nav .nav-link:focus, .navbar-theme-quaternary .navbar-nav .nav-link.active, .navbar-theme-quaternary .navbar-nav .nav-link:hover,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link.active,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-quaternary .navbar-nav .dropdown-item,
  .navbar-theme-quaternary .navbar-nav .dropdown-item:focus,
  .navbar-theme-quaternary .navbar-nav .dropdown-item.active,
  .navbar-theme-quaternary .navbar-nav .dropdown-item:hover,
  .navbar-theme-quaternary .navbar-nav .list-group-item,
  .navbar-theme-quaternary .navbar-nav .list-group-item:focus,
  .navbar-theme-quaternary .navbar-nav .list-group-item.active,
  .navbar-theme-quaternary .navbar-nav .list-group-item:hover {
    color: #C96480;
    background: transparent;
  }
  .navbar-theme-quaternary .navbar-nav .nav-link.disabled,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-quaternary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-quaternary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-quaternary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-quaternary .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-quaternary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-primary-app:not(.headroom) {
  background-color: #EBF4F6;
}

.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-primary-app .navbar-nav .dropdown-item.active, .navbar-theme-primary-app .navbar-nav .dropdown-item:hover,
.navbar-theme-primary-app .navbar-nav .list-group-item.active,
.navbar-theme-primary-app .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-primary-app .navbar-nav .nav-link, .navbar-theme-primary-app .navbar-nav .nav-link:focus, .navbar-theme-primary-app .navbar-nav .nav-link.active, .navbar-theme-primary-app .navbar-nav .nav-link:hover,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary-app .navbar-nav .dropdown-item,
  .navbar-theme-primary-app .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary-app .navbar-nav .dropdown-item.active,
  .navbar-theme-primary-app .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary-app .navbar-nav .list-group-item,
  .navbar-theme-primary-app .navbar-nav .list-group-item:focus,
  .navbar-theme-primary-app .navbar-nav .list-group-item.active,
  .navbar-theme-primary-app .navbar-nav .list-group-item:hover {
    color: #EBF4F6;
    background: transparent;
  }
  .navbar-theme-primary-app .navbar-nav .nav-link.disabled,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-primary-app .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary-app .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-primary-app .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-primary-app .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-primary-app .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-secondary-app:not(.headroom) {
  background-color: #424AA0;
}

.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-secondary-app .navbar-nav .dropdown-item.active, .navbar-theme-secondary-app .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary-app .navbar-nav .list-group-item.active,
.navbar-theme-secondary-app .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-secondary-app .navbar-nav .nav-link, .navbar-theme-secondary-app .navbar-nav .nav-link:focus, .navbar-theme-secondary-app .navbar-nav .nav-link.active, .navbar-theme-secondary-app .navbar-nav .nav-link:hover,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary-app .navbar-nav .list-group-item,
  .navbar-theme-secondary-app .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary-app .navbar-nav .list-group-item.active,
  .navbar-theme-secondary-app .navbar-nav .list-group-item:hover {
    color: #424AA0;
    background: transparent;
  }
  .navbar-theme-secondary-app .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary-app .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-secondary-app .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-secondary-app .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-secondary-app .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-light:not(.headroom) {
  background-color: #eaedf2;
}

.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-light .navbar-nav .dropdown-item.active, .navbar-theme-light .navbar-nav .dropdown-item:hover,
.navbar-theme-light .navbar-nav .list-group-item.active,
.navbar-theme-light .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-light .navbar-nav .nav-link, .navbar-theme-light .navbar-nav .nav-link:focus, .navbar-theme-light .navbar-nav .nav-link.active, .navbar-theme-light .navbar-nav .nav-link:hover,
  .navbar-theme-light .navbar-nav .show > .nav-link,
  .navbar-theme-light .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light .navbar-nav .show > .nav-link.active,
  .navbar-theme-light .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light .navbar-nav .dropdown-item,
  .navbar-theme-light .navbar-nav .dropdown-item:focus,
  .navbar-theme-light .navbar-nav .dropdown-item.active,
  .navbar-theme-light .navbar-nav .dropdown-item:hover,
  .navbar-theme-light .navbar-nav .list-group-item,
  .navbar-theme-light .navbar-nav .list-group-item:focus,
  .navbar-theme-light .navbar-nav .list-group-item.active,
  .navbar-theme-light .navbar-nav .list-group-item:hover {
    color: #eaedf2;
    background: transparent;
  }
  .navbar-theme-light .navbar-nav .nav-link.disabled,
  .navbar-theme-light .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-light .navbar-nav .dropdown-item.disabled,
  .navbar-theme-light .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-light .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-light .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-light .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-lighten:not(.headroom) {
  background-color: #F0F3F6;
}

.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-lighten .navbar-nav .dropdown-item.active, .navbar-theme-lighten .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten .navbar-nav .list-group-item.active,
.navbar-theme-lighten .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-lighten .navbar-nav .nav-link, .navbar-theme-lighten .navbar-nav .nav-link:focus, .navbar-theme-lighten .navbar-nav .nav-link.active, .navbar-theme-lighten .navbar-nav .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .show > .nav-link,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .dropdown-item,
  .navbar-theme-lighten .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten .navbar-nav .list-group-item,
  .navbar-theme-lighten .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten .navbar-nav .list-group-item.active,
  .navbar-theme-lighten .navbar-nav .list-group-item:hover {
    color: #F0F3F6;
    background: transparent;
  }
  .navbar-theme-lighten .navbar-nav .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .dropdown-item.disabled,
  .navbar-theme-lighten .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-lighten .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-lighten .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-lighten .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-success:not(.headroom) {
  background-color: #05A677;
}

.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-success .navbar-nav .dropdown-item.active, .navbar-theme-success .navbar-nav .dropdown-item:hover,
.navbar-theme-success .navbar-nav .list-group-item.active,
.navbar-theme-success .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-success .navbar-nav .nav-link, .navbar-theme-success .navbar-nav .nav-link:focus, .navbar-theme-success .navbar-nav .nav-link.active, .navbar-theme-success .navbar-nav .nav-link:hover,
  .navbar-theme-success .navbar-nav .show > .nav-link,
  .navbar-theme-success .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success .navbar-nav .show > .nav-link.active,
  .navbar-theme-success .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success .navbar-nav .dropdown-item,
  .navbar-theme-success .navbar-nav .dropdown-item:focus,
  .navbar-theme-success .navbar-nav .dropdown-item.active,
  .navbar-theme-success .navbar-nav .dropdown-item:hover,
  .navbar-theme-success .navbar-nav .list-group-item,
  .navbar-theme-success .navbar-nav .list-group-item:focus,
  .navbar-theme-success .navbar-nav .list-group-item.active,
  .navbar-theme-success .navbar-nav .list-group-item:hover {
    color: #05A677;
    background: transparent;
  }
  .navbar-theme-success .navbar-nav .nav-link.disabled,
  .navbar-theme-success .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-success .navbar-nav .dropdown-item.disabled,
  .navbar-theme-success .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-success .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-success .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-success .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-info:not(.headroom) {
  background-color: #0948B3;
}

.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-info .navbar-nav .dropdown-item.active, .navbar-theme-info .navbar-nav .dropdown-item:hover,
.navbar-theme-info .navbar-nav .list-group-item.active,
.navbar-theme-info .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-info .navbar-nav .nav-link, .navbar-theme-info .navbar-nav .nav-link:focus, .navbar-theme-info .navbar-nav .nav-link.active, .navbar-theme-info .navbar-nav .nav-link:hover,
  .navbar-theme-info .navbar-nav .show > .nav-link,
  .navbar-theme-info .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info .navbar-nav .show > .nav-link.active,
  .navbar-theme-info .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info .navbar-nav .dropdown-item,
  .navbar-theme-info .navbar-nav .dropdown-item:focus,
  .navbar-theme-info .navbar-nav .dropdown-item.active,
  .navbar-theme-info .navbar-nav .dropdown-item:hover,
  .navbar-theme-info .navbar-nav .list-group-item,
  .navbar-theme-info .navbar-nav .list-group-item:focus,
  .navbar-theme-info .navbar-nav .list-group-item.active,
  .navbar-theme-info .navbar-nav .list-group-item:hover {
    color: #0948B3;
    background: transparent;
  }
  .navbar-theme-info .navbar-nav .nav-link.disabled,
  .navbar-theme-info .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-info .navbar-nav .dropdown-item.disabled,
  .navbar-theme-info .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-info .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-info .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-info .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-warning:not(.headroom) {
  background-color: #f5b759;
}

.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-warning .navbar-nav .dropdown-item.active, .navbar-theme-warning .navbar-nav .dropdown-item:hover,
.navbar-theme-warning .navbar-nav .list-group-item.active,
.navbar-theme-warning .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-warning .navbar-nav .nav-link, .navbar-theme-warning .navbar-nav .nav-link:focus, .navbar-theme-warning .navbar-nav .nav-link.active, .navbar-theme-warning .navbar-nav .nav-link:hover,
  .navbar-theme-warning .navbar-nav .show > .nav-link,
  .navbar-theme-warning .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning .navbar-nav .dropdown-item,
  .navbar-theme-warning .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning .navbar-nav .dropdown-item.active,
  .navbar-theme-warning .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning .navbar-nav .list-group-item,
  .navbar-theme-warning .navbar-nav .list-group-item:focus,
  .navbar-theme-warning .navbar-nav .list-group-item.active,
  .navbar-theme-warning .navbar-nav .list-group-item:hover {
    color: #f5b759;
    background: transparent;
  }
  .navbar-theme-warning .navbar-nav .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .dropdown-item.disabled,
  .navbar-theme-warning .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-warning .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-warning .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-warning .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-danger:not(.headroom) {
  background-color: #FA5252;
}

.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-danger .navbar-nav .dropdown-item.active, .navbar-theme-danger .navbar-nav .dropdown-item:hover,
.navbar-theme-danger .navbar-nav .list-group-item.active,
.navbar-theme-danger .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-danger .navbar-nav .nav-link, .navbar-theme-danger .navbar-nav .nav-link:focus, .navbar-theme-danger .navbar-nav .nav-link.active, .navbar-theme-danger .navbar-nav .nav-link:hover,
  .navbar-theme-danger .navbar-nav .show > .nav-link,
  .navbar-theme-danger .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger .navbar-nav .dropdown-item,
  .navbar-theme-danger .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger .navbar-nav .dropdown-item.active,
  .navbar-theme-danger .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger .navbar-nav .list-group-item,
  .navbar-theme-danger .navbar-nav .list-group-item:focus,
  .navbar-theme-danger .navbar-nav .list-group-item.active,
  .navbar-theme-danger .navbar-nav .list-group-item:hover {
    color: #FA5252;
    background: transparent;
  }
  .navbar-theme-danger .navbar-nav .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .dropdown-item.disabled,
  .navbar-theme-danger .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-danger .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-danger .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-danger .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-white:not(.headroom) {
  background-color: #ffffff;
}

.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-white .navbar-nav .dropdown-item.active, .navbar-theme-white .navbar-nav .dropdown-item:hover,
.navbar-theme-white .navbar-nav .list-group-item.active,
.navbar-theme-white .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-white .navbar-nav .nav-link, .navbar-theme-white .navbar-nav .nav-link:focus, .navbar-theme-white .navbar-nav .nav-link.active, .navbar-theme-white .navbar-nav .nav-link:hover,
  .navbar-theme-white .navbar-nav .show > .nav-link,
  .navbar-theme-white .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white .navbar-nav .show > .nav-link.active,
  .navbar-theme-white .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white .navbar-nav .dropdown-item,
  .navbar-theme-white .navbar-nav .dropdown-item:focus,
  .navbar-theme-white .navbar-nav .dropdown-item.active,
  .navbar-theme-white .navbar-nav .dropdown-item:hover,
  .navbar-theme-white .navbar-nav .list-group-item,
  .navbar-theme-white .navbar-nav .list-group-item:focus,
  .navbar-theme-white .navbar-nav .list-group-item.active,
  .navbar-theme-white .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent;
  }
  .navbar-theme-white .navbar-nav .nav-link.disabled,
  .navbar-theme-white .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-white .navbar-nav .dropdown-item.disabled,
  .navbar-theme-white .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-white .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-white .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-white .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray:not(.headroom) {
  background-color: #4A5073;
}

.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray .navbar-nav .dropdown-item.active, .navbar-theme-gray .navbar-nav .dropdown-item:hover,
.navbar-theme-gray .navbar-nav .list-group-item.active,
.navbar-theme-gray .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray .navbar-nav .nav-link, .navbar-theme-gray .navbar-nav .nav-link:focus, .navbar-theme-gray .navbar-nav .nav-link.active, .navbar-theme-gray .navbar-nav .nav-link:hover,
  .navbar-theme-gray .navbar-nav .show > .nav-link,
  .navbar-theme-gray .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray .navbar-nav .dropdown-item,
  .navbar-theme-gray .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray .navbar-nav .dropdown-item.active,
  .navbar-theme-gray .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray .navbar-nav .list-group-item,
  .navbar-theme-gray .navbar-nav .list-group-item:focus,
  .navbar-theme-gray .navbar-nav .list-group-item.active,
  .navbar-theme-gray .navbar-nav .list-group-item:hover {
    color: #4A5073;
    background: transparent;
  }
  .navbar-theme-gray .navbar-nav .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-indigo:not(.headroom) {
  background-color: #4c5680;
}

.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-indigo .navbar-nav .dropdown-item.active, .navbar-theme-indigo .navbar-nav .dropdown-item:hover,
.navbar-theme-indigo .navbar-nav .list-group-item.active,
.navbar-theme-indigo .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-indigo .navbar-nav .nav-link, .navbar-theme-indigo .navbar-nav .nav-link:focus, .navbar-theme-indigo .navbar-nav .nav-link.active, .navbar-theme-indigo .navbar-nav .nav-link:hover,
  .navbar-theme-indigo .navbar-nav .show > .nav-link,
  .navbar-theme-indigo .navbar-nav .show > .nav-link:focus,
  .navbar-theme-indigo .navbar-nav .show > .nav-link.active,
  .navbar-theme-indigo .navbar-nav .show > .nav-link:hover,
  .navbar-theme-indigo .navbar-nav .dropdown-item,
  .navbar-theme-indigo .navbar-nav .dropdown-item:focus,
  .navbar-theme-indigo .navbar-nav .dropdown-item.active,
  .navbar-theme-indigo .navbar-nav .dropdown-item:hover,
  .navbar-theme-indigo .navbar-nav .list-group-item,
  .navbar-theme-indigo .navbar-nav .list-group-item:focus,
  .navbar-theme-indigo .navbar-nav .list-group-item.active,
  .navbar-theme-indigo .navbar-nav .list-group-item:hover {
    color: #4c5680;
    background: transparent;
  }
  .navbar-theme-indigo .navbar-nav .nav-link.disabled,
  .navbar-theme-indigo .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-indigo .navbar-nav .dropdown-item.disabled,
  .navbar-theme-indigo .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-indigo .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-indigo .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-indigo .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dark:not(.headroom) {
  background-color: #262B40;
}

.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-dark .navbar-nav .dropdown-item.active, .navbar-theme-dark .navbar-nav .dropdown-item:hover,
.navbar-theme-dark .navbar-nav .list-group-item.active,
.navbar-theme-dark .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dark .navbar-nav .nav-link, .navbar-theme-dark .navbar-nav .nav-link:focus, .navbar-theme-dark .navbar-nav .nav-link.active, .navbar-theme-dark .navbar-nav .nav-link:hover,
  .navbar-theme-dark .navbar-nav .show > .nav-link,
  .navbar-theme-dark .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark .navbar-nav .dropdown-item,
  .navbar-theme-dark .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark .navbar-nav .dropdown-item.active,
  .navbar-theme-dark .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark .navbar-nav .list-group-item,
  .navbar-theme-dark .navbar-nav .list-group-item:focus,
  .navbar-theme-dark .navbar-nav .list-group-item.active,
  .navbar-theme-dark .navbar-nav .list-group-item:hover {
    color: #262B40;
    background: transparent;
  }
  .navbar-theme-dark .navbar-nav .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dark .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-dark .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-dark .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-dark .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft:not(.headroom) {
  background-color: #f5f8fb;
}

.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-soft .navbar-nav .dropdown-item.active, .navbar-theme-soft .navbar-nav .dropdown-item:hover,
.navbar-theme-soft .navbar-nav .list-group-item.active,
.navbar-theme-soft .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft .navbar-nav .nav-link, .navbar-theme-soft .navbar-nav .nav-link:focus, .navbar-theme-soft .navbar-nav .nav-link.active, .navbar-theme-soft .navbar-nav .nav-link:hover,
  .navbar-theme-soft .navbar-nav .show > .nav-link,
  .navbar-theme-soft .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft .navbar-nav .dropdown-item,
  .navbar-theme-soft .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft .navbar-nav .dropdown-item.active,
  .navbar-theme-soft .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft .navbar-nav .list-group-item,
  .navbar-theme-soft .navbar-nav .list-group-item:focus,
  .navbar-theme-soft .navbar-nav .list-group-item.active,
  .navbar-theme-soft .navbar-nav .list-group-item:hover {
    color: #f5f8fb;
    background: transparent;
  }
  .navbar-theme-soft .navbar-nav .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-soft .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-soft .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-soft .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-black:not(.headroom) {
  background-color: #2e3650;
}

.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-black .navbar-nav .dropdown-item.active, .navbar-theme-black .navbar-nav .dropdown-item:hover,
.navbar-theme-black .navbar-nav .list-group-item.active,
.navbar-theme-black .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-black .navbar-nav .nav-link, .navbar-theme-black .navbar-nav .nav-link:focus, .navbar-theme-black .navbar-nav .nav-link.active, .navbar-theme-black .navbar-nav .nav-link:hover,
  .navbar-theme-black .navbar-nav .show > .nav-link,
  .navbar-theme-black .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black .navbar-nav .show > .nav-link.active,
  .navbar-theme-black .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black .navbar-nav .dropdown-item,
  .navbar-theme-black .navbar-nav .dropdown-item:focus,
  .navbar-theme-black .navbar-nav .dropdown-item.active,
  .navbar-theme-black .navbar-nav .dropdown-item:hover,
  .navbar-theme-black .navbar-nav .list-group-item,
  .navbar-theme-black .navbar-nav .list-group-item:focus,
  .navbar-theme-black .navbar-nav .list-group-item.active,
  .navbar-theme-black .navbar-nav .list-group-item:hover {
    color: #2e3650;
    background: transparent;
  }
  .navbar-theme-black .navbar-nav .nav-link.disabled,
  .navbar-theme-black .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-black .navbar-nav .dropdown-item.disabled,
  .navbar-theme-black .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-black .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-black .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-black .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-blue:not(.headroom) {
  background-color: #0948B3;
}

.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-blue .navbar-nav .dropdown-item.active, .navbar-theme-blue .navbar-nav .dropdown-item:hover,
.navbar-theme-blue .navbar-nav .list-group-item.active,
.navbar-theme-blue .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-blue .navbar-nav .nav-link, .navbar-theme-blue .navbar-nav .nav-link:focus, .navbar-theme-blue .navbar-nav .nav-link.active, .navbar-theme-blue .navbar-nav .nav-link:hover,
  .navbar-theme-blue .navbar-nav .show > .nav-link,
  .navbar-theme-blue .navbar-nav .show > .nav-link:focus,
  .navbar-theme-blue .navbar-nav .show > .nav-link.active,
  .navbar-theme-blue .navbar-nav .show > .nav-link:hover,
  .navbar-theme-blue .navbar-nav .dropdown-item,
  .navbar-theme-blue .navbar-nav .dropdown-item:focus,
  .navbar-theme-blue .navbar-nav .dropdown-item.active,
  .navbar-theme-blue .navbar-nav .dropdown-item:hover,
  .navbar-theme-blue .navbar-nav .list-group-item,
  .navbar-theme-blue .navbar-nav .list-group-item:focus,
  .navbar-theme-blue .navbar-nav .list-group-item.active,
  .navbar-theme-blue .navbar-nav .list-group-item:hover {
    color: #0948B3;
    background: transparent;
  }
  .navbar-theme-blue .navbar-nav .nav-link.disabled,
  .navbar-theme-blue .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-blue .navbar-nav .dropdown-item.disabled,
  .navbar-theme-blue .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-blue .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-blue .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-blue .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-pink:not(.headroom) {
  background-color: #C96480;
}

.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-pink .navbar-nav .dropdown-item.active, .navbar-theme-pink .navbar-nav .dropdown-item:hover,
.navbar-theme-pink .navbar-nav .list-group-item.active,
.navbar-theme-pink .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-pink .navbar-nav .nav-link, .navbar-theme-pink .navbar-nav .nav-link:focus, .navbar-theme-pink .navbar-nav .nav-link.active, .navbar-theme-pink .navbar-nav .nav-link:hover,
  .navbar-theme-pink .navbar-nav .show > .nav-link,
  .navbar-theme-pink .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pink .navbar-nav .show > .nav-link.active,
  .navbar-theme-pink .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pink .navbar-nav .dropdown-item,
  .navbar-theme-pink .navbar-nav .dropdown-item:focus,
  .navbar-theme-pink .navbar-nav .dropdown-item.active,
  .navbar-theme-pink .navbar-nav .dropdown-item:hover,
  .navbar-theme-pink .navbar-nav .list-group-item,
  .navbar-theme-pink .navbar-nav .list-group-item:focus,
  .navbar-theme-pink .navbar-nav .list-group-item.active,
  .navbar-theme-pink .navbar-nav .list-group-item:hover {
    color: #C96480;
    background: transparent;
  }
  .navbar-theme-pink .navbar-nav .nav-link.disabled,
  .navbar-theme-pink .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-pink .navbar-nav .dropdown-item.disabled,
  .navbar-theme-pink .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-pink .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-pink .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-pink .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-cyan:not(.headroom) {
  background-color: #63b1bd;
}

.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-cyan .navbar-nav .dropdown-item.active, .navbar-theme-cyan .navbar-nav .dropdown-item:hover,
.navbar-theme-cyan .navbar-nav .list-group-item.active,
.navbar-theme-cyan .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-cyan .navbar-nav .nav-link, .navbar-theme-cyan .navbar-nav .nav-link:focus, .navbar-theme-cyan .navbar-nav .nav-link.active, .navbar-theme-cyan .navbar-nav .nav-link:hover,
  .navbar-theme-cyan .navbar-nav .show > .nav-link,
  .navbar-theme-cyan .navbar-nav .show > .nav-link:focus,
  .navbar-theme-cyan .navbar-nav .show > .nav-link.active,
  .navbar-theme-cyan .navbar-nav .show > .nav-link:hover,
  .navbar-theme-cyan .navbar-nav .dropdown-item,
  .navbar-theme-cyan .navbar-nav .dropdown-item:focus,
  .navbar-theme-cyan .navbar-nav .dropdown-item.active,
  .navbar-theme-cyan .navbar-nav .dropdown-item:hover,
  .navbar-theme-cyan .navbar-nav .list-group-item,
  .navbar-theme-cyan .navbar-nav .list-group-item:focus,
  .navbar-theme-cyan .navbar-nav .list-group-item.active,
  .navbar-theme-cyan .navbar-nav .list-group-item:hover {
    color: #63b1bd;
    background: transparent;
  }
  .navbar-theme-cyan .navbar-nav .nav-link.disabled,
  .navbar-theme-cyan .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-cyan .navbar-nav .dropdown-item.disabled,
  .navbar-theme-cyan .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-cyan .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-cyan .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-cyan .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-purple:not(.headroom) {
  background-color: #8965e0;
}

.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-purple .navbar-nav .dropdown-item.active, .navbar-theme-purple .navbar-nav .dropdown-item:hover,
.navbar-theme-purple .navbar-nav .list-group-item.active,
.navbar-theme-purple .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-purple .navbar-nav .nav-link, .navbar-theme-purple .navbar-nav .nav-link:focus, .navbar-theme-purple .navbar-nav .nav-link.active, .navbar-theme-purple .navbar-nav .nav-link:hover,
  .navbar-theme-purple .navbar-nav .show > .nav-link,
  .navbar-theme-purple .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple .navbar-nav .dropdown-item,
  .navbar-theme-purple .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple .navbar-nav .dropdown-item.active,
  .navbar-theme-purple .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple .navbar-nav .list-group-item,
  .navbar-theme-purple .navbar-nav .list-group-item:focus,
  .navbar-theme-purple .navbar-nav .list-group-item.active,
  .navbar-theme-purple .navbar-nav .list-group-item:hover {
    color: #8965e0;
    background: transparent;
  }
  .navbar-theme-purple .navbar-nav .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .dropdown-item.disabled,
  .navbar-theme-purple .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-purple .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-purple .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-purple .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft-indigo:not(.headroom) {
  background-color: #f5e8ff;
}

.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-soft-indigo .navbar-nav .dropdown-item.active, .navbar-theme-soft-indigo .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-indigo .navbar-nav .list-group-item.active,
.navbar-theme-soft-indigo .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft-indigo .navbar-nav .nav-link, .navbar-theme-soft-indigo .navbar-nav .nav-link:focus, .navbar-theme-soft-indigo .navbar-nav .nav-link.active, .navbar-theme-soft-indigo .navbar-nav .nav-link:hover,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item.active,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item:hover {
    color: #f5e8ff;
    background: transparent;
  }
  .navbar-theme-soft-indigo .navbar-nav .nav-link.disabled,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-soft-indigo .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-soft-indigo .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft-green:not(.headroom) {
  background-color: #2CA58D;
}

.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-soft-green .navbar-nav .dropdown-item.active, .navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green .navbar-nav .list-group-item.active,
.navbar-theme-soft-green .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-soft-green .navbar-nav .nav-link, .navbar-theme-soft-green .navbar-nav .nav-link:focus, .navbar-theme-soft-green .navbar-nav .nav-link.active, .navbar-theme-soft-green .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .dropdown-item,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green .navbar-nav .list-group-item,
  .navbar-theme-soft-green .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green .navbar-nav .list-group-item:hover {
    color: #2CA58D;
    background: transparent;
  }
  .navbar-theme-soft-green .navbar-nav .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft-green .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-soft-green .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-soft-green .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-soft-green .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-100:not(.headroom) {
  background-color: #f3f7fa;
}

.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-100 .navbar-nav .dropdown-item.active, .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100 .navbar-nav .list-group-item.active,
.navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-100 .navbar-nav .nav-link, .navbar-theme-gray-100 .navbar-nav .nav-link:focus, .navbar-theme-gray-100 .navbar-nav .nav-link.active, .navbar-theme-gray-100 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100 .navbar-nav .list-group-item,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
    color: #f3f7fa;
    background: transparent;
  }
  .navbar-theme-gray-100 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-200:not(.headroom) {
  background-color: #f5f8fb;
}

.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-200 .navbar-nav .dropdown-item.active, .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200 .navbar-nav .list-group-item.active,
.navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-200 .navbar-nav .nav-link, .navbar-theme-gray-200 .navbar-nav .nav-link:focus, .navbar-theme-gray-200 .navbar-nav .nav-link.active, .navbar-theme-gray-200 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200 .navbar-nav .list-group-item,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
    color: #f5f8fb;
    background: transparent;
  }
  .navbar-theme-gray-200 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-300:not(.headroom) {
  background-color: #F0F3F6;
}

.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-300 .navbar-nav .dropdown-item.active, .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300 .navbar-nav .list-group-item.active,
.navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-300 .navbar-nav .nav-link, .navbar-theme-gray-300 .navbar-nav .nav-link:focus, .navbar-theme-gray-300 .navbar-nav .nav-link.active, .navbar-theme-gray-300 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300 .navbar-nav .list-group-item,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
    color: #F0F3F6;
    background: transparent;
  }
  .navbar-theme-gray-300 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-400:not(.headroom) {
  background-color: #eaedf2;
}

.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-400 .navbar-nav .dropdown-item.active, .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400 .navbar-nav .list-group-item.active,
.navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-400 .navbar-nav .nav-link, .navbar-theme-gray-400 .navbar-nav .nav-link:focus, .navbar-theme-gray-400 .navbar-nav .nav-link.active, .navbar-theme-gray-400 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400 .navbar-nav .list-group-item,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
    color: #eaedf2;
    background: transparent;
  }
  .navbar-theme-gray-400 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-500:not(.headroom) {
  background-color: #d1d7e0;
}

.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-500 .navbar-nav .dropdown-item.active, .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500 .navbar-nav .list-group-item.active,
.navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-500 .navbar-nav .nav-link, .navbar-theme-gray-500 .navbar-nav .nav-link:focus, .navbar-theme-gray-500 .navbar-nav .nav-link.active, .navbar-theme-gray-500 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500 .navbar-nav .list-group-item,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
    color: #d1d7e0;
    background: transparent;
  }
  .navbar-theme-gray-500 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-600:not(.headroom) {
  background-color: #93a5be;
}

.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-600 .navbar-nav .dropdown-item.active, .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600 .navbar-nav .list-group-item.active,
.navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-600 .navbar-nav .nav-link, .navbar-theme-gray-600 .navbar-nav .nav-link:focus, .navbar-theme-gray-600 .navbar-nav .nav-link.active, .navbar-theme-gray-600 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600 .navbar-nav .list-group-item,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
    color: #93a5be;
    background: transparent;
  }
  .navbar-theme-gray-600 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-700:not(.headroom) {
  background-color: #66799e;
}

.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-700 .navbar-nav .dropdown-item.active, .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700 .navbar-nav .list-group-item.active,
.navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-700 .navbar-nav .nav-link, .navbar-theme-gray-700 .navbar-nav .nav-link:focus, .navbar-theme-gray-700 .navbar-nav .nav-link.active, .navbar-theme-gray-700 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700 .navbar-nav .list-group-item,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
    color: #66799e;
    background: transparent;
  }
  .navbar-theme-gray-700 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-800:not(.headroom) {
  background-color: #506690;
}

.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-gray-800 .navbar-nav .dropdown-item.active, .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800 .navbar-nav .list-group-item.active,
.navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-gray-800 .navbar-nav .nav-link, .navbar-theme-gray-800 .navbar-nav .nav-link:focus, .navbar-theme-gray-800 .navbar-nav .nav-link.active, .navbar-theme-gray-800 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800 .navbar-nav .list-group-item,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
    color: #506690;
    background: transparent;
  }
  .navbar-theme-gray-800 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-facebook:not(.headroom) {
  background-color: #3b5999;
}

.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-facebook .navbar-nav .dropdown-item.active, .navbar-theme-facebook .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook .navbar-nav .list-group-item.active,
.navbar-theme-facebook .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-facebook .navbar-nav .nav-link, .navbar-theme-facebook .navbar-nav .nav-link:focus, .navbar-theme-facebook .navbar-nav .nav-link.active, .navbar-theme-facebook .navbar-nav .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .show > .nav-link,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .dropdown-item,
  .navbar-theme-facebook .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook .navbar-nav .list-group-item,
  .navbar-theme-facebook .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook .navbar-nav .list-group-item.active,
  .navbar-theme-facebook .navbar-nav .list-group-item:hover {
    color: #3b5999;
    background: transparent;
  }
  .navbar-theme-facebook .navbar-nav .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .dropdown-item.disabled,
  .navbar-theme-facebook .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-facebook .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-facebook .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-facebook .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-twitter:not(.headroom) {
  background-color: #1da1f2;
}

.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-twitter .navbar-nav .dropdown-item.active, .navbar-theme-twitter .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter .navbar-nav .list-group-item.active,
.navbar-theme-twitter .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-twitter .navbar-nav .nav-link, .navbar-theme-twitter .navbar-nav .nav-link:focus, .navbar-theme-twitter .navbar-nav .nav-link.active, .navbar-theme-twitter .navbar-nav .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .show > .nav-link,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .dropdown-item,
  .navbar-theme-twitter .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter .navbar-nav .list-group-item,
  .navbar-theme-twitter .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter .navbar-nav .list-group-item.active,
  .navbar-theme-twitter .navbar-nav .list-group-item:hover {
    color: #1da1f2;
    background: transparent;
  }
  .navbar-theme-twitter .navbar-nav .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitter .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-twitter .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-twitter .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-twitter .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-google:not(.headroom) {
  background-color: #DB4337;
}

.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-google .navbar-nav .dropdown-item.active, .navbar-theme-google .navbar-nav .dropdown-item:hover,
.navbar-theme-google .navbar-nav .list-group-item.active,
.navbar-theme-google .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-google .navbar-nav .nav-link, .navbar-theme-google .navbar-nav .nav-link:focus, .navbar-theme-google .navbar-nav .nav-link.active, .navbar-theme-google .navbar-nav .nav-link:hover,
  .navbar-theme-google .navbar-nav .show > .nav-link,
  .navbar-theme-google .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google .navbar-nav .show > .nav-link.active,
  .navbar-theme-google .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google .navbar-nav .dropdown-item,
  .navbar-theme-google .navbar-nav .dropdown-item:focus,
  .navbar-theme-google .navbar-nav .dropdown-item.active,
  .navbar-theme-google .navbar-nav .dropdown-item:hover,
  .navbar-theme-google .navbar-nav .list-group-item,
  .navbar-theme-google .navbar-nav .list-group-item:focus,
  .navbar-theme-google .navbar-nav .list-group-item.active,
  .navbar-theme-google .navbar-nav .list-group-item:hover {
    color: #DB4337;
    background: transparent;
  }
  .navbar-theme-google .navbar-nav .nav-link.disabled,
  .navbar-theme-google .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-google .navbar-nav .dropdown-item.disabled,
  .navbar-theme-google .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-google .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-google .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-google .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-instagram:not(.headroom) {
  background-color: #e4405f;
}

.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-instagram .navbar-nav .dropdown-item.active, .navbar-theme-instagram .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram .navbar-nav .list-group-item.active,
.navbar-theme-instagram .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-instagram .navbar-nav .nav-link, .navbar-theme-instagram .navbar-nav .nav-link:focus, .navbar-theme-instagram .navbar-nav .nav-link.active, .navbar-theme-instagram .navbar-nav .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .show > .nav-link,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .dropdown-item,
  .navbar-theme-instagram .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram .navbar-nav .list-group-item,
  .navbar-theme-instagram .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram .navbar-nav .list-group-item.active,
  .navbar-theme-instagram .navbar-nav .list-group-item:hover {
    color: #e4405f;
    background: transparent;
  }
  .navbar-theme-instagram .navbar-nav .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .dropdown-item.disabled,
  .navbar-theme-instagram .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-instagram .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-instagram .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-instagram .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-pinterest:not(.headroom) {
  background-color: #bd081c;
}

.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-pinterest .navbar-nav .dropdown-item.active, .navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest .navbar-nav .list-group-item.active,
.navbar-theme-pinterest .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-pinterest .navbar-nav .nav-link, .navbar-theme-pinterest .navbar-nav .nav-link:focus, .navbar-theme-pinterest .navbar-nav .nav-link.active, .navbar-theme-pinterest .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .dropdown-item,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest .navbar-nav .list-group-item,
  .navbar-theme-pinterest .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest .navbar-nav .list-group-item:hover {
    color: #bd081c;
    background: transparent;
  }
  .navbar-theme-pinterest .navbar-nav .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.disabled,
  .navbar-theme-pinterest .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-pinterest .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-pinterest .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-pinterest .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-youtube:not(.headroom) {
  background-color: #cd201f;
}

.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-youtube .navbar-nav .dropdown-item.active, .navbar-theme-youtube .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube .navbar-nav .list-group-item.active,
.navbar-theme-youtube .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-youtube .navbar-nav .nav-link, .navbar-theme-youtube .navbar-nav .nav-link:focus, .navbar-theme-youtube .navbar-nav .nav-link.active, .navbar-theme-youtube .navbar-nav .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .show > .nav-link,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .dropdown-item,
  .navbar-theme-youtube .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube .navbar-nav .list-group-item,
  .navbar-theme-youtube .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube .navbar-nav .list-group-item.active,
  .navbar-theme-youtube .navbar-nav .list-group-item:hover {
    color: #cd201f;
    background: transparent;
  }
  .navbar-theme-youtube .navbar-nav .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .dropdown-item.disabled,
  .navbar-theme-youtube .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-youtube .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-youtube .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-youtube .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-slack:not(.headroom) {
  background-color: #3aaf85;
}

.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-slack .navbar-nav .dropdown-item.active, .navbar-theme-slack .navbar-nav .dropdown-item:hover,
.navbar-theme-slack .navbar-nav .list-group-item.active,
.navbar-theme-slack .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-slack .navbar-nav .nav-link, .navbar-theme-slack .navbar-nav .nav-link:focus, .navbar-theme-slack .navbar-nav .nav-link.active, .navbar-theme-slack .navbar-nav .nav-link:hover,
  .navbar-theme-slack .navbar-nav .show > .nav-link,
  .navbar-theme-slack .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack .navbar-nav .dropdown-item,
  .navbar-theme-slack .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack .navbar-nav .dropdown-item.active,
  .navbar-theme-slack .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack .navbar-nav .list-group-item,
  .navbar-theme-slack .navbar-nav .list-group-item:focus,
  .navbar-theme-slack .navbar-nav .list-group-item.active,
  .navbar-theme-slack .navbar-nav .list-group-item:hover {
    color: #3aaf85;
    background: transparent;
  }
  .navbar-theme-slack .navbar-nav .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .dropdown-item.disabled,
  .navbar-theme-slack .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-slack .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-slack .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-slack .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dribbble:not(.headroom) {
  background-color: #ea4c89;
}

.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-dribbble .navbar-nav .dropdown-item.active, .navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble .navbar-nav .list-group-item.active,
.navbar-theme-dribbble .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dribbble .navbar-nav .nav-link, .navbar-theme-dribbble .navbar-nav .nav-link:focus, .navbar-theme-dribbble .navbar-nav .nav-link.active, .navbar-theme-dribbble .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .dropdown-item,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble .navbar-nav .list-group-item,
  .navbar-theme-dribbble .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble .navbar-nav .list-group-item:hover {
    color: #ea4c89;
    background: transparent;
  }
  .navbar-theme-dribbble .navbar-nav .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dribbble .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-dribbble .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-dribbble .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-dribbble .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dropbox:not(.headroom) {
  background-color: #1E90FF;
}

.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-dropbox .navbar-nav .dropdown-item.active, .navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox .navbar-nav .list-group-item.active,
.navbar-theme-dropbox .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-dropbox .navbar-nav .nav-link, .navbar-theme-dropbox .navbar-nav .nav-link:focus, .navbar-theme-dropbox .navbar-nav .nav-link.active, .navbar-theme-dropbox .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .dropdown-item,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox .navbar-nav .list-group-item,
  .navbar-theme-dropbox .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox .navbar-nav .list-group-item:hover {
    color: #1E90FF;
    background: transparent;
  }
  .navbar-theme-dropbox .navbar-nav .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dropbox .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-dropbox .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-dropbox .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-dropbox .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-twitch:not(.headroom) {
  background-color: #4B367C;
}

.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-twitch .navbar-nav .dropdown-item.active, .navbar-theme-twitch .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch .navbar-nav .list-group-item.active,
.navbar-theme-twitch .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-twitch .navbar-nav .nav-link, .navbar-theme-twitch .navbar-nav .nav-link:focus, .navbar-theme-twitch .navbar-nav .nav-link.active, .navbar-theme-twitch .navbar-nav .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .show > .nav-link,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .dropdown-item,
  .navbar-theme-twitch .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch .navbar-nav .list-group-item,
  .navbar-theme-twitch .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch .navbar-nav .list-group-item.active,
  .navbar-theme-twitch .navbar-nav .list-group-item:hover {
    color: #4B367C;
    background: transparent;
  }
  .navbar-theme-twitch .navbar-nav .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitch .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-twitch .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-twitch .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-twitch .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-paypal:not(.headroom) {
  background-color: #ecb32c;
}

.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-paypal .navbar-nav .dropdown-item.active, .navbar-theme-paypal .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal .navbar-nav .list-group-item.active,
.navbar-theme-paypal .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-paypal .navbar-nav .nav-link, .navbar-theme-paypal .navbar-nav .nav-link:focus, .navbar-theme-paypal .navbar-nav .nav-link.active, .navbar-theme-paypal .navbar-nav .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .show > .nav-link,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .dropdown-item,
  .navbar-theme-paypal .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal .navbar-nav .list-group-item,
  .navbar-theme-paypal .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal .navbar-nav .list-group-item.active,
  .navbar-theme-paypal .navbar-nav .list-group-item:hover {
    color: #ecb32c;
    background: transparent;
  }
  .navbar-theme-paypal .navbar-nav .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .dropdown-item.disabled,
  .navbar-theme-paypal .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-paypal .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-paypal .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-paypal .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-behance:not(.headroom) {
  background-color: #0057ff;
}

.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-behance .navbar-nav .dropdown-item.active, .navbar-theme-behance .navbar-nav .dropdown-item:hover,
.navbar-theme-behance .navbar-nav .list-group-item.active,
.navbar-theme-behance .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-behance .navbar-nav .nav-link, .navbar-theme-behance .navbar-nav .nav-link:focus, .navbar-theme-behance .navbar-nav .nav-link.active, .navbar-theme-behance .navbar-nav .nav-link:hover,
  .navbar-theme-behance .navbar-nav .show > .nav-link,
  .navbar-theme-behance .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance .navbar-nav .dropdown-item,
  .navbar-theme-behance .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance .navbar-nav .dropdown-item.active,
  .navbar-theme-behance .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance .navbar-nav .list-group-item,
  .navbar-theme-behance .navbar-nav .list-group-item:focus,
  .navbar-theme-behance .navbar-nav .list-group-item.active,
  .navbar-theme-behance .navbar-nav .list-group-item:hover {
    color: #0057ff;
    background: transparent;
  }
  .navbar-theme-behance .navbar-nav .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .dropdown-item.disabled,
  .navbar-theme-behance .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-behance .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-behance .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-behance .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-reddit:not(.headroom) {
  background-color: #E84422;
}

.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-reddit .navbar-nav .dropdown-item.active, .navbar-theme-reddit .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit .navbar-nav .list-group-item.active,
.navbar-theme-reddit .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-reddit .navbar-nav .nav-link, .navbar-theme-reddit .navbar-nav .nav-link:focus, .navbar-theme-reddit .navbar-nav .nav-link.active, .navbar-theme-reddit .navbar-nav .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .show > .nav-link,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .dropdown-item,
  .navbar-theme-reddit .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit .navbar-nav .list-group-item,
  .navbar-theme-reddit .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit .navbar-nav .list-group-item.active,
  .navbar-theme-reddit .navbar-nav .list-group-item:hover {
    color: #E84422;
    background: transparent;
  }
  .navbar-theme-reddit .navbar-nav .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .dropdown-item.disabled,
  .navbar-theme-reddit .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-reddit .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-reddit .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-reddit .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-github:not(.headroom) {
  background-color: #222222;
}

.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-github .navbar-nav .dropdown-item.active, .navbar-theme-github .navbar-nav .dropdown-item:hover,
.navbar-theme-github .navbar-nav .list-group-item.active,
.navbar-theme-github .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-github .navbar-nav .nav-link, .navbar-theme-github .navbar-nav .nav-link:focus, .navbar-theme-github .navbar-nav .nav-link.active, .navbar-theme-github .navbar-nav .nav-link:hover,
  .navbar-theme-github .navbar-nav .show > .nav-link,
  .navbar-theme-github .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github .navbar-nav .show > .nav-link.active,
  .navbar-theme-github .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github .navbar-nav .dropdown-item,
  .navbar-theme-github .navbar-nav .dropdown-item:focus,
  .navbar-theme-github .navbar-nav .dropdown-item.active,
  .navbar-theme-github .navbar-nav .dropdown-item:hover,
  .navbar-theme-github .navbar-nav .list-group-item,
  .navbar-theme-github .navbar-nav .list-group-item:focus,
  .navbar-theme-github .navbar-nav .list-group-item.active,
  .navbar-theme-github .navbar-nav .list-group-item:hover {
    color: #222222;
    background: transparent;
  }
  .navbar-theme-github .navbar-nav .nav-link.disabled,
  .navbar-theme-github .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-github .navbar-nav .dropdown-item.disabled,
  .navbar-theme-github .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-github .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-github .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-github .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-adobe:not(.headroom) {
  background-color: #ff9a00;
}

.navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-adobe.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-adobe .navbar-nav .dropdown-item.active, .navbar-theme-adobe .navbar-nav .dropdown-item:hover,
.navbar-theme-adobe .navbar-nav .list-group-item.active,
.navbar-theme-adobe .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-adobe .navbar-nav .nav-link, .navbar-theme-adobe .navbar-nav .nav-link:focus, .navbar-theme-adobe .navbar-nav .nav-link.active, .navbar-theme-adobe .navbar-nav .nav-link:hover,
  .navbar-theme-adobe .navbar-nav .show > .nav-link,
  .navbar-theme-adobe .navbar-nav .show > .nav-link:focus,
  .navbar-theme-adobe .navbar-nav .show > .nav-link.active,
  .navbar-theme-adobe .navbar-nav .show > .nav-link:hover,
  .navbar-theme-adobe .navbar-nav .dropdown-item,
  .navbar-theme-adobe .navbar-nav .dropdown-item:focus,
  .navbar-theme-adobe .navbar-nav .dropdown-item.active,
  .navbar-theme-adobe .navbar-nav .dropdown-item:hover,
  .navbar-theme-adobe .navbar-nav .list-group-item,
  .navbar-theme-adobe .navbar-nav .list-group-item:focus,
  .navbar-theme-adobe .navbar-nav .list-group-item.active,
  .navbar-theme-adobe .navbar-nav .list-group-item:hover {
    color: #ff9a00;
    background: transparent;
  }
  .navbar-theme-adobe .navbar-nav .nav-link.disabled,
  .navbar-theme-adobe .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-adobe .navbar-nav .dropdown-item.disabled,
  .navbar-theme-adobe .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-adobe .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-adobe .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-adobe .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-bootstrap:not(.headroom) {
  background-color: #7C277D;
}

.navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-bootstrap.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-bootstrap .navbar-nav .dropdown-item.active, .navbar-theme-bootstrap .navbar-nav .dropdown-item:hover,
.navbar-theme-bootstrap .navbar-nav .list-group-item.active,
.navbar-theme-bootstrap .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-bootstrap .navbar-nav .nav-link, .navbar-theme-bootstrap .navbar-nav .nav-link:focus, .navbar-theme-bootstrap .navbar-nav .nav-link.active, .navbar-theme-bootstrap .navbar-nav .nav-link:hover,
  .navbar-theme-bootstrap .navbar-nav .show > .nav-link,
  .navbar-theme-bootstrap .navbar-nav .show > .nav-link:focus,
  .navbar-theme-bootstrap .navbar-nav .show > .nav-link.active,
  .navbar-theme-bootstrap .navbar-nav .show > .nav-link:hover,
  .navbar-theme-bootstrap .navbar-nav .dropdown-item,
  .navbar-theme-bootstrap .navbar-nav .dropdown-item:focus,
  .navbar-theme-bootstrap .navbar-nav .dropdown-item.active,
  .navbar-theme-bootstrap .navbar-nav .dropdown-item:hover,
  .navbar-theme-bootstrap .navbar-nav .list-group-item,
  .navbar-theme-bootstrap .navbar-nav .list-group-item:focus,
  .navbar-theme-bootstrap .navbar-nav .list-group-item.active,
  .navbar-theme-bootstrap .navbar-nav .list-group-item:hover {
    color: #7C277D;
    background: transparent;
  }
  .navbar-theme-bootstrap .navbar-nav .nav-link.disabled,
  .navbar-theme-bootstrap .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-bootstrap .navbar-nav .dropdown-item.disabled,
  .navbar-theme-bootstrap .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-bootstrap .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-bootstrap .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-bootstrap .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-python:not(.headroom) {
  background-color: #306998;
}

.navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-python.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-python .navbar-nav .dropdown-item.active, .navbar-theme-python .navbar-nav .dropdown-item:hover,
.navbar-theme-python .navbar-nav .list-group-item.active,
.navbar-theme-python .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-python .navbar-nav .nav-link, .navbar-theme-python .navbar-nav .nav-link:focus, .navbar-theme-python .navbar-nav .nav-link.active, .navbar-theme-python .navbar-nav .nav-link:hover,
  .navbar-theme-python .navbar-nav .show > .nav-link,
  .navbar-theme-python .navbar-nav .show > .nav-link:focus,
  .navbar-theme-python .navbar-nav .show > .nav-link.active,
  .navbar-theme-python .navbar-nav .show > .nav-link:hover,
  .navbar-theme-python .navbar-nav .dropdown-item,
  .navbar-theme-python .navbar-nav .dropdown-item:focus,
  .navbar-theme-python .navbar-nav .dropdown-item.active,
  .navbar-theme-python .navbar-nav .dropdown-item:hover,
  .navbar-theme-python .navbar-nav .list-group-item,
  .navbar-theme-python .navbar-nav .list-group-item:focus,
  .navbar-theme-python .navbar-nav .list-group-item.active,
  .navbar-theme-python .navbar-nav .list-group-item:hover {
    color: #306998;
    background: transparent;
  }
  .navbar-theme-python .navbar-nav .nav-link.disabled,
  .navbar-theme-python .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-python .navbar-nav .dropdown-item.disabled,
  .navbar-theme-python .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-python .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-python .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-python .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-react:not(.headroom) {
  background-color: #00d8ff;
}

.navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-react.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-react .navbar-nav .dropdown-item.active, .navbar-theme-react .navbar-nav .dropdown-item:hover,
.navbar-theme-react .navbar-nav .list-group-item.active,
.navbar-theme-react .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-react .navbar-nav .nav-link, .navbar-theme-react .navbar-nav .nav-link:focus, .navbar-theme-react .navbar-nav .nav-link.active, .navbar-theme-react .navbar-nav .nav-link:hover,
  .navbar-theme-react .navbar-nav .show > .nav-link,
  .navbar-theme-react .navbar-nav .show > .nav-link:focus,
  .navbar-theme-react .navbar-nav .show > .nav-link.active,
  .navbar-theme-react .navbar-nav .show > .nav-link:hover,
  .navbar-theme-react .navbar-nav .dropdown-item,
  .navbar-theme-react .navbar-nav .dropdown-item:focus,
  .navbar-theme-react .navbar-nav .dropdown-item.active,
  .navbar-theme-react .navbar-nav .dropdown-item:hover,
  .navbar-theme-react .navbar-nav .list-group-item,
  .navbar-theme-react .navbar-nav .list-group-item:focus,
  .navbar-theme-react .navbar-nav .list-group-item.active,
  .navbar-theme-react .navbar-nav .list-group-item:hover {
    color: #00d8ff;
    background: transparent;
  }
  .navbar-theme-react .navbar-nav .nav-link.disabled,
  .navbar-theme-react .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-react .navbar-nav .dropdown-item.disabled,
  .navbar-theme-react .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-react .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-react .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-react .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-azure:not(.headroom) {
  background-color: #2892df;
}

.navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-azure.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-azure .navbar-nav .dropdown-item.active, .navbar-theme-azure .navbar-nav .dropdown-item:hover,
.navbar-theme-azure .navbar-nav .list-group-item.active,
.navbar-theme-azure .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-azure .navbar-nav .nav-link, .navbar-theme-azure .navbar-nav .nav-link:focus, .navbar-theme-azure .navbar-nav .nav-link.active, .navbar-theme-azure .navbar-nav .nav-link:hover,
  .navbar-theme-azure .navbar-nav .show > .nav-link,
  .navbar-theme-azure .navbar-nav .show > .nav-link:focus,
  .navbar-theme-azure .navbar-nav .show > .nav-link.active,
  .navbar-theme-azure .navbar-nav .show > .nav-link:hover,
  .navbar-theme-azure .navbar-nav .dropdown-item,
  .navbar-theme-azure .navbar-nav .dropdown-item:focus,
  .navbar-theme-azure .navbar-nav .dropdown-item.active,
  .navbar-theme-azure .navbar-nav .dropdown-item:hover,
  .navbar-theme-azure .navbar-nav .list-group-item,
  .navbar-theme-azure .navbar-nav .list-group-item:focus,
  .navbar-theme-azure .navbar-nav .list-group-item.active,
  .navbar-theme-azure .navbar-nav .list-group-item:hover {
    color: #2892df;
    background: transparent;
  }
  .navbar-theme-azure .navbar-nav .nav-link.disabled,
  .navbar-theme-azure .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-azure .navbar-nav .dropdown-item.disabled,
  .navbar-theme-azure .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-azure .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-azure .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-azure .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-js:not(.headroom) {
  background-color: #EFD81F;
}

.navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-js.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-js .navbar-nav .dropdown-item.active, .navbar-theme-js .navbar-nav .dropdown-item:hover,
.navbar-theme-js .navbar-nav .list-group-item.active,
.navbar-theme-js .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-js .navbar-nav .nav-link, .navbar-theme-js .navbar-nav .nav-link:focus, .navbar-theme-js .navbar-nav .nav-link.active, .navbar-theme-js .navbar-nav .nav-link:hover,
  .navbar-theme-js .navbar-nav .show > .nav-link,
  .navbar-theme-js .navbar-nav .show > .nav-link:focus,
  .navbar-theme-js .navbar-nav .show > .nav-link.active,
  .navbar-theme-js .navbar-nav .show > .nav-link:hover,
  .navbar-theme-js .navbar-nav .dropdown-item,
  .navbar-theme-js .navbar-nav .dropdown-item:focus,
  .navbar-theme-js .navbar-nav .dropdown-item.active,
  .navbar-theme-js .navbar-nav .dropdown-item:hover,
  .navbar-theme-js .navbar-nav .list-group-item,
  .navbar-theme-js .navbar-nav .list-group-item:focus,
  .navbar-theme-js .navbar-nav .list-group-item.active,
  .navbar-theme-js .navbar-nav .list-group-item:hover {
    color: #EFD81F;
    background: transparent;
  }
  .navbar-theme-js .navbar-nav .nav-link.disabled,
  .navbar-theme-js .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-js .navbar-nav .dropdown-item.disabled,
  .navbar-theme-js .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-js .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-js .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-js .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-swe:not(.headroom) {
  background-color: #FFCF3B;
}

.navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active, .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .nav-link, .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .nav-link:focus, .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .nav-link.active, .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-swe.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262B40;
  }
}

.navbar-theme-swe .navbar-nav .dropdown-item.active, .navbar-theme-swe .navbar-nav .dropdown-item:hover,
.navbar-theme-swe .navbar-nav .list-group-item.active,
.navbar-theme-swe .navbar-nav .list-group-item:hover {
  color: #262B40;
}
@media (max-width: 767.98px) {
  .navbar-theme-swe .navbar-nav .nav-link, .navbar-theme-swe .navbar-nav .nav-link:focus, .navbar-theme-swe .navbar-nav .nav-link.active, .navbar-theme-swe .navbar-nav .nav-link:hover,
  .navbar-theme-swe .navbar-nav .show > .nav-link,
  .navbar-theme-swe .navbar-nav .show > .nav-link:focus,
  .navbar-theme-swe .navbar-nav .show > .nav-link.active,
  .navbar-theme-swe .navbar-nav .show > .nav-link:hover,
  .navbar-theme-swe .navbar-nav .dropdown-item,
  .navbar-theme-swe .navbar-nav .dropdown-item:focus,
  .navbar-theme-swe .navbar-nav .dropdown-item.active,
  .navbar-theme-swe .navbar-nav .dropdown-item:hover,
  .navbar-theme-swe .navbar-nav .list-group-item,
  .navbar-theme-swe .navbar-nav .list-group-item:focus,
  .navbar-theme-swe .navbar-nav .list-group-item.active,
  .navbar-theme-swe .navbar-nav .list-group-item:hover {
    color: #FFCF3B;
    background: transparent;
  }
  .navbar-theme-swe .navbar-nav .nav-link.disabled,
  .navbar-theme-swe .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-swe .navbar-nav .dropdown-item.disabled,
  .navbar-theme-swe .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }
  .navbar-theme-swe .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }
  .navbar-theme-swe .navbar-nav .dropdown-item {
    padding-right: 0;
  }
  .navbar-theme-swe .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.dropdown-menu {
  top: 100%;
}
.dropdown-menu span {
  width: 30px;
}

.navbar-transparent {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

@media (min-width: 992px) {
  .navbar-nav .nav-item [data-toggle=dropdown]::after {
    transition: all 0.2s ease;
  }
  .navbar-nav .nav-item.show [data-toggle=dropdown]::after {
    transform: rotate(180deg);
  }
  .navbar-nav .nav-link i {
    margin-right: 0.3rem;
    font-size: 0.75rem;
  }
  .navbar-nav .nav-link-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1rem;
  }
  .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
  .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
    border-radius: 0.5rem;
  }
  .navbar-nav .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }
  .navbar-nav .dropdown-menu-center {
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav .dropdown-menu.show,
  .navbar-nav .dropdown-megamenu.show,
  .navbar-nav .dropdown-megamenu-md.show {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-nav .dropdown-menu.close,
  .navbar-nav .dropdown-megamenu.close,
  .navbar-nav .dropdown-megamenu-md.close {
    display: block;
  }
  .navbar-nav .dropdown-submenu .dropdown-menu {
    transform: none;
  }
  .navbar-nav .dropdown-menu {
    position: relative;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease;
  }
  .navbar-nav .dropdown.show > .dropdown-menu,
  .navbar-nav .dropdown-submenu.show > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-nav .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }
  .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.9);
  }
}
.navbar-collapse-header {
  display: none;
}

.navbar-collapse {
  width: auto;
}

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    width: 100%;
  }
  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }
  .dropdown-menu-center {
    right: 0;
    left: auto;
    transform: translate(0, 0);
  }
  .navbar-collapse {
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1050;
    overflow-y: auto;
    height: calc(100vh - 30px) !important;
    opacity: 0;
  }
  .navbar-collapse .navbar-toggler {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
  }
  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }
  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-collapse .collapse-brand img {
    height: 35px;
  }
  .navbar-collapse .collapse-close {
    text-align: right;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #ffffff;
    animation: show-navbar-collapse 0.2s ease forwards;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}
.navbar-dashboard {
  border-radius: 0.5rem;
}

.icon-notifications .unread-notifications {
  position: absolute;
  right: -5px;
  top: -5px;
}

/**
 * = Sections
 */
.section {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section-header {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 3rem;
}

@media (min-width: 576px) {
  .section {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .section-header {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .section-header.section-sm {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .section-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .section-sm {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .section {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .section-header {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .section-header.section-sm {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-xl {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .section-lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .section-sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media (min-width: 768px) {
  .section-hero {
    height: 100vh;
  }
}

.line-bottom::after {
  content: "";
  display: block;
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40%;
  height: 1px;
  background: radial-gradient(ellipse at center, #eaedf2 0, rgba(255, 255, 255, 0) 80%);
}

.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 767.98px) {
  .section-profile-cover {
    height: 400px;
  }
}

.components-section > .form-control + .form-control {
  margin-top: 0.5rem;
}
.components-section > .nav + .nav,
.components-section > .alert + .alert,
.components-section > .navbar + .navbar,
.components-section > .progress + .progress,
.components-section > .progress + .btn,
.components-section .badge,
.components-section .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.components-section .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.components-section .btn-group .btn {
  margin: 0;
}
.components-section .alert {
  margin: 0;
}
.components-section .alert + .alert {
  margin-top: 1.25rem;
}
.components-section .badge {
  margin-right: 0.5rem;
}
.components-section .modal-footer .btn {
  margin: 0;
}

.presentation-box {
  position: relative;
}
.presentation-box .gadget {
  height: 500px;
  max-width: 100%;
}
.presentation-box .pricing-card,
.presentation-box .dropdown-btn-img,
.presentation-box .blog-card,
.presentation-box .profile-card,
.presentation-box .navbar-img,
.presentation-box .modal-img,
.presentation-box .social-btn-img {
  position: absolute;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  border-radius: 0.5rem;
}
.presentation-box .profile-card {
  left: 67%;
  top: 19%;
  height: 100px;
}
.presentation-box .navbar-img {
  left: 50%;
  top: 56%;
  height: 65px;
}
.presentation-box .modal-img {
  left: 38%;
  top: 80%;
  height: 100px;
}
.presentation-box .blog-card {
  left: 2%;
  top: 20%;
  height: 60px;
}
.presentation-box .pricing-card {
  left: 15%;
  top: 47%;
  height: 125px;
}
.presentation-box .social-btn-img {
  left: 51%;
  top: 78%;
  height: 25px;
}
.presentation-box .dropdown-btn-img {
  left: 25%;
  top: 10%;
  height: 25px;
}
@media (min-width: 768px) {
  .presentation-box .gadget {
    height: 600px;
    max-width: 100%;
  }
  .presentation-box .pricing-card,
  .presentation-box .dropdown-btn-img,
  .presentation-box .blog-card,
  .presentation-box .profile-card,
  .presentation-box .navbar-img,
  .presentation-box .modal-img,
  .presentation-box .social-btn-img {
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    border-radius: 0.5rem;
  }
  .presentation-box .profile-card {
    left: 65%;
    top: 12%;
    height: 150px;
  }
  .presentation-box .navbar-img {
    left: 51%;
    top: 55%;
    height: 80px;
  }
  .presentation-box .modal-img {
    left: 36%;
    top: 80%;
    height: 100px;
  }
  .presentation-box .blog-card {
    left: 7%;
    top: 20%;
    height: 80px;
  }
  .presentation-box .pricing-card {
    left: 18%;
    top: 40%;
    height: 150px;
  }
  .presentation-box .social-btn-img {
    left: 60%;
    top: 80%;
    height: 25px;
  }
  .presentation-box .dropdown-btn-img {
    left: 30%;
    top: 9%;
    height: 25px;
  }
}
@media (min-width: 992px) {
  .presentation-box {
    width: 650px;
  }
  .presentation-box .gadget {
    height: auto;
  }
  .presentation-box .pricing-card,
  .presentation-box .dropdown-btn-img,
  .presentation-box .blog-card,
  .presentation-box .profile-card,
  .presentation-box .navbar-img,
  .presentation-box .modal-img,
  .presentation-box .social-btn-img {
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    border-radius: 0.5rem;
  }
  .presentation-box .profile-card {
    left: 75%;
    top: 3%;
    height: 200px;
  }
  .presentation-box .navbar-img {
    left: 55%;
    top: 65%;
    height: 120px;
  }
  .presentation-box .modal-img {
    left: 19%;
    top: 67%;
    height: 170px;
  }
  .presentation-box .blog-card {
    left: 7%;
    top: 3%;
    height: 100px;
  }
  .presentation-box .pricing-card {
    left: 0%;
    top: 30%;
    height: 165px;
  }
  .presentation-box .social-btn-img {
    left: 2%;
    top: 78%;
    height: 25px;
  }
  .presentation-box .dropdown-btn-img {
    left: 30%;
    top: -12%;
    height: 25px;
  }
}

.card-box {
  -o-perspective: 900px;
  perspective: 900px;
  width: 250px;
  position: relative;
}
.card-box .card-component {
  transform-style: preserve-3d;
  position: relative;
  height: 300px;
}
@media (min-width: 992px) {
  .card-box .card-component {
    height: 450px;
  }
}
.card-box .card-component.card-component-lg {
  height: auto;
  max-height: 800px;
}
@media (min-width: 768px) {
  .card-box .card-component.card-component-lg {
    max-height: 410px;
  }
}
.card-box .card-component.card-component-xs {
  height: auto;
  max-height: 230px;
}
.card-box .card-component .front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  border-radius: 0.5rem;
  backface-visibility: hidden;
  transform: rotateY(24deg);
}
.card-box .card-component .front:hover {
  cursor: pointer;
}
.card-box .page-card {
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  border-radius: 0.5rem;
}
.card-box .page-card:hover {
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .card-box {
    width: 200px;
  }
}
@media (min-width: 992px) {
  .card-box {
    width: 260px;
  }
}

.copy-docs {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.2s ease;
}
.copy-docs.copied {
  background: #05A677;
}
.copy-docs:hover {
  cursor: pointer;
}

.index-icon {
  position: absolute;
  font-size: 38px;
  color: #dcdcdc;
  transition: 0.3s all ease;
}

.index-icon-javascript {
  bottom: 80px;
  left: 40px;
}

.index-icon-javascript:hover {
  color: #f1da1c;
}

.index-icon-bootstrap {
  bottom: -150px;
  right: -7%;
}

.index-icon-bootstrap:hover {
  color: #553d7c;
}

.icon-sass:hover {
  color: #CD6799;
}

.index-icon-code {
  bottom: 180px;
  left: -80px;
}

.index-icon-code:hover {
  color: #ff7f66;
}

.index-icon-gulp:hover {
  color: #e34a4f;
}

.index-icon-gulp {
  left: 250px;
  bottom: -10px;
}

.index-icon-html5 {
  right: 30px;
  bottom: 150px;
}

.index-icon-html5:hover {
  color: #e54b20;
}

.index-icon-css3 {
  right: 40px;
  bottom: -20px;
}

.index-icon-css3:hover {
  color: #e54b20;
}

.index-icon-npm {
  right: 180px;
  bottom: 20px;
}

.index-icon-npm:hover {
  color: #cc3f3d;
}

.index-icon-fontawesome {
  right: 340px;
  bottom: -40px;
}

.index-icon-fontawesome:hover {
  color: #3499EF;
}

.index-icon-illustrations {
  left: 20px;
  bottom: -40px;
}

.index-icon-illustrations:hover {
  color: #61DAFB;
}

/**
 * = Footers
 */
.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.footer ul li {
  display: inline-block;
}
.footer ul li a {
  text-decoration: none;
  position: relative;
  display: block;
  color: #66799e;
}
.footer ul li a:hover {
  color: #262B40;
}
.footer ul li .btn {
  margin: 0;
}
.footer ul.links-horizontal:first-child a {
  padding-left: 0;
}
.footer ul.links-horizontal:last-child a {
  padding-right: 0;
}
.footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}
.footer ul.links-vertical li a {
  padding: 5px;
}
.footer ul.links-vertical li a:hover {
  color: inherit !important;
}
.footer ul.icon-box i {
  line-height: 1.7;
}
.footer .social-buttons a,
.footer .social-buttons .btn {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.125rem 0.375rem;
}
.footer .footer-brand {
  font-size: 1.25rem;
  font-weight: 600;
}
.footer .footer-brand img.common {
  height: 40px;
}
.footer .footer-brand img {
  height: 40px;
  width: 40px;
}
.footer .footer-brand:hover, .footer .footer-brand:focus {
  color: #2e3650;
}
.footer .footer-language-link {
  font-size: 0.875rem;
}
.footer .footer-language-link i {
  font-size: 0.75rem;
}
.footer .copyright {
  font-size: 0.875rem;
}
.footer .pull-center {
  display: inline-block;
  float: none;
}

/**
 * = Sidebars
 */
#doc-index:not(.collapse.show), .doc-sidebar {
  display: none;
}

@media (min-width: 992px) {
  #doc-index {
    display: block;
  }
  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: sticky;
    top: 2rem;
  }
  .doc-sidebar .nav-link[data-toggle=collapse] > .icon {
    transform: rotateZ(-90deg);
    position: relative;
    right: 0.25rem;
  }
  .doc-sidebar .nav .nav-item {
    font-size: 0.875rem;
  }
}
.upgrade-to-pro {
  position: fixed;
  left: 15px;
  bottom: 15px;
  width: 230px;
  z-index: 999;
}

/*
 * Sidebar
*/
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  transition: max-width 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 260px;
  }
}
.sidebar .nav {
  white-space: nowrap;
}
.sidebar .nav-item {
  border: 0.0625rem solid transparent;
  margin-bottom: 0.2rem;
}
.sidebar .nav-item.active > .nav-link {
  color: #eaedf2;
  background-color: #2e3650;
  border: 0.0625rem solid #4c5680;
}
.sidebar .nav-item .nav-link {
  color: #eaedf2;
  border: 0.0625rem solid #262B40;
}
.sidebar .nav-item .nav-link:hover {
  color: #eaedf2;
  background-color: #2e3650;
}
.sidebar .nav-item .nav-link.active {
  color: #ffffff;
}
.sidebar .nav-link + .multi-level {
  margin-top: 0.2rem;
}
.sidebar .nav-link {
  font-size: 1rem;
  vertical-align: middle;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;
}
.sidebar .nav-link .sidebar-icon {
  margin-right: 0.5rem;
  color: #ffffff;
}
.sidebar .nav-link .sidebar-icon span {
  min-width: 22px;
}
.sidebar .nav-link .sidebar-icon svg {
  min-width: 22px;
}
.sidebar .nav-link .sidebar-icon.svg-icon {
  margin-right: 14px;
}
.sidebar .nav-link .link-arrow {
  font-size: 0.875rem;
}
.sidebar .nav-link:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(76, 86, 128, 0.2);
}
.sidebar .nav-link.active {
  color: #262B40;
}
.sidebar .multi-level .nav-link {
  padding-left: 45px;
}
.sidebar .sidebar-text,
.sidebar .link-arrow,
.sidebar .badge,
.sidebar .notification-count {
  opacity: 1;
  transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-text,
  .sidebar .link-arrow,
  .sidebar .badge,
  .sidebar .notification-count {
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar .sidebar-text-contracted {
    display: none;
    transition: opacity 0.3s;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-text-contracted {
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar.contracted {
    max-width: 95px;
  }
  .sidebar.contracted .sidebar-text,
  .sidebar.contracted .link-arrow,
  .sidebar.contracted .badge {
    opacity: 0;
  }
  .sidebar.contracted .notification-count {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 35px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 0.7rem;
    padding: 0.2rem;
  }
  .sidebar.contracted .nav-item {
    position: relative;
    width: 47px;
  }
  .sidebar.contracted .nav-item.active > .nav-link {
    background-color: #262B40;
  }
  .sidebar.contracted .nav-item .nav-link:hover {
    background-color: #262B40;
  }
  .sidebar.contracted .sidebar-text-contracted {
    display: inline;
  }
  .sidebar.contracted .multi-level .nav-link {
    padding-left: 17px;
  }
  .sidebar.contracted .nav-item {
    white-space: nowrap;
  }
  .sidebar.contracted .sidebar-icon {
    text-align: center;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .sidebar.contracted + .content {
    margin-left: 95px;
  }
}

.sidebar-inner {
  position: relative;
  overflow-y: hidden;
}
.sidebar-inner .accordion-button::after {
  background-image: none;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-size: 0.875rem;
  font-weight: 900;
  height: 1.25rem;
  width: 0.5rem;
}
.sidebar-inner .accordion-button:not(.collapsed) {
  background-color: #2e3650;
}
.sidebar-inner .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
  transition: all 0.2s ease;
}
.sidebar-inner .accordion {
  padding-top: 0.25rem;
}
.sidebar-inner .accordion-body {
  padding: 0.5rem 0 0 0;
}
.sidebar-inner .accordion-collapse {
  border: 0;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}

@media (max-width: 575.98px) {
  .sidebar {
    width: 100%;
  }
}
.content {
  overflow: visible;
  padding: 0 1rem 0 1rem;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .content {
    transition: none;
  }
}
@media (min-width: 768px) {
  .content {
    margin-left: 260px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;
}
.sidebar-toggle:hover, .sidebar-toggle:focus {
  outline: none;
  box-shadow: none;
  background: #eaedf2;
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/**
 * = Accordions
 */
.accordion-button {
  text-align: left;
}
.accordion-button .btn-check:focus + .btn, .accordion-button .btn:focus {
  outline: 0;
  box-shadow: none;
}
.accordion-button:hover {
  background-color: #f5f8fb;
}

.accordion-flush .accordion-button {
  background-color: transparent;
}
.accordion-flush .accordion-button:not(.collapsed) {
  color: #61DAFB;
}
.accordion-flush .accordion-button:focus {
  border-color: #F0F3F6;
  box-shadow: none;
}
.accordion-flush .accordion-collapse {
  border: 0;
}

.alert-heading {
  font-weight: 600;
}

.alert-icon {
  margin-bottom: 0.5rem;
}
.alert-icon span {
  font-size: 2rem;
}

/**
 * = Avatars
 */
.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-link img {
  width: 4rem;
  height: 4rem;
}

.author-image img {
  width: 3rem;
  height: 3rem;
}

.user-avatar {
  height: 2.5rem;
  width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
}
.user-avatar.xs-avatar {
  height: 1.5rem;
  width: 1.5rem;
}
.user-avatar.xs-avatar img {
  height: 1.5rem;
  width: 1.5rem;
}
.user-avatar .md-avatar {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}
.user-avatar.lg-avatar {
  height: 3.5rem;
  width: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  font-size: 0.75rem;
}
@media (max-width: 991.98px) {
  .user-avatar.lg-avatar {
    height: 2rem;
    width: 2rem;
    min-height: 2rem;
    min-width: 2rem;
  }
}
.user-avatar.xl-avatar {
  height: 4.5rem;
  width: 4.5rem;
  min-height: 4.5rem;
  min-width: 4.5rem;
}
.user-avatar.large-avatar {
  border: 2px solid #ffffff;
  height: 10rem;
  width: 10rem;
  min-height: 10rem;
  min-width: 10rem;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #ffffff;
}
.avatar-group .avatar:hover {
  z-index: 3;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

/**
 * = Badges
 */
.badge {
  font-size: 0.75em;
  font-weight: 700;
}
.badge a {
  color: #ffffff;
}
.badge.super-badge {
  vertical-align: super;
}

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
}

.badge-inline {
  margin-right: 0.625rem;
}
.badge-inline + span {
  top: 2px;
  position: relative;
}
.badge-inline + span > a {
  text-decoration: underline;
}

.badge-md {
  padding: 0.25rem 0.4rem;
}

.badge-lg {
  padding: 0.35rem 0.85rem;
}

.badge-xl {
  font-size: 1.25rem;
  padding: 0.35rem 0.85rem;
}

.btn .badge-corner {
  position: absolute;
  top: -50%;
  right: 0.5rem;
  transform: translate(50%, 50%);
  margin: 0;
  border: 3px solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.wi-tags a {
  display: inline-block;
  padding: 0.125rem 0.875rem;
  margin: 0.25rem;
  line-height: 2;
  font-size: 0.875rem;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
}

.pixel-pro-badge {
  position: relative;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  right: -20px;
  padding: 5px 14px;
  top: -46px;
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}
@media (max-width: 575.98px) {
  .pixel-pro-badge {
    font-size: 1rem;
    right: -13px;
    padding: 5px 10px;
    top: -23px;
  }
}

.subscription-badge {
  top: -19px;
  right: -12px;
  font-size: 0.75rem;
}

/*
* = Buttons
*/
.btn.btn-circle {
  border-radius: 50%;
}
.btn.btn-md {
  padding: 0.65rem 1.25rem;
}
.btn.btn-xs {
  padding: 0.175rem 0.45rem;
  font-size: 0.7rem;
}

.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.btn-icon-only.btn-xs {
  width: 1.7rem;
  height: 1.7rem;
}
.btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
  width: 2rem;
  height: 2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.copy-code-button {
  position: absolute;
  top: 22px;
  right: 20px;
  background-color: transparent;
  color: #93a5be;
  border: 0;
  box-shadow: none;
}
.copy-code-button:hover {
  background-color: #262B40;
  color: #ffffff;
  border: 0;
  box-shadow: none;
}

.copy-code-text {
  position: absolute;
  top: 24px;
  right: 90px;
}

/**
 * = Breadcrumbs
 */
.breadcrumb-item {
  font-size: 0.75rem;
}
.breadcrumb-item, .breadcrumb-item a {
  color: #66799e;
  font-weight: 400;
  font-size: 0.75rem;
}
@media (min-width: 576px) {
  .breadcrumb-item, .breadcrumb-item a {
    font-size: 0.875rem;
  }
}
.breadcrumb-item.active {
  font-weight: 400;
}
.breadcrumb-item + .breadcrumb-item:before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #66799e;
}

.breadcrumb-transparent {
  background: transparent;
  padding: 0;
}

.breadcrumb-primary {
  background: #262B40;
}
.breadcrumb-primary .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-primary.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
  color: #262B40;
}

.breadcrumb-secondary {
  background: #61DAFB;
}
.breadcrumb-secondary .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-secondary.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-secondary.breadcrumb-transparent .breadcrumb-item.active {
  color: #61DAFB;
}

.breadcrumb-tertiary {
  background: #1B998B;
}
.breadcrumb-tertiary .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-tertiary.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-tertiary.breadcrumb-transparent .breadcrumb-item.active {
  color: #1B998B;
}

.breadcrumb-quaternary {
  background: #C96480;
}
.breadcrumb-quaternary .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-quaternary.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-quaternary.breadcrumb-transparent .breadcrumb-item.active {
  color: #C96480;
}

.breadcrumb-primary-app {
  background: #EBF4F6;
}
.breadcrumb-primary-app .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-primary-app.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-primary-app.breadcrumb-transparent .breadcrumb-item.active {
  color: #EBF4F6;
}

.breadcrumb-secondary-app {
  background: #424AA0;
}
.breadcrumb-secondary-app .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-secondary-app.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-secondary-app.breadcrumb-transparent .breadcrumb-item.active {
  color: #424AA0;
}

.breadcrumb-light {
  background: #eaedf2;
}
.breadcrumb-light .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-light.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-light.breadcrumb-transparent .breadcrumb-item.active {
  color: #eaedf2;
}

.breadcrumb-lighten {
  background: #F0F3F6;
}
.breadcrumb-lighten .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-lighten.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-lighten.breadcrumb-transparent .breadcrumb-item.active {
  color: #F0F3F6;
}

.breadcrumb-success {
  background: #05A677;
}
.breadcrumb-success .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-success.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-success.breadcrumb-transparent .breadcrumb-item.active {
  color: #05A677;
}

.breadcrumb-info {
  background: #0948B3;
}
.breadcrumb-info .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-info.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-info.breadcrumb-transparent .breadcrumb-item.active {
  color: #0948B3;
}

.breadcrumb-warning {
  background: #f5b759;
}
.breadcrumb-warning .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-warning.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-warning.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5b759;
}

.breadcrumb-danger {
  background: #FA5252;
}
.breadcrumb-danger .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-danger.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-danger.breadcrumb-transparent .breadcrumb-item.active {
  color: #FA5252;
}

.breadcrumb-white {
  background: #ffffff;
}
.breadcrumb-white .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-white.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-white.breadcrumb-transparent .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray {
  background: #4A5073;
}
.breadcrumb-gray .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray.breadcrumb-transparent .breadcrumb-item.active {
  color: #4A5073;
}

.breadcrumb-indigo {
  background: #4c5680;
}
.breadcrumb-indigo .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-indigo.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-indigo.breadcrumb-transparent .breadcrumb-item.active {
  color: #4c5680;
}

.breadcrumb-dark {
  background: #262B40;
}
.breadcrumb-dark .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-dark.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-dark.breadcrumb-transparent .breadcrumb-item.active {
  color: #262B40;
}

.breadcrumb-soft {
  background: #f5f8fb;
}
.breadcrumb-soft .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-soft.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-soft.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5f8fb;
}

.breadcrumb-black {
  background: #2e3650;
}
.breadcrumb-black .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-black.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-black.breadcrumb-transparent .breadcrumb-item.active {
  color: #2e3650;
}

.breadcrumb-blue {
  background: #0948B3;
}
.breadcrumb-blue .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-blue.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-blue.breadcrumb-transparent .breadcrumb-item.active {
  color: #0948B3;
}

.breadcrumb-pink {
  background: #C96480;
}
.breadcrumb-pink .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-pink.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-pink.breadcrumb-transparent .breadcrumb-item.active {
  color: #C96480;
}

.breadcrumb-cyan {
  background: #63b1bd;
}
.breadcrumb-cyan .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-cyan.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-cyan.breadcrumb-transparent .breadcrumb-item.active {
  color: #63b1bd;
}

.breadcrumb-purple {
  background: #8965e0;
}
.breadcrumb-purple .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-purple.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-purple.breadcrumb-transparent .breadcrumb-item.active {
  color: #8965e0;
}

.breadcrumb-soft-indigo {
  background: #f5e8ff;
}
.breadcrumb-soft-indigo .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-soft-indigo.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-soft-indigo.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5e8ff;
}

.breadcrumb-soft-green {
  background: #2CA58D;
}
.breadcrumb-soft-green .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-soft-green.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-soft-green.breadcrumb-transparent .breadcrumb-item.active {
  color: #2CA58D;
}

.breadcrumb-gray-100 {
  background: #f3f7fa;
}
.breadcrumb-gray-100 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-100.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-100.breadcrumb-transparent .breadcrumb-item.active {
  color: #f3f7fa;
}

.breadcrumb-gray-200 {
  background: #f5f8fb;
}
.breadcrumb-gray-200 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-200.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-200.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5f8fb;
}

.breadcrumb-gray-300 {
  background: #F0F3F6;
}
.breadcrumb-gray-300 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-300.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-300.breadcrumb-transparent .breadcrumb-item.active {
  color: #F0F3F6;
}

.breadcrumb-gray-400 {
  background: #eaedf2;
}
.breadcrumb-gray-400 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-400.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-400.breadcrumb-transparent .breadcrumb-item.active {
  color: #eaedf2;
}

.breadcrumb-gray-500 {
  background: #d1d7e0;
}
.breadcrumb-gray-500 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-500.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-500.breadcrumb-transparent .breadcrumb-item.active {
  color: #d1d7e0;
}

.breadcrumb-gray-600 {
  background: #93a5be;
}
.breadcrumb-gray-600 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-600.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-600.breadcrumb-transparent .breadcrumb-item.active {
  color: #93a5be;
}

.breadcrumb-gray-700 {
  background: #66799e;
}
.breadcrumb-gray-700 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-700.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-700.breadcrumb-transparent .breadcrumb-item.active {
  color: #66799e;
}

.breadcrumb-gray-800 {
  background: #506690;
}
.breadcrumb-gray-800 .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-gray-800.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-gray-800.breadcrumb-transparent .breadcrumb-item.active {
  color: #506690;
}

.breadcrumb-facebook {
  background: #3b5999;
}
.breadcrumb-facebook .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-facebook.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-facebook.breadcrumb-transparent .breadcrumb-item.active {
  color: #3b5999;
}

.breadcrumb-twitter {
  background: #1da1f2;
}
.breadcrumb-twitter .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-twitter.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-twitter.breadcrumb-transparent .breadcrumb-item.active {
  color: #1da1f2;
}

.breadcrumb-google {
  background: #DB4337;
}
.breadcrumb-google .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-google.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-google.breadcrumb-transparent .breadcrumb-item.active {
  color: #DB4337;
}

.breadcrumb-instagram {
  background: #e4405f;
}
.breadcrumb-instagram .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-instagram.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-instagram.breadcrumb-transparent .breadcrumb-item.active {
  color: #e4405f;
}

.breadcrumb-pinterest {
  background: #bd081c;
}
.breadcrumb-pinterest .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-pinterest.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-pinterest.breadcrumb-transparent .breadcrumb-item.active {
  color: #bd081c;
}

.breadcrumb-youtube {
  background: #cd201f;
}
.breadcrumb-youtube .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-youtube.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-youtube.breadcrumb-transparent .breadcrumb-item.active {
  color: #cd201f;
}

.breadcrumb-slack {
  background: #3aaf85;
}
.breadcrumb-slack .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-slack.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-slack.breadcrumb-transparent .breadcrumb-item.active {
  color: #3aaf85;
}

.breadcrumb-dribbble {
  background: #ea4c89;
}
.breadcrumb-dribbble .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-dribbble.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-dribbble.breadcrumb-transparent .breadcrumb-item.active {
  color: #ea4c89;
}

.breadcrumb-dropbox {
  background: #1E90FF;
}
.breadcrumb-dropbox .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-dropbox.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-dropbox.breadcrumb-transparent .breadcrumb-item.active {
  color: #1E90FF;
}

.breadcrumb-twitch {
  background: #4B367C;
}
.breadcrumb-twitch .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-twitch.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-twitch.breadcrumb-transparent .breadcrumb-item.active {
  color: #4B367C;
}

.breadcrumb-paypal {
  background: #ecb32c;
}
.breadcrumb-paypal .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-paypal.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-paypal.breadcrumb-transparent .breadcrumb-item.active {
  color: #ecb32c;
}

.breadcrumb-behance {
  background: #0057ff;
}
.breadcrumb-behance .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-behance.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-behance.breadcrumb-transparent .breadcrumb-item.active {
  color: #0057ff;
}

.breadcrumb-reddit {
  background: #E84422;
}
.breadcrumb-reddit .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-reddit.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-reddit.breadcrumb-transparent .breadcrumb-item.active {
  color: #E84422;
}

.breadcrumb-github {
  background: #222222;
}
.breadcrumb-github .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-github.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-github.breadcrumb-transparent .breadcrumb-item.active {
  color: #222222;
}

.breadcrumb-adobe {
  background: #ff9a00;
}
.breadcrumb-adobe .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-adobe.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-adobe.breadcrumb-transparent .breadcrumb-item.active {
  color: #ff9a00;
}

.breadcrumb-bootstrap {
  background: #7C277D;
}
.breadcrumb-bootstrap .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-bootstrap.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-bootstrap.breadcrumb-transparent .breadcrumb-item.active {
  color: #7C277D;
}

.breadcrumb-python {
  background: #306998;
}
.breadcrumb-python .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-python.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-python.breadcrumb-transparent .breadcrumb-item.active {
  color: #306998;
}

.breadcrumb-react {
  background: #00d8ff;
}
.breadcrumb-react .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-react.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-react.breadcrumb-transparent .breadcrumb-item.active {
  color: #00d8ff;
}

.breadcrumb-azure {
  background: #2892df;
}
.breadcrumb-azure .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-azure.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-azure.breadcrumb-transparent .breadcrumb-item.active {
  color: #2892df;
}

.breadcrumb-js {
  background: #EFD81F;
}
.breadcrumb-js .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-js.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-js.breadcrumb-transparent .breadcrumb-item.active {
  color: #EFD81F;
}

.breadcrumb-swe {
  background: #FFCF3B;
}
.breadcrumb-swe .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-swe.breadcrumb-transparent {
  background: transparent;
}
.breadcrumb-swe.breadcrumb-transparent .breadcrumb-item.active {
  color: #FFCF3B;
}

.breadcrumb-text-light .breadcrumb-item, .breadcrumb-text-light .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb-text-light .breadcrumb-item:before {
  color: #ffffff;
}

/**
 * = Blog cards
 */
.card-img-overlay {
  padding: 0;
}
.card-img-overlay .card-footer,
.card-img-overlay .card-header {
  background: transparent;
  border-color: #66799e;
}

/**
 * = Cards
 */
.card {
  position: relative;
}
.card .card-header {
  background: transparent;
}
.card.hover-state:hover {
  background-color: #f5f8fb;
}
.card .profile-cover {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 200px;
}
@media (min-width: 992px) {
  .card.timeline-card:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: 0.75rem;
    border: 8px solid #2e3650;
    border-color: transparent #e0e6ec #e0e6ec transparent;
    box-sizing: border-box;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }
  .card.left-timeline-card:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: -5px;
    border: 8px solid #2e3650;
    border-color: transparent #e0e6ec #e0e6ec transparent;
    box-sizing: border-box;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }
}

.message-wrapper .card,
.task-wrapper .card {
  border: 0;
}

.customer-testimonial .content-wrapper:before {
  content: "";
  position: absolute;
  top: 25px;
  left: -1px;
  margin-left: 0.75rem;
  border: 8px solid #2e3650;
  border-color: transparent #e0e6ec #e0e6ec transparent;
  box-sizing: border-box;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.profile-page .card-profile {
  margin-top: -150px;
}
.profile-page .card-profile .card-profile-image {
  position: relative;
}
.profile-page .card-profile .card-profile-image img {
  max-width: 180px;
  border-radius: 0.5rem;
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  transition: all 0.2s ease;
}
.profile-page .card-profile .card-profile-image img:hover {
  transform: translate(-50%, -33%);
}
.profile-page .card-profile .card-profile-stats {
  padding: 1rem 0;
}
.profile-page .card-profile .card-profile-stats > div {
  text-align: center;
  margin-right: 1rem;
  padding: 0.875rem;
}
.profile-page .card-profile .card-profile-stats > div:last-child {
  margin-right: 0;
}
.profile-page .card-profile .card-profile-stats > div .heading {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
}
.profile-page .card-profile .card-profile-stats > div .description {
  font-size: 0.875rem;
  color: #d1d7e0;
}
.profile-page .card-profile .card-profile-actions {
  padding: 0.875rem;
}
.profile-page .card-profile .card-profile-actions {
  margin-top: 110px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .profile-page .card-profile .card-profile-stats {
    margin-top: 30px;
  }
}

.card-footer {
  background-color: transparent;
}

.card-stats {
  padding-left: 1.9rem;
}

.card .card-blockquote {
  position: relative;
  padding: 2rem;
}
.card .card-blockquote .svg-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: 95px;
  top: -94px;
  left: 0;
}

.card-lift-hover:hover {
  transform: translateY(-20px);
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .card-lift-hover:hover {
    transition: none;
  }
}

.page-preview {
  display: block;
  position: relative;
}
.page-preview .show-on-hover {
  position: absolute;
  bottom: -25px;
  background: rgba(38, 43, 64, 0.85);
  padding: 10px 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: calc(100% + 28px);
  left: -14px;
  opacity: 0;
  transition: 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .page-preview .show-on-hover {
    transition: none;
  }
}
.page-preview.page-preview-lg .show-on-hover {
  left: -30px;
  width: calc(100% + 60px);
  bottom: -20px;
}
.page-preview:hover .show-on-hover {
  z-index: 99;
  opacity: 1;
}

.live-editor textarea, .live-editor pre {
  font-size: 1.2em !important;
  padding: 0 !important;
}
.live-editor textarea:focus {
  outline: none !important;
}

.prism-code {
  padding: 0 !important;
}

.theme-settings {
  z-index: 99;
  position: fixed;
  right: 15px;
  bottom: 0;
}
.theme-settings, .theme-settings .card-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-settings-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.65rem;
}

.theme-settings-expand {
  right: 15px;
  bottom: 0;
}
.theme-settings-expand:hover {
  background: #f5f8fb;
  cursor: pointer;
}

/**
 * = Bootstrap carousels
 */
.carousel-caption, .carousel-caption h5, .carousel-caption .h5 {
  color: #ffffff;
}

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #ffffff;
}
.carousel-indicators .active {
  opacity: 1;
  background: #ffffff;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: auto;
  height: auto;
  font-weight: 900;
}

.carousel-control-prev-icon {
  background-image: none;
}
.carousel-control-prev-icon::before {
  content: "\f060";
  font-family: "Font Awesome 5 Free";
  font-size: 2rem;
}

.carousel-control-next-icon {
  background-image: none;
}
.carousel-control-next-icon:before {
  font-family: "Font Awesome 5 Free";
  content: "\f061";
  font-size: 2rem;
}

/**
 * = Close
 */
.close {
  transition: all 0.2s ease;
}
.close > span:not(.sr-only) {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  background-color: transparent;
  color: #2e3650;
  line-height: 17px;
  border-radius: 50%;
  font-size: 1.25rem;
  transition: all 0.2s ease;
}
.close:hover, .close:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}
.close:hover span:not(.sr-only), .close:focus span:not(.sr-only) {
  background-color: transparent;
}

/**
 * = Counters
 */
.counter-alternate::after {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-size: 2.25rem;
  color: #262B40;
  font-weight: 900;
}

/**
 * = Custom forms
 */
.custom-control-label:before {
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label span {
  position: relative;
  top: 2px;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: #262B40;
}

.custom-file-label {
  background-color: #f3f3f5;
}
.custom-file-label::after {
  background-color: #f3f3f5;
}

.custom-select {
  font-size: 1rem;
  box-shadow: none;
}
.custom-select.custom-select-shadow {
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  transition: box-shadow 0.15s ease;
}
.custom-select.custom-select-shadow:focus {
  box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
}
.custom-select:hover {
  cursor: pointer;
}

.rating-star.star-lg label {
  margin-bottom: 0.5rem;
}
.rating-star.star-lg label::before {
  font-size: 1.5rem;
}
.rating-star label {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  color: #eaedf2;
}
.rating-star label:before {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: all 0.2s ease;
  font-size: 1.0625rem;
}
.rating-star input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.rating-star > input:checked ~ label {
  color: #f5b759;
}
.rating-star > label:hover {
  color: #f5b759;
}
.rating-star > label:hover ~ label {
  color: #f5b759;
}

.ct-bar {
  stroke-linecap: round;
  stroke-width: 10px;
}

.ct-label {
  fill: #4A5073;
  color: #4A5073;
  font-size: 0.75rem;
  margin-top: 6px;
  font-weight: 600;
}

.ct-slice-pie {
  stroke: #ffffff;
  stroke-width: 3px;
}

.ct-chart-donut .ct-series {
  stroke: #ffffff;
  stroke-width: 3px;
}

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
  font-size: 0.75rem;
  font-weight: 600;
}

.small-chart {
  margin-left: -1rem;
}

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.ct-series-g .ct-grid {
  stroke: #61DAFB;
  stroke-width: 2px;
  stroke-dasharray: 2px;
}
.ct-series-g .ct-label.ct-horizontal.ct-end {
  margin-left: -9px;
  margin-top: 10px;
  color: #262B40;
}

/**
 * = Dropdowns
 */
.dropdown-menu {
  min-width: 12rem;
}
.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.dropdown-menu .dropdown-header {
  color: #4A5073;
  font-weight: 600;
}
.dropdown-menu .dropdown-item {
  color: #66799e;
  transition: all 0.2s ease;
  font-weight: 300;
}
.dropdown-menu .dropdown-itema:hover {
  color: #4A5073;
}
.show .dropdown-menu {
  animation: show-dropdown 0.2s ease forwards;
}
.dropdown-menu.dropdown-menu-xs {
  min-width: 120px;
  max-width: 120px;
  border: 0.0625rem solid #eaedf2;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}

.dropdown-divider {
  margin: 0.375rem 0;
  border-color: #eaedf2;
}

[data-toggle]:hover {
  cursor: pointer;
}

.dropdown-toggle:after, .dropright .dropdown-toggle:after, .dropleft .dropdown-toggle:before, .dropup .dropdown-toggle:after {
  display: none;
}

.dropdown-menu-sm {
  min-width: 100px;
  border: 0.3rem;
}

.dropdown-menu-md {
  min-width: 180px;
  border: 0.3rem;
}

.dropdown-menu-lg {
  min-width: 350px;
  border-radius: 0.3rem;
}
@media (max-width: 991.98px) {
  .dropdown-menu-lg {
    min-width: 285px;
    transform: translateX(15%);
  }
}

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: 0.3rem;
}
@media (max-width: 1199.98px) {
  .dropdown-menu-xl {
    min-width: 285px;
    transform: translateX(15%);
  }
}

.user-dropdown.dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
}

@media (max-width: 991.98px) {
  .notifications-dropdown[style] {
    left: auto !important;
    right: 0 !important;
  }
}
.dropzone {
  min-height: 150px;
  border: 2px dashed #93a5be;
  background: #ffffff;
  cursor: pointer;
}
.dropzone:hover {
  background-color: #f5f8fb;
}
.dropzone .dz-message .dz-button {
  font-size: 1.25rem;
  font-weight: 600;
}

.dropzone-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.5rem 0 1.5rem 0;
}

.dropzone-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-height: 100px;
}

.dropzone-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone-filename {
  white-space: normal;
  padding: 0 0.4em;
  margin: 5px 0 5px 0;
  border-radius: 3px;
}

.dropzone-filename:not(:hover) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control {
  box-shadow: none;
}
@media (min-width: 992px) {
  .form-control.form-control-lg {
    padding: 1rem 0.75rem;
  }
}

.form-check-input.round-check[type=checkbox] {
  border-radius: 50%;
}

.search-bar .form-control {
  width: 280px;
  transition: width 0.2s ease-in-out;
}
@media (max-width: 575.98px) {
  .search-bar .form-control {
    width: 120px;
  }
  .search-bar .form-control:focus {
    width: 150px;
  }
}

.input-group .form-control:focus {
  border-color: #d1d7e0;
}
.input-group .form-control:focus + .input-group-text {
  border-color: #d1d7e0;
}

.focused .form-control {
  border-color: #566190;
  background-color: #ffffff;
}

.form-switch {
  padding-left: 2.75em;
}
.form-switch .form-check-input {
  height: 1.275em;
}

.form-select .form-select-lg {
  padding: 0.55rem 1.75rem 0.55rem 0.75rem;
}

.file-field input[type=file] {
  max-width: 230px;
  position: absolute;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  padding-bottom: 30px;
}

.file-field span {
  cursor: pointer;
}

.datepicker-dropdown {
  z-index: 9999;
}

/**
 * = Icon boxes
 */
.icon-box {
  padding: 1rem;
  position: relative;
}
.icon-box .icon-box-body {
  position: relative;
}

.icon {
  text-align: center;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.icon span,
.icon svg {
  font-size: 2rem;
}
.icon.icon-small span,
.icon.icon-small svg {
  font-size: 0.675rem;
}
.icon.icon-xs span,
.icon.icon-xs svg {
  font-size: 0.875rem;
}
.icon.icon-sm span,
.icon.icon-sm svg {
  font-size: 1.25rem;
}
.icon.icon-lg span,
.icon.icon-lg svg {
  font-size: 2.75rem;
}
.icon.icon-lg svg {
  height: 2rem;
}
.icon.icon-xl span {
  font-size: 4.5rem;
}
.icon.icon-xl svg {
  height: 3.5rem;
}
.icon.w-20 {
  width: 20px;
}
.icon.w-30 {
  width: 30px;
}
.icon.rounded-circle .icon-bordered {
  border-radius: 50%;
}

.icon-shape {
  width: 4.5rem;
  height: 4.5rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-shape span,
.icon-shape svg {
  font-size: 2rem;
}
.icon-shape.icon-xs {
  width: 2rem;
  height: 2rem;
}
.icon-shape.icon-xs span,
.icon-shape.icon-xs svg {
  font-size: 0.875rem;
}
.icon-shape.icon-sm {
  width: 3rem;
  height: 3rem;
}
.icon-shape.icon-sm span,
.icon-shape.icon-sm svg {
  font-size: 1.25rem;
}
.icon-shape.icon-lg {
  width: 5.5rem;
  height: 5.5rem;
}
.icon-shape.icon-lg span,
.icon-shape.icon-lg svg {
  font-size: 1.875rem;
}

.image-shape {
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-shape span {
  font-size: 2rem;
  z-index: 1;
}
.image-shape.icon-parallax {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  background-repeat: no-repeat;
}

.list-icon {
  width: 45px;
}

.shape-xs {
  width: 10px;
  height: 10px;
}

.icon-badge {
  display: inline-block;
  position: absolute;
  top: -11px;
  right: 6px;
  height: 7px;
  width: 7px;
  background-color: #FA5252;
}

.github-big-icon {
  position: absolute;
  right: 200px;
  top: 165px;
}
.github-big-icon span {
  font-size: 800px;
  opacity: 0.1;
}
.github-big-icon svg {
  font-size: 800px;
  opacity: 0.1;
}

.react-big-icon {
  position: absolute;
  opacity: 0.05;
}
@media (min-width: 992px) {
  .react-big-icon {
    right: -35%;
    bottom: -30%;
    font-size: 800px;
  }
}
@media (min-width: 1200px) {
  .react-big-icon {
    right: -15%;
    bottom: -35%;
    font-size: 800px;
  }
}

/**
 * = Images
 */
.image-xl {
  height: 20rem;
}
.image-xl img {
  height: 20rem;
}

.image-lg {
  height: 12rem;
}
.image-lg img {
  height: 12rem;
}

.image-md {
  height: 5.5rem;
}
.image-md img {
  height: 5.5rem;
}

.image-sm {
  height: 3rem;
}
.image-sm img {
  height: 3rem;
}

.image-xs {
  height: 1.5rem;
}
.image-xs img {
  height: 1.5rem;
}

.image-small {
  height: 1rem;
}
.image-small img {
  height: 1rem;
}

.img-thumbnail {
  border-width: 0.125rem;
  box-shadow: none;
}

.full-image {
  height: 100%;
}

.gallery-feed img {
  width: 20%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
}

.language-flag {
  width: auto;
  height: 1rem;
  margin-right: 0.4rem;
  position: relative;
  top: -2px;
}

@media (min-width: 576px) {
  .effect-img-2 {
    position: absolute;
    right: 5rem;
    top: 19%;
    z-index: 2;
    margin: 0;
  }
  .effect-img-1, .effect-img-2 {
    margin: 0 0 3rem;
    width: 350px;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  .effect-img-2 {
    right: 0.425rem;
    top: 0;
  }
}
/**
 * = Input groups
 */
.input-group {
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}
.input-group .form-control {
  box-shadow: none;
}
.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}
.input-group .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}
.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  font-size: 0.875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-right: 0;
}

.input-group-prepend {
  margin-right: -2px;
}

.focused .input-group-text {
  color: #66799e;
  background-color: #ffffff;
}
.focused .input-group-shadow {
  box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
}

/**
 * = List groups
 */
.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.list-group-item {
  border: 0;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #262B40;
  border-color: #262B40;
}
.list-group-item i {
  width: 1rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}

.list-group-content {
  flex: 1;
  min-width: 0;
}
.list-group-content > p {
  color: #d1d7e0;
  line-height: 1.5;
  margin: 0.2rem 0 0;
}

.list-group-heading {
  font-size: 1rem;
  color: #506690;
}
.list-group-heading > small, .list-group-heading > .small {
  float: right;
  color: #d1d7e0;
  font-weight: 500;
}

.list-group.simple-list .list-group-item {
  background: transparent;
  border: none;
  padding: 0.375rem 0.125rem;
}
.list-group.simple-list .list-group-item i {
  vertical-align: middle;
  width: 35px;
  display: inline-block;
}

.news-list .row > [class*=col] {
  padding: 0 0.25rem;
}
.news-list > li:not(:last-child) {
  margin-bottom: 0.75rem;
}
.news-list img {
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/**
 * = Modals
 */
.modal.static-example {
  position: relative;
  display: block;
}

.modal-content {
  border: 0;
  border-radius: 0.3rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}

/**
 * = Navs
 */
.nav-link {
  color: #506690;
}
.nav-link:hover, .nav-link.active {
  color: #262B40;
}
.nav-link:hover img, .nav-link.active img {
  opacity: inherit;
  transition: all 0.2s ease;
}
.nav-link span {
  position: relative;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  border: 0;
  padding: 1rem 1rem;
}
.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #262B40;
}
.nav-tabs.nav-dark .nav-link.active {
  color: #ffffff;
  background-color: #12358a;
}
.nav-tabs.nav-light .nav-link.active {
  color: #2e3650;
  background-color: #eaedf2;
}
.nav-tabs.nav-light .nav-link:hover {
  color: #2e3650;
}

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}
.nav-pills .nav-link {
  padding: 0.75rem 0.85rem;
  transition: all 0.2s ease;
  box-shadow: none;
  border: 1px solid #eaedf2;
}
.nav-pills .nav-link.avatar-link {
  border: 0;
}
.nav-pills .nav-link:hover {
  color: #262B40;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #262B40;
  background-color: #f8f8f8;
  border-color: #262B40;
}
.nav-pills.rounded .nav-link {
  border-radius: 30px;
}
.nav-pills.bordered-pill-md .nav-link {
  border: 0.125rem solid #f5f8fb;
  font-weight: 600;
}
.nav-pills.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
}
.nav-pills.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pills .nav-item {
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0;
  }
}
.nav-pill-circle .nav-link {
  display: flex;
  text-align: center;
  height: 80px;
  width: 80px;
  padding: 0;
  box-shadow: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.nav-pill-circle .nav-link.avatar-link {
  box-shadow: none;
}
.nav-pill-circle.vertical-tab .nav-link-icon i, .nav-pill-circle.vertical-tab .nav-link-icon svg {
  font-size: 1.5rem;
}
.nav-pill-circle.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pill-circle .nav-link-icon i, .nav-pill-circle .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
}

.nav-pill-square .nav-link {
  text-align: center;
  min-width: 80px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pill-square.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
  min-width: 100px;
}
.nav-pill-square.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pill-square .nav-link-icon i, .nav-pill-square .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
  line-height: 50px;
}

.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-wrapper + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .tab-pane pre {
  padding: 0;
  margin: 0;
}
.tab-content > .active {
  display: block;
}

/**
 * = Paginations
 */
.circle-pagination .page-link,
.circle-pagination span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
}

/**
 * = Pricing cards
 */
.pricing-card .card-header {
  background: transparent;
  z-index: 2;
}
.pricing-card .card-body {
  z-index: 2;
}
.pricing-card .pricing-value {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 0.375rem;
  border-style: double;
  margin: 0 auto;
}
.pricing-card .pricing-value span {
  line-height: 140px;
}
.pricing-card .list-group-item {
  background: transparent;
  border: none;
  padding: 0.375rem 0.125rem;
}
.pricing-card .list-group-item i {
  display: inline-block;
  vertical-align: middle;
  width: 35px;
}
@media (max-width: 767.98px) {
  .pricing-card {
    margin-top: 1rem;
  }
}

/**
 * = Popovers
 */
.popover {
  border: 0;
}

.popover-header {
  font-weight: 600;
}

.popover-primary {
  background-color: #262B40;
}
.popover-primary .popover-header {
  background-color: #262B40;
  color: color-yiq(#262B40);
}
.popover-primary .popover-body {
  color: color-yiq(#262B40);
}
.popover-primary .popover-header {
  border-color: rgba(38, 43, 64, 0.2);
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #262B40;
}
.popover-primary.bs-popover-right .arrow::after {
  border-right-color: #262B40;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #262B40;
}
.popover-primary.bs-popover-left .arrow::after {
  border-left-color: #262B40;
}

.popover-secondary {
  background-color: #61DAFB;
}
.popover-secondary .popover-header {
  background-color: #61DAFB;
  color: color-yiq(#61DAFB);
}
.popover-secondary .popover-body {
  color: color-yiq(#61DAFB);
}
.popover-secondary .popover-header {
  border-color: rgba(97, 218, 251, 0.2);
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #61DAFB;
}
.popover-secondary.bs-popover-right .arrow::after {
  border-right-color: #61DAFB;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #61DAFB;
}
.popover-secondary.bs-popover-left .arrow::after {
  border-left-color: #61DAFB;
}

.popover-tertiary {
  background-color: #1B998B;
}
.popover-tertiary .popover-header {
  background-color: #1B998B;
  color: color-yiq(#1B998B);
}
.popover-tertiary .popover-body {
  color: color-yiq(#1B998B);
}
.popover-tertiary .popover-header {
  border-color: rgba(27, 153, 139, 0.2);
}
.popover-tertiary.bs-popover-top .arrow::after, .popover-tertiary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #1B998B;
}
.popover-tertiary.bs-popover-right .arrow::after {
  border-right-color: #1B998B;
}
.popover-tertiary.bs-popover-bottom .arrow::after, .popover-tertiary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #1B998B;
}
.popover-tertiary.bs-popover-left .arrow::after {
  border-left-color: #1B998B;
}

.popover-quaternary {
  background-color: #C96480;
}
.popover-quaternary .popover-header {
  background-color: #C96480;
  color: color-yiq(#C96480);
}
.popover-quaternary .popover-body {
  color: color-yiq(#C96480);
}
.popover-quaternary .popover-header {
  border-color: rgba(201, 100, 128, 0.2);
}
.popover-quaternary.bs-popover-top .arrow::after, .popover-quaternary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #C96480;
}
.popover-quaternary.bs-popover-right .arrow::after {
  border-right-color: #C96480;
}
.popover-quaternary.bs-popover-bottom .arrow::after, .popover-quaternary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #C96480;
}
.popover-quaternary.bs-popover-left .arrow::after {
  border-left-color: #C96480;
}

.popover-primary-app {
  background-color: #EBF4F6;
}
.popover-primary-app .popover-header {
  background-color: #EBF4F6;
  color: color-yiq(#EBF4F6);
}
.popover-primary-app .popover-body {
  color: color-yiq(#EBF4F6);
}
.popover-primary-app .popover-header {
  border-color: rgba(235, 244, 246, 0.2);
}
.popover-primary-app.bs-popover-top .arrow::after, .popover-primary-app.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #EBF4F6;
}
.popover-primary-app.bs-popover-right .arrow::after {
  border-right-color: #EBF4F6;
}
.popover-primary-app.bs-popover-bottom .arrow::after, .popover-primary-app.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #EBF4F6;
}
.popover-primary-app.bs-popover-left .arrow::after {
  border-left-color: #EBF4F6;
}

.popover-secondary-app {
  background-color: #424AA0;
}
.popover-secondary-app .popover-header {
  background-color: #424AA0;
  color: color-yiq(#424AA0);
}
.popover-secondary-app .popover-body {
  color: color-yiq(#424AA0);
}
.popover-secondary-app .popover-header {
  border-color: rgba(66, 74, 160, 0.2);
}
.popover-secondary-app.bs-popover-top .arrow::after, .popover-secondary-app.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #424AA0;
}
.popover-secondary-app.bs-popover-right .arrow::after {
  border-right-color: #424AA0;
}
.popover-secondary-app.bs-popover-bottom .arrow::after, .popover-secondary-app.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #424AA0;
}
.popover-secondary-app.bs-popover-left .arrow::after {
  border-left-color: #424AA0;
}

.popover-light {
  background-color: #eaedf2;
}
.popover-light .popover-header {
  background-color: #eaedf2;
  color: color-yiq(#eaedf2);
}
.popover-light .popover-body {
  color: color-yiq(#eaedf2);
}
.popover-light .popover-header {
  border-color: rgba(234, 237, 242, 0.2);
}
.popover-light.bs-popover-top .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #eaedf2;
}
.popover-light.bs-popover-right .arrow::after {
  border-right-color: #eaedf2;
}
.popover-light.bs-popover-bottom .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #eaedf2;
}
.popover-light.bs-popover-left .arrow::after {
  border-left-color: #eaedf2;
}

.popover-lighten {
  background-color: #F0F3F6;
}
.popover-lighten .popover-header {
  background-color: #F0F3F6;
  color: color-yiq(#F0F3F6);
}
.popover-lighten .popover-body {
  color: color-yiq(#F0F3F6);
}
.popover-lighten .popover-header {
  border-color: rgba(240, 243, 246, 0.2);
}
.popover-lighten.bs-popover-top .arrow::after, .popover-lighten.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #F0F3F6;
}
.popover-lighten.bs-popover-right .arrow::after {
  border-right-color: #F0F3F6;
}
.popover-lighten.bs-popover-bottom .arrow::after, .popover-lighten.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #F0F3F6;
}
.popover-lighten.bs-popover-left .arrow::after {
  border-left-color: #F0F3F6;
}

.popover-success {
  background-color: #05A677;
}
.popover-success .popover-header {
  background-color: #05A677;
  color: color-yiq(#05A677);
}
.popover-success .popover-body {
  color: color-yiq(#05A677);
}
.popover-success .popover-header {
  border-color: rgba(5, 166, 119, 0.2);
}
.popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #05A677;
}
.popover-success.bs-popover-right .arrow::after {
  border-right-color: #05A677;
}
.popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #05A677;
}
.popover-success.bs-popover-left .arrow::after {
  border-left-color: #05A677;
}

.popover-info {
  background-color: #0948B3;
}
.popover-info .popover-header {
  background-color: #0948B3;
  color: color-yiq(#0948B3);
}
.popover-info .popover-body {
  color: color-yiq(#0948B3);
}
.popover-info .popover-header {
  border-color: rgba(9, 72, 179, 0.2);
}
.popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #0948B3;
}
.popover-info.bs-popover-right .arrow::after {
  border-right-color: #0948B3;
}
.popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #0948B3;
}
.popover-info.bs-popover-left .arrow::after {
  border-left-color: #0948B3;
}

.popover-warning {
  background-color: #f5b759;
}
.popover-warning .popover-header {
  background-color: #f5b759;
  color: color-yiq(#f5b759);
}
.popover-warning .popover-body {
  color: color-yiq(#f5b759);
}
.popover-warning .popover-header {
  border-color: rgba(245, 183, 89, 0.2);
}
.popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #f5b759;
}
.popover-warning.bs-popover-right .arrow::after {
  border-right-color: #f5b759;
}
.popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #f5b759;
}
.popover-warning.bs-popover-left .arrow::after {
  border-left-color: #f5b759;
}

.popover-danger {
  background-color: #FA5252;
}
.popover-danger .popover-header {
  background-color: #FA5252;
  color: color-yiq(#FA5252);
}
.popover-danger .popover-body {
  color: color-yiq(#FA5252);
}
.popover-danger .popover-header {
  border-color: rgba(250, 82, 82, 0.2);
}
.popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FA5252;
}
.popover-danger.bs-popover-right .arrow::after {
  border-right-color: #FA5252;
}
.popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FA5252;
}
.popover-danger.bs-popover-left .arrow::after {
  border-left-color: #FA5252;
}

.popover-white {
  background-color: #ffffff;
}
.popover-white .popover-header {
  background-color: #ffffff;
  color: color-yiq(#ffffff);
}
.popover-white .popover-body {
  color: color-yiq(#ffffff);
}
.popover-white .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-white.bs-popover-top .arrow::after, .popover-white.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #ffffff;
}
.popover-white.bs-popover-right .arrow::after {
  border-right-color: #ffffff;
}
.popover-white.bs-popover-bottom .arrow::after, .popover-white.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #ffffff;
}
.popover-white.bs-popover-left .arrow::after {
  border-left-color: #ffffff;
}

.popover-gray {
  background-color: #4A5073;
}
.popover-gray .popover-header {
  background-color: #4A5073;
  color: color-yiq(#4A5073);
}
.popover-gray .popover-body {
  color: color-yiq(#4A5073);
}
.popover-gray .popover-header {
  border-color: rgba(74, 80, 115, 0.2);
}
.popover-gray.bs-popover-top .arrow::after, .popover-gray.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #4A5073;
}
.popover-gray.bs-popover-right .arrow::after {
  border-right-color: #4A5073;
}
.popover-gray.bs-popover-bottom .arrow::after, .popover-gray.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #4A5073;
}
.popover-gray.bs-popover-left .arrow::after {
  border-left-color: #4A5073;
}

.popover-indigo {
  background-color: #4c5680;
}
.popover-indigo .popover-header {
  background-color: #4c5680;
  color: color-yiq(#4c5680);
}
.popover-indigo .popover-body {
  color: color-yiq(#4c5680);
}
.popover-indigo .popover-header {
  border-color: rgba(76, 86, 128, 0.2);
}
.popover-indigo.bs-popover-top .arrow::after, .popover-indigo.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #4c5680;
}
.popover-indigo.bs-popover-right .arrow::after {
  border-right-color: #4c5680;
}
.popover-indigo.bs-popover-bottom .arrow::after, .popover-indigo.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #4c5680;
}
.popover-indigo.bs-popover-left .arrow::after {
  border-left-color: #4c5680;
}

.popover-dark {
  background-color: #262B40;
}
.popover-dark .popover-header {
  background-color: #262B40;
  color: color-yiq(#262B40);
}
.popover-dark .popover-body {
  color: color-yiq(#262B40);
}
.popover-dark .popover-header {
  border-color: rgba(38, 43, 64, 0.2);
}
.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #262B40;
}
.popover-dark.bs-popover-right .arrow::after {
  border-right-color: #262B40;
}
.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #262B40;
}
.popover-dark.bs-popover-left .arrow::after {
  border-left-color: #262B40;
}

.popover-soft {
  background-color: #f5f8fb;
}
.popover-soft .popover-header {
  background-color: #f5f8fb;
  color: color-yiq(#f5f8fb);
}
.popover-soft .popover-body {
  color: color-yiq(#f5f8fb);
}
.popover-soft .popover-header {
  border-color: rgba(245, 248, 251, 0.2);
}
.popover-soft.bs-popover-top .arrow::after, .popover-soft.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #f5f8fb;
}
.popover-soft.bs-popover-right .arrow::after {
  border-right-color: #f5f8fb;
}
.popover-soft.bs-popover-bottom .arrow::after, .popover-soft.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #f5f8fb;
}
.popover-soft.bs-popover-left .arrow::after {
  border-left-color: #f5f8fb;
}

.popover-black {
  background-color: #2e3650;
}
.popover-black .popover-header {
  background-color: #2e3650;
  color: color-yiq(#2e3650);
}
.popover-black .popover-body {
  color: color-yiq(#2e3650);
}
.popover-black .popover-header {
  border-color: rgba(46, 54, 80, 0.2);
}
.popover-black.bs-popover-top .arrow::after, .popover-black.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #2e3650;
}
.popover-black.bs-popover-right .arrow::after {
  border-right-color: #2e3650;
}
.popover-black.bs-popover-bottom .arrow::after, .popover-black.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #2e3650;
}
.popover-black.bs-popover-left .arrow::after {
  border-left-color: #2e3650;
}

.popover-blue {
  background-color: #0948B3;
}
.popover-blue .popover-header {
  background-color: #0948B3;
  color: color-yiq(#0948B3);
}
.popover-blue .popover-body {
  color: color-yiq(#0948B3);
}
.popover-blue .popover-header {
  border-color: rgba(9, 72, 179, 0.2);
}
.popover-blue.bs-popover-top .arrow::after, .popover-blue.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #0948B3;
}
.popover-blue.bs-popover-right .arrow::after {
  border-right-color: #0948B3;
}
.popover-blue.bs-popover-bottom .arrow::after, .popover-blue.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #0948B3;
}
.popover-blue.bs-popover-left .arrow::after {
  border-left-color: #0948B3;
}

.popover-pink {
  background-color: #C96480;
}
.popover-pink .popover-header {
  background-color: #C96480;
  color: color-yiq(#C96480);
}
.popover-pink .popover-body {
  color: color-yiq(#C96480);
}
.popover-pink .popover-header {
  border-color: rgba(201, 100, 128, 0.2);
}
.popover-pink.bs-popover-top .arrow::after, .popover-pink.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #C96480;
}
.popover-pink.bs-popover-right .arrow::after {
  border-right-color: #C96480;
}
.popover-pink.bs-popover-bottom .arrow::after, .popover-pink.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #C96480;
}
.popover-pink.bs-popover-left .arrow::after {
  border-left-color: #C96480;
}

.popover-cyan {
  background-color: #63b1bd;
}
.popover-cyan .popover-header {
  background-color: #63b1bd;
  color: color-yiq(#63b1bd);
}
.popover-cyan .popover-body {
  color: color-yiq(#63b1bd);
}
.popover-cyan .popover-header {
  border-color: rgba(99, 177, 189, 0.2);
}
.popover-cyan.bs-popover-top .arrow::after, .popover-cyan.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #63b1bd;
}
.popover-cyan.bs-popover-right .arrow::after {
  border-right-color: #63b1bd;
}
.popover-cyan.bs-popover-bottom .arrow::after, .popover-cyan.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #63b1bd;
}
.popover-cyan.bs-popover-left .arrow::after {
  border-left-color: #63b1bd;
}

.popover-purple {
  background-color: #8965e0;
}
.popover-purple .popover-header {
  background-color: #8965e0;
  color: color-yiq(#8965e0);
}
.popover-purple .popover-body {
  color: color-yiq(#8965e0);
}
.popover-purple .popover-header {
  border-color: rgba(137, 101, 224, 0.2);
}
.popover-purple.bs-popover-top .arrow::after, .popover-purple.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #8965e0;
}
.popover-purple.bs-popover-right .arrow::after {
  border-right-color: #8965e0;
}
.popover-purple.bs-popover-bottom .arrow::after, .popover-purple.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #8965e0;
}
.popover-purple.bs-popover-left .arrow::after {
  border-left-color: #8965e0;
}

.popover-soft-indigo {
  background-color: #f5e8ff;
}
.popover-soft-indigo .popover-header {
  background-color: #f5e8ff;
  color: color-yiq(#f5e8ff);
}
.popover-soft-indigo .popover-body {
  color: color-yiq(#f5e8ff);
}
.popover-soft-indigo .popover-header {
  border-color: rgba(245, 232, 255, 0.2);
}
.popover-soft-indigo.bs-popover-top .arrow::after, .popover-soft-indigo.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #f5e8ff;
}
.popover-soft-indigo.bs-popover-right .arrow::after {
  border-right-color: #f5e8ff;
}
.popover-soft-indigo.bs-popover-bottom .arrow::after, .popover-soft-indigo.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #f5e8ff;
}
.popover-soft-indigo.bs-popover-left .arrow::after {
  border-left-color: #f5e8ff;
}

.popover-soft-green {
  background-color: #2CA58D;
}
.popover-soft-green .popover-header {
  background-color: #2CA58D;
  color: color-yiq(#2CA58D);
}
.popover-soft-green .popover-body {
  color: color-yiq(#2CA58D);
}
.popover-soft-green .popover-header {
  border-color: rgba(44, 165, 141, 0.2);
}
.popover-soft-green.bs-popover-top .arrow::after, .popover-soft-green.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #2CA58D;
}
.popover-soft-green.bs-popover-right .arrow::after {
  border-right-color: #2CA58D;
}
.popover-soft-green.bs-popover-bottom .arrow::after, .popover-soft-green.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #2CA58D;
}
.popover-soft-green.bs-popover-left .arrow::after {
  border-left-color: #2CA58D;
}

.popover-gray-100 {
  background-color: #f3f7fa;
}
.popover-gray-100 .popover-header {
  background-color: #f3f7fa;
  color: color-yiq(#f3f7fa);
}
.popover-gray-100 .popover-body {
  color: color-yiq(#f3f7fa);
}
.popover-gray-100 .popover-header {
  border-color: rgba(243, 247, 250, 0.2);
}
.popover-gray-100.bs-popover-top .arrow::after, .popover-gray-100.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #f3f7fa;
}
.popover-gray-100.bs-popover-right .arrow::after {
  border-right-color: #f3f7fa;
}
.popover-gray-100.bs-popover-bottom .arrow::after, .popover-gray-100.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #f3f7fa;
}
.popover-gray-100.bs-popover-left .arrow::after {
  border-left-color: #f3f7fa;
}

.popover-gray-200 {
  background-color: #f5f8fb;
}
.popover-gray-200 .popover-header {
  background-color: #f5f8fb;
  color: color-yiq(#f5f8fb);
}
.popover-gray-200 .popover-body {
  color: color-yiq(#f5f8fb);
}
.popover-gray-200 .popover-header {
  border-color: rgba(245, 248, 251, 0.2);
}
.popover-gray-200.bs-popover-top .arrow::after, .popover-gray-200.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #f5f8fb;
}
.popover-gray-200.bs-popover-right .arrow::after {
  border-right-color: #f5f8fb;
}
.popover-gray-200.bs-popover-bottom .arrow::after, .popover-gray-200.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #f5f8fb;
}
.popover-gray-200.bs-popover-left .arrow::after {
  border-left-color: #f5f8fb;
}

.popover-gray-300 {
  background-color: #F0F3F6;
}
.popover-gray-300 .popover-header {
  background-color: #F0F3F6;
  color: color-yiq(#F0F3F6);
}
.popover-gray-300 .popover-body {
  color: color-yiq(#F0F3F6);
}
.popover-gray-300 .popover-header {
  border-color: rgba(240, 243, 246, 0.2);
}
.popover-gray-300.bs-popover-top .arrow::after, .popover-gray-300.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #F0F3F6;
}
.popover-gray-300.bs-popover-right .arrow::after {
  border-right-color: #F0F3F6;
}
.popover-gray-300.bs-popover-bottom .arrow::after, .popover-gray-300.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #F0F3F6;
}
.popover-gray-300.bs-popover-left .arrow::after {
  border-left-color: #F0F3F6;
}

.popover-gray-400 {
  background-color: #eaedf2;
}
.popover-gray-400 .popover-header {
  background-color: #eaedf2;
  color: color-yiq(#eaedf2);
}
.popover-gray-400 .popover-body {
  color: color-yiq(#eaedf2);
}
.popover-gray-400 .popover-header {
  border-color: rgba(234, 237, 242, 0.2);
}
.popover-gray-400.bs-popover-top .arrow::after, .popover-gray-400.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #eaedf2;
}
.popover-gray-400.bs-popover-right .arrow::after {
  border-right-color: #eaedf2;
}
.popover-gray-400.bs-popover-bottom .arrow::after, .popover-gray-400.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #eaedf2;
}
.popover-gray-400.bs-popover-left .arrow::after {
  border-left-color: #eaedf2;
}

.popover-gray-500 {
  background-color: #d1d7e0;
}
.popover-gray-500 .popover-header {
  background-color: #d1d7e0;
  color: color-yiq(#d1d7e0);
}
.popover-gray-500 .popover-body {
  color: color-yiq(#d1d7e0);
}
.popover-gray-500 .popover-header {
  border-color: rgba(209, 215, 224, 0.2);
}
.popover-gray-500.bs-popover-top .arrow::after, .popover-gray-500.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #d1d7e0;
}
.popover-gray-500.bs-popover-right .arrow::after {
  border-right-color: #d1d7e0;
}
.popover-gray-500.bs-popover-bottom .arrow::after, .popover-gray-500.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #d1d7e0;
}
.popover-gray-500.bs-popover-left .arrow::after {
  border-left-color: #d1d7e0;
}

.popover-gray-600 {
  background-color: #93a5be;
}
.popover-gray-600 .popover-header {
  background-color: #93a5be;
  color: color-yiq(#93a5be);
}
.popover-gray-600 .popover-body {
  color: color-yiq(#93a5be);
}
.popover-gray-600 .popover-header {
  border-color: rgba(147, 165, 190, 0.2);
}
.popover-gray-600.bs-popover-top .arrow::after, .popover-gray-600.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #93a5be;
}
.popover-gray-600.bs-popover-right .arrow::after {
  border-right-color: #93a5be;
}
.popover-gray-600.bs-popover-bottom .arrow::after, .popover-gray-600.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #93a5be;
}
.popover-gray-600.bs-popover-left .arrow::after {
  border-left-color: #93a5be;
}

.popover-gray-700 {
  background-color: #66799e;
}
.popover-gray-700 .popover-header {
  background-color: #66799e;
  color: color-yiq(#66799e);
}
.popover-gray-700 .popover-body {
  color: color-yiq(#66799e);
}
.popover-gray-700 .popover-header {
  border-color: rgba(102, 121, 158, 0.2);
}
.popover-gray-700.bs-popover-top .arrow::after, .popover-gray-700.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #66799e;
}
.popover-gray-700.bs-popover-right .arrow::after {
  border-right-color: #66799e;
}
.popover-gray-700.bs-popover-bottom .arrow::after, .popover-gray-700.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #66799e;
}
.popover-gray-700.bs-popover-left .arrow::after {
  border-left-color: #66799e;
}

.popover-gray-800 {
  background-color: #506690;
}
.popover-gray-800 .popover-header {
  background-color: #506690;
  color: color-yiq(#506690);
}
.popover-gray-800 .popover-body {
  color: color-yiq(#506690);
}
.popover-gray-800 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-gray-800.bs-popover-top .arrow::after, .popover-gray-800.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #506690;
}
.popover-gray-800.bs-popover-right .arrow::after {
  border-right-color: #506690;
}
.popover-gray-800.bs-popover-bottom .arrow::after, .popover-gray-800.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #506690;
}
.popover-gray-800.bs-popover-left .arrow::after {
  border-left-color: #506690;
}

.popover-facebook {
  background-color: #3b5999;
}
.popover-facebook .popover-header {
  background-color: #3b5999;
  color: color-yiq(#3b5999);
}
.popover-facebook .popover-body {
  color: color-yiq(#3b5999);
}
.popover-facebook .popover-header {
  border-color: rgba(59, 89, 153, 0.2);
}
.popover-facebook.bs-popover-top .arrow::after, .popover-facebook.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #3b5999;
}
.popover-facebook.bs-popover-right .arrow::after {
  border-right-color: #3b5999;
}
.popover-facebook.bs-popover-bottom .arrow::after, .popover-facebook.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #3b5999;
}
.popover-facebook.bs-popover-left .arrow::after {
  border-left-color: #3b5999;
}

.popover-twitter {
  background-color: #1da1f2;
}
.popover-twitter .popover-header {
  background-color: #1da1f2;
  color: color-yiq(#1da1f2);
}
.popover-twitter .popover-body {
  color: color-yiq(#1da1f2);
}
.popover-twitter .popover-header {
  border-color: rgba(29, 161, 242, 0.2);
}
.popover-twitter.bs-popover-top .arrow::after, .popover-twitter.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #1da1f2;
}
.popover-twitter.bs-popover-right .arrow::after {
  border-right-color: #1da1f2;
}
.popover-twitter.bs-popover-bottom .arrow::after, .popover-twitter.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #1da1f2;
}
.popover-twitter.bs-popover-left .arrow::after {
  border-left-color: #1da1f2;
}

.popover-google {
  background-color: #DB4337;
}
.popover-google .popover-header {
  background-color: #DB4337;
  color: color-yiq(#DB4337);
}
.popover-google .popover-body {
  color: color-yiq(#DB4337);
}
.popover-google .popover-header {
  border-color: rgba(219, 67, 55, 0.2);
}
.popover-google.bs-popover-top .arrow::after, .popover-google.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #DB4337;
}
.popover-google.bs-popover-right .arrow::after {
  border-right-color: #DB4337;
}
.popover-google.bs-popover-bottom .arrow::after, .popover-google.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #DB4337;
}
.popover-google.bs-popover-left .arrow::after {
  border-left-color: #DB4337;
}

.popover-instagram {
  background-color: #e4405f;
}
.popover-instagram .popover-header {
  background-color: #e4405f;
  color: color-yiq(#e4405f);
}
.popover-instagram .popover-body {
  color: color-yiq(#e4405f);
}
.popover-instagram .popover-header {
  border-color: rgba(228, 64, 95, 0.2);
}
.popover-instagram.bs-popover-top .arrow::after, .popover-instagram.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #e4405f;
}
.popover-instagram.bs-popover-right .arrow::after {
  border-right-color: #e4405f;
}
.popover-instagram.bs-popover-bottom .arrow::after, .popover-instagram.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #e4405f;
}
.popover-instagram.bs-popover-left .arrow::after {
  border-left-color: #e4405f;
}

.popover-pinterest {
  background-color: #bd081c;
}
.popover-pinterest .popover-header {
  background-color: #bd081c;
  color: color-yiq(#bd081c);
}
.popover-pinterest .popover-body {
  color: color-yiq(#bd081c);
}
.popover-pinterest .popover-header {
  border-color: rgba(189, 8, 28, 0.2);
}
.popover-pinterest.bs-popover-top .arrow::after, .popover-pinterest.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #bd081c;
}
.popover-pinterest.bs-popover-right .arrow::after {
  border-right-color: #bd081c;
}
.popover-pinterest.bs-popover-bottom .arrow::after, .popover-pinterest.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #bd081c;
}
.popover-pinterest.bs-popover-left .arrow::after {
  border-left-color: #bd081c;
}

.popover-youtube {
  background-color: #cd201f;
}
.popover-youtube .popover-header {
  background-color: #cd201f;
  color: color-yiq(#cd201f);
}
.popover-youtube .popover-body {
  color: color-yiq(#cd201f);
}
.popover-youtube .popover-header {
  border-color: rgba(205, 32, 31, 0.2);
}
.popover-youtube.bs-popover-top .arrow::after, .popover-youtube.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #cd201f;
}
.popover-youtube.bs-popover-right .arrow::after {
  border-right-color: #cd201f;
}
.popover-youtube.bs-popover-bottom .arrow::after, .popover-youtube.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #cd201f;
}
.popover-youtube.bs-popover-left .arrow::after {
  border-left-color: #cd201f;
}

.popover-slack {
  background-color: #3aaf85;
}
.popover-slack .popover-header {
  background-color: #3aaf85;
  color: color-yiq(#3aaf85);
}
.popover-slack .popover-body {
  color: color-yiq(#3aaf85);
}
.popover-slack .popover-header {
  border-color: rgba(58, 175, 133, 0.2);
}
.popover-slack.bs-popover-top .arrow::after, .popover-slack.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #3aaf85;
}
.popover-slack.bs-popover-right .arrow::after {
  border-right-color: #3aaf85;
}
.popover-slack.bs-popover-bottom .arrow::after, .popover-slack.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #3aaf85;
}
.popover-slack.bs-popover-left .arrow::after {
  border-left-color: #3aaf85;
}

.popover-dribbble {
  background-color: #ea4c89;
}
.popover-dribbble .popover-header {
  background-color: #ea4c89;
  color: color-yiq(#ea4c89);
}
.popover-dribbble .popover-body {
  color: color-yiq(#ea4c89);
}
.popover-dribbble .popover-header {
  border-color: rgba(234, 76, 137, 0.2);
}
.popover-dribbble.bs-popover-top .arrow::after, .popover-dribbble.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #ea4c89;
}
.popover-dribbble.bs-popover-right .arrow::after {
  border-right-color: #ea4c89;
}
.popover-dribbble.bs-popover-bottom .arrow::after, .popover-dribbble.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #ea4c89;
}
.popover-dribbble.bs-popover-left .arrow::after {
  border-left-color: #ea4c89;
}

.popover-dropbox {
  background-color: #1E90FF;
}
.popover-dropbox .popover-header {
  background-color: #1E90FF;
  color: color-yiq(#1E90FF);
}
.popover-dropbox .popover-body {
  color: color-yiq(#1E90FF);
}
.popover-dropbox .popover-header {
  border-color: rgba(30, 144, 255, 0.2);
}
.popover-dropbox.bs-popover-top .arrow::after, .popover-dropbox.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #1E90FF;
}
.popover-dropbox.bs-popover-right .arrow::after {
  border-right-color: #1E90FF;
}
.popover-dropbox.bs-popover-bottom .arrow::after, .popover-dropbox.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #1E90FF;
}
.popover-dropbox.bs-popover-left .arrow::after {
  border-left-color: #1E90FF;
}

.popover-twitch {
  background-color: #4B367C;
}
.popover-twitch .popover-header {
  background-color: #4B367C;
  color: color-yiq(#4B367C);
}
.popover-twitch .popover-body {
  color: color-yiq(#4B367C);
}
.popover-twitch .popover-header {
  border-color: rgba(75, 54, 124, 0.2);
}
.popover-twitch.bs-popover-top .arrow::after, .popover-twitch.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #4B367C;
}
.popover-twitch.bs-popover-right .arrow::after {
  border-right-color: #4B367C;
}
.popover-twitch.bs-popover-bottom .arrow::after, .popover-twitch.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #4B367C;
}
.popover-twitch.bs-popover-left .arrow::after {
  border-left-color: #4B367C;
}

.popover-paypal {
  background-color: #ecb32c;
}
.popover-paypal .popover-header {
  background-color: #ecb32c;
  color: color-yiq(#ecb32c);
}
.popover-paypal .popover-body {
  color: color-yiq(#ecb32c);
}
.popover-paypal .popover-header {
  border-color: rgba(236, 179, 44, 0.2);
}
.popover-paypal.bs-popover-top .arrow::after, .popover-paypal.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #ecb32c;
}
.popover-paypal.bs-popover-right .arrow::after {
  border-right-color: #ecb32c;
}
.popover-paypal.bs-popover-bottom .arrow::after, .popover-paypal.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #ecb32c;
}
.popover-paypal.bs-popover-left .arrow::after {
  border-left-color: #ecb32c;
}

.popover-behance {
  background-color: #0057ff;
}
.popover-behance .popover-header {
  background-color: #0057ff;
  color: color-yiq(#0057ff);
}
.popover-behance .popover-body {
  color: color-yiq(#0057ff);
}
.popover-behance .popover-header {
  border-color: rgba(0, 87, 255, 0.2);
}
.popover-behance.bs-popover-top .arrow::after, .popover-behance.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #0057ff;
}
.popover-behance.bs-popover-right .arrow::after {
  border-right-color: #0057ff;
}
.popover-behance.bs-popover-bottom .arrow::after, .popover-behance.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #0057ff;
}
.popover-behance.bs-popover-left .arrow::after {
  border-left-color: #0057ff;
}

.popover-reddit {
  background-color: #E84422;
}
.popover-reddit .popover-header {
  background-color: #E84422;
  color: color-yiq(#E84422);
}
.popover-reddit .popover-body {
  color: color-yiq(#E84422);
}
.popover-reddit .popover-header {
  border-color: rgba(232, 68, 34, 0.2);
}
.popover-reddit.bs-popover-top .arrow::after, .popover-reddit.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #E84422;
}
.popover-reddit.bs-popover-right .arrow::after {
  border-right-color: #E84422;
}
.popover-reddit.bs-popover-bottom .arrow::after, .popover-reddit.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #E84422;
}
.popover-reddit.bs-popover-left .arrow::after {
  border-left-color: #E84422;
}

.popover-github {
  background-color: #222222;
}
.popover-github .popover-header {
  background-color: #222222;
  color: color-yiq(#222222);
}
.popover-github .popover-body {
  color: color-yiq(#222222);
}
.popover-github .popover-header {
  border-color: rgba(34, 34, 34, 0.2);
}
.popover-github.bs-popover-top .arrow::after, .popover-github.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #222222;
}
.popover-github.bs-popover-right .arrow::after {
  border-right-color: #222222;
}
.popover-github.bs-popover-bottom .arrow::after, .popover-github.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #222222;
}
.popover-github.bs-popover-left .arrow::after {
  border-left-color: #222222;
}

.popover-adobe {
  background-color: #ff9a00;
}
.popover-adobe .popover-header {
  background-color: #ff9a00;
  color: color-yiq(#ff9a00);
}
.popover-adobe .popover-body {
  color: color-yiq(#ff9a00);
}
.popover-adobe .popover-header {
  border-color: rgba(255, 154, 0, 0.2);
}
.popover-adobe.bs-popover-top .arrow::after, .popover-adobe.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #ff9a00;
}
.popover-adobe.bs-popover-right .arrow::after {
  border-right-color: #ff9a00;
}
.popover-adobe.bs-popover-bottom .arrow::after, .popover-adobe.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #ff9a00;
}
.popover-adobe.bs-popover-left .arrow::after {
  border-left-color: #ff9a00;
}

.popover-bootstrap {
  background-color: #7C277D;
}
.popover-bootstrap .popover-header {
  background-color: #7C277D;
  color: color-yiq(#7C277D);
}
.popover-bootstrap .popover-body {
  color: color-yiq(#7C277D);
}
.popover-bootstrap .popover-header {
  border-color: rgba(124, 39, 125, 0.2);
}
.popover-bootstrap.bs-popover-top .arrow::after, .popover-bootstrap.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #7C277D;
}
.popover-bootstrap.bs-popover-right .arrow::after {
  border-right-color: #7C277D;
}
.popover-bootstrap.bs-popover-bottom .arrow::after, .popover-bootstrap.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #7C277D;
}
.popover-bootstrap.bs-popover-left .arrow::after {
  border-left-color: #7C277D;
}

.popover-python {
  background-color: #306998;
}
.popover-python .popover-header {
  background-color: #306998;
  color: color-yiq(#306998);
}
.popover-python .popover-body {
  color: color-yiq(#306998);
}
.popover-python .popover-header {
  border-color: rgba(48, 105, 152, 0.2);
}
.popover-python.bs-popover-top .arrow::after, .popover-python.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #306998;
}
.popover-python.bs-popover-right .arrow::after {
  border-right-color: #306998;
}
.popover-python.bs-popover-bottom .arrow::after, .popover-python.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #306998;
}
.popover-python.bs-popover-left .arrow::after {
  border-left-color: #306998;
}

.popover-react {
  background-color: #00d8ff;
}
.popover-react .popover-header {
  background-color: #00d8ff;
  color: color-yiq(#00d8ff);
}
.popover-react .popover-body {
  color: color-yiq(#00d8ff);
}
.popover-react .popover-header {
  border-color: rgba(0, 216, 255, 0.2);
}
.popover-react.bs-popover-top .arrow::after, .popover-react.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #00d8ff;
}
.popover-react.bs-popover-right .arrow::after {
  border-right-color: #00d8ff;
}
.popover-react.bs-popover-bottom .arrow::after, .popover-react.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #00d8ff;
}
.popover-react.bs-popover-left .arrow::after {
  border-left-color: #00d8ff;
}

.popover-azure {
  background-color: #2892df;
}
.popover-azure .popover-header {
  background-color: #2892df;
  color: color-yiq(#2892df);
}
.popover-azure .popover-body {
  color: color-yiq(#2892df);
}
.popover-azure .popover-header {
  border-color: rgba(40, 146, 223, 0.2);
}
.popover-azure.bs-popover-top .arrow::after, .popover-azure.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #2892df;
}
.popover-azure.bs-popover-right .arrow::after {
  border-right-color: #2892df;
}
.popover-azure.bs-popover-bottom .arrow::after, .popover-azure.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #2892df;
}
.popover-azure.bs-popover-left .arrow::after {
  border-left-color: #2892df;
}

.popover-js {
  background-color: #EFD81F;
}
.popover-js .popover-header {
  background-color: #EFD81F;
  color: color-yiq(#EFD81F);
}
.popover-js .popover-body {
  color: color-yiq(#EFD81F);
}
.popover-js .popover-header {
  border-color: rgba(239, 216, 31, 0.2);
}
.popover-js.bs-popover-top .arrow::after, .popover-js.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #EFD81F;
}
.popover-js.bs-popover-right .arrow::after {
  border-right-color: #EFD81F;
}
.popover-js.bs-popover-bottom .arrow::after, .popover-js.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #EFD81F;
}
.popover-js.bs-popover-left .arrow::after {
  border-left-color: #EFD81F;
}

.popover-swe {
  background-color: #FFCF3B;
}
.popover-swe .popover-header {
  background-color: #FFCF3B;
  color: color-yiq(#FFCF3B);
}
.popover-swe .popover-body {
  color: color-yiq(#FFCF3B);
}
.popover-swe .popover-header {
  border-color: rgba(255, 207, 59, 0.2);
}
.popover-swe.bs-popover-top .arrow::after, .popover-swe.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FFCF3B;
}
.popover-swe.bs-popover-right .arrow::after {
  border-right-color: #FFCF3B;
}
.popover-swe.bs-popover-bottom .arrow::after, .popover-swe.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FFCF3B;
}
.popover-swe.bs-popover-left .arrow::after {
  border-left-color: #FFCF3B;
}

/**
 * = Progress bars
 */
.progress-wrapper {
  position: relative;
}

.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}

.progress {
  height: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.1rem;
  background-color: #f5f8fb;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-xl {
  height: 0.8rem;
}

.progress-lg {
  height: 0.6rem;
}

.progress-sm {
  height: 0.2rem;
}

.progress-info {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 0.5rem;
}
.progress-info .progress-label span {
  display: inline-block;
  color: #262B40;
  font-size: 0.875rem;
  font-weight: 600;
}
.progress-info .progress-percentage {
  text-align: right;
}
.progress-info .progress-percentage span {
  display: inline-block;
  color: #66799e;
  font-size: 0.75rem;
  font-weight: 600;
}

.info-xl .progress-label span,
.info-xl .progress-percentage span {
  font-size: 0.875rem;
}
.info-xl .progress-percentage {
  text-align: right;
}

.progress-tooltip {
  background: #262B40;
  color: #ffffff;
  font-weight: 600;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: 0.75rem;
  position: relative;
  border-radius: 0.1rem;
}
.progress-tooltip:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 10px;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-top-color: #4A5073;
  border-width: 6px;
}
@keyframes animate-positive {
  0% {
    width: 0%;
  }
}
.progress-info .bg-primary::after {
  border-top-color: #262B40;
}

.progress-info .bg-secondary::after {
  border-top-color: #61DAFB;
}

.progress-info .bg-tertiary::after {
  border-top-color: #1B998B;
}

.progress-info .bg-quaternary::after {
  border-top-color: #C96480;
}

.progress-info .bg-primary-app::after {
  border-top-color: #EBF4F6;
}

.progress-info .bg-secondary-app::after {
  border-top-color: #424AA0;
}

.progress-info .bg-light::after {
  border-top-color: #eaedf2;
}

.progress-info .bg-lighten::after {
  border-top-color: #F0F3F6;
}

.progress-info .bg-success::after {
  border-top-color: #05A677;
}

.progress-info .bg-info::after {
  border-top-color: #0948B3;
}

.progress-info .bg-warning::after {
  border-top-color: #f5b759;
}

.progress-info .bg-danger::after {
  border-top-color: #FA5252;
}

.progress-info .bg-white::after {
  border-top-color: #ffffff;
}

.progress-info .bg-gray::after {
  border-top-color: #4A5073;
}

.progress-info .bg-indigo::after {
  border-top-color: #4c5680;
}

.progress-info .bg-dark::after {
  border-top-color: #262B40;
}

.progress-info .bg-soft::after {
  border-top-color: #f5f8fb;
}

.progress-info .bg-black::after {
  border-top-color: #2e3650;
}

.progress-info .bg-blue::after {
  border-top-color: #0948B3;
}

.progress-info .bg-pink::after {
  border-top-color: #C96480;
}

.progress-info .bg-cyan::after {
  border-top-color: #63b1bd;
}

.progress-info .bg-purple::after {
  border-top-color: #8965e0;
}

.progress-info .bg-soft-indigo::after {
  border-top-color: #f5e8ff;
}

.progress-info .bg-soft-green::after {
  border-top-color: #2CA58D;
}

.progress-info .bg-gray-100::after {
  border-top-color: #f3f7fa;
}

.progress-info .bg-gray-200::after {
  border-top-color: #f5f8fb;
}

.progress-info .bg-gray-300::after {
  border-top-color: #F0F3F6;
}

.progress-info .bg-gray-400::after {
  border-top-color: #eaedf2;
}

.progress-info .bg-gray-500::after {
  border-top-color: #d1d7e0;
}

.progress-info .bg-gray-600::after {
  border-top-color: #93a5be;
}

.progress-info .bg-gray-700::after {
  border-top-color: #66799e;
}

.progress-info .bg-gray-800::after {
  border-top-color: #506690;
}

.progress-info .bg-facebook::after {
  border-top-color: #3b5999;
}

.progress-info .bg-twitter::after {
  border-top-color: #1da1f2;
}

.progress-info .bg-google::after {
  border-top-color: #DB4337;
}

.progress-info .bg-instagram::after {
  border-top-color: #e4405f;
}

.progress-info .bg-pinterest::after {
  border-top-color: #bd081c;
}

.progress-info .bg-youtube::after {
  border-top-color: #cd201f;
}

.progress-info .bg-slack::after {
  border-top-color: #3aaf85;
}

.progress-info .bg-dribbble::after {
  border-top-color: #ea4c89;
}

.progress-info .bg-dropbox::after {
  border-top-color: #1E90FF;
}

.progress-info .bg-twitch::after {
  border-top-color: #4B367C;
}

.progress-info .bg-paypal::after {
  border-top-color: #ecb32c;
}

.progress-info .bg-behance::after {
  border-top-color: #0057ff;
}

.progress-info .bg-reddit::after {
  border-top-color: #E84422;
}

.progress-info .bg-github::after {
  border-top-color: #222222;
}

.progress-info .bg-adobe::after {
  border-top-color: #ff9a00;
}

.progress-info .bg-bootstrap::after {
  border-top-color: #7C277D;
}

.progress-info .bg-python::after {
  border-top-color: #306998;
}

.progress-info .bg-react::after {
  border-top-color: #00d8ff;
}

.progress-info .bg-azure::after {
  border-top-color: #2892df;
}

.progress-info .bg-js::after {
  border-top-color: #EFD81F;
}

.progress-info .bg-swe::after {
  border-top-color: #FFCF3B;
}

/**
 * = Shapes
 */
.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-md {
  width: 2rem;
  height: 2rem;
}

.avatar-lg {
  width: 3rem;
  height: 3rem;
}

.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  border-top: 0;
}
.table.comparison-table thead th {
  font-size: 1.25rem;
  color: #262B40;
}
.table.comparison-table td {
  font-size: 1rem;
}
.table th {
  font-weight: 600;
  padding: 1rem;
}
.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}
.table td,
.table th {
  font-size: 0.875rem;
  white-space: nowrap;
}
.table.user-table thead th {
  border-top: 0;
}
.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}
.table .thead-dark th {
  background-color: #262B40;
  color: #ffffff;
}
.table .thead-dark th a {
  color: #262B40;
}
.table .thead-light th {
  background-color: #f5f8fb;
  color: #262B40;
}
.table .thead-light th a {
  color: #262B40;
}

.table-hover tr {
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .table-hover tr {
    transition: none;
  }
}

.table-flush td,
.table-flush th {
  border-left: 0;
  border-right: 0;
}
.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th {
  border-top: 0;
}
.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th {
  border-bottom: 0;
}

.card .table {
  margin-bottom: 0;
}
.card .table td,
.card .table th {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.table [data-sort] {
  cursor: pointer;
}
.table .custom-toggle {
  display: block;
}
.table .thead-dark [data-sort]::after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
  margin-left: 0.25rem;
}
.table .thead-light [data-sort]::after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
  margin-left: 0.25rem;
}

.dataTables_wrapper .dataTables_info {
  padding-left: 1.5rem;
}
.dataTables_wrapper .dataTables_paginate {
  padding-right: 1.5rem;
}
.dataTables_wrapper .dataTables_length label {
  padding-left: 1.5rem;
}
.dataTables_wrapper .dataTables_filter {
  padding-right: 1.5rem;
}

.dataTable-top,
.dataTable-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .dataTable-top,
  .dataTable-bottom {
    flex-direction: column;
  }
  .dataTable-top .dataTable-info,
  .dataTable-bottom .dataTable-info {
    margin-bottom: 1rem;
  }
}

.dataTable-top {
  padding: 0 1.5rem 1rem 1.5rem;
}

.dataTable-bottom {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.dataTable-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.dataTable-pagination a {
  padding: 0.375rem 0.75rem;
}
.dataTable-pagination li:first-child a {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.dataTable-pagination li:last-child a {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.dataTable-pagination > li:not(:first-child) a {
  margin-left: -0.0625rem;
}
.dataTable-pagination > li.active a {
  z-index: 3;
  color: #ffffff;
  background-color: #262B40;
  border-color: #262B40;
}
.dataTable-pagination > li.disabled a {
  color: #93a5be;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #F0F3F6;
}
.dataTable-pagination > li a {
  position: relative;
  display: block;
  color: #262B40;
  background-color: #ffffff;
  border: 0.0625rem solid #eaedf2;
}
.dataTable-pagination > li a:hover {
  z-index: 2;
  color: #0a0b10;
  background-color: #f5f8fb;
  border-color: #F0F3F6;
}
.dataTable-pagination > li a:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.dataTable-selector {
  display: inline-block;
  width: auto;
  height: calc(1.5em + 1.225rem);
  padding: 0.55rem 1.75rem 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.dataTable-selector:focus {
  border-color: #566190;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.dataTable-selector:focus::-ms-value {
  color: #66799e;
  background-color: #ffffff;
}
.dataTable-selector[multiple], .dataTable-selector[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.dataTable-selector:disabled {
  color: #93a5be;
  background-color: #f5f8fb;
}
.dataTable-selector:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #66799e;
}

.dataTable-input {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 1.225rem);
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dataTable-input {
    transition: none;
  }
}
.dataTable-input:focus {
  color: #66799e;
  background-color: #ffffff;
  border-color: #566190;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}
.dataTable-input::-moz-placeholder {
  color: #506690;
  opacity: 1;
}
.dataTable-input::placeholder {
  color: #506690;
  opacity: 1;
}
.dataTable-input:disabled, .dataTable-input[readonly] {
  background-color: #f5f8fb;
  opacity: 1;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.search-label {
  width: 100%;
}

/**
 * = Typography
 */
h1,
.h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

p, ol li, ul li, .p {
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

a, .text-action {
  color: #262B40;
  transition: all 0.2s ease;
}
a:hover, .text-action:hover {
  color: #0a0b10;
  cursor: pointer;
}

.lead + .btn-wrapper {
  margin-top: 3rem;
}
@media (max-width: 767.98px) {
  .lead {
    font-size: 1rem;
  }
}

.line-through {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

.text-italic {
  font-style: italic !important;
}

.description {
  font-size: 0.875rem;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .display-2 {
    font-size: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .display-3 {
    font-size: 1.875rem;
  }
}
@media (max-width: 575.98px) {
  .display-4 {
    font-size: 1.5rem;
  }
}
.blockquote {
  font-style: italic;
}
@media (max-width: 767.98px) {
  .blockquote.blockquote-large {
    font-size: 1.25rem;
  }
}
.blockquote .blockquote-footer {
  font-size: 0.875rem;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .blockquote .blockquote-footer {
    font-size: 1.25rem;
  }
}

.font-small {
  font-size: 0.875rem;
  font-weight: 300;
}

.font-base {
  font-size: 1rem;
  font-weight: 300;
}

.font-medium {
  font-size: 1.25rem;
  font-weight: 300;
}

code[class*=language-], pre[class*=language-] {
  text-shadow: none;
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
}

.text-primary, .text-primary:hover {
  color: #262B40 !important;
}
.text-primary a:not(.btn), .text-primary h1, .text-primary h2, .text-primary h3, .text-primary h4, .text-primary h5, .text-primary h6, .text-primary .h1, .text-primary .h2, .text-primary .h3, .text-primary .h4, .text-primary .h5, .text-primary .h6, .text-primary .display-1, .text-primary .display-2, .text-primary .display-3, .text-primary .display-4 {
  color: #262B40;
}
.text-primary .text-muted {
  color: rgba(38, 43, 64, 0.7) !important;
}

.text-secondary, .text-secondary:hover {
  color: #61DAFB !important;
}
.text-secondary a:not(.btn), .text-secondary h1, .text-secondary h2, .text-secondary h3, .text-secondary h4, .text-secondary h5, .text-secondary h6, .text-secondary .h1, .text-secondary .h2, .text-secondary .h3, .text-secondary .h4, .text-secondary .h5, .text-secondary .h6, .text-secondary .display-1, .text-secondary .display-2, .text-secondary .display-3, .text-secondary .display-4 {
  color: #61DAFB;
}
.text-secondary .text-muted {
  color: rgba(97, 218, 251, 0.7) !important;
}

.text-tertiary, .text-tertiary:hover {
  color: #1B998B !important;
}
.text-tertiary a:not(.btn), .text-tertiary h1, .text-tertiary h2, .text-tertiary h3, .text-tertiary h4, .text-tertiary h5, .text-tertiary h6, .text-tertiary .h1, .text-tertiary .h2, .text-tertiary .h3, .text-tertiary .h4, .text-tertiary .h5, .text-tertiary .h6, .text-tertiary .display-1, .text-tertiary .display-2, .text-tertiary .display-3, .text-tertiary .display-4 {
  color: #1B998B;
}
.text-tertiary .text-muted {
  color: rgba(27, 153, 139, 0.7) !important;
}

.text-quaternary, .text-quaternary:hover {
  color: #C96480 !important;
}
.text-quaternary a:not(.btn), .text-quaternary h1, .text-quaternary h2, .text-quaternary h3, .text-quaternary h4, .text-quaternary h5, .text-quaternary h6, .text-quaternary .h1, .text-quaternary .h2, .text-quaternary .h3, .text-quaternary .h4, .text-quaternary .h5, .text-quaternary .h6, .text-quaternary .display-1, .text-quaternary .display-2, .text-quaternary .display-3, .text-quaternary .display-4 {
  color: #C96480;
}
.text-quaternary .text-muted {
  color: rgba(201, 100, 128, 0.7) !important;
}

.text-primary-app, .text-primary-app:hover {
  color: #EBF4F6 !important;
}
.text-primary-app a:not(.btn), .text-primary-app h1, .text-primary-app h2, .text-primary-app h3, .text-primary-app h4, .text-primary-app h5, .text-primary-app h6, .text-primary-app .h1, .text-primary-app .h2, .text-primary-app .h3, .text-primary-app .h4, .text-primary-app .h5, .text-primary-app .h6, .text-primary-app .display-1, .text-primary-app .display-2, .text-primary-app .display-3, .text-primary-app .display-4 {
  color: #EBF4F6;
}
.text-primary-app .text-muted {
  color: rgba(235, 244, 246, 0.7) !important;
}

.text-secondary-app, .text-secondary-app:hover {
  color: #424AA0 !important;
}
.text-secondary-app a:not(.btn), .text-secondary-app h1, .text-secondary-app h2, .text-secondary-app h3, .text-secondary-app h4, .text-secondary-app h5, .text-secondary-app h6, .text-secondary-app .h1, .text-secondary-app .h2, .text-secondary-app .h3, .text-secondary-app .h4, .text-secondary-app .h5, .text-secondary-app .h6, .text-secondary-app .display-1, .text-secondary-app .display-2, .text-secondary-app .display-3, .text-secondary-app .display-4 {
  color: #424AA0;
}
.text-secondary-app .text-muted {
  color: rgba(66, 74, 160, 0.7) !important;
}

.text-light, .text-light:hover {
  color: #eaedf2 !important;
}
.text-light a:not(.btn), .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6, .text-light .display-1, .text-light .display-2, .text-light .display-3, .text-light .display-4 {
  color: #eaedf2;
}
.text-light .text-muted {
  color: rgba(234, 237, 242, 0.7) !important;
}

.text-lighten, .text-lighten:hover {
  color: #F0F3F6 !important;
}
.text-lighten a:not(.btn), .text-lighten h1, .text-lighten h2, .text-lighten h3, .text-lighten h4, .text-lighten h5, .text-lighten h6, .text-lighten .h1, .text-lighten .h2, .text-lighten .h3, .text-lighten .h4, .text-lighten .h5, .text-lighten .h6, .text-lighten .display-1, .text-lighten .display-2, .text-lighten .display-3, .text-lighten .display-4 {
  color: #F0F3F6;
}
.text-lighten .text-muted {
  color: rgba(240, 243, 246, 0.7) !important;
}

.text-success, .text-success:hover {
  color: #05A677 !important;
}
.text-success a:not(.btn), .text-success h1, .text-success h2, .text-success h3, .text-success h4, .text-success h5, .text-success h6, .text-success .h1, .text-success .h2, .text-success .h3, .text-success .h4, .text-success .h5, .text-success .h6, .text-success .display-1, .text-success .display-2, .text-success .display-3, .text-success .display-4 {
  color: #05A677;
}
.text-success .text-muted {
  color: rgba(5, 166, 119, 0.7) !important;
}

.text-info, .text-info:hover {
  color: #0948B3 !important;
}
.text-info a:not(.btn), .text-info h1, .text-info h2, .text-info h3, .text-info h4, .text-info h5, .text-info h6, .text-info .h1, .text-info .h2, .text-info .h3, .text-info .h4, .text-info .h5, .text-info .h6, .text-info .display-1, .text-info .display-2, .text-info .display-3, .text-info .display-4 {
  color: #0948B3;
}
.text-info .text-muted {
  color: rgba(9, 72, 179, 0.7) !important;
}

.text-warning, .text-warning:hover {
  color: #f5b759 !important;
}
.text-warning a:not(.btn), .text-warning h1, .text-warning h2, .text-warning h3, .text-warning h4, .text-warning h5, .text-warning h6, .text-warning .h1, .text-warning .h2, .text-warning .h3, .text-warning .h4, .text-warning .h5, .text-warning .h6, .text-warning .display-1, .text-warning .display-2, .text-warning .display-3, .text-warning .display-4 {
  color: #f5b759;
}
.text-warning .text-muted {
  color: rgba(245, 183, 89, 0.7) !important;
}

.text-danger, .text-danger:hover {
  color: #FA5252 !important;
}
.text-danger a:not(.btn), .text-danger h1, .text-danger h2, .text-danger h3, .text-danger h4, .text-danger h5, .text-danger h6, .text-danger .h1, .text-danger .h2, .text-danger .h3, .text-danger .h4, .text-danger .h5, .text-danger .h6, .text-danger .display-1, .text-danger .display-2, .text-danger .display-3, .text-danger .display-4 {
  color: #FA5252;
}
.text-danger .text-muted {
  color: rgba(250, 82, 82, 0.7) !important;
}

.text-white, .text-white:hover {
  color: #ffffff !important;
}
.text-white a:not(.btn), .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6, .text-white .display-1, .text-white .display-2, .text-white .display-3, .text-white .display-4 {
  color: #ffffff;
}
.text-white .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-gray, .text-gray:hover {
  color: #4A5073 !important;
}
.text-gray a:not(.btn), .text-gray h1, .text-gray h2, .text-gray h3, .text-gray h4, .text-gray h5, .text-gray h6, .text-gray .h1, .text-gray .h2, .text-gray .h3, .text-gray .h4, .text-gray .h5, .text-gray .h6, .text-gray .display-1, .text-gray .display-2, .text-gray .display-3, .text-gray .display-4 {
  color: #4A5073;
}
.text-gray .text-muted {
  color: rgba(74, 80, 115, 0.7) !important;
}

.text-indigo, .text-indigo:hover {
  color: #4c5680 !important;
}
.text-indigo a:not(.btn), .text-indigo h1, .text-indigo h2, .text-indigo h3, .text-indigo h4, .text-indigo h5, .text-indigo h6, .text-indigo .h1, .text-indigo .h2, .text-indigo .h3, .text-indigo .h4, .text-indigo .h5, .text-indigo .h6, .text-indigo .display-1, .text-indigo .display-2, .text-indigo .display-3, .text-indigo .display-4 {
  color: #4c5680;
}
.text-indigo .text-muted {
  color: rgba(76, 86, 128, 0.7) !important;
}

.text-dark, .text-dark:hover {
  color: #262B40 !important;
}
.text-dark a:not(.btn), .text-dark h1, .text-dark h2, .text-dark h3, .text-dark h4, .text-dark h5, .text-dark h6, .text-dark .h1, .text-dark .h2, .text-dark .h3, .text-dark .h4, .text-dark .h5, .text-dark .h6, .text-dark .display-1, .text-dark .display-2, .text-dark .display-3, .text-dark .display-4 {
  color: #262B40;
}
.text-dark .text-muted {
  color: rgba(38, 43, 64, 0.7) !important;
}

.text-soft, .text-soft:hover {
  color: #f5f8fb !important;
}
.text-soft a:not(.btn), .text-soft h1, .text-soft h2, .text-soft h3, .text-soft h4, .text-soft h5, .text-soft h6, .text-soft .h1, .text-soft .h2, .text-soft .h3, .text-soft .h4, .text-soft .h5, .text-soft .h6, .text-soft .display-1, .text-soft .display-2, .text-soft .display-3, .text-soft .display-4 {
  color: #f5f8fb;
}
.text-soft .text-muted {
  color: rgba(245, 248, 251, 0.7) !important;
}

.text-black, .text-black:hover {
  color: #2e3650 !important;
}
.text-black a:not(.btn), .text-black h1, .text-black h2, .text-black h3, .text-black h4, .text-black h5, .text-black h6, .text-black .h1, .text-black .h2, .text-black .h3, .text-black .h4, .text-black .h5, .text-black .h6, .text-black .display-1, .text-black .display-2, .text-black .display-3, .text-black .display-4 {
  color: #2e3650;
}
.text-black .text-muted {
  color: rgba(46, 54, 80, 0.7) !important;
}

.text-blue, .text-blue:hover {
  color: #0948B3 !important;
}
.text-blue a:not(.btn), .text-blue h1, .text-blue h2, .text-blue h3, .text-blue h4, .text-blue h5, .text-blue h6, .text-blue .h1, .text-blue .h2, .text-blue .h3, .text-blue .h4, .text-blue .h5, .text-blue .h6, .text-blue .display-1, .text-blue .display-2, .text-blue .display-3, .text-blue .display-4 {
  color: #0948B3;
}
.text-blue .text-muted {
  color: rgba(9, 72, 179, 0.7) !important;
}

.text-pink, .text-pink:hover {
  color: #C96480 !important;
}
.text-pink a:not(.btn), .text-pink h1, .text-pink h2, .text-pink h3, .text-pink h4, .text-pink h5, .text-pink h6, .text-pink .h1, .text-pink .h2, .text-pink .h3, .text-pink .h4, .text-pink .h5, .text-pink .h6, .text-pink .display-1, .text-pink .display-2, .text-pink .display-3, .text-pink .display-4 {
  color: #C96480;
}
.text-pink .text-muted {
  color: rgba(201, 100, 128, 0.7) !important;
}

.text-cyan, .text-cyan:hover {
  color: #63b1bd !important;
}
.text-cyan a:not(.btn), .text-cyan h1, .text-cyan h2, .text-cyan h3, .text-cyan h4, .text-cyan h5, .text-cyan h6, .text-cyan .h1, .text-cyan .h2, .text-cyan .h3, .text-cyan .h4, .text-cyan .h5, .text-cyan .h6, .text-cyan .display-1, .text-cyan .display-2, .text-cyan .display-3, .text-cyan .display-4 {
  color: #63b1bd;
}
.text-cyan .text-muted {
  color: rgba(99, 177, 189, 0.7) !important;
}

.text-purple, .text-purple:hover {
  color: #8965e0 !important;
}
.text-purple a:not(.btn), .text-purple h1, .text-purple h2, .text-purple h3, .text-purple h4, .text-purple h5, .text-purple h6, .text-purple .h1, .text-purple .h2, .text-purple .h3, .text-purple .h4, .text-purple .h5, .text-purple .h6, .text-purple .display-1, .text-purple .display-2, .text-purple .display-3, .text-purple .display-4 {
  color: #8965e0;
}
.text-purple .text-muted {
  color: rgba(137, 101, 224, 0.7) !important;
}

.text-soft-indigo, .text-soft-indigo:hover {
  color: #f5e8ff !important;
}
.text-soft-indigo a:not(.btn), .text-soft-indigo h1, .text-soft-indigo h2, .text-soft-indigo h3, .text-soft-indigo h4, .text-soft-indigo h5, .text-soft-indigo h6, .text-soft-indigo .h1, .text-soft-indigo .h2, .text-soft-indigo .h3, .text-soft-indigo .h4, .text-soft-indigo .h5, .text-soft-indigo .h6, .text-soft-indigo .display-1, .text-soft-indigo .display-2, .text-soft-indigo .display-3, .text-soft-indigo .display-4 {
  color: #f5e8ff;
}
.text-soft-indigo .text-muted {
  color: rgba(245, 232, 255, 0.7) !important;
}

.text-soft-green, .text-soft-green:hover {
  color: #2CA58D !important;
}
.text-soft-green a:not(.btn), .text-soft-green h1, .text-soft-green h2, .text-soft-green h3, .text-soft-green h4, .text-soft-green h5, .text-soft-green h6, .text-soft-green .h1, .text-soft-green .h2, .text-soft-green .h3, .text-soft-green .h4, .text-soft-green .h5, .text-soft-green .h6, .text-soft-green .display-1, .text-soft-green .display-2, .text-soft-green .display-3, .text-soft-green .display-4 {
  color: #2CA58D;
}
.text-soft-green .text-muted {
  color: rgba(44, 165, 141, 0.7) !important;
}

.text-gray-100, .text-gray-100:hover {
  color: #f3f7fa !important;
}
.text-gray-100 a:not(.btn), .text-gray-100 h1, .text-gray-100 h2, .text-gray-100 h3, .text-gray-100 h4, .text-gray-100 h5, .text-gray-100 h6, .text-gray-100 .h1, .text-gray-100 .h2, .text-gray-100 .h3, .text-gray-100 .h4, .text-gray-100 .h5, .text-gray-100 .h6, .text-gray-100 .display-1, .text-gray-100 .display-2, .text-gray-100 .display-3, .text-gray-100 .display-4 {
  color: #f3f7fa;
}
.text-gray-100 .text-muted {
  color: rgba(243, 247, 250, 0.7) !important;
}

.text-gray-200, .text-gray-200:hover {
  color: #f5f8fb !important;
}
.text-gray-200 a:not(.btn), .text-gray-200 h1, .text-gray-200 h2, .text-gray-200 h3, .text-gray-200 h4, .text-gray-200 h5, .text-gray-200 h6, .text-gray-200 .h1, .text-gray-200 .h2, .text-gray-200 .h3, .text-gray-200 .h4, .text-gray-200 .h5, .text-gray-200 .h6, .text-gray-200 .display-1, .text-gray-200 .display-2, .text-gray-200 .display-3, .text-gray-200 .display-4 {
  color: #f5f8fb;
}
.text-gray-200 .text-muted {
  color: rgba(245, 248, 251, 0.7) !important;
}

.text-gray-300, .text-gray-300:hover {
  color: #F0F3F6 !important;
}
.text-gray-300 a:not(.btn), .text-gray-300 h1, .text-gray-300 h2, .text-gray-300 h3, .text-gray-300 h4, .text-gray-300 h5, .text-gray-300 h6, .text-gray-300 .h1, .text-gray-300 .h2, .text-gray-300 .h3, .text-gray-300 .h4, .text-gray-300 .h5, .text-gray-300 .h6, .text-gray-300 .display-1, .text-gray-300 .display-2, .text-gray-300 .display-3, .text-gray-300 .display-4 {
  color: #F0F3F6;
}
.text-gray-300 .text-muted {
  color: rgba(240, 243, 246, 0.7) !important;
}

.text-gray-400, .text-gray-400:hover {
  color: #eaedf2 !important;
}
.text-gray-400 a:not(.btn), .text-gray-400 h1, .text-gray-400 h2, .text-gray-400 h3, .text-gray-400 h4, .text-gray-400 h5, .text-gray-400 h6, .text-gray-400 .h1, .text-gray-400 .h2, .text-gray-400 .h3, .text-gray-400 .h4, .text-gray-400 .h5, .text-gray-400 .h6, .text-gray-400 .display-1, .text-gray-400 .display-2, .text-gray-400 .display-3, .text-gray-400 .display-4 {
  color: #eaedf2;
}
.text-gray-400 .text-muted {
  color: rgba(234, 237, 242, 0.7) !important;
}

.text-gray-500, .text-gray-500:hover {
  color: #d1d7e0 !important;
}
.text-gray-500 a:not(.btn), .text-gray-500 h1, .text-gray-500 h2, .text-gray-500 h3, .text-gray-500 h4, .text-gray-500 h5, .text-gray-500 h6, .text-gray-500 .h1, .text-gray-500 .h2, .text-gray-500 .h3, .text-gray-500 .h4, .text-gray-500 .h5, .text-gray-500 .h6, .text-gray-500 .display-1, .text-gray-500 .display-2, .text-gray-500 .display-3, .text-gray-500 .display-4 {
  color: #d1d7e0;
}
.text-gray-500 .text-muted {
  color: rgba(209, 215, 224, 0.7) !important;
}

.text-gray-600, .text-gray-600:hover {
  color: #93a5be !important;
}
.text-gray-600 a:not(.btn), .text-gray-600 h1, .text-gray-600 h2, .text-gray-600 h3, .text-gray-600 h4, .text-gray-600 h5, .text-gray-600 h6, .text-gray-600 .h1, .text-gray-600 .h2, .text-gray-600 .h3, .text-gray-600 .h4, .text-gray-600 .h5, .text-gray-600 .h6, .text-gray-600 .display-1, .text-gray-600 .display-2, .text-gray-600 .display-3, .text-gray-600 .display-4 {
  color: #93a5be;
}
.text-gray-600 .text-muted {
  color: rgba(147, 165, 190, 0.7) !important;
}

.text-gray-700, .text-gray-700:hover {
  color: #66799e !important;
}
.text-gray-700 a:not(.btn), .text-gray-700 h1, .text-gray-700 h2, .text-gray-700 h3, .text-gray-700 h4, .text-gray-700 h5, .text-gray-700 h6, .text-gray-700 .h1, .text-gray-700 .h2, .text-gray-700 .h3, .text-gray-700 .h4, .text-gray-700 .h5, .text-gray-700 .h6, .text-gray-700 .display-1, .text-gray-700 .display-2, .text-gray-700 .display-3, .text-gray-700 .display-4 {
  color: #66799e;
}
.text-gray-700 .text-muted {
  color: rgba(102, 121, 158, 0.7) !important;
}

.text-gray-800, .text-gray-800:hover {
  color: #506690 !important;
}
.text-gray-800 a:not(.btn), .text-gray-800 h1, .text-gray-800 h2, .text-gray-800 h3, .text-gray-800 h4, .text-gray-800 h5, .text-gray-800 h6, .text-gray-800 .h1, .text-gray-800 .h2, .text-gray-800 .h3, .text-gray-800 .h4, .text-gray-800 .h5, .text-gray-800 .h6, .text-gray-800 .display-1, .text-gray-800 .display-2, .text-gray-800 .display-3, .text-gray-800 .display-4 {
  color: #506690;
}
.text-gray-800 .text-muted {
  color: rgba(80, 102, 144, 0.7) !important;
}

.text-facebook, .text-facebook:hover {
  color: #3b5999 !important;
}
.text-facebook a:not(.btn), .text-facebook h1, .text-facebook h2, .text-facebook h3, .text-facebook h4, .text-facebook h5, .text-facebook h6, .text-facebook .h1, .text-facebook .h2, .text-facebook .h3, .text-facebook .h4, .text-facebook .h5, .text-facebook .h6, .text-facebook .display-1, .text-facebook .display-2, .text-facebook .display-3, .text-facebook .display-4 {
  color: #3b5999;
}
.text-facebook .text-muted {
  color: rgba(59, 89, 153, 0.7) !important;
}

.text-twitter, .text-twitter:hover {
  color: #1da1f2 !important;
}
.text-twitter a:not(.btn), .text-twitter h1, .text-twitter h2, .text-twitter h3, .text-twitter h4, .text-twitter h5, .text-twitter h6, .text-twitter .h1, .text-twitter .h2, .text-twitter .h3, .text-twitter .h4, .text-twitter .h5, .text-twitter .h6, .text-twitter .display-1, .text-twitter .display-2, .text-twitter .display-3, .text-twitter .display-4 {
  color: #1da1f2;
}
.text-twitter .text-muted {
  color: rgba(29, 161, 242, 0.7) !important;
}

.text-google, .text-google:hover {
  color: #DB4337 !important;
}
.text-google a:not(.btn), .text-google h1, .text-google h2, .text-google h3, .text-google h4, .text-google h5, .text-google h6, .text-google .h1, .text-google .h2, .text-google .h3, .text-google .h4, .text-google .h5, .text-google .h6, .text-google .display-1, .text-google .display-2, .text-google .display-3, .text-google .display-4 {
  color: #DB4337;
}
.text-google .text-muted {
  color: rgba(219, 67, 55, 0.7) !important;
}

.text-instagram, .text-instagram:hover {
  color: #e4405f !important;
}
.text-instagram a:not(.btn), .text-instagram h1, .text-instagram h2, .text-instagram h3, .text-instagram h4, .text-instagram h5, .text-instagram h6, .text-instagram .h1, .text-instagram .h2, .text-instagram .h3, .text-instagram .h4, .text-instagram .h5, .text-instagram .h6, .text-instagram .display-1, .text-instagram .display-2, .text-instagram .display-3, .text-instagram .display-4 {
  color: #e4405f;
}
.text-instagram .text-muted {
  color: rgba(228, 64, 95, 0.7) !important;
}

.text-pinterest, .text-pinterest:hover {
  color: #bd081c !important;
}
.text-pinterest a:not(.btn), .text-pinterest h1, .text-pinterest h2, .text-pinterest h3, .text-pinterest h4, .text-pinterest h5, .text-pinterest h6, .text-pinterest .h1, .text-pinterest .h2, .text-pinterest .h3, .text-pinterest .h4, .text-pinterest .h5, .text-pinterest .h6, .text-pinterest .display-1, .text-pinterest .display-2, .text-pinterest .display-3, .text-pinterest .display-4 {
  color: #bd081c;
}
.text-pinterest .text-muted {
  color: rgba(189, 8, 28, 0.7) !important;
}

.text-youtube, .text-youtube:hover {
  color: #cd201f !important;
}
.text-youtube a:not(.btn), .text-youtube h1, .text-youtube h2, .text-youtube h3, .text-youtube h4, .text-youtube h5, .text-youtube h6, .text-youtube .h1, .text-youtube .h2, .text-youtube .h3, .text-youtube .h4, .text-youtube .h5, .text-youtube .h6, .text-youtube .display-1, .text-youtube .display-2, .text-youtube .display-3, .text-youtube .display-4 {
  color: #cd201f;
}
.text-youtube .text-muted {
  color: rgba(205, 32, 31, 0.7) !important;
}

.text-slack, .text-slack:hover {
  color: #3aaf85 !important;
}
.text-slack a:not(.btn), .text-slack h1, .text-slack h2, .text-slack h3, .text-slack h4, .text-slack h5, .text-slack h6, .text-slack .h1, .text-slack .h2, .text-slack .h3, .text-slack .h4, .text-slack .h5, .text-slack .h6, .text-slack .display-1, .text-slack .display-2, .text-slack .display-3, .text-slack .display-4 {
  color: #3aaf85;
}
.text-slack .text-muted {
  color: rgba(58, 175, 133, 0.7) !important;
}

.text-dribbble, .text-dribbble:hover {
  color: #ea4c89 !important;
}
.text-dribbble a:not(.btn), .text-dribbble h1, .text-dribbble h2, .text-dribbble h3, .text-dribbble h4, .text-dribbble h5, .text-dribbble h6, .text-dribbble .h1, .text-dribbble .h2, .text-dribbble .h3, .text-dribbble .h4, .text-dribbble .h5, .text-dribbble .h6, .text-dribbble .display-1, .text-dribbble .display-2, .text-dribbble .display-3, .text-dribbble .display-4 {
  color: #ea4c89;
}
.text-dribbble .text-muted {
  color: rgba(234, 76, 137, 0.7) !important;
}

.text-dropbox, .text-dropbox:hover {
  color: #1E90FF !important;
}
.text-dropbox a:not(.btn), .text-dropbox h1, .text-dropbox h2, .text-dropbox h3, .text-dropbox h4, .text-dropbox h5, .text-dropbox h6, .text-dropbox .h1, .text-dropbox .h2, .text-dropbox .h3, .text-dropbox .h4, .text-dropbox .h5, .text-dropbox .h6, .text-dropbox .display-1, .text-dropbox .display-2, .text-dropbox .display-3, .text-dropbox .display-4 {
  color: #1E90FF;
}
.text-dropbox .text-muted {
  color: rgba(30, 144, 255, 0.7) !important;
}

.text-twitch, .text-twitch:hover {
  color: #4B367C !important;
}
.text-twitch a:not(.btn), .text-twitch h1, .text-twitch h2, .text-twitch h3, .text-twitch h4, .text-twitch h5, .text-twitch h6, .text-twitch .h1, .text-twitch .h2, .text-twitch .h3, .text-twitch .h4, .text-twitch .h5, .text-twitch .h6, .text-twitch .display-1, .text-twitch .display-2, .text-twitch .display-3, .text-twitch .display-4 {
  color: #4B367C;
}
.text-twitch .text-muted {
  color: rgba(75, 54, 124, 0.7) !important;
}

.text-paypal, .text-paypal:hover {
  color: #ecb32c !important;
}
.text-paypal a:not(.btn), .text-paypal h1, .text-paypal h2, .text-paypal h3, .text-paypal h4, .text-paypal h5, .text-paypal h6, .text-paypal .h1, .text-paypal .h2, .text-paypal .h3, .text-paypal .h4, .text-paypal .h5, .text-paypal .h6, .text-paypal .display-1, .text-paypal .display-2, .text-paypal .display-3, .text-paypal .display-4 {
  color: #ecb32c;
}
.text-paypal .text-muted {
  color: rgba(236, 179, 44, 0.7) !important;
}

.text-behance, .text-behance:hover {
  color: #0057ff !important;
}
.text-behance a:not(.btn), .text-behance h1, .text-behance h2, .text-behance h3, .text-behance h4, .text-behance h5, .text-behance h6, .text-behance .h1, .text-behance .h2, .text-behance .h3, .text-behance .h4, .text-behance .h5, .text-behance .h6, .text-behance .display-1, .text-behance .display-2, .text-behance .display-3, .text-behance .display-4 {
  color: #0057ff;
}
.text-behance .text-muted {
  color: rgba(0, 87, 255, 0.7) !important;
}

.text-reddit, .text-reddit:hover {
  color: #E84422 !important;
}
.text-reddit a:not(.btn), .text-reddit h1, .text-reddit h2, .text-reddit h3, .text-reddit h4, .text-reddit h5, .text-reddit h6, .text-reddit .h1, .text-reddit .h2, .text-reddit .h3, .text-reddit .h4, .text-reddit .h5, .text-reddit .h6, .text-reddit .display-1, .text-reddit .display-2, .text-reddit .display-3, .text-reddit .display-4 {
  color: #E84422;
}
.text-reddit .text-muted {
  color: rgba(232, 68, 34, 0.7) !important;
}

.text-github, .text-github:hover {
  color: #222222 !important;
}
.text-github a:not(.btn), .text-github h1, .text-github h2, .text-github h3, .text-github h4, .text-github h5, .text-github h6, .text-github .h1, .text-github .h2, .text-github .h3, .text-github .h4, .text-github .h5, .text-github .h6, .text-github .display-1, .text-github .display-2, .text-github .display-3, .text-github .display-4 {
  color: #222222;
}
.text-github .text-muted {
  color: rgba(34, 34, 34, 0.7) !important;
}

.text-adobe, .text-adobe:hover {
  color: #ff9a00 !important;
}
.text-adobe a:not(.btn), .text-adobe h1, .text-adobe h2, .text-adobe h3, .text-adobe h4, .text-adobe h5, .text-adobe h6, .text-adobe .h1, .text-adobe .h2, .text-adobe .h3, .text-adobe .h4, .text-adobe .h5, .text-adobe .h6, .text-adobe .display-1, .text-adobe .display-2, .text-adobe .display-3, .text-adobe .display-4 {
  color: #ff9a00;
}
.text-adobe .text-muted {
  color: rgba(255, 154, 0, 0.7) !important;
}

.text-bootstrap, .text-bootstrap:hover {
  color: #7C277D !important;
}
.text-bootstrap a:not(.btn), .text-bootstrap h1, .text-bootstrap h2, .text-bootstrap h3, .text-bootstrap h4, .text-bootstrap h5, .text-bootstrap h6, .text-bootstrap .h1, .text-bootstrap .h2, .text-bootstrap .h3, .text-bootstrap .h4, .text-bootstrap .h5, .text-bootstrap .h6, .text-bootstrap .display-1, .text-bootstrap .display-2, .text-bootstrap .display-3, .text-bootstrap .display-4 {
  color: #7C277D;
}
.text-bootstrap .text-muted {
  color: rgba(124, 39, 125, 0.7) !important;
}

.text-python, .text-python:hover {
  color: #306998 !important;
}
.text-python a:not(.btn), .text-python h1, .text-python h2, .text-python h3, .text-python h4, .text-python h5, .text-python h6, .text-python .h1, .text-python .h2, .text-python .h3, .text-python .h4, .text-python .h5, .text-python .h6, .text-python .display-1, .text-python .display-2, .text-python .display-3, .text-python .display-4 {
  color: #306998;
}
.text-python .text-muted {
  color: rgba(48, 105, 152, 0.7) !important;
}

.text-react, .text-react:hover {
  color: #00d8ff !important;
}
.text-react a:not(.btn), .text-react h1, .text-react h2, .text-react h3, .text-react h4, .text-react h5, .text-react h6, .text-react .h1, .text-react .h2, .text-react .h3, .text-react .h4, .text-react .h5, .text-react .h6, .text-react .display-1, .text-react .display-2, .text-react .display-3, .text-react .display-4 {
  color: #00d8ff;
}
.text-react .text-muted {
  color: rgba(0, 216, 255, 0.7) !important;
}

.text-azure, .text-azure:hover {
  color: #2892df !important;
}
.text-azure a:not(.btn), .text-azure h1, .text-azure h2, .text-azure h3, .text-azure h4, .text-azure h5, .text-azure h6, .text-azure .h1, .text-azure .h2, .text-azure .h3, .text-azure .h4, .text-azure .h5, .text-azure .h6, .text-azure .display-1, .text-azure .display-2, .text-azure .display-3, .text-azure .display-4 {
  color: #2892df;
}
.text-azure .text-muted {
  color: rgba(40, 146, 223, 0.7) !important;
}

.text-js, .text-js:hover {
  color: #EFD81F !important;
}
.text-js a:not(.btn), .text-js h1, .text-js h2, .text-js h3, .text-js h4, .text-js h5, .text-js h6, .text-js .h1, .text-js .h2, .text-js .h3, .text-js .h4, .text-js .h5, .text-js .h6, .text-js .display-1, .text-js .display-2, .text-js .display-3, .text-js .display-4 {
  color: #EFD81F;
}
.text-js .text-muted {
  color: rgba(239, 216, 31, 0.7) !important;
}

.text-swe, .text-swe:hover {
  color: #FFCF3B !important;
}
.text-swe a:not(.btn), .text-swe h1, .text-swe h2, .text-swe h3, .text-swe h4, .text-swe h5, .text-swe h6, .text-swe .h1, .text-swe .h2, .text-swe .h3, .text-swe .h4, .text-swe .h5, .text-swe .h6, .text-swe .display-1, .text-swe .display-2, .text-swe .display-3, .text-swe .display-4 {
  color: #FFCF3B;
}
.text-swe .text-muted {
  color: rgba(255, 207, 59, 0.7) !important;
}

article p, article ul, article ol, article blockquote {
  margin-bottom: 10px;
}
article p, article ul li, article ol li {
  font-size: 1.1rem;
}
article h1, article h2, article h3, article h4, article h5, article h6,
article .h1, article .h2, article .h3, article .h4, article .h5, article .h6 {
  margin-bottom: 10px;
}

.folder-structure li {
  font-size: 1.2rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hero-title {
  font-size: 3rem;
}
@media (min-width: 992px) {
  .hero-title {
    font-size: 4.275rem;
  }
}

p a, .docs-list li a {
  color: #0948B3;
  text-decoration: underline;
}
p a:hover, .docs-list li a:hover {
  color: #0948B3;
  text-decoration: none;
}

.code-editor-error pre {
  margin-bottom: 0;
}

/**
 * = Timelines
 */
.list-group-timeline .list-group-item {
  position: relative;
}
.list-group-timeline .list-group-item .col-auto {
  z-index: 999;
}
.list-group-timeline .list-group-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 1rem;
  left: 2.5rem;
  height: 100%;
  border-left: 1px solid #eaedf2;
}

.tooltip {
  margin-bottom: 0.375rem;
}

.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #f5f8fb;
  border: 1px solid #eaedf2;
  border-radius: 0.5rem;
  color: #262B40;
  font-weight: 600;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease;
}

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #f5f8fb;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area, .ct-line {
  pointer-events: none;
}

.preloader {
  display: flex;
  align-items: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: height 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .preloader {
    transition: none;
  }
}
.preloader .loader-element {
  transition: spin 0.3s linear infinite;
  animation: spin 3s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .preloader .loader-element {
    transition: none;
  }
}
.preloader.show {
  height: 0;
}
.preloader.show .loader-element {
  display: none !important;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
[data-simplebar] {
  position: fixed;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s 0.5s linear;
}

.simplebar-scrolling .simplebar-scrollbar:before,
.simplebar-hover .simplebar-scrollbar:before,
.simplebar-mouse-entered .simplebar-scrollbar:before {
  opacity: 0.5;
  transition-delay: 0s;
  transition-duration: 0s;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition-delay: 0s;
  transition-duration: 0s;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar::before {
  background-color: #131620;
}/*# sourceMappingURL=index.css.map */